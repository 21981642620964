import React, { useMemo, useState, useRef, useLayoutEffect, useCallback } from 'react'
import { Box } from '@mui/material'
import { BarStack } from '@visx/shape'
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale'
import { AxisBottom, AxisLeft, AxisScale, TickRendererProps } from '@visx/axis'
import { LegendOrdinal } from '@visx/legend'
import { Tooltip, defaultStyles } from '@visx/tooltip'
import { animated, useSpring, to } from '@react-spring/web'
import { Colors, Fonts } from '../../../Utils/theme'
import { SxProps, Theme } from '@mui/material/styles'

// Constants for animation configurations
const ANIMATION_DURATION = 500

// Helper function to convert rem to pixels dynamically
const remToPixels = (rem: number): number => {
  const rootFontSize = parseFloat(
    getComputedStyle(document.documentElement).fontSize
  )
  return rem * rootFontSize
}

// Styles object for MUI specific components
const muiStyles: { [key: string]: SxProps<Theme> } = {
  container: {
    width: '100%',
    height: 'calc(100% - 2.5rem)',
    position: 'relative',
    // border: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  euroSign: {
    position: 'absolute',
    top: '0.75rem',
    left: '2.75rem',
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  yearLabel: {
    position: 'absolute',
    top: '1.25rem',
    alignSelf: 'center',
    fontSize: '1.125rem',
    fontWeight: 'bold',
  },
  legend: {
    display: 'flex',
    fontSize: '1.125rem',
    fontFamily: Fonts.body,
    opacity: 1,
    visibility: 'visible',
  },
}

// Regular CSS styles object for Tooltip and other SVG elements
const cssStyles = {
  tooltip: {
    ...defaultStyles,
    position: 'fixed',
    transform: 'translate(-50%, calc(-100% - 1rem))',
    background: Colors.primaryDarker,
    color: Colors.white,
    padding: '0.75rem 1rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    fontFamily: Fonts.body,
    maxWidth: '13rem',
    textAlign: 'center',
    zIndex: 1000,
    pointerEvents: 'none',
  } as React.CSSProperties,
  tooltipTitle: {
    fontSize: '1.125rem',
    fontFamily: Fonts.body,
  } as React.CSSProperties,
  tooltipContent: {
    marginTop: '0.5rem',
    fontSize: '1rem',
  } as React.CSSProperties,
  axisTick: {
    fill: Colors.text,
    fontSize: '1rem',
    fontFamily: Fonts.body,
    textAnchor: 'end' as const,
    dx: '-0.5rem',
    dy: '0.32em',
  },
  bottomTickLabel: () => ({
    fill: Colors.text,
    fontSize: '1rem',
    fontFamily: Fonts.body,
    textAnchor: 'middle' as const,
    dy: '0.5rem',
    dx: '0'
  }),
}

// BarData represents each bar in the chart
type BarData = {
  x: string | number
  [key: string]: number | string
}

interface CostBarChartProps {
  data: {
    id: string
    color: string
    data: {
      x: number
      y: number
    }[]
  }[]
  colors: string[]
  keys: string[]
  year: number
  isYearly?: boolean
}

/*
// Format month names based on index
const getFormattedMonth = (index: string | number) => {
  const month = DateTime.fromObject({ month: parseInt(index.toString()) })
    .setLocale('fi')
    .toFormat('LLLL')
  return upperFirst(month)
}
*/

const formatXAxisValue = (value: string | number, isYearly: boolean, currentYear: number) => {
  if (isYearly) {
    const numValue = parseInt(value.toString())
    return (currentYear - 10 + (numValue - 1)).toString()
  }
  return value.toString().padStart(2, '0')
}

// Transform line chart data into bar chart format
const transformToBarData = (
  data: {
    id: string
    data: {
      x: number
      y: number
    }[]
  }[],
  keys: string[],
  isYearly: boolean = false
): BarData[] => {
  if (!data || data.length === 0) return []

  const transformedData: BarData[] = []

  data[0].data.forEach((point, index) => {
    // Format x value based on whether it's yearly or monthly
    const xValue = isYearly 
      // Use 1-12 for yearly data
      ? (index + 1).toString()
      // Keep month formatting for monthly data
      : point.x.toString().padStart(2, '0')

    const barDataEntry: BarData = { x: xValue }

    // Make sure we get data from all series
    data.forEach((series) => {
      if (series.data[index]) {
        barDataEntry[series.id] = series.data[index].y
      } else {
        // Set default value if data point doesn't exist
        barDataEntry[series.id] = 0
      }
    })

    transformedData.push(barDataEntry)
  })

  return transformedData
}

// Function to create path with rounded top corners
const createRoundedTopRectPath = (
  x: number,
  y: number,
  width: number,
  height: number
) => {
  const radius = Math.min(width / 2, height)
  if (radius === 0) {
    return `
      M${x},${y + height}
      L${x},${y}
      L${x + width},${y}
      L${x + width},${y + height}
      Z
    `
  }
  return `
    M${x},${y + height}
    L${x},${y + radius}
    Q${x},${y} ${x + radius},${y}
    L${x + width - radius},${y}
    Q${x + width},${y} ${x + width},${y + radius}
    L${x + width},${y + height}
    Z
  `
}

interface BarChartTooltipProps {
  tooltipData: { key: string; value: number; xValue: string }
  tooltipLeft: number
  tooltipTop: number
  year: number
  isYearly: boolean
  getTotalMonthlyCost: (x: number) => number
}

// Tooltip for rendering bar details (on hover)
const BarChartTooltip: React.FC<BarChartTooltipProps> = ({
  tooltipData,
  tooltipLeft,
  tooltipTop,
  year,
  isYearly,
  getTotalMonthlyCost,
}) => {
  const formatTooltipDate = () => {
    if (isYearly) {
      return null
    }
    return `${tooltipData.xValue}/${year}`
  }

  return (
    <Tooltip top={tooltipTop} left={tooltipLeft} style={cssStyles.tooltip}>
      <div>
        <strong style={cssStyles.tooltipTitle}>{tooltipData.key}</strong>
        <div style={cssStyles.tooltipContent}>
          {formatTooltipDate()} {tooltipData.value.toLocaleString('fi-FI', { maximumFractionDigits: 2 })} €
        </div>
        <div style={cssStyles.tooltipContent}>
          Kulut yht.{' '}
          {getTotalMonthlyCost(parseInt(tooltipData.xValue))?.toLocaleString('fi-FI', { maximumFractionDigits: 2 })} €
        </div>
      </div>
    </Tooltip>
  )
}

interface AnimatedBarProps {
  x: number
  y: number
  width: number
  height: number
  fill: string
  innerHeight: number
  onMouseMove: React.MouseEventHandler<SVGRectElement>
  onMouseLeave: React.MouseEventHandler<SVGRectElement>
}

// Animated bar component
const AnimatedBar: React.FC<AnimatedBarProps> = ({
  x,
  y,
  width,
  height,
  fill,
  innerHeight,
  onMouseMove,
  onMouseLeave,
}) => {
  const springProps = useSpring({
    from: { x, y: innerHeight, width, height: 0 },
    to: { x, y, width, height },
    config: { duration: ANIMATION_DURATION },
  })

  return (
    <animated.rect
      x={springProps.x}
      y={springProps.y}
      width={springProps.width}
      height={springProps.height}
      fill={fill}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    />
  )
}

interface AnimatedPathProps {
  x: number
  y: number
  width: number
  height: number
  fill: string
  innerHeight: number
  onMouseMove: React.MouseEventHandler<SVGPathElement>
  onMouseLeave: React.MouseEventHandler<SVGPathElement>
}

// Animated path component
const AnimatedPath: React.FC<AnimatedPathProps> = ({
  x,
  y,
  width,
  height,
  fill,
  innerHeight,
  onMouseMove,
  onMouseLeave,
}) => {
  const springProps = useSpring({
    from: { x, y: innerHeight, width, height: 0 },
    to: { x, y, width, height },
    config: { duration: ANIMATION_DURATION },
  })

  const path = to(
    [springProps.x, springProps.y, springProps.width, springProps.height],
    (x, y, width, height) => createRoundedTopRectPath(x, y, width, height)
  )

  return (
    <animated.path
      d={path}
      fill={fill}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    />
  )
}

// Animated Tick component for Axis
interface AnimatedTickProps {
  x: number
  y: number
  formattedValue?: string | number
  opacity: number
}

// Animated tick component for rendering axis ticks
const AnimatedTick: React.FC<AnimatedTickProps> = ({
  x,
  y,
  formattedValue,
  opacity,
}) => {
  const springProps = useSpring({
    to: { x, y, opacity },
    config: { duration: ANIMATION_DURATION },
  })

  return (
    <animated.g
      transform={to(
        [springProps.x, springProps.y],
        (x, y) => `translate(${x}, ${y})`
      )}
    >
      <animated.text style={cssStyles.axisTick} opacity={springProps.opacity}>
        {(formattedValue ?? '').toString()}
      </animated.text>
    </animated.g>
  )
}

interface AnimatedAxisLeftProps {
  scale: AxisScale<number>
  left?: number
  top?: number
}

// AnimatedAxisLeft component for rendering animated axis ticks on the left
const AnimatedAxisLeft: React.FC<AnimatedAxisLeftProps> = ({
  scale,
  left = 0,
  top = 0,
}) => {
  const formatTickValue = (value: number | { valueOf(): number }) =>
    new Intl.NumberFormat('fi-FI').format(value.valueOf())

  return (
    <AxisLeft
      top={top}
      left={left}
      scale={scale}
      tickFormat={formatTickValue}
      tickComponent={(tickProps: TickRendererProps) => {
        const { x = 0, y = 0, formattedValue } = tickProps
        return (
          <AnimatedTick
            x={x}
            y={y}
            formattedValue={formattedValue}
            opacity={1}
          />
        )
      }}
      hideTicks
      hideAxisLine
      tickLabelProps={() => cssStyles.axisTick}
    />
  )
}

// Main VisxCostBarChart component
const CostBarChart: React.FC<CostBarChartProps> = ({
  data,
  colors,
  keys,
  year = 2024,
  isYearly = false,
}) => {
  const barData = useMemo(
    () => transformToBarData(data, keys, isYearly), 
    [data, keys, isYearly]
  )
  const [width, setWidth] = useState<number>(0)
  const [height, setHeight] = useState<number>(0)
  const [dimensionsReady, setDimensionsReady] = useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const boundingRect = containerRef.current.getBoundingClientRect()
        setWidth(boundingRect.width)
        setHeight(boundingRect.height)
        setDimensionsReady(true)
      }
    }

    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  const margin = {
    top: remToPixels(3.25),
    right: remToPixels(3),
    bottom: remToPixels(3),
    left: remToPixels(5),
  }

  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom

  // Update year label to show range in yearly mode
  const yearLabel = useMemo(() => {
    if (isYearly) {
      return `${year - 10} - ${year + 1}`
    }
    return year.toString()
  }, [year, isYearly])

  const xScale = useMemo(() => {
    return scaleBand<string>({
      domain: barData.map((d) => d.x as string),
      range: [0, innerWidth],
      paddingInner: 0.5,
      paddingOuter: 0.25,
      align: 0.5 
    })
  }, [barData, innerWidth])

  const maxValue = useMemo(() => {
    if (barData.length > 0) {
      const calculatedMax = Math.max(
        ...barData.map((d) => {
          return keys.reduce((acc, key) => {
            return acc + (d[key] as number)
          }, 0)
        })
      )
      return calculatedMax > 0 && isFinite(calculatedMax) ? calculatedMax : 20000
    }
    return 20000
  }, [barData, keys])

  const yScale = useMemo(() => {
    return scaleLinear<number>({
      domain: [0, maxValue],
      range: [innerHeight, 0],
      nice: true,
    })
  }, [maxValue, innerHeight])

  const colorScale = useMemo(() => {
    return scaleOrdinal<string, string>({
      domain: keys,
      range: colors,
    })
  }, [keys, colors])

  const legendScale = useMemo(() => {
    return scaleOrdinal<string, string>({
      domain: [...keys].reverse(),
      range: [...colors].reverse(),
    })
  }, [keys, colors])

  const [tooltipData, setTooltipData] = useState<{
    key: string
    value: number
    xValue: string
  } | null>(null)
  const [tooltipLeft, setTooltipLeft] = useState<number>(0)
  const [tooltipTop, setTooltipTop] = useState<number>(0)

  const getTotalMonthlyCost = (x: number): number => {
    return barData
      .filter((d) => d.x === x.toString().padStart(2, '0'))
      .reduce((acc, d) => {
        return keys.reduce((accInner, key) => accInner + (d[key] as number), acc)
      }, 0)
  }

  const handleMouseMove = useCallback(
    (event: React.MouseEvent, barKey: string, barValue: number, xValue: string) => {
      setTooltipLeft(event.clientX)
      setTooltipTop(event.clientY)
      setTooltipData({
        key: barKey,
        value: barValue,
        xValue,
      })
    },
    []
  )

  const handleMouseLeave = useCallback(() => {
    setTooltipData(null)
  }, [])

  return (
    <Box ref={containerRef} sx={muiStyles.container}>
      <Box sx={muiStyles.euroSign}>€</Box>
      <Box sx={muiStyles.yearLabel}>{yearLabel}</Box>
      {dimensionsReady && (
        <svg
          width={width}
          height={height}
          role='img'
          aria-label='Kustannuskehityksen pylväskuvaaja'
          style={{ overflow: 'visible' }}
        >
          <g transform={`translate(${margin.left},${margin.top})`}>
            <AnimatedAxisLeft scale={yScale} left={0} top={0} />
            <AxisBottom
              top={innerHeight}
              scale={xScale}
              tickFormat={(value: any) => formatXAxisValue(value, isYearly, year)}
              hideTicks
              hideAxisLine
              tickLabelProps={cssStyles.bottomTickLabel}
            />
            {barData.length > 0 && (
              <BarStack
                data={barData}
                keys={keys}
                x={(d) => d.x as string}
                xScale={xScale}
                yScale={yScale}
                color={colorScale}
              >
                {(barStacks) =>
                  barStacks.map((barStack) =>
                    barStack.bars.map((bar) => {
                      const isTopBar = bar.key === keys[keys.length - 1]
                      const barX = bar.x + remToPixels(0.5)
                      const barWidth = bar.width - remToPixels(1)
                      const barY = bar.y
                      const barHeight = bar.height
                      const adjustedBarY = Math.min(barY, innerHeight)
                      const adjustedBarHeight =
                        barY + barHeight > innerHeight
                          ? innerHeight - barY
                          : barHeight

                      const commonProps = {
                        x: barX,
                        y: adjustedBarY,
                        width: barWidth,
                        height: adjustedBarHeight,
                        fill: bar.color,
                        innerHeight,
                        onMouseMove: (event: React.MouseEvent) => {
                          handleMouseMove(
                            event,
                            bar.key,
                            bar.bar.data[bar.key] as number,
                            bar.bar.data.x as string
                          )
                        },
                        onMouseLeave: handleMouseLeave,
                      }

                      if (isTopBar) {
                        return (
                          <AnimatedPath
                            key={`bar-stack-${barStack.index}-${bar.index}`}
                            {...commonProps}
                          />
                        )
                      } else {
                        return (
                          <AnimatedBar
                            key={`bar-stack-${barStack.index}-${bar.index}`}
                            {...commonProps}
                          />
                        )
                      }
                    })
                  )
                }
              </BarStack>
            )}
          </g>
        </svg>
      )}
      <LegendOrdinal
        scale={legendScale}
        direction='row'
        labelMargin='0 30px 0 0'
        shape='circle'
        shapeMargin='0 5px 0 0'
        labelFormat={(label: string) => label}
        style={muiStyles.legend as React.CSSProperties}
      />
      {tooltipData && (
        <BarChartTooltip
          tooltipData={tooltipData}
          tooltipLeft={tooltipLeft}
          tooltipTop={tooltipTop}
          year={year}
          isYearly={isYearly}
          getTotalMonthlyCost={getTotalMonthlyCost}
        />
      )}
    </Box>
  )
}

export default CostBarChart
