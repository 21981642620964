import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import EditIcon from '../../Assets/Icons/pencil.svg'
import DefaultProfilePhoto from '../../Assets/Images/user.png'
import DefaultCoverPhoto from '../../Assets/Images/cover.png'
import { Colors } from '../../Utils/theme'

export default function CoverImage(props: any) {
  const { bgPhotoUrl, profile, profilePhotoUrl } = props

  const onUploadProfilePhoto = useCallback((files: any) => {
    if (files && files?.length) {
      const file = files?.[0]
      props.onUpdateProfilePhoto(file)
    }
  }, [])
  const onUploadBackgroundPhoto = useCallback((files: any) => {
    if (files && files?.length) {
      const file = files?.[0]
      props.onUpdateBackgroundPhoto(file)
    }
  }, [])

  const bgDrop = useDropzone({
    accept: {
      'image/jpeg': ['.jpg'],
      'image/png': ['.png']
    },
    multiple: false,
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    onDrop: onUploadBackgroundPhoto
  })

  const profileDrop = useDropzone({
    accept: {
      'image/jpeg': ['.jpg'],
      'image/png': ['.png']
    },
    multiple: false,
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    onDrop: onUploadProfilePhoto
  })

  const renderEditBackgroundPhotoButton = () => {
    if (props?.onUpdateBackgroundPhoto) {
      return (
        <Box {...bgDrop.getRootProps()}>
          <input {...bgDrop.getInputProps()} />
          <ButtonBase sx={styles.editBackgroundButton} onClick={bgDrop.open}>
            <Box sx={styles.editIcon} component='img' src={EditIcon} alt='edit' />
          </ButtonBase>
        </Box>
      )
    }
    return null
  }

  const renderProfilePhotoEditButton = () => {
    if (props?.onUpdateProfilePhoto) {
      return (
        <Box {...profileDrop.getRootProps()}>
          <input {...profileDrop.getInputProps()} />
          <ButtonBase sx={styles.editProfileButton} onClick={profileDrop.open}>
            <Box sx={styles.editIcon} component='img' src={EditIcon} alt='edit' />
          </ButtonBase>
        </Box>
      )
    }
    return null
  }

  const renderProfilePhoto = () => {
    if (profile) {
      return (
        <>
          <Box
            sx={styles.profilePhoto}
            component='img'
            src={profilePhotoUrl || DefaultProfilePhoto}
            alt='profile photo'
          />
          {renderProfilePhotoEditButton()}
        </>
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box
        sx={styles.bgPhoto}
        component='img'
        src={bgPhotoUrl || DefaultCoverPhoto}
        alt='background photo'
        onClick={props?.onClick}
      />
      {renderEditBackgroundPhotoButton()}
      {renderProfilePhoto()}
    </Box>
  )
}

const styles = {
  container: {
    width: '100%',
    position: 'relative'
  },
  bgPhoto: {
    width: '100%',
    objectFit: 'cover',
    borderRadius: '0.625rem',
    backgroundColor: Colors.imageBackground,
    height: {
      xxl: '17rem',
      xs: '15rem'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  profilePhoto: {
    width: '11rem',
    height: '11rem',
    borderRadius: '50%',
    position: 'absolute',
    bottom: '-5rem',
    right: '5rem',
    border: `0.5rem solid ${Colors.white}`,
    backgroundColor: Colors.imageBackground
  },
  editBackgroundButton: {
    position: 'absolute',
    bottom: '1.5rem',
    left: '1.5rem',
    backgroundColor: Colors.button,
    width: '3rem',
    height: '3rem',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: Colors.buttonHover
    }
  },
  editProfileButton: {
    position: 'absolute',
    bottom: '-4.5rem',
    right: '6rem',
    backgroundColor: Colors.button,
    borderRadius: '50%',
    width: '3rem',
    height: '3rem',
    '&:hover': {
      backgroundColor: Colors.buttonHover
    }
  },
  editIcon: {
    width: '1.5rem',
    height: '1.5rem'
  },
  editBackgroundInput: {
    position: 'absolute',
    bottom: '1.5rem',
    left: '1.5rem',
    width: '3rem',
    height: '3rem',
    borderRadius: '50%',
    display: 'none'
  },
  editProfileInput: {
    position: 'absolute',
    bottom: '-4.5rem',
    right: '6rem',
    width: '3rem',
    height: '3rem',
    borderRadius: '50%',
    display: 'none'
  }
} as const