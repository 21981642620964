import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import upperFirst from 'lodash/upperFirst'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextEditor from '../../../Components/Common/TextEditor'
import SquareButton from '../../../Components/Common/SquareButton'
import PopupButton from '../../../Components/Common/PopupButton'
import SidebarCreateActions from '../../../Components/Common/SidebarCreateActions'
import TrashIcon from '../../../Assets/Icons/bin.svg'
import { Button, Input, Select } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import { FinancingTypes, OfferTypes, ProjectTargets, ProjectTypes } from '../../../Utils/constants'

const CreateProject = () => {
  const { projectStore }: any = useStore()
  const { t } = useTranslation()

  const params = useParams()

  const [nameFi, setNameFi] = useState('')
  const [descriptionFi, setDescriptionFi] = useState('')
  const [type, setType] = useState(null)
  const [target, setTarget] = useState(null)
  const [startTime, setStartTime] = useState<any>(null)
  const [endTime, setEndTime] = useState<any>(null)
  const [totalCost, setTotalCost] = useState<any>(null)
  const [loanOfferType, setLoanOfferType] = useState(null)
  const [financingType, setFinancingType] = useState(null)
  const [financier, setFinancier] = useState(null)
  const [loanInterestRate, setLoanInterestRate] = useState(null)
  const [costs, setCosts] = useState<any>([])

  useEffect(() => {
    // TODO: get properties
  }, [])

  const ProjectTypeOptions = Object.values(ProjectTypes).map((option: any) => ({ 
    value: option,
    label: t(option)
  }))
  const ProjectTargetOptions = Object.values(ProjectTargets).map((option: any) => ({
    value: option,
    label: t(option)
  }))
  const LoanOfferTypeOptions = Object.values(OfferTypes).map((option: any) => ({
    value: option,
    label: t(option)
  }))
  const FinancingTypeOptions = Object.values(FinancingTypes).map((option: any) => ({
    value: option,
    label: t(option)
  }))

  const StartTimeOptions = () => {
    const options = []
    for (let i = 2020; i <= 2030; i++) {
      for (let j = 1; j <= 12; j++) {
        options.push({
          value: `${i}-${j > 9 ? j : `0${j}`}-01`,
          label: upperFirst(DateTime
            .fromObject({ year: i, month: j })
            .setLocale('fi')
            .toFormat('LLLL yyyy'))
        })
      }
    }
    return options
  }
  const EndTimeOptions = () => {
    const options = []
    const startYear = Number(startTime?.slice(0, 4))
    for (let i = startYear; i <= 2030; i++) {
      if (i === startYear) {
        const startMonth = Number(startTime?.slice(5, 7))
        for (let j = startMonth; j <= 12; j++) {
          options.push({
            value: `${i}-${j > 9 ? j : `0${j}`}-01`,
            label: upperFirst(DateTime
              .fromObject({ year: i, month: j })
              .setLocale('fi')
              .toFormat('LLLL yyyy'))
          })
        }
      } else {
        for (let j = 1; j <= 12; j++) {
          options.push({
            value: `${i}-${j > 9 ? j : `0${j}`}-01`,
            label: upperFirst(DateTime
              .fromObject({ year: i, month: j })
              .setLocale('fi')
              .toFormat('LLLL yyyy'))
          })
        }
      }
    }
    return options
  }

  const CostYearOptions = useMemo(() => {
    const startYear = DateTime.fromISO(startTime).toFormat('yyyy')
    const endYear = DateTime.fromISO(endTime).toFormat('yyyy')
    const years = []
    for (let i = parseInt(startYear); i <= parseInt(endYear); i++) {
      years.push({ value: i, label: `${i}` })
    }
    return years
  }, [startTime, endTime, costs])

  const isSaveDisabled = () => {
    if (!nameFi || !type) return true
    return false
  }

  const addCost = () => {
    setCosts([...costs, { year: null, total: 0 }])
  }

  const removeCost = (index: number) => {
    const newCosts = costs.filter((link: any, i: number) => i !== index)
    setCosts(newCosts)
  }

  const updateCostYear = (value: string, index: number) => {
    const newCosts = [...costs]
    costs[index].year = value
    setCosts(newCosts)
  }

  const updateCostTotal = (value: string, index: number) => {
    const newCosts = [...costs]
    costs[index].total = value
    setCosts(newCosts)
  }

  const create = () => {
    const callback = () => {
      setNameFi('')
      setDescriptionFi('')
      setType(null)
      setTarget(null)
      setStartTime(null)
      setEndTime(null)
      setTotalCost(null)
      setLoanOfferType(null)
      setFinancingType(null)
      setCosts([])
    }
    const newProject = {
      nameFi,
      descriptionFi,
      type,
      target,
      startTime,
      endTime,
      totalCost,
      loanOfferType,
      financingType,
      financier,
      loanInterestRate,
      costs: costs.filter((cost: any) => cost?.year && cost?.total)
    }
    projectStore.createProject(params?.uuid, newProject, callback)
  }
  // const cancel = () => navigate(-1)

  const renderCosts = () => {
    return costs.map((cost: any, index: number) => (
      <Box key={index} sx={styles.row}>
        <Select
          label={t('year')}
          options={CostYearOptions}
          value={cost?.year || ''}
          onChange={(value: any) => updateCostYear(value, index)}
          maxWidth='15rem'
          mr='1rem'
        />
        <Input
          unit='€'
          label={t('amount_of_yearly_costs')}
          value={cost?.total || 0}
          onChange={(value: any) => updateCostTotal(value, index)}
          mr='1rem'
        />
        <Box>
          <SquareButton
            icon={TrashIcon}
            onClick={() => removeCost(index)}
            size='3.125rem'
            iconSize='1.5rem'
            hoverColor={Colors.primary}
            mt='1.75rem'
          />
        </Box>
      </Box>
    ))
  }

  const renderCostsInfo = () => {
    const cumulativeCosts = costs.reduce((acc: number, cost: any) => acc + Number(cost?.total), 0)
    const totalCosts = Number(totalCost) || 0

    if (cumulativeCosts !== totalCosts) {
      return <PopupButton text={t('total_yearly_costs_mismatch')} buttonSx={styles.popup} />
    }

    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h1' sx={styles.title}>{t('add_new_project')}</Typography>
        <Typography variant='h3' mt='1rem' mb='1rem'>{t('project_basic_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Input
            label={t('project_name')}
            value={nameFi}
            onChange={setNameFi}
            mb='1.5rem'
          />
          <Box sx={styles.row}>
            <Select
              label={t('project_type')}
              options={ProjectTypeOptions}
              value={type}
              onChange={setType}
              mr='2rem'
            />
            <Select
              label={t('project_subject')}
              options={ProjectTargetOptions}
              value={target}
              onChange={setTarget}
            />
          </Box>
          <Box sx={styles.row}>
            <Select
              label={t('project_duration')}
              options={StartTimeOptions()}
              value={startTime}
              onChange={setStartTime}
            />
            <Box component='span' sx={styles.divider}>-</Box>
            <Select
              options={EndTimeOptions()}
              value={endTime}
              onChange={setEndTime}
              mt='1.875rem'
              disabled={!startTime}
            />
          </Box>
          <TextEditor
            label={t('project_description')}
            value={descriptionFi}
            onChange={setDescriptionFi}
          />
        </Box>
        <Typography variant='h3' mt='2rem' mb='1rem'>{t('costs_and_financing')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.row}>
            <Input
              unit='€'
              label={t('total_cost')}
              value={totalCost}
              onChange={setTotalCost}
              mr='2rem'
            />
            <Select
              label={t('offer_type')}
              options={LoanOfferTypeOptions}
              value={loanOfferType}
              onChange={setLoanOfferType}
            />
          </Box>
          <Box sx={styles.row}>
            <Select
              label={t('financing')}
              options={FinancingTypeOptions}
              value={financingType}
              onChange={setFinancingType}
              maxWidth='15rem'
              mr='2rem'
            />
            <Input
              label={t('financier')}
              value={financier}
              onChange={setFinancier}
              maxWidth='30rem'
              mr='2rem'
            />
            <Input
              unit='%'
              label={t('loan_interest')}
              value={loanInterestRate}
              onChange={setLoanInterestRate}
              maxWidth='13rem'
            />
          </Box>
        </Box>
        <Typography variant='h3' mt='2rem' mb='1rem'>{t('division_of_costs')}</Typography>
        <Box sx={styles.formContainer}>
          {renderCosts()}
          <Button
            text={t('add_new')}
            onClick={addCost}
            width='11rem'
            mb='1.5rem'
          />
        </Box>
        <Box sx={styles.formContainer} mt='2rem' mb='1rem'>
          <Box sx={styles.row} justifyContent='space-between'>
            <Typography variant='h4'>{t('total_yearly_costs')}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row'  }}>
              <Typography variant='h4'>
                {totalCost ? totalCost?.toLocaleString('fi') : 0}€
              </Typography>
              {renderCostsInfo()}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <SidebarCreateActions
          onSave={create}
          saveText={t('create_project')}
          saveDisabled={isSaveDisabled()}
          mt='7.7325rem'
        />
      </Box>
    </Box>
  )
}

export default observer(CreateProject)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginRight: {
      xl: '3rem',
      lgplus: '2.5rem',
      xs: '2rem'
    }
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  title: {
    borderBottom: `4px solid ${Colors.border}`,
    mt: '0.75rem',
    pb: '1rem',
    mb: '1.25rem'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: {
      lgplus: '2rem 2rem 0.5rem 2rem',
      xs: '1.625rem 1.5rem 0.125rem 1.5rem'
    },
    overflow: 'hidden'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  divider: {
    margin: '0rem 0.75rem',
    marginTop: '1.625rem',
    fontSize: '1.5rem',
    fontWeight: 700,
    color: Colors.text
  },
  rightColumn: {
    position: 'sticky',
    top: '2rem',
    ml: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  },
  popup: {
    ml: '0.875rem'
  }
} as const
