export const validateBusinessId = (businessId: string): boolean => {
  // Ensure the format is correct (7 digits, hyphen, 1 digit)
  const regex = /^[0-9]{7}-[0-9]{1}$/
  if (!regex.test(businessId)) {
    return false
  }

  // Remove hyphen for easier processing
  const digits = businessId.replace('-', '')

  // Convert the first 7 digits to an array of integers
  const baseDigits: number[] = digits.substring(0, 7).split('').map(Number)

  // Get the check digit
  const checkDigit: number = parseInt(digits[7])

  // The weights for each position
  const weights: number[] = [7, 9, 10, 5, 8, 4, 2]

  // Calculate the weighted sum
  let sum: number = 0
  for (let i = 0; i < baseDigits.length; i++) {
      sum += baseDigits[i] * weights[i]
  }

  // Calculate the remainder when divided by 11
  const remainder: number = sum % 11

  // Calculate the expected check digit
  let calculatedCheckDigit: number = 11 - remainder

  // If the remainder is 0, the check digit should be 0, if it's 1 the ID is invalid
  if (calculatedCheckDigit === 11) {
    calculatedCheckDigit = 0
  } else if (calculatedCheckDigit === 10) {
    // Invalid business ID
    return false
  }

  // Compare the calculated check digit with the actual check digit
  return calculatedCheckDigit === checkDigit
}