import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from './Button'
import { Colors } from '../../Utils/theme'

export default function SidebarCreateActions(props: any) {
  const { t } = useTranslation()

  const { saveText, saveDisabled, mt } = props

  const onSave = () => props.onSave()
  // const onCancel = () => props.onCancel()

  return (
    <Box sx={styles.container} mt={mt ?? '8.125rem'}>
      <Box sx={styles.contentContainer}>
        <Typography variant='h5' sx={styles.title}>
          {t('actions')}
        </Typography>
        <Box sx={styles.actionInfo}>
          <Box sx={styles.stateContainer}>
            <Box sx={styles.actionItemColumn}>
              <Typography variant='body1' sx={styles.label}>
                {t('state')}:
              </Typography>
              <Typography variant='body1' color={Colors.text}>
                {saveDisabled? t('information_missing') : t('ready_to_save')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.footer}>
        {/*
        <Button
          text={t('cancel')}
          onClick={onCancel}
          sx={styles.cancelButton}
          mr='1rem'
        />
        */}
        <Button
          text={saveText || t('save')}
          onClick={onSave}
          width='100%'
          disabled={saveDisabled}
        />
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: {
      lgplus: '17rem',
      xs: '14rem'
    },
    maxWidth: {
      lgplus: '17rem',
      xs: '14rem'
    },
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    // p: '2rem 2rem 0.5rem 2rem',
    mb: '1.5rem'
  },
  contentContainer: {
    p: {
      lgplus: '1.5rem 1.5rem 1.5rem 1.5rem',
      xs: '1.125rem 1.125rem 1.125rem 1.125rem'
    }
  },
  title: {
    fontWeight: 800,
    alignSelf: 'flex-start',
    mb: '1rem',
    textTransform: 'uppercase'
  },
  actionInfo: {

  },
  label: {
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.primary,
    mb: '0.0625rem'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem',
    backgroundColor: Colors.secondary10,
    borderTop: `1px solid ${Colors.secondary}` 
  },
  stateContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: '0.375rem'
  },
  actionItemColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  cancelButton: {
    backgroundColor: Colors.transparent,
    color: Colors.primary,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: Colors.transparent,
      textDecoration: 'underline'
    }
  },
} as const