import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
// import LinkIcon from '../../Assets/Icons/link.svg'
import styled from '@emotion/styled'
import { LinkExternal } from '@emotion-icons/boxicons-regular'
import { Colors, Fonts } from '../../Utils/theme'

const LinkIcon = styled(LinkExternal)`
  color: inherit;
  height: 1.5rem;
  width: auto;
  margin-right: 0.25rem;
  @media (max-width: 1200px) {
    width: 0;
  }
`

export default function Tab(props: any) {
  const { text, value, selected } = props

  const getStyle = () => {
    let buttonStyles: any = styles.container
    if (props?.first) {
      buttonStyles = { ...buttonStyles, ...styles.first }
    }
    if (selected) {
      buttonStyles = { ...buttonStyles, ...styles.selected }
    }
    return buttonStyles
  }

  const onClick = () => props.onClick(value)

  const renderCount = () => {
    if (props?.count) {
      return (
        <Box sx={styles.count}>
          <Typography variant='body1'>{props.count}</Typography>
        </Box>
      )
    }
    return null
  }

  const renderLinkIcon = () => {
    if (props?.link) {
      return <LinkIcon />
    }
    return null
  }

  return (
    <ButtonBase onClick={onClick} sx={getStyle()}>
      {renderLinkIcon()}
      {text}
      {renderCount()}
    </ButtonBase>
  )
}

const styles = {
  container: {
    height: '3.5rem',
    borderRight: `1px solid ${Colors.border}`,
    fontFamily: Fonts.body,
    fontSize: {
      xl: '1rem',
      xs: '0.875rem'
    },
    fontWeight: 600,
    color: Colors.inactive,
    textTransform: 'uppercase',
    transition: 'background-color 0.3s',
    padding: {
      xxl: '0 1.5rem',
      xl: '0 1rem',
      xs: '0 0.875rem'
    },
    '&:hover': {
      color: Colors.primary,
      backgroundColor: Colors.secondary20
    }
  },
  first: {
    borderTopLeftRadius: '0.625rem',
    borderBottomLeftRadius: '0.625rem'
  },
  selected: {
    backgroundColor: Colors.primary,
    color: Colors.white,
    fontWeight: 700,
    '&:hover': {
      backgroundColor: Colors.primary90
    }
  },
  count: {
    width: '2rem',
    height: '2rem',
    borderRadius: '50%',
    backgroundColor: Colors.primary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '0.5rem'
  }
} as const