import Box from '@mui/material/Box'
import FormComponent from './FormComponent'
import { ComponentTypes } from '../../Utils/layout'
import { Colors } from '../../Utils/theme'

export default function CustomForm(props: any) {
  const { section, values } = props

  // console.log('LAST_UPDATED_VALUE', props.lastUpdatedValue)

  const onChange = (key: string, value: any) => props.onChange(key, value)

  const renderComponents = () => {
    return (section || []).map((item: any, index: number) => {
      if (item?.type === ComponentTypes.Row) {
        return (
          <Box key={item?.key || item?.label || index} sx={styles.row}>
            {item?.items?.map((rowItem: any, index: number) => (
              <FormComponent
                key={rowItem?.key || rowItem?.label}
                item={rowItem}
                value={values?.[rowItem?.key] || null}
                onChange={onChange}
                shouldUpdate={props.lastUpdatedValue === `${rowItem?.key}_${values?.[rowItem?.key]}`}
                mr={index === item?.items?.length - 1 ? 0 : '1.5rem'}
                disabled={rowItem?.disabled || props?.disabled}
              />
            ))}
          </Box>
        )
      }
      return (
        <FormComponent
          key={item?.key || item?.label || index}
          item={item}
          value={values?.[item?.key] || null}
          onChange={onChange}
          shouldUpdate={props.lastUpdatedValue === `${item?.key}_${values?.[item?.key]}`}
          isFirst={index === 0}
          isLast={index === section?.items?.length - 1}
          disabled={item?.disabled || props?.disabled}
        />
      )
    })
  }

  return <Box sx={styles.formContainer}>{renderComponents()}</Box>
}

const styles = {
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: {
      lgplus: '2rem 2rem 0.5rem 2rem',
      xs: '1.625rem 1.5rem 0.125rem 1.5rem'
    },
    mb: '1.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  }
} as const