import { useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Backdrop from '@mui/material/Backdrop'
import EditIcon from '../../Assets/Icons/edit.svg'
import MenuIcon from '../../Assets/Icons/menu-primary.svg'
import { Colors } from '../../Utils/theme'

export default function Tabs(props: any) {
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const onMenuClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const onMenuClose = () => setAnchorEl(null)

  const renderEditButton = () => {
    if (props?.onEditClick) {
      return (
        <ButtonBase onClick={props.onEditClick} sx={styles.editButton}>
          <Box sx={styles.editIcon} component='img' src={EditIcon} alt='edit' />
          {t('edit')}
        </ButtonBase>
      )
    }
    return null
  }

  const renderMenuItems = () => {
    return props.menuOptions.map((option: any, index: number) => {
      return (
        <MenuItem key={index} onClick={option.onClick}>
          {option.text || '-'}
        </MenuItem>
      )
    })
  }

  const renderMenuButton = () => {
    if (props?.menuOptions) {
      return (
        <>
          <ButtonBase onClick={onMenuClick} sx={styles.menuButton}>
            <Box sx={styles.menuIcon} component='img' src={MenuIcon} alt='menu' />
          </ButtonBase>
          <Backdrop sx={styles.backdrop} open={isMenuOpen} onClick={onMenuClose}>
            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={onMenuClose}
              // anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              {renderMenuItems()}
            </Menu>
          </Backdrop>
        </>
      )
    }
    return null
  }

  return (
    <Box sx={styles.container} mt={props?.mt || 0} mb={props?.mb || 0}>
      <Box sx={styles.tabs}>{props.children}</Box>
      <Box sx={styles.actionsRow}>
        {renderEditButton()}
        {renderMenuButton()}
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    height: '3.5rem',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Colors.secondary10,
    color: Colors.black,
    border: `1px solid ${Colors.border}`,
    borderRadius: '0.625rem'
  },
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflowX: 'auto',
    flex: 1,
    minWidth: 0,
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
  actionsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 0
  },
  editButton: {
    height: '3.5rem',
    borderLeft: `1px solid ${Colors.border}`,
    padding: '0 1.5rem',
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.primary,
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: Colors.secondary20
    }
  },
  editIcon: {
    maxWidth: '1.5rem',
    marginRight: '0.5rem'
  },
  backdrop: {
    backgroundColor: Colors.backdrop,
    zIndex: 1000
  },
  menuButton: {
    width: '4rem',
    height: '3.5rem',
    borderLeft: `1px solid ${Colors.border}`,
    borderTopRightRadius: '0.625rem',
    borderBottomRightRadius: '0.625rem',
    '&:hover': {
      backgroundColor: Colors.secondary20
    }
  },
  menuIcon: {
    maxWidth: '1.5rem'
  }
} as const