import { useMemo } from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Checkbox from './Checkbox'
import NoRowsOverlay from './NoRowsOverlay'
import Typography from '@mui/material/Typography'
import { DataGrid, gridClasses } from '@mui/x-data-grid'
import { Colors } from '../../Utils/theme'

const Table = (props: any) => {
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  
  const ColumnHeaderHeight = useMemo(() => {
    if (lgUp) {
      return 64
    } else if (smUp) {
      return 56
    } else {
      return 48
    }
  }, [lgUp, smUp])

  const RowHeight = useMemo(() => {
    if (lgUp) {
      return 64
    } else if (smUp) {
      return 56
    } else {
      return 48
    }
  }, [lgUp, smUp])

  const columns = useMemo(() => props.columns, [props.columns])
  const rows = useMemo(() => props.rows, [props.rows])
  const getRowClassName = (params: any) => {
    if (params?.indexRelativeToCurrentPage % 2 === 0) {
      return 'even-dg-row'
    }
    return 'odd-dg-row'
  }

  const renderTitle = () => {
    if (props.title) {
      return (
        <Typography
          variant='h3'
          sx={{...styles.title, ...(props.titleSx ?? {})}}
        >
          {props.title ?? ''}
        </Typography>
      )
    }
    return null
  }

  const renderNestedLines = () => {
    if (props?.nested) {
      return (
        <>
          <Box sx={styles.verticalLine} />
          <Box sx={styles.horizontalLine} />
        </>
      )
    }
    return null
  }

  return (
    <Box
      sx={{
        ...styles.tableContainer,
        ...(props?.nested ? styles.nestedTableContainer : {}),
        ...(props.sx ?? {})
      }}
    >
      {renderTitle()}
      {renderNestedLines()}
      <DataGrid
        columns={columns}
        rows={rows}
        sx={styles.table}
        columnHeaderHeight={ColumnHeaderHeight}
        rowHeight={RowHeight}
        getRowClassName={getRowClassName}
        slots={{
          baseCheckbox: Checkbox,
          noRowsOverlay: NoRowsOverlay
        }}
        getRowId={props.getRowId}
        checkboxSelection={!props?.hideCheckboxSelection}
        autoHeight={!props.loading && !rows?.length}
        hideFooter
        disableRowSelectionOnClick
      />
    </Box>
  )
}

export default Table

const styles = {
  tableContainer: {
    width: '100%',
    /*
    maxHeight: {
      lg: 'calc(100vh - 10rem - 2.5rem - 3.125rem)',
      xs: 'calc(100vh - 8.5rem - 2.5rem - 3.125rem)'
    },
    */
    backgroundColor: Colors.white,
    boxShadow: 0
  },
  nestedTableContainer: {
    position: 'relative',
    width: 'calc(100% - 4rem)',
    ml: '4rem',
    mt: '1rem'
  },
  table: {
    boxShadow: 0,
    '&, [class^=MuiDataGrid]': {
      border: 'none'
    },
    '& .MuiDataGrid-cell': {
      fontSize: '1rem',
      color: Colors.heading
    },
    '& .MuiDataGrid-cell:hover': {
    },
    '& .MuiDataGrid-cell--textLeft': {
      pl: '1.125rem'
    },
    '& .kiinteistomuisti-table-column--header': {
      backgroundColor: Colors.heading,
      borderTopLeftRadius: 0
    },
    '& .kiinteistomuisti-table--header': {
      backgroundColor: Colors.primary,
      color: Colors.text,
      // borderLeft: `1px solid ${Colors.border}`,
      pl: '1.125rem'
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
      fontSize: '1rem',
      // lineHeight: '1.125rem',
      color: Colors.white,
      textTransform: 'uppercase'
    },
    '& .kiinteistomuisti-table--header > .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .kiinteistomuisti-table-column--header > .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
      outline: 'none',
    },
    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
      outline: 'none',
    },
    '.MuiDataGrid-iconButtonContainer': {
      visibility: 'visible',
    },
    '.MuiDataGrid-sortIcon': {
      color: Colors.white,
      opacity: 'inherit !important',
    },
    '--DataGrid-overlayHeight': {
      height: '10rem'
    },
    '& .even-dg-row': {
      backgroundColor: Colors.secondary10
    },
    '& .odd-dg-row': {
      backgroundColor: Colors.white
    }
  },
  title: {
    mt: '2rem',
    mb: '1.25rem'
  },
  verticalLine: {
    position: 'absolute',
    top: '-1rem',
    left: '-2rem',
    width: 0,
    height: 'calc(100% + 0.875rem)',
    borderRight: `0.125rem dashed ${Colors.black}`,
  },
  horizontalLine: {
    position: 'absolute',
    top: '2rem',
    left: '-1.75rem',
    width: '1.625rem',
    height: 0,
    borderBottom: `0.125rem dashed ${Colors.black}`,
  }
}
