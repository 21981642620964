import { useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Backdrop from '@mui/material/Backdrop'
import IconButton from './IconButton'
import DefaultProfilePhoto from '../../Assets/Images/user.png'
import { Colors, Fonts } from '../../Utils/theme'

export default function Comment(props: any) {
  const { t } = useTranslation()

  const { comment } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const onMenuClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const onMenuClose = () => setAnchorEl(null)

  const getUpdatedAt = () => {
    if (comment?.updatedAt) {
      const day = DateTime.fromISO(comment.updatedAt).toFormat('dd.MM.yyyy')
      const time = DateTime.fromISO(comment.updatedAt).toFormat('HH.mm')
      return `${day} ${t('at')} ${time}`
    }
    return ''
  }

  const toggleEdit = () => {
    onMenuClose()
    props.editComment(comment)
  }

  const archiveComment = () => {
    onMenuClose()
    props.archiveComment(comment)
  }

  const MenuOptions = [
    { text: t('edit_comment'), onClick: toggleEdit },
    { text: t('archive_comment'), onClick: archiveComment }
  ]

  const renderMenuItems = () => {
    return MenuOptions.map((option: any, index: number) => {
      return <MenuItem key={index} onClick={option.onClick}>{option.text || '-'}</MenuItem>
    })
  }

  const renderMenuButton = () => {
    if (MenuOptions) {
      return (
        <>
          <IconButton
            icon='menu'
            onClick={onMenuClick}
            sx={styles.menuButton}
            iconSize='1.125rem'
          />
          <Backdrop sx={styles.backdrop} open={isMenuOpen} onClick={onMenuClose}>
            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={onMenuClose}
              // anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              {renderMenuItems()}
            </Menu>
          </Backdrop>
        </>
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.row}>
        <Avatar
          sx={styles.avatar}
          src={comment?.user?.profilePhoto || DefaultProfilePhoto}
          alt='photo'
        />
        <Box sx={styles.column}>
          <Typography variant='h5' sx={styles.name}>
            {comment?.user?.firstName} {comment?.user?.lastName}
          </Typography>
          <Typography variant='body1' sx={styles.updatedAt}>
            {getUpdatedAt()}
          </Typography>
        </Box>
      </Box>
      <Typography variant='body1' sx={styles.comment}>
        {comment?.text || ''}
      </Typography>
      {renderMenuButton()}
    </Box>
  )
}

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1rem',
    mb: '1rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  avatar: {
    width: '2.5rem',
    height: '2.5rem'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    ml: '0.875rem'
  },
  name: {
    fontFamily: Fonts.heading,
    color: Colors.heading,
    fontWeight: 700,
    fontSize: '1rem',
    mb: '0.05rem'
  },
  updatedAt: {
    color: Colors.primary,
    fontWeight: 400,
    fontSize: '0.875rem'
  },
  comment: {
    fontFamily: Fonts.body,
    color: Colors.text,
    fontWeight: 400,
    fontSize: '1rem',
    mt: '0.75rem'
  },
  menuButton: {
    position: 'absolute',
    top: '1.375rem',
    right: '1rem',
    height: '2rem',
    width: '2rem'
  },
  backdrop: {
    backgroundColor: Colors.backdrop,
    zIndex: 1000
  }
} as const