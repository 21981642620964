import { Routes, Route, Navigate } from 'react-router-dom'
import { AuthLayout } from '../../Layouts'
import { Login, ForgotPassword, PasswordChange, VerifyAccount } from './Auth'

export default function Unauthorized() {
  return (
    <AuthLayout>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/password-change' element={<PasswordChange />} />
        <Route path='/verify-account' element={<VerifyAccount />} />
        {/*}
        <Route path='/email-change' element={<EmailChange />} />
        */}
        <Route path='*' element={<Navigate replace to='/login' />} />
      </Routes>
    </AuthLayout>
  )
}
