import { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from './Button'
import Chip from './Chip'
import Input from './Input'
import Select from './Select'
import TextEditor from './TextEditor'
import DateInput from './DateInput'
import TimeInput from './TimeInput'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ButtonBase from '@mui/material/ButtonBase'
import PlusIcon from '../../Assets/Icons/plus.svg'
import { Colors } from '../../Utils/theme'

export default function ReminderModal(props: any) {
  const { onClose, loading, onSave } = props
  const { t } = useTranslation()

  const {
    mode,
    tab,
    property,
    reminder,
    getTargetEntityOptions,
    getTargetSubEntityOptions,
    getTargetOptions,
    disabled
  } = props

  // General
  const [phase, setPhase] = useState<number>(1)

  // Section 1/3
  // This is general category for the reminder (e.g. 'areas', 'buildings', 'apartments')
  const [targetEntity, setTargetEntity] = useState<string>('')
  // This is sub category for the reminder (e.g. 'building', 'space', 'apartment', 'room')
  const [targetSubEntity, setTargetSubEntity] = useState<string>('')
  // This is the action target for the reminder (e.g. 'sewer_system' etc.)
  const [target, setTarget] = useState<string>('')

  // Section 2/3
  const [actionTitle, setActionTitle] = useState<string>('')
  const [actionDate, setActionDate] = useState<string>('')
  const [actionTime, setActionTime] = useState<string>('')
  const [reminders, setReminders] = useState<any>([{
    title: '',
    date: '',
    time: ''
  }])

  // Section 3/3
  const [assignedUser, setAssignedUser] = useState<string>('')
  const [additionalInfo, setAdditionalInfo] = useState<string>('')

  useEffect(() => {
    if (mode === 'create') {
      if (props?.building) {
        setTargetEntity('buildings')
        setTargetSubEntity(props?.building?.uuid)
      } else if (props?.space) {
        setTargetEntity('buildings')
        setTargetSubEntity(props?.space?.uuid)
      } else if (props?.apartment) {
        setTargetEntity('apartments')
        setTargetSubEntity(props?.apartment?.uuid)
      } else if (props?.room) {
        setTargetEntity('apartments')
        setTargetSubEntity(props?.room?.uuid)
      }
    }
  }, [])

  useEffect(() => {
    if (reminder && mode === 'edit') {
      setTargetEntity(reminder.targetEntity)
      setTargetSubEntity(
        reminder.targetSubEntity === 'building' ?
          reminder.buildingUuid :
          reminder.targetSubEntity === 'space' ?
          reminder.spaceUuid :
          reminder.targetSubEntity === 'apartment' ?
          reminder.apartmentUuid :
          reminder.targetSubEntity === 'room' ?
          reminder.roomUuid :
          ''
      )
      setTarget(reminder.target)
      setActionTitle(reminder.actionTitle)
      setActionDate(DateTime.fromISO(reminder?.actionDate).toFormat('yyyy-MM-dd'))
      setActionTime(DateTime.fromISO(reminder?.actionDate).toFormat('HH:mm'))
      setReminders(reminder?.scheduledReminders?.map((reminder: any) => ({
        uuid: reminder.uuid,
        title: reminder.title,
        date: DateTime.fromISO(reminder.scheduledAt).toFormat('yyyy-MM-dd'),
        time: DateTime.fromISO(reminder.scheduledAt).toFormat('HH:mm')
      })) ?? ([
        {
          title: '',
          date: '',
          time: ''
        }
      ]))
      setAssignedUser(reminder?.assignedUser?.uuid)
      setAdditionalInfo(reminder.additionalInfo)
    }
  }, [reminder])

  useEffect(() => {
    if (mode === 'create' && tab) {
      if (tab === 'areas') {
        setTargetEntity('areas')
      } else if (tab === 'buildings') {
        setTargetEntity('buildings')
      } else if (tab === 'apartments') {
        setTargetEntity('apartments')
      }
    }
  }, [tab])

  const updateActionDate = (value: string) => {
    for (let i = 0; i < reminders.length; i++) {
      if (!reminders[i]?.date || reminders[i]?.date === actionDate) {
        const updatedReminders = [...reminders]
        updatedReminders[i].date = value
        setReminders(updatedReminders)
      }
    }
    setActionDate(value)
  }

  const updateActionTime = (value: string) => {
    for (let i = 0; i < reminders.length; i++) {
      if (!reminders[i].time || reminders[i].time === actionTime) {
        const updatedReminders = [...reminders]
        updatedReminders[i].time = value
        setReminders(updatedReminders)
      }
    }
    setActionTime(value)
  }

  const addReminderItem = () => {
    setReminders([
      ...reminders,
      {
        title: '',
        date: actionDate || '',
        time: actionTime || ''
      }
    ])
  }

  const removeReminderItem = (index: number) => {
    const updatedReminders = [...reminders]
    updatedReminders.splice(index, 1)
    setReminders(updatedReminders)
  }

  const updateReminderTitle = (index: number, value: string) => {
    const updatedReminders = [...reminders]
    updatedReminders[index].title = value
    setReminders(updatedReminders)
  }

  const updateReminderDate = (index: number, value: string) => {
    const updatedReminders = [...reminders]
    updatedReminders[index].date = value
    setReminders(updatedReminders)
  }

  const updateReminderTime = (index: number, value: string) => {
    const updatedReminders = [...reminders]
    updatedReminders[index].time = value
    setReminders(updatedReminders)
  }

  const Title = useMemo(() => {
    if (mode === 'edit') {
      return t('edit_reminder')
    }
    return t('add_reminder')
  }, [mode])

  const getIsSaveDisabled = () => {
    if (phase === 1) {
      return !targetEntity || !target
    } else if (phase === 2) {
      return !actionTitle ||
        !actionDate ||
        !actionTime ||
        !reminders?.length ||
        reminders?.some((reminder: any) => {
          return !reminder?.title || !reminder?.date || !reminder?.time
        })
    }
    if (phase === 3 && disabled) {
      return true
    }
    return false
  }

  const getIsAddItemDisabled = () => {
    if (reminders?.length === 0) {
      return reminders?.some((reminder: any) => {
        return !reminder?.title || !reminder?.date || !reminder?.time
      })
    }
  }

  const onPreviousClick = () => {
    if (phase > 1) {
      setPhase(phase - 1)
    } else {
      onClose()
    }
  }

  const onNextClick = () => {
    if (phase < 3) {
      setPhase(phase + 1)
    } else {
      const payload: any = {
        targetEntity,
        target,
        actionTitle,
        actionDate: DateTime
          .fromFormat(`${actionDate} ${actionTime}`, `yyyy-MM-dd HH:mm`)
          .toJSDate(),
        reminders: reminders.map((reminder: any) => ({
          uuid: reminder?.uuid || undefined,
          title: reminder.title,
          scheduledAt: DateTime
            .fromFormat(`${reminder.date} ${reminder.time}`, `yyyy-MM-dd HH:mm`)
            .toJSDate()
        })),
        additionalInfo,
        assignedUser: assignedUser || null
      }

      if (targetEntity === 'buildings') {
        const entity = getTargetSubEntityOptions(targetEntity)
          ?.find((item: any) => item.value === targetSubEntity)
        if (entity?.parentValue) {
          payload.targetSubEntity = 'space'
          payload.buildingUuid = entity?.parentValue
          payload.spaceUuid = entity?.value
        } else {
          payload.targetSubEntity = 'building'
          payload.buildingUuid = entity?.value
        }
      } else if (targetEntity === 'apartments') {
        const entity = getTargetSubEntityOptions(targetEntity)
          ?.find((item: any) => item.value === targetSubEntity)
        if (entity?.parentValue) {
          payload.targetSubEntity = 'room'
          payload.apartmentUuid = entity?.parentValue
          payload.roomUuid = targetSubEntity
        } else {
          payload.targetSubEntity = 'apartment'
          payload.apartmentUuid = targetSubEntity
        }
      } else if (targetEntity === 'areas') {
        payload.targetSubEntity = 'area'
        payload.areaUuid = targetSubEntity
      }
      onSave(payload)
    }
  }
  
  const renderContentSection = () => {
    if (phase === 1) {
      return (
        <>
          <Input
            label={t('property')}
            value={property?.nameFi}
            onChange={() => {}}
            mb='1.25rem'
            valueAsChip
          />
          <Select
            label={t('reminder_target_is')}
            placeholder={t('select')}
            value={targetEntity}
            onChange={setTargetEntity}
            options={getTargetEntityOptions()}
            mb='1.25rem'
            disabled={disabled}
          />
          {targetEntity && getTargetSubEntityOptions(targetEntity)?.length ? (
            <Select
              placeholder={t('select_reminder_sub_entity')}
              value={targetSubEntity}
              onChange={setTargetSubEntity}
              options={getTargetSubEntityOptions(targetEntity)}
              mb='1.25rem'
              disabled={disabled}
            />
          ) : null}
          {targetEntity && (
            <Select
              label={t('action_target')}
              placeholder={t('select')}
              value={target}
              onChange={setTarget}
              options={getTargetOptions(targetEntity, targetSubEntity)}
              mb='1.25rem'
              disabled={disabled}
            />
          )}
        </>
      )
    } else if (phase === 2) {
      return (
        <>
          <Input
            label={t('title')}
            value={actionTitle}
            onChange={setActionTitle}
            mb='1.25rem'
            disabled={disabled}
          />
          <Box sx={styles.row}>
            <DateInput
              label={t('action_date')}
              value={actionDate}
              onChange={updateActionDate}
              mr='1.5rem'
              disabled={disabled}
            />
            <TimeInput
              label={t('clock_time')}
              value={actionTime}
              onChange={updateActionTime}
              disabled={disabled}
            />
          </Box>
          {
            reminders?.map((reminder: any, index: number) => (
              <Box key={index} sx={styles.reminderItemContainer} mt={index === 0 ? '1.5rem' : '2rem'}>
                {!disabled ? (
                  <ButtonBase
                    sx={styles.deleteReminderButton}
                    onClick={() => removeReminderItem(index)}
                  >
                    <Typography variant='body1' sx={styles.deleteReminderText}>
                      {t('delete')}
                    </Typography>
                  </ButtonBase>
                ) : null}
                <Input
                  label={`${t('reminder')} ${index + 1}`}
                  value={reminder?.title}
                  onChange={(value: any) => updateReminderTitle(index, value)}
                  mb='1.25rem'
                  disabled={disabled}
                />
                <Box sx={styles.row}>
                  <DateInput
                    label={t('reminder_date')}
                    value={reminder?.date}
                    onChange={(value: any) => updateReminderDate(index, value)}
                    mr='1.5rem'
                    disabled={disabled}
                  />
                  <TimeInput
                    label={t('clock_time')}
                    value={reminder?.time}
                    onChange={(value: any) => updateReminderTime(index, value)}
                    disabled={disabled}
                  />
                </Box>
              </Box>
            ))
          }
          {!disabled ? (
            <Box sx={styles.addReminderButtonContainer}>
              <ButtonBase
                sx={styles.addReminderButton}
                onClick={addReminderItem}
                disabled={getIsAddItemDisabled() || disabled}
              >
                <Box component='img' src={PlusIcon} alt='plus' />
                <Typography variant='body1' sx={styles.addReminderText}>
                  {t('add_reminder')}
                </Typography>
              </ButtonBase>
            </Box>
          ) : null}
        </>
      )
    } else if (phase === 3) {
      return (
        <>
          <Select
            label={t('assigned_user')}
            placeholder={t('select')}
            value={assignedUser}
            onChange={setAssignedUser}
            options={props?.users || []}
            mb='1.25rem'
          />
          <TextEditor
            label={t('additional_info')}
            value={additionalInfo}
            onChange={setAdditionalInfo}
            disabled={disabled}
          />
        </>
      )
    }
  }

  return (
    <Dialog
      onClose={onClose}
      sx={styles.dialog}
      disableRestoreFocus
      open
    >
      <Chip onClick={onClose} sx={styles.closeButton} text={t('close')} />
      <DialogTitle variant='h2' sx={styles.title}>{Title}</DialogTitle>
      <DialogContent sx={styles.content}>
        <Box sx={styles.countContainer}>
          <Typography variant='body1' sx={styles.count}>{phase}/3</Typography>
        </Box>
        {renderContentSection()}
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.outlinedButton}
          text={phase === 1 ? t('cancel') : t('back')}
          onClick={onPreviousClick}
          variant='secondary'
        />
        <Button
          sx={styles.button}
          text={phase < 3 ? t('next') : t('save')}
          onClick={onNextClick}
          loading={loading}
          disabled={getIsSaveDisabled()}
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '39rem',
      borderRadius: '0.625rem',
      padding: '1.25rem 0.75rem'
    }
  },
  closeButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem'
  },
  title: {
    color: Colors.primary
  },
  content: {
  },
  contentText: {
    marginBottom: '1.5rem'
  },
  outlinedButton: {
    fontWeight: 700,
    minWidth: '10rem',
    color: Colors.button,
    border: `0.0625rem solid ${Colors.button}`,
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    }
  },
  button: {
    pl: '2.5rem',
    pr: '2.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: '1rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'center',
    width: 'calc(100% - 1.5rem)',
    border: 0
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  },
  countContainer: {
    height: '2rem',
    width: '3rem',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mt: '0.125rem',
    mb: '1rem'
  },
  count: {
    fontSize: '1.125rem',
    fontWeight: 700,
    color: Colors.text
  },
  reminderItemContainer: {
    position: 'relative',
  },
  addReminderButtonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    mt: '1.5rem',
    mb: '0.5rem',
    p: '0.5rem 0rem',
    borderTop: `0.0625rem solid ${Colors.secondary}`,
    borderBottom: `0.0625rem solid ${Colors.secondary}`
  },
  addReminderButton: {
    height: '3rem',
    borderRadius: '0.3125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      '> p': {
        color: Colors.primary
      },
    }
  },
  addReminderText: {
    color: Colors.primary,
    fontSize: '1rem',
    fontWeight: 700,
    ml: '0.5rem'
  },
  deleteReminderButton: {
    position: 'absolute',
    top: '0.0625rem',
    right: '0',
    opacity: 0.8,
    zIndex: 1,
    '&:hover': {
      cursor: 'pointer',
      opacity: 1,
      transition: '0.2s',
      '> p': {
        textDecoration: 'underline'
      }
    }
  },
  deleteReminderText: {
    fontSize: '1rem',
    color: Colors.red,
    fontWeight: 600
  }
} as const
