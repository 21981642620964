import Lightbox from 'yet-another-react-lightbox'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
// import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'

export default function CustomLightbox(props: any) {
  const { open, onClose, index, images } = props

  return (
    <Lightbox
      open={open}
      close={onClose}
      index={index}
      slides={images || []}
      plugins={[Thumbnails]}
    />
  )
}