import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class UserStore {
  rootStore

  users: any = []
  totalUsers: number | null = null
  user: any = null
  userEvents: any = []
  filters: any = null
  loading = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setUsers = (users: any) => { this.users = users }
  setTotalUsers = (total: number | null) => { this.totalUsers = total }
  setUser = (user: any) => { this.user = user }
  setUserEvents = (events: any) => { this.userEvents = events }
  setFilters = (filters: any) => { this.filters = filters }
  setLoading = (loading: boolean) => { this.loading = loading }

  reset() {
    this.setUsers([])
    this.setTotalUsers(null)
    this.setUser(null)
    this.setUserEvents([])
    this.setFilters(null)
    this.setLoading(false)
  }

  async getUsers() {
    this.setLoading(true)
    try {
      const response: any = await Api.getUsers()
      if (response?.ok) {
        const users = response.data?.items || []
        const total = response.data?.total || 0
        this.setUsers(users)
        this.setTotalUsers(total)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getUser(uuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getUser(uuid)
      if (response?.ok) {
        const user = response?.data || null
        this.setUser(user)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateUser(uuid: string, body: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateUser(uuid, body)
      if (response?.ok) {
        const user = response?.data || null
        this.setUser(user)
        showMessage(i18n.t('profile_updated'))
      } else {
        showMessage(i18n.t('profile_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateUserProfilePhoto(uuid: string, file: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateUserProfilePhoto(uuid, file)
      if (response?.ok) {
        const user = response?.data || null
        this.setUser(user)
        showMessage(i18n.t('profile_updated'))
      } else {
        showMessage(i18n.t('profile_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archiveUserProfilePhoto(uuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.archiveUserProfilePhoto(uuid)
      if (response?.ok) {
        const user = response?.data || null
        this.setUser(user)
        showMessage(i18n.t('profile_updated'))
      } else {
        showMessage(i18n.t('profile_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async createUser(body: any, callback: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.createUser(body)
      if (response?.ok) {
        this.getUsers()
        showMessage(i18n.t('user_created'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('user_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archiveUser(uuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.archiveUser(uuid)
      if (response?.ok) {
        const user = response?.data || null
        this.setUser(user)
        this.getUsers()
        showMessage(i18n.t('user_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('user_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async restoreUser(uuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.restoreUser(uuid)
      if (response?.ok) {
        const user = response?.data || null
        this.setUser(user)
        this.getUsers()
        showMessage(i18n.t('user_restored'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('user_restore_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}
