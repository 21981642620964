import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { Input, Button } from '../../../Components'
import { Colors } from '../../../Utils/theme'

const Login = () => {
  const { t } = useTranslation()
  const { sessionStore }: any = useStore()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)

  const clearEmail = () => setEmail('')
  const togglePasswordVisible = () => setPasswordVisible(!passwordVisible)

  const handleKeyPress = (evt: any) => {
    if (evt.key === 'Enter' && email && password) {
      login()
    }
  }
  const login = () => {
    sessionStore.login(email, password)
    setPassword('')
  }

  return (
    <Box sx={styles.container}>
      <Typography variant='h1' sx={styles.title}>{t('login')}</Typography>
      <Typography variant='body1' sx={styles.info}>{t('login_info')}</Typography>
      <Input
        placeholder={t('email')}
        value={email}
        onChange={setEmail}
        onAction={clearEmail}
        auth
      />
      <Input
        type={passwordVisible ? 'text' : 'password'}
        placeholder={t('password')}
        value={password}
        onChange={setPassword}
        onAction={togglePasswordVisible}
        handleKeyPress={handleKeyPress}
        password
        auth
      />
      <Typography variant='body2' sx={styles.link}>
        <Link sx={styles.linkText} href='forgot-password' variant='body2'>{t('forgot_password')}</Link>
      </Typography>
      <Button sx={styles.button} text={t('login')} onClick={login} />
    </Box>
  )
}

export default observer(Login)

const styles = {
  container: {
    width: {
      sm: '40rem',
      xs: '100%'
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    color: Colors.white,
    mb: '1.5rem'
  },
  info: {
    fontSize: '1rem',
    color: Colors.white90,
    marginBottom: '2.75rem',
    textAlign: 'center'
  },
  button: {
    height: '4rem',
    width: '100%',
  },
  link: {
    color: Colors.white,
    alignSelf: 'flex-end',
    marginTop: '0.5rem',
    marginBottom: '2rem',
    ':hover': {
      textDecoration: 'underline'
    }
  },
  linkText: {
    fontSize: '1rem',
    color: Colors.white,
    textDecoration: 'none'
  }
} as const

