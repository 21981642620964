import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class SpaceStore {
  rootStore

  spaces: any = []
  space: any = null
  floor: any = null
  ceiling: any = null
  wall: any = null
  files: any = []
  loading = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setSpaces = (spaces: any) => { this.spaces = spaces }
  setSpace = (space: any) => { this.space = space }
  setFloor = (floor: any) => { this.floor = floor }
  setCeiling = (ceiling: any) => { this.ceiling = ceiling }
  setWall = (wall: any) => { this.wall = wall }
  setFiles = (files: any) => { this.files = files }
  setLoading = (loading: boolean) => { this.loading = loading }

  reset() {
    this.setSpaces([])
    this.setSpace(null)
    this.setFloor(null)
    this.setCeiling(null)
    this.setWall(null)
    this.setFiles([])
    this.setLoading(false)
  }

  async getSpaces(pUuid: string, bUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getSpaces(pUuid, bUuid)
      if (response?.ok) {
        const spaces = response.data?.items || []
        this.setSpaces(spaces)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getSpace(pUuid: string, bUuid: string, sUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getSpace(pUuid, bUuid, sUuid)
      if (response?.ok) {
        const space = response?.data || null
        this.setSpace(space)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getSpaceFloor(pUuid: string, bUuid: string, sUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getSpaceFloor(pUuid, bUuid, sUuid)
      if (response?.ok) {
        const space = response?.data || null
        this.setSpace(space)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getSpaceCeiling(pUuid: string, bUuid: string, sUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getSpaceCeiling(pUuid, bUuid, sUuid)
      if (response?.ok) {
        const space = response?.data || null
        this.setSpace(space)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getSpaceWall(pUuid: string, bUuid: string, sUuid: string, wallNumber: number) {
    this.setLoading(true)
    try {
      const response: any = await Api.getSpaceWall(pUuid, bUuid, sUuid, wallNumber)
      if (response?.ok) {
        const space = response?.data || null
        this.setSpace(space)
        this.setWall(space?.[`wall${wallNumber}`])
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async createSpace(pUuid: string, bUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.createSpace(pUuid, bUuid, payload)
      if (response?.ok) {
        this.getSpaces(pUuid, bUuid)
        showMessage(i18n.t('space_created'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('space_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateSpace(pUuid: string, bUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateSpace(pUuid, bUuid, this.space.uuid, payload)
      if (response?.ok) {
        this.getSpaces(pUuid, bUuid)
        showMessage(i18n.t('space_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('space_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateSpaceFloor(pUuid: string, bUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateSpaceFloor(pUuid, bUuid, this.space.uuid, payload)
      if (response?.ok) {
        const data = response?.data || null
        this.setSpace(data)
        this.getSpaces(pUuid, bUuid)
        showMessage(i18n.t('space_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('space_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateSpaceCeiling(pUuid: string, bUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateSpaceCeiling(pUuid, bUuid, this.space.uuid, payload)
      if (response?.ok) {
        const data = response?.data || null
        this.setSpace(data)
        this.getSpaces(pUuid, bUuid)
        showMessage(i18n.t('space_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('space_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateSpaceWall(pUuid: string, bUuid: string, wallNumber: number, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateSpaceWall(pUuid, bUuid, this.space.uuid, wallNumber, payload)
      if (response?.ok) {
        const data = response?.data || null
        this.setSpace(data)
        this.getSpaces(pUuid, bUuid)
        showMessage(i18n.t('space_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('space_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateSpaceBackgroundPhoto(pUuid: string, bUuid: string, file: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateSpaceBackgroundPhoto(pUuid, bUuid, this.space.uuid, file)
      if (response?.ok) {
        const data = response?.data || null
        this.setSpace(data)
        showMessage(i18n.t('space_updated'))
      } else {
        showMessage(i18n.t('space_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archiveSpace(pUuid: string, bUuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.archiveSpace(pUuid, bUuid, this.space.uuid)
      if (response?.ok) {
        this.getSpaces(pUuid, bUuid)
        showMessage(i18n.t('space_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('space_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}
