import Box from '@mui/material/Box'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimeField } from '@mui/x-date-pickers/TimeField'
import { DateTime } from 'luxon'
import InputLabel from '@mui/material/InputLabel'
import ClockIcon from '../../Assets/Icons/clock.svg'
import { Colors } from '../../Utils/theme'

export default function TimeInput(props: any) {
  const {
    id,
    label,
    value,
    inputSx,
    disabled,
  } = props

  const getContainerStyle = () => {
    let extraStyles: any = {}
    if (props?.sx) extraStyles = { ...extraStyles, ...props.sx }
    if (props.width) extraStyles.width = props.width
    if (props.minWidth) extraStyles.minWidth = props.minWidth
    if (props.maxWidth) extraStyles.maxWidth = props.maxWidth
    if (props.ml) extraStyles.ml = props.ml
    if (props.mr) extraStyles.mr = props.mr
    if (props.mb) extraStyles.mb = props.mb
    if (props.mt) extraStyles.mt = props.mt
    if (Object.keys(extraStyles).length) {
      return { ...styles.container, ...extraStyles }
    }
    return styles.container
  }


  const getInputStyle = () => {
    let inputStyles: any = styles.input
    if (inputSx) inputStyles = { ...inputStyles, ...inputSx }
    if (props?.inputMinHeight) inputStyles.minHeight = props.inputMinHeight
    if (props?.unit) {
      inputStyles = { ...inputStyles, paddingRight: '3.125rem' }
    }
    if (props?.invalid) {
      inputStyles = { ...inputStyles, border: `2px solid ${Colors.red}` }
    }
    return inputStyles
  }

  const getIconStyle = () => {
    let iconStyles: any = { ...styles.icon }
    if (props?.iconSx) iconStyles = { ...iconStyles, ...props.iconSx }
    return iconStyles
  }

  const getValue = () => {
    if (value) {
      if (value instanceof DateTime) {
        return value
      }
      return DateTime.fromFormat(value, 'HH:mm')
    }
    return null
  }

  const onChange = (newValue: any) => props.onChange(newValue.toFormat('HH:mm'))

  const renderLabel = () => {
    if (label) {
      return (
        <InputLabel
          sx={{ ...styles.label, fontWeight: props?.labelFontWeight || 600 }}
          htmlFor={id}
          disableAnimation
        >
          {label}
        </InputLabel>
      )
    }
    return null
  }

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='fi'>
      <Box sx={getContainerStyle()}>
        {renderLabel()}
        <TimeField
          value={getValue()}
          onChange={onChange}
          format='HH:mm'
          timezone='Europe/Helsinki'
          InputProps={{ sx: getInputStyle() }}
          disabled={disabled}
        />
        <Box sx={!props?.label ? styles.unitAltContainer : styles.unitContainer}>
          <Box component='img' sx={getIconStyle()} src={ClockIcon} onClick={() => {}} />
        </Box>
      </Box>
    </LocalizationProvider>
  )
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    position: 'relative'
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  label: {
    margin: 0,
    marginBottom: '0.5rem',
    color: Colors.heading,
    fontSize: '1rem',
    fontWeight: 600
  },
  input: {
    height: '3.125rem',
    fontSize: '1.125rem',
    color: Colors.text,
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    borderColor: Colors.border,
    padding: '0rem 0.125rem',
    border: `0.0625rem solid ${Colors.border}`,
    '&::placeholder': {
      color: Colors.text
    },
    '&.Mui-focused': {
      border: `0.125rem solid ${Colors.border}`,
    }
  },
  unitContainer: {
    position: 'absolute',
    top: '2.0625rem',
    right: '2px',
    height: 'calc(3.125rem - 0.25rem)',
    width: '3.125rem',
    backgroundColor: Colors.grayBg,
    borderTopRightRadius: '0.3125rem',
    borderBottomRightRadius: '0.3125rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderLeft: `0.0625rem solid ${Colors.border}`,
    padding: 0
  },
  unitAltContainer: {
    position: 'absolute',
    top: '0.125rem',
    right: '2px',
    height: 'calc(3.125rem - 0.25rem)',
    width: '3.125rem',
    backgroundColor: Colors.grayBg,
    borderTopRightRadius: '0.3125rem',
    borderBottomRightRadius: '0.3125rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderLeft: `0.0625rem solid ${Colors.border}`,
    padding: 0
  },
  icon: {
    position: 'absolute',
    cursor: 'pointer',
    width: '1.5rem',
    opacity: 0.8,
    ':hover': {
      opacity: 1
    }
  },
} as const
