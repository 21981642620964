import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class PropertyStore {
  rootStore

  properties: any = []
  totalProperties: number = 0
  property: any = null
  files: any = []
  filters: any = null
  entities: any = []
  loading = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setProperties = (properties: any) => { this.properties = properties }
  setTotalProperties = (totalProperties: number) => { this.totalProperties = totalProperties }
  setProperty = (property: any) => { this.property = property }
  setFiles = (files: any) => { this.files = files }
  setFilters = (filters: any) => { this.filters = filters }
  setEntities = (entities: any) => { this.entities = entities }
  setLoading = (loading: boolean) => { this.loading = loading }

  reset() {
    this.setProperties([])
    this.setTotalProperties(0)
    this.setProperty(null)
    this.setFiles([])
    this.setFilters(null)
    this.setLoading(false)
  }

  async getProperties() {
    this.setLoading(true)
    try {
      const response: any = await Api.getProperties()
      if (response?.ok) {
        const properties = response.data?.items || []
        const total = response.data?.total || 0
        this.setProperties(properties)
        this.setTotalProperties(total)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getProperty(uuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getProperty(uuid)
      if (response?.ok) {
        const property = response?.data || null
        this.setProperty(property)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async createProperty(payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.createProperty(payload)
      if (response?.ok) {
        this.getProperties()
        showMessage(i18n.t('property_created'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('property_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateProperty(uuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateProperty(uuid, payload)
      if (response?.ok) {
        this.getProperties()
        showMessage(i18n.t('property_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('property_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updatePropertyBackgroundPhoto(file: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updatePropertyBackgroundPhoto(this.property?.uuid, file)
      if (response?.ok) {
        const data = response?.data || null
        this.setProperty(data)
        showMessage(i18n.t('property_updated'))
      } else {
        showMessage(i18n.t('property_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archiveProperty(uuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.archiveProperty(uuid)
      if (response?.ok) {
        this.getProperties()
        showMessage(i18n.t('property_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('property_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getPropertyFiles() {
    this.setLoading(true)
    try {
      const response: any = await Api.getPropertyFiles(this.property.uuid)
      if (response?.ok) {
        const files = response?.data?.items || []
        this.setFiles(files)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async uploadPropertyFiles(files: any) {
    this.setLoading(true)
    try {
      const okResponses = []
      const errorResponses = []
      for (const file of files) {
        const response: any = await Api.uploadPropertyFile(this.property.uuid, file)
        if (response?.ok) {
          okResponses.push(response)
        } else {
          errorResponses.push(response)
        }
      }
      if (okResponses?.length) {
        this.getPropertyFiles()
        if (okResponses?.length === files?.length) {
          showMessage(i18n.t('attachments_uploaded'))
        }
      } else {
        showMessage(i18n.t('attachment_upload_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archivePropertyFile(uuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.archivePropertyFile(this.property.uuid, uuid)
      if (response?.ok) {
        this.getPropertyFiles()
        showMessage(i18n.t('file_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('file_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getPropertyAreas(pUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getPropertyAreas(pUuid)
      if (response?.ok) {
        const property = response?.data || null
        this.setProperty(property)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updatePropertyAreas(pUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updatePropertyAreas(pUuid, payload)
      if (response?.ok) {
        this.getPropertyAreas(pUuid)
        showMessage(i18n.t('property_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('property_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getPropertyEntities(pUuid: string, params?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.getPropertyEntities(pUuid, params || {})
      if (response?.ok) {
        const entities = response?.data || null
        this.setEntities(entities)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}
