import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import SidebarCreateActions from '../../../Components/Common/SidebarCreateActions'
import { Input, MultiSelect, Select } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import { UserRoles, UserRolesWithProperty } from '../../../Utils/constants'

const CreateUser = () => {
  const { t } = useTranslation()

  const { sessionStore, userStore, propertyStore }: any = useStore()
  const { me, isAdmin } = sessionStore

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [teamsEmail, setTeamsEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [role, setRole] = useState('')
  const [properties, setProperties] = useState<any>(null)
  // const [apartment, setApartment] = useState<any>(null)

  useEffect(() => {
    if (isAdmin) {
      propertyStore.getProperties()
    }
  }, [])

  const getPropertyOptions = () => {
    return propertyStore.properties.map((item: any) => {
      return {
        label: item.nameFi,
        value: item.uuid
      }
    })
  }
  
  /*
  const getApartmentOptions = () => {
    return propertyStore.apartments.map((item: any) => {
      return {
        label: item.nameFi,
        value: item.uuid
      }
    })
  }
  */

  const getRoleOptions = () => {
    if (me?.role === UserRoles.Admin) {
      return [
        { value: UserRoles.Admin, label: t(UserRoles.Admin) },
        { value: UserRoles.PropertyManager, label: t(UserRoles.PropertyManager) },
        // { value: UserRoles.Shareholder, label: t(UserRoles.Shareholder) }
      ]
    }
    if (me?.role === UserRoles.PropertyManager) {
      return [
        // { value: UserRoles.Shareholder, label: t(UserRoles.Shareholder) }
      ]
    }
    return []
  }

  const create = () => {
    const callback = () => {
      setFirstName('')
      setLastName('')
      setEmail('')
      setTeamsEmail('')
      setPhone('')
      setProperties(null)
      // setApartment(null)
      setRole('')
      /*
      if (me?.role === UserRoles.PropertyManager) {
        setRole(UserRoles.Shareholder)
      } else {
        setRole('')
      }
      */
    }
    const newUser = {
      firstName,
      lastName,
      email,
      teamsEmail: teamsEmail || null,
      phone,
      role,
      properties
      // apartment
    }

    userStore.createUser(newUser, callback)
  }
  // const cancel = () => navigate(-1)

  const isSaveDisabled = () => {
    if (!email || !role || !firstName || !lastName) return true
    if (!email.includes('@')) return true
    if (UserRolesWithProperty.includes(role) && !properties?.length) return true
    return false
  }

  const renderPropertySelect = () => {
    if (role && UserRolesWithProperty.includes(role)) {
      return (
        <MultiSelect
          label={`${t('properties')}*`}
          options={getPropertyOptions()}
          value={properties}
          onChange={setProperties}
          mb='1.5rem'
        />
      )
    }
    return null
  }

  /*
  const renderApartmentSelect = () => {
    if (role && UserRolesWithApartment.includes(role) && property) {
      return (
        <Select
          label={t('apartment')}
          options={getApartmentOptions()}
          value={apartment}
          onChange={setApartment}
          mb='1.5rem'
        />
      )
    }
    return null
  }
  */

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h1' sx={styles.title}>{t('add_user')}</Typography>
        <Typography variant='h3' mt='1rem' mb='1rem'>{t('user_information')}</Typography>
         <Box sx={styles.formContainer}>
          <Box sx={styles.row}>
            <Input
              label={`${t('first_name')}*`}
              value={firstName}
              onChange={setFirstName}
              maxWidth='22rem'
              mr='2rem'
            />
            <Input
              label={`${t('last_name')}*`}
              value={lastName}
              onChange={setLastName}
            />
          </Box>
          <Input
            label={`${t('email')}*`}
            value={email}
            onChange={setEmail}
            mb='1.5rem'
          />
          <Input
            label={t('teams_email')}
            placeholder={t('teams_email_placeholder')}
            value={teamsEmail}
            onChange={setTeamsEmail}
            mb='1.5rem'
          />
          <Input
            label={t('phone')}
            value={phone}
            onChange={setPhone}
            mb='1.5rem'
          />
        </Box>
        <Typography variant='h3' mt='1rem' mb='1rem'>{t('permissions')}</Typography>
        <Box sx={styles.formContainer}>
          <Select
            label={`${t('user_level')}*`}
            options={getRoleOptions()}
            value={role}
            onChange={setRole}
            mb='1.5rem'
          />
          {renderPropertySelect()}
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <SidebarCreateActions
          onSave={create}
          saveText={t('create_user')}
          saveDisabled={isSaveDisabled()}
          mt='9.67125rem'
        />
      </Box>
    </Box>
  )
}

export default observer(CreateUser)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginRight: {
      xl: '3rem',
      lgplus: '2.5rem',
      xs: '2rem'
    }
  },
  title: {
    borderBottom: `4px solid ${Colors.border}`,
    mt: '0.75rem',
    pb: '1rem',
    mb: '1.25rem'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: {
      lgplus: '2rem 2rem 0.5rem 2rem',
      xs: '1.625rem 1.5rem 0.125rem 1.5rem'
    },
    mb: '1.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    mb: '1.5rem'
  },
  subtitle: {
    alignSelf: 'flex-start',
    mb: '1.25rem',
    textTransform: 'uppercase'
  },
  rightColumn: {
    position: 'sticky',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  },
} as const
