import { useMemo, useState, useEffect, useRef, useCallback, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import CollapseSection from '../../../Components/Common/CollapseSection'
import CustomForm from '../../../Components/Common/CustomForm'
import { BuildingPartsLayout, BuildingSections } from '../../../Utils/building'
import { ComponentTypes } from '../../../Utils/layout'

const BuildingParts = (props: any) => {
  const { sessionStore, buildingStore }: any = useStore()
  const { isAdmin } = sessionStore
  const { building }  = buildingStore
  const { t } = useTranslation()
  
  const window: any = useRef({})
  const apartmentWindow: any = useRef({})
  const commonSpaceWindow: any = useRef({})
  const door: any = useRef({})
  const balcony: any = useRef({})
  const terrace: any = useRef({})
  
  const [lastUpdatedValue, setLastUpdatedValue] = useState('')
  const [openSections, setOpenSections] = useState([
    ...BuildingPartsLayout.map((section: any) => section.title)
  ])
  
  const calculateTotalWindowCount = () => {
    let sum = 0
    if (window?.current?.apartmentWindowCount) {
      const apartmentWindowCount = parseInt(window?.current?.apartmentWindowCount)
      if (apartmentWindowCount && !isNaN(apartmentWindowCount)) {
        sum += apartmentWindowCount
      }
    }
    if (window?.current?.commonSpaceWindowCount) {
      const commonSpaceWindowCount = parseInt(window?.current?.commonSpaceWindowCount)
      if (commonSpaceWindowCount && !isNaN(commonSpaceWindowCount)) {
        sum += commonSpaceWindowCount
      }
    }
    return sum
  }

  const calculateTotalApartmentWindowCount = () => {
    let sum = 0
    if (apartmentWindow?.current?.apartmentWindowMsuCount) {
      const apartmentWindowMsuCount = parseInt(apartmentWindow?.current?.apartmentWindowMsuCount)
      if (apartmentWindowMsuCount && !isNaN(apartmentWindowMsuCount)) {
        sum += apartmentWindowMsuCount
      }
    }
    if (apartmentWindow?.current?.apartmentWindowMsCount) {
      const apartmentWindowMsCount = parseInt(apartmentWindow?.current?.apartmentWindowMsCount)
      if (apartmentWindowMsCount && !isNaN(apartmentWindowMsCount)) {
        sum += apartmentWindowMsCount
      }
    }
    if (apartmentWindow?.current?.apartmentWindowMskCount) {
      const apartmentWindowMskCount = parseInt(apartmentWindow?.current?.apartmentWindowMskCount)
      if (apartmentWindowMskCount && !isNaN(apartmentWindowMskCount)) {
        sum += apartmentWindowMskCount
      }
    }
    if (apartmentWindow?.current?.apartmentWindowMseCount) {
      const apartmentWindowMseCount = parseInt(apartmentWindow?.current?.apartmentWindowMseCount)
      if (apartmentWindowMseCount && !isNaN(apartmentWindowMseCount)) {
        sum += apartmentWindowMseCount
      }
    }
    if (apartmentWindow?.current?.apartmentWindowMseaCount) {
      const apartmentWindowMseaCount = parseInt(apartmentWindow?.current?.apartmentWindowMseaCount)
      if (apartmentWindowMseaCount && !isNaN(apartmentWindowMseaCount)) {
        sum += apartmentWindowMseaCount
      }
    }
    if (apartmentWindow?.current?.apartmentWindowMekCount) {
      const apartmentWindowMekCount = parseInt(apartmentWindow?.current?.apartmentWindowMekCount)
      if (apartmentWindowMekCount && !isNaN(apartmentWindowMekCount)) {
        sum += apartmentWindowMekCount
      }
    }
    if (apartmentWindow?.current?.apartmentWindowMekaCount) {
      const apartmentWindowMekaCount = parseInt(apartmentWindow?.current?.apartmentWindowMekaCount)
      if (apartmentWindowMekaCount && !isNaN(apartmentWindowMekaCount)) {
        sum += apartmentWindowMekaCount
      }
    }
    if (apartmentWindow?.current?.apartmentWindowSeCount) {
      const apartmentWindowSeCount = parseInt(apartmentWindow?.current?.apartmentWindowSeCount)
      if (apartmentWindowSeCount && !isNaN(apartmentWindowSeCount)) {
        sum += apartmentWindowSeCount
      }
    }
    if (apartmentWindow?.current?.apartmentWindowMs2eCount) {
      const apartmentWindowMs2eCount = parseInt(apartmentWindow?.current?.apartmentWindowMs2eCount)
      if (apartmentWindowMs2eCount && !isNaN(apartmentWindowMs2eCount)) {
        sum += apartmentWindowMs2eCount
      }
    }
    if (apartmentWindow?.current?.apartmentWindowMs3eCount) {
      const apartmentWindowMs3eCount = parseInt(apartmentWindow?.current?.apartmentWindowMs3eCount)
      if (apartmentWindowMs3eCount && !isNaN(apartmentWindowMs3eCount)) {
        sum += apartmentWindowMs3eCount
      }
    }
    if (apartmentWindow?.current?.apartmentWindowDkCount) {
      const apartmentWindowDkCount = parseInt(apartmentWindow?.current?.apartmentWindowDkCount)
      if (apartmentWindowDkCount && !isNaN(apartmentWindowDkCount)) {
        sum += apartmentWindowDkCount
      }
    }
    window.current['totalCount'] = calculateTotalWindowCount()
    return sum
  }

  const calculateTotalCommonSpaceWindowCount = () => {
    let sum = 0
    if (commonSpaceWindow?.current?.commonSpaceWindowMsuCount) {
      const commonSpaceWindowMsuCount = parseInt(commonSpaceWindow?.current?.commonSpaceWindowMsuCount)
      if (commonSpaceWindowMsuCount && !isNaN(commonSpaceWindowMsuCount)) {
        sum += commonSpaceWindowMsuCount
      }
    }
    if (commonSpaceWindow?.current?.commonSpaceWindowMsCount) {
      const commonSpaceWindowMsCount = parseInt(commonSpaceWindow?.current?.commonSpaceWindowMsCount)
      if (commonSpaceWindowMsCount && !isNaN(commonSpaceWindowMsCount)) {
        sum += commonSpaceWindowMsCount
      }
    }
    if (commonSpaceWindow?.current?.commonSpaceWindowMskCount) {
      const commonSpaceWindowMskCount = parseInt(commonSpaceWindow?.current?.commonSpaceWindowMskCount)
      if (commonSpaceWindowMskCount && !isNaN(commonSpaceWindowMskCount)) {
        sum += commonSpaceWindowMskCount
      }
    }
    if (commonSpaceWindow?.current?.commonSpaceWindowMseCount) {
      const commonSpaceWindowMseCount = parseInt(commonSpaceWindow?.current?.commonSpaceWindowMseCount)
      if (commonSpaceWindowMseCount && !isNaN(commonSpaceWindowMseCount)) {
        sum += commonSpaceWindowMseCount
      }
    }
    if (commonSpaceWindow?.current?.commonSpaceWindowMseaCount) {
      const commonSpaceWindowMseaCount = parseInt(commonSpaceWindow?.current?.commonSpaceWindowMseaCount)
      if (commonSpaceWindowMseaCount && !isNaN(commonSpaceWindowMseaCount)) {
        sum += commonSpaceWindowMseaCount
      }
    }
    if (commonSpaceWindow?.current?.commonSpaceWindowMekCount) {
      const commonSpaceWindowMekCount = parseInt(commonSpaceWindow?.current?.commonSpaceWindowMekCount)
      if (commonSpaceWindowMekCount && !isNaN(commonSpaceWindowMekCount)) {
        sum += commonSpaceWindowMekCount
      }
    }
    if (commonSpaceWindow?.current?.commonSpaceWindowMekaCount) {
      const commonSpaceWindowMekaCount = parseInt(commonSpaceWindow?.current?.commonSpaceWindowMekaCount)
      if (commonSpaceWindowMekaCount && !isNaN(commonSpaceWindowMekaCount)) {
        sum += commonSpaceWindowMekaCount
      }
    }
    if (commonSpaceWindow?.current?.commonSpaceWindowSeCount) {
      const commonSpaceWindowSeCount = parseInt(commonSpaceWindow?.current?.commonSpaceWindowSeCount)
      if (commonSpaceWindowSeCount && !isNaN(commonSpaceWindowSeCount)) {
        sum += commonSpaceWindowSeCount
      }
    }
    if (commonSpaceWindow?.current?.commonSpaceWindowMs2eCount) {
      const commonSpaceWindowMs2eCount = parseInt(commonSpaceWindow?.current?.commonSpaceWindowMs2eCount)
      if (commonSpaceWindowMs2eCount && !isNaN(commonSpaceWindowMs2eCount)) {
        sum += commonSpaceWindowMs2eCount
      }
    }
    if (commonSpaceWindow?.current?.commonSpaceWindowMs3eCount) {
      const commonSpaceWindowMs3eCount = parseInt(commonSpaceWindow?.current?.commonSpaceWindowMs3eCount)
      if (commonSpaceWindowMs3eCount && !isNaN(commonSpaceWindowMs3eCount)) {
        sum += commonSpaceWindowMs3eCount
      }
    }
    if (commonSpaceWindow?.current?.commonSpaceWindowDkCount) {
      const commonSpaceWindowDkCount = parseInt(commonSpaceWindow?.current?.commonSpaceWindowDkCount)
      if (commonSpaceWindowDkCount && !isNaN(commonSpaceWindowDkCount)) {
        sum += commonSpaceWindowDkCount
      }
    }
    window.current['totalCount'] = calculateTotalWindowCount()
    return sum
  }

  const calculateTotalDoorCount = () => {
    let sum = 0
    if (door?.current?.exteriorDoorCount) {
      const exteriorDoorCount = parseInt(door?.current?.exteriorDoorCount)
      if (exteriorDoorCount && !isNaN(exteriorDoorCount)) {
        sum += exteriorDoorCount
      }
    }
    if (door?.current?.balconyAndTerraceDoorCount) {
      const balconyAndTerraceDoorCount = parseInt(door?.current?.balconyAndTerraceDoorCount)
      if (balconyAndTerraceDoorCount && !isNaN(balconyAndTerraceDoorCount)) {
        sum += balconyAndTerraceDoorCount
      }
    }
    if (door?.current?.commonSpaceDoorCount) {
      const commonSpaceDoorCount = parseInt(door?.current?.commonSpaceDoorCount)
      if (commonSpaceDoorCount && !isNaN(commonSpaceDoorCount)) {
        sum += commonSpaceDoorCount
      }
    }
    if (door?.current?.apartmentDoorCount) {
      const apartmentDoorCount = parseInt(door?.current?.apartmentDoorCount)
      if (apartmentDoorCount && !isNaN(apartmentDoorCount)) {
        sum += apartmentDoorCount
      }
    }
    if (door?.current?.interiorDoorCount) {
      const interiorDoorCount = parseInt(door?.current?.interiorDoorCount)
      if (interiorDoorCount && !isNaN(interiorDoorCount)) {
        sum += interiorDoorCount
      }
    }
    return sum
  }

  const calculateTotalGlazedBalconyCount = () => {
    let sum = 0
    if (balcony?.current?.apartmentBalconyGlazedCount) {
      const apartmentBalconyGlazedCount = parseInt(balcony?.current?.apartmentBalconyGlazedCount)
      if (apartmentBalconyGlazedCount && !isNaN(apartmentBalconyGlazedCount)) {
        sum += apartmentBalconyGlazedCount
      }
    }
    if (balcony?.current?.commonSpaceBalconyGlazedCount) {
      const commonSpaceBalconyGlazedCount = parseInt(balcony?.current?.commonSpaceBalconyGlazedCount)
      if (commonSpaceBalconyGlazedCount && !isNaN(commonSpaceBalconyGlazedCount)) {
        sum += commonSpaceBalconyGlazedCount
      }
    }
    return sum
  }

  const calculateTotalGlazedTerraceCount = () => {
    let sum = 0
    if (terrace?.current?.apartmentTerraceGlazedCount) {
      const apartmentTerraceGlazedCount = parseInt(terrace?.current?.apartmentTerraceGlazedCount)
      if (apartmentTerraceGlazedCount && !isNaN(apartmentTerraceGlazedCount)) {
        sum += apartmentTerraceGlazedCount
      }
    }
    if (terrace?.current?.commonSpaceTerraceGlazedCount) {
      const commonSpaceTerraceGlazedCount = parseInt(terrace?.current?.commonSpaceTerraceGlazedCount)
      if (commonSpaceTerraceGlazedCount && !isNaN(commonSpaceTerraceGlazedCount)) {
        sum += commonSpaceTerraceGlazedCount
      }
    }
    return sum
  }

  useEffect(() => {
    if (building) {
      for (const [key, value] of Object.entries(building?.apartmentWindow || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          if ([
            'apartmentWindowTypes',
            'apartmentWindowMsuProperties',
            'apartmentWindowMsProperties',
            'apartmentWindowMskProperties',
            'apartmentWindowMseProperties',
            'apartmentWindowMseaProperties',
            'apartmentWindowMekProperties',
            'apartmentWindowMekaProperties',
            'apartmentWindowSeProperties',
            'apartmentWindowMs2eProperties',
            'apartmentWindowMs3eProperties',
            'apartmentWindowDkProperties',
            ].includes(key)
          ) {
            apartmentWindow.current[key] = building?.apartmentWindow?.[key]?.length ? 
              building?.apartmentWindow?.[key]?.split(',') :
              []
          } else {
            apartmentWindow.current[key] = value
          }
        }
      }
      window.current['apartmentWindowCount'] = calculateTotalApartmentWindowCount()
      for (const [key, value] of Object.entries(building?.commonSpaceWindow || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          if ([
            'commonSpaceWindowTypes',
            'commonSpaceWindowMsuProperties',
            'commonSpaceWindowMsProperties',
            'commonSpaceWindowMskProperties',
            'commonSpaceWindowMseProperties',
            'commonSpaceWindowMseaProperties',
            'commonSpaceWindowMekProperties',
            'commonSpaceWindowMekaProperties',
            'commonSpaceWindowSeProperties',
            'commonSpaceWindowMs2eProperties',
            'commonSpaceWindowMs3eProperties',
            'commonSpaceWindowDkProperties',
            ].includes(key)
          ) {
            commonSpaceWindow.current[key] = building?.commonSpaceWindow?.[key]?.length ? 
              building?.commonSpaceWindow?.[key]?.split(',') :
              []
          } else {
            commonSpaceWindow.current[key] = value
          }
        }
      }
      window.current['commonSpaceWindowCount'] = calculateTotalCommonSpaceWindowCount()
      for (const [key, value] of Object.entries(building?.door || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          door.current[key] = value
        }
      }
      door.current['totalCount'] = calculateTotalDoorCount()
      for (const [key, value] of Object.entries(building?.balcony || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          balcony.current[key] = value
        }
      }
      balcony.current['totalCount'] = calculateTotalGlazedBalconyCount()
      for (const [key, value] of Object.entries(building?.terrace || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          terrace.current[key] = value
        }
      }
      terrace.current['totalCount'] = calculateTotalGlazedTerraceCount()
      setLastUpdatedValue(DateTime.now().toISO())
    }
  }, [building])

  const onSave = () => {
    const updatedBuilding = {
      apartmentWindow: {
        ...apartmentWindow.current,
        apartmentWindowTypes: apartmentWindow.current.apartmentWindowTypes?.join(','),
        apartmentWindowMsuProperties: apartmentWindow.current.apartmentWindowMsuProperties?.join(','),
        apartmentWindowMsProperties: apartmentWindow.current.apartmentWindowMsProperties?.join(','),
        apartmentWindowMskProperties: apartmentWindow.current.apartmentWindowMskProperties?.join(','),
        apartmentWindowMseProperties: apartmentWindow.current.apartmentWindowMseProperties?.join(','),
        apartmentWindowMseaProperties: apartmentWindow.current.apartmentWindowMseaProperties?.join(','),
        apartmentWindowMekProperties: apartmentWindow.current.apartmentWindowMekProperties?.join(','),
        apartmentWindowMekaProperties: apartmentWindow.current.apartmentWindowMekaProperties?.join(','),
        apartmentWindowSeProperties: apartmentWindow.current.apartmentWindowSeProperties?.join(','),
        apartmentWindowMs2eProperties: apartmentWindow.current.apartmentWindowMs2eProperties?.join(','),
        apartmentWindowMs3eProperties: apartmentWindow.current.apartmentWindowMs3eProperties?.join(','),
        apartmentWindowDkProperties: apartmentWindow.current.apartmentWindowDkProperties?.join(','),
      },
      commonSpaceWindow: {
        ...commonSpaceWindow.current,
        commonSpaceWindowTypes: commonSpaceWindow.current.commonSpaceWindowTypes?.join(','),
        commonSpaceWindowMsuProperties: commonSpaceWindow.current.commonSpaceWindowMsuProperties?.join(','),
        commonSpaceWindowMsProperties: commonSpaceWindow.current.commonSpaceWindowMsProperties?.join(','),
        commonSpaceWindowMskProperties: commonSpaceWindow.current.commonSpaceWindowMskProperties?.join(','),
        commonSpaceWindowMseProperties: commonSpaceWindow.current.commonSpaceWindowMseProperties?.join(','),
        commonSpaceWindowMseaProperties: commonSpaceWindow.current.commonSpaceWindowMseaProperties?.join(','),
        commonSpaceWindowMekProperties: commonSpaceWindow.current.commonSpaceWindowMekProperties?.join(','),
        commonSpaceWindowMekaProperties: commonSpaceWindow.current.commonSpaceWindowMekaProperties?.join(','),
        commonSpaceWindowSeProperties: commonSpaceWindow.current.commonSpaceWindowSeProperties?.join(','),
        commonSpaceWindowMs2eProperties: commonSpaceWindow.current.commonSpaceWindowMs2eProperties?.join(','),
        commonSpaceWindowMs3eProperties: commonSpaceWindow.current.commonSpaceWindowMs3eProperties?.join(','),
        commonSpaceWindowDkProperties: commonSpaceWindow.current.commonSpaceWindowDkProperties?.join(','),
      },
      door: door.current,
      balcony: balcony.current,
      terrace: terrace.current,
    }
    buildingStore.updateBuildingParts(props?.uuid, updatedBuilding)
  }

  useImperativeHandle(
    props?.saveRef,
    () => ({ onSave }),
    []
  )

  // Collapse state toggles
  const toggleOpenSection = (section: string) => {
    if (openSections.includes(section)) {
      setOpenSections(openSections.filter((item: string) => item !== section))
    } else {
      setOpenSections([...openSections, section])
    }
  }

  const onChange = (category: string, key: string, value: any) => {
    if (category === BuildingSections.Window) {
      if (key?.includes('apartmentWindow')) {
        apartmentWindow.current = { ...(apartmentWindow?.current || {}), [key]: value }
        if ([
          'apartmentWindowMsuCount',
          'apartmentWindowMsCount',
          'apartmentWindowMskCount',
          'apartmentWindowMseCount',
          'apartmentWindowMseaCount',
          'apartmentWindowMekCount',
          'apartmentWindowMekaCount',
          'apartmentWindowSeCount',
          'apartmentWindowMs2eCount',
          'apartmentWindowMs3eCount',
          'apartmentWindowDkCount',
          ].includes(key)
        ) {
          window.current['apartmentWindowCount'] = calculateTotalApartmentWindowCount()
        }
      } else if (key?.includes('commonSpaceWindow')) {
        commonSpaceWindow.current = { ...(commonSpaceWindow?.current || {}), [key]: value }
        if ([
          'commonSpaceWindowMsuCount',
          'commonSpaceWindowMsCount',
          'commonSpaceWindowMskCount',
          'commonSpaceWindowMseCount',
          'commonSpaceWindowMseaCount',
          'commonSpaceWindowMekCount',
          'commonSpaceWindowMekaCount',
          'commonSpaceWindowSeCount',
          'commonSpaceWindowMs2eCount',
          'commonSpaceWindowMs3eCount',
          'commonSpaceWindowDkCount',
          ].includes(key)
        ) {
          window.current['commonSpaceWindowCount'] = calculateTotalCommonSpaceWindowCount()
        }
      }
    } else if (category === BuildingSections.Door) {
      door.current = { ...(door?.current || {}), [key]: value }
      if (
        key === 'exteriorDoorCount' ||
        key === 'balconyAndTerraceDoorCount' ||
        key === 'commonSpaceDoorCount' ||
        key === 'apartmentDoorCount' ||
        key === 'interiorDoorCount'
      ) {
        door.current['totalCount'] = calculateTotalDoorCount()
      }
    } else if (category === BuildingSections.Balcony) {
      balcony.current = { ...(balcony?.current || {}), [key]: value }
      if (key === 'apartmentBalconyGlazedCount' || key === 'commonSpaceBalconyGlazedCount') {
        balcony.current['totalCount'] = calculateTotalGlazedBalconyCount()
      }
    } else if (category === BuildingSections.Terrace) {
      terrace.current = { ...(terrace?.current || {}), [key]: value }
      if (key === 'apartmentTerraceGlazedCount' || key === 'commonSpaceTerraceGlazedCount') {
        terrace.current['totalCount'] = calculateTotalGlazedTerraceCount()
      }
    } else {
      console.log('Unknown category', category)
    }
    setLastUpdatedValue(`${key}_${value}`)
  }

  const getValues = useCallback((category: string) => {
    if (category === BuildingSections.Window) {
      return {
        ...apartmentWindow?.current,
        ...commonSpaceWindow?.current,
        ...window?.current
      }
    } else if (category === BuildingSections.Door) {
      return door?.current || {}
    } else if (category === BuildingSections.Balcony) {
      return balcony?.current || {}
    } else if (category === BuildingSections.Terrace) {
      return terrace?.current || {}
    }
  }, [window, door, balcony, terrace])

  const renderCustomForm = (sections: any, title: string, index: number) => {
    if (sections?.length) {
      // Extract values from the current category
      const values = getValues(title)
      // Filter out items that should not be visible
      const items = sections.map((section: any) => {
        if (!section?.visibleIfValues && !section?.items && !section?.items?.find((item: any) => item?.visibleIfValues)) {
          // If no visibleIfValues are defined, show the item
          return section
        }
        if (!section?.visibleIfValues) {
          if (section?.items) {
            return {
              ...section,
              // Filter out items that should not be visible
              items: section?.items?.filter((item: any) => {
                if (!item?.visibleIfValues) {
                  return true
                }
                return item?.visibleIfValues?.every((visibleIfValue: any) => {
                  if (Array.isArray(values?.[visibleIfValue.key])) {
                    return visibleIfValue.values?.some((value: any) => values?.[visibleIfValue.key]?.includes(value))
                  }
                  // const values = getValues(visibleIfValue.key?.split('_')[0])
                  return visibleIfValue.values?.includes(values?.[visibleIfValue.key])
                })
              })
            }
          }
        }
        // Check if all visibleIfValues are met
        const conditionsMet = section?.visibleIfValues?.every((visibleIfValue: any) => {
          if (Array.isArray(values?.[visibleIfValue.key])) {
            return visibleIfValue.values?.some((value: any) => values?.[visibleIfValue.key]?.includes(value))
          }
          // const values = getValues(visibleIfValue.key?.split('_')[0])
          return visibleIfValue.values?.includes(values?.[visibleIfValue.key])
        })
        if (conditionsMet) {
          return section
        }
        return null
      }).filter((item: any) => item)

      // Remove sequential Divider items and also the first and last ones if they are Dividers
      for (let i = 0; i < items.length; i++) {
        if (items[i]?.type === ComponentTypes.Divider) {
          if (i === 0) {
            items.shift()
          } else if (i === items.length - 1) {
            items.pop()
          } else if (items[i + 1]?.type === ComponentTypes.Divider || items[i - 1]?.type === ComponentTypes.Subtitle) {
            items.splice(i, 1)
            i--
          }
        }
      }

      if (items?.length === 1 && items[0]?.type === ComponentTypes.Subtitle) {
        return null
      }
      if (!items?.length) {
        return null
      }
      return (
        <CustomForm
          key={`form-${title}-${index}`}
          section={items}
          values={values}
          onChange={(key: string, value: string) => onChange(title, key, value)}
          lastUpdatedValue={lastUpdatedValue}
          disabled={!isAdmin}
        />
      )
    }
    return null
  }

  const renderCustomFormContainer = (sections: any, title: string): any => {
    return sections.map((section: any, index: number) => renderCustomForm(section, title, index))
  }

  const BuildingSectionsLayout = useMemo(() => {
    return BuildingPartsLayout.map((layout: any) => {
      if (!layout?.sections?.length) {
        return null
      }
      return (
        <CollapseSection
          key={`section-${layout.title}`}
          title={t(layout?.label ?? layout.title)}
          open={openSections?.includes(layout.title)}
          onClick={() => toggleOpenSection(layout.title)}
        >
          {renderCustomFormContainer(layout.sections, layout.title)}
        </CollapseSection>
      )
    })
  }, [openSections, lastUpdatedValue])

  // Do not show anything if params are missing
  const uuid = props?.uuid || null
  if (!uuid || uuid?.length !== 36) {
    return <CircularProgress sx={styles.loading} />
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>{BuildingSectionsLayout}</Box>
    </Box>
  )
}

export default observer(BuildingParts)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'hidden'
  },
  loading: {
    margin: 'auto'
  }
} as const
