import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import { HexColorPicker } from 'react-colorful'
import Chip from './Chip'
import { Colors } from '../../Utils/theme'

export default function ColorPicker(props: any) {
  const { t } = useTranslation()

  const { onChange, value } = props

  const [color, setColor] = useState(value ?? Colors.white)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)

  useEffect(() => {
    if (!anchor && value) {
      setColor(value)
    }
  }, [value])

  useEffect(() => {
    if (color && anchor) {
      onChange(color)
    }
  }, [color])

  const openPicker = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget)
  }
  const hidePicker = () => setAnchor(null)

  return (
    <>
      <ButtonBase
        sx={styles.button}
        onClick={openPicker}
        aria-describedby={!!anchor ? 'custom-popup' : undefined}
      >
        <Box sx={{ ...styles.color, backgroundColor: color ?? Colors.white }} />
      </ButtonBase>
      <BasePopup
        id={!!anchor ? 'custom-popup' : undefined}
        open={!!anchor}
        anchor={anchor}
        placement='top'
        style={{ zIndex: 1024 }}
      >
        <Box sx={styles.content}>
          <Chip
            onClick={hidePicker}
            buttonSx={styles.chipButton}
            sx={styles.chip}
            text={t('close')}
          />
          <HexColorPicker
            color={color}
            onChange={setColor}
            style={styles.colorPicker}
          />
        </Box>
      </BasePopup>
    </>
  )
}

const styles = {
  button: {
    position: 'absolute',
    top: '2.5rem',
    left: '0.5rem',
    height: '2rem',
    width: '2rem',
    padding: 0
  },
  color: {
    height: '100%',
    width: '100%',
    borderRadius: '0.3125rem'
  },
  content: {
    padding: '1.25rem',
    paddingTop: '3.25rem',
    margin: '0.5rem',
    marginBottom: '1rem',
    borderRadius: '0.5rem',
    backgroundColor: Colors.white,
    boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.25)'
  },
  chipButton: {
    position: 'absolute',
    top: '1.25rem',
    right: '1.5rem'
  },
  chip: {
    minWidth: '4rem'
  },
  colorPicker: {
    width: '18rem'
  }
} as const