import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import upperFirst from 'lodash/upperFirst'
import Box from '@mui/material/Box'
import CostLineChart from '../../../Components/Common/Charts/CostLineChart'
import CostBarChart from '../../../Components/Common/Charts/CostBarChart'
import IconButton from '../../../Components/Common/IconButton'
import { Select, MultiSelect } from '../../../Components'
import { CostTypes, DefaultGraphColors, GraphTypes } from '../../../Utils/constants'
import { fromCents } from '../../../Utils/transformers'
import { Colors } from '../../../Utils/theme'

const Costs = () => {
  const { propertyStore, projectStore, costStore }: any = useStore()
  const { t } = useTranslation()
  const params = useParams()

  const { property } = propertyStore
  const { tab } = projectStore
  const { costs } = costStore

  const [types, setTypes] = useState<string[]>(Object.values(CostTypes))
  const [inflationRate, setInflationRate] = useState<number | null>(null)
  const [interestRate, setInterestRate] = useState<any>(null)
  const [selectedPeriod, setSelectedPeriod] = useState('monthly')
  const [currentYear, setCurrentYear] = useState(parseInt(DateTime.now().toFormat('yyyy')))

  const updateTypes = (updatedTypes: string[]) => {
    // Format in same order as the cost types
    const formattedTypes: any = Object.values(CostTypes)
      .map((type) => updatedTypes?.find((val) => val === type) ? type : null)
      .filter((type) => type)
    setTypes(formattedTypes)
  }

  const toPreviousTimePeriod = () => {
    if (selectedPeriod === 'monthly') {
      setCurrentYear(currentYear - 1)
    } else if (selectedPeriod === 'yearly') {
      setCurrentYear(currentYear - 12)
    }
  }
  const toNextTimePeriod = () => { 
    if (selectedPeriod === 'monthly' && currentYear < DateTime.now().year + 1) {
      setCurrentYear(currentYear + 1)
    } else if (selectedPeriod === 'yearly' && currentYear < DateTime.now().year) {
      setCurrentYear(currentYear + 12)
    }
  }

  const updateSelectedPeriod = (period: string) => {
    setSelectedPeriod(period)
    if (period === 'yearly') {
      setCurrentYear(DateTime.now().year)
    }
  }

  useEffect(() => {
    if (tab === 'projects') {
      // navigate(`/properties/${params.uuid}/projects`)
    }
  }, [tab])

  useEffect(() => {
    const uuid = params?.uuid || null
    if (uuid && uuid?.length === 36) {
      propertyStore.getProperty(uuid)
      projectStore.setTab('costs')
    }
  }, [])

  useEffect(() => {
    if (property) {
      costStore.getCosts(property.uuid)
    }
  }, [property])

  useEffect(() => {
    if (costs) {
      if (selectedPeriod === 'monthly') {
        // Update inflation and interest rates to the current year's inflation rate
        const currentYearCost = costs?.[currentYear] || null
        if (currentYearCost) {
          setInflationRate(currentYearCost.inflationRate)
          setInterestRate(currentYearCost.interestRate)
        } else {
          setInflationRate(2)
          setInterestRate(null)
        }
      }
    }
  }, [currentYear, costs])

  const CostTypeOptions = Object.values(CostTypes).map((option: any) => ({ 
    value: option,
    label: t(option)
  }))
  const InflationOptions = Array.from({ length: 100 }, (_, i) => i).map((i) => ({
    value: i / 10,
    label: `${i / 10}%`?.replace('.', ',')
  }))
  const InterestOptions = Array.from({ length: 150 }, (_, i) => i).map((i) => ({
    value: i / 10,
    label: `${i / 10}%`?.replace('.', ',')
  }))
  const TimePeriodOptions = [
    { value: 'monthly', label: t('by_month') },
    { value: 'yearly', label: t('by_year') }
  ]
  
  const CostsInPeriod = useMemo(() => {
    if (costs) {
      if (selectedPeriod === 'monthly') {
        const yearlyInflation = costs?.[currentYear]?.inflationRate || 0
        const yearlyInterest = costs?.[currentYear]?.interestRate || 0
        const costsForPeriod = costs?.[currentYear]?.costs
        const dataForPeriod = types.map((type) => {
          return {
            id: t(type),
            color: property?.costGraphColors?.[type] || DefaultGraphColors?.[type],
            data: Array.from({ length: 12 }, (_, i) => {
              const cost = costsForPeriod?.[i + 1] || null
              let monthlyCost = cost ? cost?.costs.find((c: any) => c.type === type)?.total : 0
              
              if (type !== CostTypes.Loans && (yearlyInflation || yearlyInflation === 0) && inflationRate !== yearlyInflation) {
                // Convert percentage values to decimals for inflation calculation
                const oldInflationDecimal = yearlyInflation / 100
                const newInflationDecimal = Number(inflationRate || 0) / 100
                // Remove old inflation rate effect and apply new rate
                const baseAmount = monthlyCost / (1 + oldInflationDecimal)
                monthlyCost = baseAmount * (1 + newInflationDecimal)
              } 
              else if (type === CostTypes.Loans && (yearlyInterest || yearlyInterest === 0) && interestRate !== yearlyInterest) {
                // Convert percentage values to decimals for interest calculation
                const oldInterestDecimal = yearlyInterest / 100
                const newInterestDecimal = Number(interestRate || 0) / 100
                // Remove old interest rate effect and apply new rate
                const baseAmount = monthlyCost / (1 + oldInterestDecimal)
                monthlyCost = baseAmount * (1 + newInterestDecimal)
              }
        
              return {
                x: i + 1,
                y: fromCents(monthlyCost)
              }
            })
          }
        })
        return dataForPeriod
      } else {
        const dataForPeriod = types.map((type) => {
          return {
            id: t(type),
            color: property?.costGraphColors?.[type] || DefaultGraphColors[type],
            data: Array.from({ length: 12 }, (_, i) => {
              const year = currentYear - 10 + i
              const cost = costs?.[year]
              // Remove _ from type and capitalize each word after 'total'
              const formattedType = type?.split('_').map((word) => upperFirst(word)).join('')
              let yearlyCost = cost ? cost?.[`total${formattedType}`] : 0
              const yearlyInflation = cost?.inflationRate || 0
              const yearlyInterest = cost?.interestRate || 0
        
              if (type !== CostTypes.Loans && (yearlyInflation || yearlyInflation === 0) && inflationRate !== yearlyInflation) {
                // Convert percentage values to decimals for inflation calculation
                const oldInflationDecimal = yearlyInflation / 100
                const newInflationDecimal = Number(inflationRate || 0) / 100
                // Remove old inflation rate effect and apply new rate
                const baseAmount = yearlyCost / (1 + oldInflationDecimal)
                yearlyCost = baseAmount * (1 + newInflationDecimal)
              } 
              else if (type === CostTypes.Loans && (yearlyInterest || yearlyInterest === 0) && interestRate !== yearlyInterest) {
                // Convert percentage values to decimals for interest calculation
                const oldInterestDecimal = yearlyInterest / 100
                const newInterestDecimal = Number(interestRate || 0) / 100
                // Remove old interest rate effect and apply new rate
                const baseAmount = yearlyCost / (1 + oldInterestDecimal)
                yearlyCost = baseAmount * (1 + newInterestDecimal)
              }
        
              return {
                x: i + 1,
                y: fromCents(yearlyCost)
              }
            })
          }
        })
        return dataForPeriod
      }
    }
    return []
  }, [costs, types, inflationRate, interestRate, selectedPeriod, currentYear])

  const CostsChart = useMemo(() => {
    if (CostsInPeriod?.length) {
      if (property?.costGraphType === GraphTypes.Line) {
        return (
          <CostLineChart
            costTypes={types.map((type) => t(type))}
            data={CostsInPeriod}
            colors={types.map((type) => property?.costGraphColors?.[type] || DefaultGraphColors[type])}
            year={currentYear}
            isYearly={selectedPeriod === 'yearly'}
          />
        )
      }
      return (
        <CostBarChart
          keys={types.map((type) => t(type)).reverse()}
          data={CostsInPeriod}
          colors={
            types.map((type) => property?.costGraphColors?.[type] || DefaultGraphColors[type]).reverse()
          }
          year={currentYear}
          isYearly={selectedPeriod === 'yearly'}
        />
      )
    }
    return null
  }, [selectedPeriod, costs, types, inflationRate, interestRate, currentYear])

  const renderCostTypeFilter = () => {
    if (property?.showCostTypeFilter) {
      return (
        <MultiSelect
          options={CostTypeOptions}
          value={types}
          onChange={updateTypes}
          placeholder={t('cost_type')}
          sx={styles.sortSelect}
          width='17.5rem'
          mr='1.5rem'
          showCountAsValue
        />
      )
    }
    return null
  }

  const renderInflationSettings = () => {
    if (property?.showInflationVariableAdjustment) {
      return (
        <Select
          options={InflationOptions}
          value={inflationRate}
          onChange={setInflationRate}
          placeholder={t('inflation')}
          sx={styles.sortSelect}
          width='13rem'
          mr='1.5rem'
          badgeColor={Colors.primary}
          badgeLeftOffset='6.125rem'
          valueAsBadge
        />
      )
    }
    return null
  }

  const renderLoanInterestSettings = () => {
    if (property?.showLoanInterestAdjustment) {
      return (
        <Select
          options={InterestOptions}
          value={interestRate}
          onChange={setInterestRate}
          placeholder={t('interest')}
          sx={styles.sortSelect}
          width='12rem'
          badgeColor={Colors.primary}
          badgeLeftOffset='4.75rem'
          valueAsBadge
        />
      )
    }
    return null
  }

  if (!property || !params.uuid || params.uuid?.length !== 36) {
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.actions}>
        <Box sx={styles.row}>{renderCostTypeFilter()}</Box>
        <Box sx={styles.actionsRow}>
          {renderInflationSettings()}
          {renderLoanInterestSettings()}
          {
            !property?.showInflationVariableAdjustment &&
            !property?.showLoanInterestAdjustment &&
            <Box sx={{ width: '100%' }} />
          }
          <Box sx={{ ...styles.row, mb: 0 }}>
            <IconButton
              icon='arrowLeft'
              onClick={toPreviousTimePeriod}
              sx={styles.previousButton}
              iconSize='1.125rem'
            />
            <Select
              options={TimePeriodOptions}
              value={selectedPeriod}
              onChange={updateSelectedPeriod}
              width='14.75rem'
            />
            <IconButton
              icon='arrowRight'
              onClick={toNextTimePeriod}
              sx={styles.nextButton}
              iconSize='1.125rem'
              disabled={
                (selectedPeriod === 'monthly' && currentYear === DateTime.now().year + 1) ||
                (selectedPeriod === 'yearly' && currentYear === DateTime.now().year)
              }
            />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.costsContainer}>
        <Box sx={styles.chartOuterContainer}>
          <Box sx={styles.chartContainer}>{CostsChart}</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default observer(Costs)

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '0.75rem',
    pb: '4rem',
    overflow: 'hidden'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    width: '100%',
    mb: '1.25rem',
    border: 0
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1
  },
  actionsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1
  },
  sortSelect: {
    width: {
      xxl: '20rem',
      xl: '18rem',
      xs: '14rem'
    }
  },
  actionButton: {
    height: '3.125rem',
    padding: {
      xxl: '0rem 2.5rem',
      xs: '0rem 1.875rem'
    }
  },
  costsContainer: {
    minHeight: 'calc(100vh - 17rem)',
    // maxHeight: 'calc(100vh - 13rem)',
    maxWidth: 'calc(100vw - 20rem)',
    // overflowY: 'auto',
    overflowY: 'hidden',
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    padding: '1.5rem',
    scrollBehavior: 'smooth'
  },
  chartOuterContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 17rem)',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
  },
  previousButton: {
    height: '3.125rem',
    width: '3.125rem',
    minWidth: '3.125rem',
    backgroundColor: Colors.white,
    border: `0.0625rem solid ${Colors.border}`,
    justifyContent: 'center',
    ml: '1rem',
    mr: '0.75rem',
    '&:hover': {
      backgroundColor: Colors.white50
    }
  },
  nextButton: {
    height: '3.125rem',
    width: '3.125rem',
    minWidth: '3.125rem',
    backgroundColor: Colors.white,
    border: `0.0625rem solid ${Colors.border}`,
    ml: '0.75rem',
    '&:hover': {
      backgroundColor: Colors.white50
    }
  },
} as const
