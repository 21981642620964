import {
  Subtitle,
  Input,
  Select,
  MultiSelect,
  Row,
  OtherPlaceholder,
  YearOptions,
  Option,
  IfOtherSelected,
  BooleanOptions,
  IfYesSelected,
  IfCustomSelected,
} from './layout'

export enum AreaSections {
  // Kulku- ja ajoväylät
  AccessAndDriveways = 'access_and_driveways',
  // Vedenpoisto
  WaterDrainage = 'water_drainage',
  // Nurmialueet ja istutukset
  LawnAreasAndPlantings = 'lawn_areas_and_plantings',
  // Rakennusten vierustat
  BuildingSides = 'building_sides',
  // Ulkopysäköinti
  OutdoorParking = 'outdoor_parking',
  // Pihakalusteet ja varusteet
  OutdoorFixturesAndEquipment = 'outdoor_fixtures_and_equipment',
  // Pihakansi
  YardDeck = 'yard_deck',
  // Sivurakennukset
  SideBuildings = 'side_buildings'
}

const {
  AccessAndDriveways,
  WaterDrainage,
  LawnAreasAndPlantings,
  BuildingSides,
  OutdoorParking,
  OutdoorFixturesAndEquipment,
  YardDeck,
  SideBuildings
} = AreaSections

export const AreaSectionOptions = [
  Option(AccessAndDriveways),
  Option(WaterDrainage),
  Option(LawnAreasAndPlantings),
  Option(BuildingSides),
  Option(OutdoorParking),
  Option(OutdoorFixturesAndEquipment),
  Option(YardDeck),
  Option(SideBuildings)
]

const Areas = {
  [AccessAndDriveways]: [
    [
      Subtitle('basic_information'),
      {
        key: 'materials',
        label: 'material',
        ...MultiSelect,
        options: [
          Option('asphalt', 'asphalt_paving'),
          Option('concrete_slab'),
          Option('natural_stone', 'natural_stone_paving'),
          Option('crushed_stone'),
          Option('macadam'),
          Option('stone_dust'),
          Option('concrete'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'asphaltInstallationYear',
        label: 'asphalt_installation_year',
        visibleIfValues: [IfCustomSelected('materials', 'asphalt')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'concreteSlabInstallationYear',
        label: 'concrete_slab_installation_year',
        visibleIfValues: [IfCustomSelected('materials', 'concrete_slab')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'naturalStoneInstallationYear',
        label: 'natural_stone_installation_year',
        visibleIfValues: [IfCustomSelected('materials', 'natural_stone')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'crushedStoneInstallationYear',
        label: 'crushed_stone_installation_year',
        visibleIfValues: [IfCustomSelected('materials', 'crushed_stone')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'macadamInstallationYear',
        label: 'macadam_installation_year',
        visibleIfValues: [IfCustomSelected('materials', 'macadam')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'stoneDustInstallationYear',
        label: 'stone_dust_installation_year',
        visibleIfValues: [IfCustomSelected('materials', 'stone_dust')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'concreteInstallationYear',
        label: 'concrete_installation_year',
        visibleIfValues: [IfCustomSelected('materials', 'concrete')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'otherMaterial',
        label: 'other_material',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('materials')],
        ...Input
      },
      {
        key: 'otherMaterialInstallationYear',
        label: 'other_material_installation_year',
        visibleIfValues: [IfOtherSelected('materials')],
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [WaterDrainage]: [
    [
      Subtitle('basic_information'),
      {
        key: 'type',
        label: 'type',
        ...Select,
        options: [
          Option('sloped_towards_terrain'),
          Option('through_surface_water_drains_to_terrain'),
          Option('through_stormwater_drains_to_municipal_infrastructure'),
        ]
      },
      {
        key: 'installationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [LawnAreasAndPlantings]: [
    [
      Subtitle('lawn_areas'),
      {
        key: 'lawnAreaType',
        label: 'type',
        ...Select,
        options: [
          Option('natural'),
          Option('planted'),
          Option('no_lawn_area', 'has_none'),
        ]
      },
      {
        key: 'lawnAreaEdging',
        label: 'lawn_area_edging',
        ...Select,
        options: [
          Option('no_edging'),
          Option('wood_chip', 'wood_chips'),
          Option('gravel', 'graveling'),
          Option('cobblestone'),
          Option('boulder_stone'),
          Option('curb_stone'),
          Option('wooden_edge'),
          Option('plastic_edge'),
          Option('metal_edge'),
        ]
      },
      {
        key: 'lawnAreaInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('plantings'),
      {
        key: 'plantingType',
        label: 'type',
        ...Select,
        options: [
          Option('natural'),
          Option('planted'),
          Option('no_planting', 'has_none'),
        ]
      },
      {
        key: 'plantingEdging',
        label: 'planting_edging',
        ...Select,
        options: [
          Option('no_edging'),
          Option('wood_chip', 'wood_chips'),
          Option('gravel', 'graveling'),
          Option('cobblestone'),
          Option('boulder_stone'),
          Option('curb_stone'),
          Option('wooden_edge'),
          Option('plastic_edge'),
          Option('metal_edge'),
        ]
      },
      {
        key: 'plantingInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [BuildingSides]: [
    [
      Subtitle('basic_information'),
      {
        key: 'edgePaving',
        label: 'edge_paving',
        ...Select,
        options: [
          Option('soil_or_grass'),
          Option('sand'),
          Option('stone_crush'),
          Option('macadam'),
          Option('cobblestone'),
          Option('asphalt')
        ]
      },
      {
        key: 'edgePavingInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [OutdoorParking]: [
    [
      Subtitle('basic_information'),
      {
        key: 'areaCoating',
        label: 'coating',
        ...Select,
        options: [
          Option('concrete_slab'),
          Option('natural_stone', 'natural_stone_paving'),
          Option('asphalt'),
          Option('crushed_stone'),
          Option('macadam'),
          Option('stone_dust'),
          Option('concrete')
        ]
      },
      {
        key: 'areaInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [OutdoorFixturesAndEquipment]: [
    [
      Subtitle('basic_information'),
      {
        ...Row,
        items: [
          {
            key: 'hasPlayArea',
            label: 'play_area',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasMailboxes',
            label: 'mailboxes',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasAiringRack',
            label: 'airing_rack',
            ...Select,
            options: BooleanOptions
          }
        ]
      },
      {
        ...Row,
        items: [
          {
            key: 'hasClothesline',
            label: 'clothesline',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasDryingRack',
            label: 'drying_rack',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasBarbecueArea',
            label: 'barbecue_area',
            ...Select,
            options: BooleanOptions
          }
        ]
      },
      {
        ...Row,
        items: [
          {
            key: 'hasBenches',
            label: 'benches',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasChairs',
            label: 'chairs',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasTables',
            label: 'tables',
            ...Select,
            options: BooleanOptions
          }
        ]
      },
      {
        ...Row,
        items: [
          {
            key: 'hasGardenSwings',
            label: 'garden_swings',
            ...Select,
            options: BooleanOptions,
            maxWidth: 'calc(33% - 0.8125rem)'
          }
        ]
      }
    ],
    [
      Subtitle('play_area'),
      {
        key: 'playAreaInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasPlayArea')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('mailboxes'),
      {
        key: 'mailboxInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasMailboxes')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('airing_rack'),
      {
        key: 'airingRackInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasAiringRack')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('clothesline'),
      {
        key: 'clotheslineInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasClothesline')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('drying_rack'),
      {
        key: 'dryingRackInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasDryingRack')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('barbecue_area'),
      {
        key: 'barbecueAreaInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasBarbecueArea')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('benches'),
      {
        key: 'benchesInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasBenches')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('chairs'),
      {
        key: 'chairsInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasChairs')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('tables'),
      {
        key: 'tablesInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasTables')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('garden_swings'),
      {
        key: 'gardenSwingsInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasGardenSwings')],
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [YardDeck]: [
    [
      Subtitle('above_the_yard_deck'),
      {
        ...Row,
        items: [
          {
            key: 'hasOutdoorSpaces',
            label: 'has_outdoor_spaces',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasColdIndoorSpaces',
            label: 'has_cold_indoor_spaces',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasSemiWarmIndoorSpaces',
            label: 'has_semi_warm_indoor_spaces',
            ...Select,
            options: BooleanOptions
          }
        ]
      },
      {
        ...Row,
        items: [
          {
            key: 'hasWarmIndoorSpaces',
            label: 'has_warm_indoor_spaces',
            ...Select,
            options: BooleanOptions,
            maxWidth: 'calc(33% - 0.8125rem)'
          }
        ]
      }
    ],
    [
      Subtitle('below_the_yard_deck'),
      {
        ...Row,
        items: [
          {
            key: 'hasParkingArea',
            label: 'parking_area',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasYardAreas',
            label: 'has_yard_areas',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasPathways',
            label: 'has_pathways',
            ...Select,
            options: BooleanOptions
          }
        ]
      },
      {
        ...Row,
        items: [
          {
            key: 'hasPlayAreas',
            label: 'has_play_areas',
            ...Select,
            options: BooleanOptions,
            maxWidth: 'calc(33% - 0.8125rem)'
          }
        ]
      }
    ],
    [
      Subtitle('outdoor_spaces'),
      {
        key: 'outdoorSpacesInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasOutdoorSpaces')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('cold_indoor_spaces'),
      {
        key: 'coldIndoorSpacesInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasColdIndoorSpaces')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('semi_warm_indoor_spaces'),
      {
        key: 'semiWarmIndoorSpacesInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasSemiWarmIndoorSpaces')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('warm_indoor_spaces'),
      {
        key: 'warmIndoorSpacesInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasWarmIndoorSpaces')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('parking_area'),
      {
        key: 'parkingAreaInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasParkingArea')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('yard_areas'),
      {
        key: 'yardAreasInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasYardAreas')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('pathways'),
      {
        key: 'pathwaysInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasPathways')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('play_areas'),
      {
        key: 'playAreasInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasPlayAreas')],
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [SideBuildings]: [
    [
      Subtitle('basic_information'),
      {
        ...Row,
        items: [
          {
            key: 'hasParkingBuildings',
            label: 'parking_buildings',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasYardBuildings',
            label: 'yard_buildings',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasTechnicalSpaces',
            label: 'technical_spaces',
            ...Select,
            options: BooleanOptions
          }
        ]
      }
    ],
    [
      Subtitle('parking_buildings'),
      {
        key: 'parkingBuildingsInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasParkingBuildings')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('yard_buildings'),
      {
        key: 'yardBuildingsInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasYardBuildings')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('technical_spaces'),
      {
        key: 'technicalSpacesInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasTechnicalSpaces')],
        ...Select,
        options: YearOptions()
      }
    ]
  ]
}

export const AreaLayout = [
  {
    title: AccessAndDriveways,
    sections: Areas[AccessAndDriveways]
  },
  {
    title: WaterDrainage,
    sections: Areas[WaterDrainage]
  },
  {
    title: LawnAreasAndPlantings,
    sections: Areas[LawnAreasAndPlantings]
  },
  {
    title: BuildingSides,
    sections: Areas[BuildingSides]
  },
  {
    title: OutdoorParking,
    sections: Areas[OutdoorParking]
  },
  {
    title: OutdoorFixturesAndEquipment,
    sections: Areas[OutdoorFixturesAndEquipment]
  },
  {
    title: YardDeck,
    sections: Areas[YardDeck]
  },
  {
    title: SideBuildings,
    sections: Areas[SideBuildings]
  }
]
