import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class CostStore {
  rootStore

  tab: string | null = null
  costs: any = []
  loading = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setTab = (tab: string | null) => { this.tab = tab }
  setCosts = (costs: any) => { this.costs = costs }
  setLoading = (loading: boolean) => { this.loading = loading }

  reset() {
    this.setTab(null)
    this.setCosts([])
    this.setLoading(false)
  }

  async getCosts(pUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getCosts(pUuid)
      if (response?.ok) {
        const data = response?.data || []
        const costs = data?.items || []
        this.setCosts(costs)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
  
  async createOrUpdateCosts(pUuid: string, costs: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.createOrUpdateCosts(pUuid, costs)
      if (response?.ok) {
        this.getCosts(pUuid)
        showMessage(i18n.t('costs_updated'))
      } else {
        showMessage('costs_update_failed')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateCostSettings(pUuid: string, settings: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updatePropertyCostSettings(pUuid, settings)
      if (response?.ok) {
        showMessage(i18n.t('cost_settings_updated'))
      } else {
        showMessage('cost_settings_update_failed')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}
