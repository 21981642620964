import apisauce from 'apisauce'

const ApiUrls = {
  Local: `http://127.0.0.1:1337/v1/api/`,
  Stage: 'https://kiinteistomuisti-api-staging.peanutsgroup.com/v1/api/',
  Production: 'https://api.kiinteistomuisti.com/v1/api/'
}

let ApiUrl = ApiUrls.Production
if (window.location.hostname.includes('staging.peanutsgroup.com')) {
  ApiUrl = ApiUrls.Stage
}
if (window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')) {
  ApiUrl = ApiUrls.Local
}

const create = (baseURL = ApiUrl) => {
  const api = apisauce.create({
    baseURL,
    headers: {},
    timeout: 15000,
    withCredentials: true
  })

  // Public endpoints
  const login = (body: any) => api.post('account/login', body)
  const requestPasswordReset = (body: any) => api.post('account/password-reset', body)
  const getPasswordResetRequest = (code: string) => api.get(`account/password-reset-requests/${code}`)
  const completePasswordReset = (body: any) => api.put('account/password-reset', body)
  const getInvite = (code: string) => api.get(`account/invites/${code}`)
  const verifyAccount = (body: any) => api.put('account/verify', body)

  // Me
  const logout = () => api.put('account/logout')
  const keepAlive = () => api.get('session/keep-alive')
  const getMe = () => api.get('account/me')
  const updateMe = (body: any) => api.put('account/me', body)
  const updateMyProfilePhoto = (body: any) => {
    const formData = new FormData()
    formData.append('file', body)
    const options = { headers: { 'Content-Type': 'multipart/form-data' } }
    return api.post('account/me/profile-photo', formData, options)
  }
  const archiveMyProfilePhoto = () => api.delete('account/me/profile-photo')
  const archiveMe = () => api.delete('account/me')

  // Users
  const getUsers = (params?: any) => api.get('users', params || {})
  const getUser = (uuid: string) => api.get(`users/${uuid}`)
  const updateUser = (uuid: string, body: any) => api.put(`users/${uuid}`, body)
  const updateUserProfilePhoto = (uuid: string, body: any) => {
    const formData = new FormData()
    formData.append('file', body)
    const options = { headers: { 'Content-Type': 'multipart/form-data' } }
    return api.post(`users/${uuid}/profile-photo`, formData, options)
  }
  const archiveUserProfilePhoto = (uuid: string) => api.delete(`users/${uuid}/profile-photo`)
  const createUser = (body: any) => api.post('users', body)
  const archiveUser = (uuid: string) => api.delete(`users/${uuid}`)
  const restoreUser = (uuid: string) => api.put(`users/${uuid}/restore`)

  // Properties
  const getProperties = (params?: any) => api.get('properties', params || {})
  const getProperty = (uuid: string) => api.get(`properties/${uuid}`)
  const getPropertyAreas = (pUuid: string) => api.get(`properties/${pUuid}/areas`)
  const createProperty = (body: any) => api.post('properties', body)
  const updateProperty = (uuid: string, body: any) => api.put(`properties/${uuid}`, body)
  const updatePropertyAreas = (pUuid: string, body: any) => api.put(`properties/${pUuid}/areas`, body)
  const updatePropertyBackgroundPhoto = (uuid: string, body: any) => {
    const formData = new FormData()
    formData.append('file', body)
    const options = { headers: { 'Content-Type': 'multipart/form-data' } }
    return api.put(`properties/${uuid}/background-photo`, formData, options)
  }
  const archiveProperty = (uuid: string) => api.delete(`properties/${uuid}`)
  const getPropertyFiles = (uuid: string) => api.get(`properties/${uuid}/files`)
  const uploadPropertyFile = (uuid: string, body: any) => {
    const formData = new FormData()
    formData.append('file', body)
    const options = { headers: { 'Content-Type': 'multipart/form-data' } }
    return api.post(`properties/${uuid}/files`, formData, options)
  }
  const archivePropertyFile = (pUuid: string, fUuid: string) => api.delete(`properties/${pUuid}/files/${fUuid}`)
  const getPropertyCostSettings = (uuid: string) => api.get(`properties/${uuid}/cost-settings`)
  const updatePropertyCostSettings = (uuid: string, body: any) => api.put(`properties/${uuid}/cost-settings`, body)
  const getPropertyEntities = (uuid: string, params?: any) => api.get(`properties/${uuid}/entities`, params || {})

  // Buildings
  const getBuildings = (uuid: string, params?: any) => api.get(`properties/${uuid}/buildings`, params || {})
  const getBuilding = (pUuid: string, bUuid: string) => api.get(`properties/${pUuid}/buildings/${bUuid}`)
  const getBuildingStructures = (pUuid: string, bUuid: string) => api.get(`properties/${pUuid}/buildings/${bUuid}/structures`)
  const getBuildingParts = (pUuid: string, bUuid: string) => api.get(`properties/${pUuid}/buildings/${bUuid}/parts`)
  const getBuildingSystems = (pUuid: string, bUuid: string) => api.get(`properties/${pUuid}/buildings/${bUuid}/systems`)
  const createBuilding = (uuid: string, body: any) => api.post(`properties/${uuid}/buildings`, body)
  const updateBuilding = (pUuid: string, bUuid: string, body: any) => api.put(`properties/${pUuid}/buildings/${bUuid}`, body)
  const updateBuildingStructures = (pUuid: string, bUuid: string, body: any) => api.put(`properties/${pUuid}/buildings/${bUuid}/structures`, body)
  const updateBuildingParts = (pUuid: string, bUuid: string, body: any) => api.put(`properties/${pUuid}/buildings/${bUuid}/parts`, body)
  const updateBuildingSystems = (pUuid: string, bUuid: string, body: any) => api.put(`properties/${pUuid}/buildings/${bUuid}/systems`, body)
  const updateBuildingBackgroundPhoto = (pUuid: string, bUuid: string, body: any) => {
    const formData = new FormData()
    formData.append('file', body)
    const options = { headers: { 'Content-Type': 'multipart/form-data' } }
    return api.put(`properties/${pUuid}/buildings/${bUuid}/background-photo`, formData, options)
  }
  const archiveBuilding = (pUuid: string, bUuid: string) => api.delete(`properties/${pUuid}/buildings/${bUuid}`)
  const getBuildingFiles = (pUuid: string, bUuid: string) => api.get(`properties/${pUuid}/buildings/${bUuid}/files`)
  const uploadBuildingFile = (pUuid: string, bUuid: string, body: any) => {
    const formData = new FormData()
    formData.append('file', body)
    const options = { headers: { 'Content-Type': 'multipart/form-data' } }
    return api.post(`properties/${pUuid}/buildings/${bUuid}/files`, formData, options)
  }
  const archiveBuildingFile = (pUuid: string, bUuid: string, fUuid: string) => api.delete(`properties/${pUuid}/buildings/${bUuid}/files/${fUuid}`)

  // Apartments
  const getApartments = (uuid: string, params?: any) => api.get(`properties/${uuid}/apartments`, params || {})
  const getApartment = (pUuid: string, aUuid: string) => api.get(`properties/${pUuid}/apartments/${aUuid}`)
  const createApartment = (pUuid: string, body: any) => api.post(`properties/${pUuid}/apartments`, body)
  const updateApartment = (pUuid: string, aUuid: string, body: any) => api.put(`properties/${pUuid}/apartments/${aUuid}`, body)
  const updateApartmentBackgroundPhoto = (pUuid: string, aUuid: string, fUuid: string) => api.put(`properties/${pUuid}/apartments/${aUuid}/background-photo`, { fUuid })
  const archiveApartment = (pUuid: string, aUuid: string) => api.delete(`properties/${pUuid}/apartments/${aUuid}`)
  const getApartmentFiles = (pUuid: string, aUuid: string) => api.get(`properties/${pUuid}/apartments/${aUuid}/files`)
  const uploadApartmentFile = (pUuid: string, aUuid: string, body: any) => {
    const formData = new FormData()
    formData.append('file', body)
    const options = { headers: { 'Content-Type': 'multipart/form-data' } }
    return api.post(`properties/${pUuid}/apartments/${aUuid}/files`, formData, options)
  }
  const updateApartmentFile = (pUuid: string, aUuid: string, fUuid: string, body: any) => api.put(`properties/${pUuid}/apartments/${aUuid}/files/${fUuid}`, body)
  const archiveApartmentFile = (pUuid: string, aUuid: string, fUuid: string) => api.delete(`properties/${pUuid}/apartments/${aUuid}/files/${fUuid}`)

  // Rooms
  const getRooms = (pUuid: string, aUuid: string, params?: any) => api.get(`properties/${pUuid}/apartments/${aUuid}/rooms`, params || {})
  const getRoom = (pUuid: string, aUuid: string, rUuid: string) => api.get(`properties/${pUuid}/apartments/${aUuid}/rooms/${rUuid}`)
  const getRoomFloor = (pUuid: string, aUuid: string, rUuid: string) => api.get(`properties/${pUuid}/apartments/${aUuid}/rooms/${rUuid}/floor`)
  const getRoomCeiling = (pUuid: string, aUuid: string, rUuid: string) => api.get(`properties/${pUuid}/apartments/${aUuid}/rooms/${rUuid}/ceiling`)
  const getRoomWall = (pUuid: string, aUuid: string, rUuid: string, wallNumber: number) => api.get(`properties/${pUuid}/apartments/${aUuid}/rooms/${rUuid}/walls/${wallNumber}`)
  const createRoom = (pUuid: string, aUuid: string, body: any) => api.post(`properties/${pUuid}/apartments/${aUuid}/rooms`, body)
  const updateRoom = (pUuid: string, aUuid: string, rUuid: string, body: any) => api.put(`properties/${pUuid}/apartments/${aUuid}/rooms/${rUuid}`, body)
  const updateRoomFloor = (pUuid: string, aUuid: string, rUuid: string, body: any) => api.put(`properties/${pUuid}/apartments/${aUuid}/rooms/${rUuid}/floor`, body)
  const updateRoomCeiling = (pUuid: string, aUuid: string, rUuid: string, body: any) => api.put(`properties/${pUuid}/apartments/${aUuid}/rooms/${rUuid}/ceiling`, body)
  const updateRoomWall = (pUuid: string, aUuid: string, rUuid: string, wallNumber: number, body: any) => api.put(`properties/${pUuid}/apartments/${aUuid}/rooms/${rUuid}/walls/${wallNumber}`, body)
  const updateRoomBackgroundPhoto = (pUuid: string, aUuid: string, rUuid: string, file: any) => {
    const formData = new FormData()
    formData.append('file', file)
    const options = { headers: { 'Content-Type': 'multipart/form-data' } }
    return api.put(`properties/${pUuid}/apartments/${aUuid}/rooms/${rUuid}/background-photo`, formData, options)
  }
  const archiveRoom = (pUuid: string, aUuid: string, rUuid: string) => api.delete(`properties/${pUuid}/apartments/${aUuid}/rooms/${rUuid}`)

  // Spaces
  const getSpaces = (pUuid: string, bUuid: string, params?: any) => api.get(`properties/${pUuid}/buildings/${bUuid}/spaces`, params || {})
  const getSpace = (pUuid: string, bUuid: string, sUuid: string) => api.get(`properties/${pUuid}/buildings/${bUuid}/spaces/${sUuid}`)
  const getSpaceFloor = (pUuid: string, bUuid: string, sUuid: string) => api.get(`properties/${pUuid}/buildings/${bUuid}/spaces/${sUuid}/floor`)
  const getSpaceCeiling = (pUuid: string, bUuid: string, sUuid: string) => api.get(`properties/${pUuid}/buildings/${bUuid}/spaces/${sUuid}/ceiling`)
  const getSpaceWall = (pUuid: string, bUuid: string, sUuid: string, wallNumber: number) => api.get(`properties/${pUuid}/buildings/${bUuid}/spaces/${sUuid}/walls/${wallNumber}`)
  const createSpace = (pUuid: string, bUuid: string, body: any) => api.post(`properties/${pUuid}/buildings/${bUuid}/spaces`, body)
  const updateSpace = (pUuid: string, bUuid: string, sUuid: string, body: any) => api.put(`properties/${pUuid}/buildings/${bUuid}/spaces/${sUuid}`, body)
  const updateSpaceFloor = (pUuid: string, bUuid: string, sUuid: string, body: any) => api.put(`properties/${pUuid}/buildings/${bUuid}/spaces/${sUuid}/floor`, body)
  const updateSpaceCeiling = (pUuid: string, bUuid: string, sUuid: string, body: any) => api.put(`properties/${pUuid}/buildings/${bUuid}/spaces/${sUuid}/ceiling`, body)
  const updateSpaceWall = (pUuid: string, bUuid: string, sUuid: string, wallNumber: number, body: any) => api.put(`properties/${pUuid}/buildings/${bUuid}/spaces/${sUuid}/walls/${wallNumber}`, body)
  const updateSpaceBackgroundPhoto = (pUuid: string, bUuid: string, sUuid: string, file: any) => {
    const formData = new FormData()
    formData.append('file', file)
    const options = { headers: { 'Content-Type': 'multipart/form-data' } }
    return api.put(`properties/${pUuid}/buildings/${bUuid}/spaces/${sUuid}/background-photo`, formData, options)
  }
  const archiveSpace = (pUuid: string, bUuid: string, sUuid: string) => api.delete(`properties/${pUuid}/buildings/${bUuid}/spaces/${sUuid}`)

  // Projects
  const getProjects = (uuid: string, params?: any) => api.get(`properties/${uuid}/projects`, params || {})
  const getProject = (pUuid: string, projUuid: string) => api.get(`properties/${pUuid}/projects/${projUuid}`)
  const createProject = (uuid: string, body: any) => api.post(`properties/${uuid}/projects`, body)
  const updateProject = (pUuid: string, projUuid: string, body: any) => api.put(`properties/${pUuid}/projects/${projUuid}`, body)
  const archiveProject = (pUuid: string, projUuid: string) => api.delete(`properties/${pUuid}/projects/${projUuid}`)
  const getProjectFiles = (pUuid: string, projUuid: string) => api.get(`properties/${pUuid}/projects/${projUuid}/files`)
  const updateProjectBackgroundPhoto = (pUuid: string, projUuid: string, fUuid: any) => api.put(`properties/${pUuid}/projects/${projUuid}/background-photo`, { fUuid })
  const uploadProjectFile = (pUuid: string, projUuid: string, body: any) => {
    const formData = new FormData()
    formData.append('file', body)
    const options = { headers: { 'Content-Type': 'multipart/form-data' } }
    return api.post(`properties/${pUuid}/projects/${projUuid}/files`, formData, options)
  }
  const archiveProjectFile = (pUuid: string, projUuid: string, fUuid: string) => api.delete(`properties/${pUuid}/projects/${projUuid}/files/${fUuid}`)
  const createProjectComment = (pUuid: string, projUuid: string, body: any) => api.post(`properties/${pUuid}/projects/${projUuid}/comments`, body)
  const updateProjectComment = (pUuid: string, projUuid: string, cUuid: string, body: any) => api.put(`properties/${pUuid}/projects/${projUuid}/comments/${cUuid}`, body)
  const archiveProjectComment = (pUuid: string, projUuid: string, cUuid: string) => api.delete(`properties/${pUuid}/projects/${projUuid}/comments/${cUuid}`)

  // Costs
  const getCosts = (uuid: string, params?: any) => api.get(`properties/${uuid}/costs`, params || {})
  const createOrUpdateCosts = (uuid: string, body: any) => api.put(`properties/${uuid}/costs`, body)

  // Reminders
  const getPropertyReminders = (uuid: string, params?: any) => api.get(`properties/${uuid}/reminders`, params || {})
  const createPropertyReminder = (uuid: string, body: any) => api.post(`properties/${uuid}/reminders`, body)
  const updatePropertyReminder = (pUuid: string, rUuid: string, body: any) => api.put(`properties/${pUuid}/reminders/${rUuid}`, body)
  const archivePropertyReminder = (pUuid: string, rUuid: string) => api.delete(`properties/${pUuid}/reminders/${rUuid}`)

  // Notifications
  const getNotifications = (params?: any) => api.get('notifications', params || {})

  return {
    logout,
    login,
    keepAlive,
    requestPasswordReset,
    getPasswordResetRequest,
    completePasswordReset,
    getInvite,
    verifyAccount,
    getMe,
    updateMe,
    updateMyProfilePhoto,
    archiveMyProfilePhoto,
    archiveMe,
    getUsers,
    getUser,
    updateUser,
    updateUserProfilePhoto,
    archiveUserProfilePhoto,
    createUser,
    archiveUser,
    restoreUser,
    getProperties,
    getProperty,
    getPropertyAreas,
    createProperty,
    updateProperty,
    updatePropertyAreas,
    updatePropertyBackgroundPhoto,
    archiveProperty,
    getPropertyFiles,
    uploadPropertyFile,
    archivePropertyFile,
    getPropertyCostSettings,
    updatePropertyCostSettings,
    getPropertyEntities,
    getApartments,
    getApartment,
    createApartment,
    updateApartment,
    updateApartmentBackgroundPhoto,
    archiveApartment,
    getApartmentFiles,
    uploadApartmentFile,
    updateApartmentFile,
    archiveApartmentFile,
    getBuildings,
    getBuilding,
    getBuildingStructures,
    getBuildingParts,
    getBuildingSystems,
    createBuilding,
    updateBuilding,
    updateBuildingStructures,
    updateBuildingParts,
    updateBuildingSystems,
    updateBuildingBackgroundPhoto,
    archiveBuilding,
    getBuildingFiles,
    uploadBuildingFile,
    archiveBuildingFile,
    getRooms,
    getRoom,
    getRoomFloor,
    getRoomCeiling,
    getRoomWall,
    createRoom,
    updateRoom,
    updateRoomFloor,
    updateRoomCeiling,
    updateRoomWall,
    updateRoomBackgroundPhoto,
    archiveRoom,
    getSpaces,
    getSpace,
    getSpaceFloor,
    getSpaceCeiling,
    getSpaceWall,
    createSpace,
    updateSpace,
    updateSpaceFloor,
    updateSpaceCeiling,
    updateSpaceWall,
    updateSpaceBackgroundPhoto,
    archiveSpace,
    getProjects,
    getProject,
    createProject,
    updateProject,
    archiveProject,
    getProjectFiles,
    updateProjectBackgroundPhoto,
    uploadProjectFile,
    archiveProjectFile,
    createProjectComment,
    updateProjectComment,
    archiveProjectComment,
    getCosts,
    createOrUpdateCosts,
    getPropertyReminders,
    createPropertyReminder,
    updatePropertyReminder,
    archivePropertyReminder,
    getNotifications
  }
}

const Api = create()

export default Api
