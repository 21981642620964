import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Input from './Input'
import Select from './Select'
import Button from './Button'
import { Colors } from '../../Utils/theme'

const TableActions = (props: any) => {
  const { t } = useTranslation()

  const renderTitle = () => {
    if (props?.title) {
      return <Typography variant='h2'>{props.title}</Typography>
    }
    return null
  }

  const renderSearchInput = () => {
    if (props?.updateSearch) {
      return (
        <Input
          placeholder={t('search_by_name')}
          value={props.searchKey}
          onChange={props.updateSearch}
          onAction={props?.clearSearch}
          iconSx={styles.clearIcon}
          sx={styles.searchInput}
          mr='1.5rem'
          search
        />
      )
    }
    return null
  }

  const renderFilterButton = () => {
    if (props?.onFilterClick) {
      return (
        <Button
          text={props?.filterCount ? `${t('filter')} (${props.filterCount})` : t('filter')}
          onClick={props.onFilterClick}
          icon='sort'
          sx={styles.actionButton}
          ml={props?.filterMarginLeft || '1.5rem'}
        />
      )
    }
    return null
  }

  const renderViewOnMapButton = () => {
    if (props?.onViewMapClick) {
      return (
        <Button
          text={t('view_on_map')}
          onClick={props.onViewMapClick}
          sx={styles.outlinedActionButton}
          variant='secondary'
          ml='1.5rem'
        />
      )
    }
    return null
  }

  const renderSortSelect = () => {
    if (props?.onSortSelect) {
      return (
        <Select
          placeholder={props?.sortPlaceholder || null}
          options={props.sortOptions}
          value={props.sort}
          onChange={props.onSortSelect}
          sx={props?.sortSx || styles.sortSelect}
          minWidth='15rem'
          disableClearable={!props?.allowClearable}
        />
      )
    }
    return null
  }

  const renderBulkActionSelect = () => {
    if (props?.onBulkActionSelect) {
      return (
        <Select
          placeholder={t('bulk_actions')}
          options={props.bulkActionOptions}
          value={props.bulkAction}
          onChange={props.onBulkActionSelect}
          sx={styles.bulkActionSelect}
          ml='1.5rem'
        />
      )
    }
    return null
  }

  const renderViewPhotosButton = () => {
    if (props?.onPhotosClick && props?.align === 'left') {
      return (
        <Button
          text={props?.photoActionText || t('photos')}
          onClick={props.onPhotosClick}
          sx={styles.outlinedActionButton}
          icon='image'
          variant='secondary'
        />
      )
    }
    return null
  }

  const renderPhotosButton = () => {
    if (props?.onPhotosClick && props?.align === 'right') {
      return (
        <Button
          text={props?.photoActionText || t('photos')}
          onClick={props.onPhotosClick}
          sx={styles.outlinedActionButton}
          icon='image'
          variant='secondary'
          ml='1.5rem'
        />
      )
    }
    return null
  }

  const renderAddButton = () => {
    if (props?.onAddClick) {
      return (
        <Button
          text={t('add_new')}
          onClick={props.onAddClick}
          sx={styles.actionButton}
          width='11rem'
          ml='1.5rem'
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.actions}>
      <Box sx={styles.row}>
        {renderTitle()}
        {renderSearchInput()}
        {renderSortSelect()}
        {renderFilterButton()}
        {renderViewOnMapButton()}
        {renderViewPhotosButton()}
      </Box>
      <Box sx={styles.actionsRow}>
        {renderBulkActionSelect()}
        {renderPhotosButton()}
        {renderAddButton()}
      </Box>
    </Box>
  )
}

export default observer(TableActions)

const styles = {
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    width: '100%',
    mb: '1.25rem',
    border: 0
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1
  },
  actionsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1
  },
  searchInput: {
    maxWidth: {
      xxl: '23rem',
      xl: '20rem',
      lg: '14rem',
      xs: '14rem'
    }
  },
  actionButton: {
    height: '3.125rem',
    padding: {
      xxl: '0rem 2.5rem',
      xs: '0rem 1.875rem'
    }
  },
  outlinedActionButton: {
    height: '3.125rem',
    padding: {
      xxl: '0rem 2rem',
      xs: '0rem 1.5rem'
    },
    border: `3px solid ${Colors.primary}`,
    color: Colors.primary,
    '&:hover': {
      backgroundColor: Colors.primary10
    }
  },
  clearIcon: {
    top: '0.675rem',
    right: '1rem',
    width: '1.675rem'
  },
  bulkActionSelect: {
    width: {
      xxl: '16rem',
      xl: '15rem',
      xs: '12rem'
    }
  },
  sortSelect: {
    width: {
      xxl: '20rem',
      xl: '18rem',
      xs: '16rem'
    }
  }
} as const
