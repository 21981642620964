import { useMemo, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import { useDropzone } from 'react-dropzone'
import { GridRenderCellParams } from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Collapse from '@mui/material/Collapse'
import SidebarActions from '../../../Components/Common/SidebarActions'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import { GridColDef } from '@mui/x-data-grid'
import { Button, Tabs, Tab, Table, Input, Select, MultiSelect } from '../../../Components'
import { Colors, Fonts } from '../../../Utils/theme'
import { UserRoles, UserTabs } from '../../../Utils/constants'

const User = () => {
  const { sessionStore, userStore, propertyStore }: any = useStore()
  const { me, isAdmin } = sessionStore
  const { user, loading } = userStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const [tab, setTab] = useState(UserTabs.GeneralInformation)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [teamsEmail, setTeamsEmail] = useState('')
  const [phone, setPhone] = useState('')
  // const [role, setRole] = useState('')
  const [properties, setProperties] = useState<any>(null)
  const [showActionConfirmation, setShowActionConfirmation] = useState(false)
  const [actionType, setActionActionType] = useState('')

  const openArchiveConfirmation = () => {
    setShowActionConfirmation(true)
    setActionActionType('archive_user')
  }
  const openActivateConfirmation = () => {
    setShowActionConfirmation(true)
    setActionActionType('activate_user')
  }
  const closeActionConfirmation = () => {
    setShowActionConfirmation(false)
    setActionActionType('')
  }

  useEffect(() => {
    const uuid = params?.uuid || null
    if (uuid && uuid?.length === 36) {
      userStore.getUser(uuid)
      if (isAdmin) {
        propertyStore.getProperties()
      }
    }
  }, [])

  const getPropertyOptions = () => {
    return propertyStore.properties.map((item: any) => {
      return {
        label: item.nameFi,
        value: item.uuid
      }
    })
  }

  useEffect(() => {
    const uuid = params?.uuid || null
    if (uuid && uuid?.length === 36) {
      if (user?.uuid === uuid) {
        setFirstName(user?.firstName || '')
        setLastName(user?.lastName || '')
        setEmail(user?.email || '')
        setTeamsEmail(user?.teamsEmail || '')
        setPhone(user?.phone || '')
        if (user?.role === UserRoles.PropertyManager) {
          setProperties(user?.managedProperties?.map((mp: any) => mp?.propertyUuid) || [])
        }
      }
    }
  }, [user])

  const getRoleOptions = () => {
    if (me?.role === UserRoles.Admin) {
      return [
        { value: UserRoles.Admin, label: t(UserRoles.Admin) },
        { value: UserRoles.PropertyManager, label: t(UserRoles.PropertyManager) }
      ]
    }
    return []
  }

  const toProperty = (row: any) => navigate(`/properties/${row?.uuid}`)

  const PropertyColumns: GridColDef[] = useMemo(() => [
    {
      field: 'nameFi',
      headerName: t('property_name'),
      flex: 1,
      // maxWidth: 700,
      minWidth: 200,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={params.row.nameFi.trim()}
          onClick={() => toProperty(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'city',
      headerName: t('city'),
      flex: 1,
      maxWidth: 220,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value ? t(value) : '-'
    },
    {
      field: 'propertyManagerName',
      headerName: t('contact_person'),
      flex: 1,
      maxWidth: 240,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value ? t(value) : '-'
    },
    {
      field: 'updatedAt',
      headerName: t('edited_at'),
      flex: 1,
      maxWidth: 220,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    }
  ], [user])

  /*
  const eventColumns: GridColDef[] = useMemo(() => [
    {
      field: 'type',
      headerName: t('event'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value ? t(value) : '-'
    },
    {
      field: 'createdAt',
      headerName: t('event_date'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    },
    {
      field: 'ipAddress',
      headerName: t('ip_address'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Chip text={params.row.email} href email />
      )
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      maxWidth: 220,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value || '-'
    }
  ], [])
  */

  const PropertyRows = useMemo(() => {
    return (user?.managedProperties || [])
      ?.map((item: any) => {
        return propertyStore?.properties?.find((p: any) => p.uuid === item.propertyUuid)
    })
  }, [user, propertyStore.properties])
  // const eventRows = useMemo(() => userStore?.userEvents, [userStore.userEvents])
  
  const changeProfilePhoto = (photo: any) => {
    if (me.uuid === user.uuid) {
      sessionStore.updateMyProfilePhoto(photo)
    } else {
      userStore.updateUserProfilePhoto(user.uuid, photo)
    }
  }

  const onUploadProfilePhoto = useCallback((files: any) => {
    if (files && files?.length) {
      const file = files?.[0]
      changeProfilePhoto(file)
    }
  }, [user])

  const onDeleteProfilePhoto = () => {
    if (me.uuid === user.uuid) {
      sessionStore.archiveMyProfilePhoto()
    } else {
      userStore.archiveUserProfilePhoto(user.uuid)
    }
  }

  const profileDrop = useDropzone({
    accept: {
      'image/jpeg': ['.jpg'],
      'image/png': ['.png']
    },
    multiple: false,
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    onDrop: onUploadProfilePhoto
  })

  const archive = () => {
    if (me.uuid === user.uuid) {
      sessionStore.archiveMe()
    } else {
      userStore.archiveUser(
        user.uuid,
        closeActionConfirmation
      )
    }
  }

  const activate = () => {
    userStore.restoreUser(
      user.uuid,
      closeActionConfirmation
    )
  }

  const save = () => {
    const updatedUser: any = {
      firstName,
      lastName,
      phone,
      teamsEmail: teamsEmail || null
    }
    if (
      isAdmin &&
      user.role === UserRoles.PropertyManager &&
      properties?.length !== user?.managedProperties?.length ||
      properties?.some((p: any) => !user?.managedProperties?.find((up: any) => up.propertyUuid === p.uuid))
    ) {
      updatedUser.properties = properties
    }
    if (me.uuid === user.uuid) {
      sessionStore.updateMe(updatedUser)
    } else {
      userStore.updateUser(user.uuid, updatedUser)
    }
  }

  if (!user) {
    return null
  }

  const renderPhotoActions = () => {
    if (user?.profilePhoto) {
      return (
        <Collapse in={me?.profilePhoto} unmountOnExit>
          <Button
            text={t('delete_photo')}
            onClick={onDeleteProfilePhoto}
            sx={styles.deleteButton}
          />
          <Box {...profileDrop.getRootProps()}>
            <input {...profileDrop.getInputProps()} />
            <Button
              text={t('change_photo')}
              onClick={profileDrop.open}
              variant='secondary'
              sx={styles.changeButton}
            />
          </Box>
        </Collapse>
      )
    }
    return (
      <Box {...profileDrop.getRootProps()}>
        <input {...profileDrop.getInputProps()} />
        <Button
          text={t('add_photo')}
          onClick={profileDrop.open}
          sx={styles.addButton}
          disabled={user?.archivedAt}
        />
      </Box>
    )
  }

  const renderPropertySelect = () => {
    if (user.role === UserRoles.PropertyManager) {
      return (
        <MultiSelect
          label={t('properties')}
          options={getPropertyOptions()}
          value={properties}
          onChange={setProperties}
          mb='1.5rem'
        />
      )
    }
    return null
  }

  const renderTabContent = () => {
    if (tab === UserTabs.GeneralInformation) {
      return (
        <>
          <Typography variant='h3' mt='0.25rem' mb='1rem'>{t('general_information')}</Typography>
          <Box sx={styles.formContainer}>
            <Box sx={styles.row}>
              <Input
                label={t('first_name')}
                value={firstName}
                onChange={setFirstName}
                maxWidth='22rem'
                mr='2rem'
              />
              <Input
                label={t('last_name')}
                value={lastName}
                onChange={setLastName}
              />
            </Box>
            <Input
              label={t('email')}
              value={email}
              onChange={setEmail}
              mb='1.5rem'
              copy
              disabled
            />
            <Input
              label={t('teams_email')}
              placeholder={t('teams_email_placeholder')}
              value={teamsEmail}
              onChange={setTeamsEmail}
              mb='1.5rem'
            />
            <Input
              label={t('phone')}
              value={phone}
              onChange={setPhone}
              mb='1.5rem'
            />
          </Box>
          <Typography variant='h3' mt='1rem' mb='1rem'>{t('permissions')}</Typography>
          <Box sx={styles.formContainer}>
            <Select
              label={t('user_role')}
              options={getRoleOptions()}
              value={user?.role}
              mb='1.5rem'
              disabled
            />
            {renderPropertySelect()}
          </Box>
        </>
      )
    } else if (tab === UserTabs.Properties && user.role === UserRoles.PropertyManager) {
      return (
        <>
          <Table
            rows={PropertyRows}
            columns={PropertyColumns}
            loading={loading}
            hideCheckboxSelection
          />
        </>
      )
    }
    /*
    else if (tab === UserTabs.Events) {
      return (
        <>
          <Typography variant='h2' mt='2rem' mb='1.5rem'>{t('events')}</Typography>
          <Table rows={eventRows} columns={eventColumns} loading={loading} />
        </>
      )
    }
    */
    return null
  }

  const renderRightColumnContent = () => {
    if (isAdmin) {
      return (
        <SidebarActions
          updatedAt={user?.updatedAt || user?.createdAt || ''}
          archivedAt={user?.archivedAt}
          statusText={t(user?.status)}
          onSave={save}
          onArchive={openArchiveConfirmation}
          onRestore={openActivateConfirmation}
          saveDisabled={!firstName || !lastName || user?.archivedAt}
          mt='4.0625rem'
        />
      )
    }
    return null
  }

  const renderActionConfirmation = () => {
    if (showActionConfirmation) {
      if (actionType === 'archive_user') {
        return (
          <ConfirmationDialog
            title={t('archive_user_title')}
            content={t('archive_user_info')}
            onClose={closeActionConfirmation}
            cancelText={t('cancel')}
            onAction={archive}
            actionText={t('archive')}
          />
        )
      } else if (actionType === 'activate_user') {
        return (
          <ConfirmationDialog
            title={t('activate_user_title')}
            content={t('activate_user_info')}
            onClose={closeActionConfirmation}
            cancelText={t('cancel')}
            onAction={activate}
            actionText={t('restore')}
          />
        )
      }
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.photoContainer}>
        <Avatar src={user?.profilePhoto} sx={styles.photo} />
        <Box sx={styles.photoActions}>{renderPhotoActions()}</Box>
      </Box>
      <Box sx={styles.contentContainer}>
        <Box sx={styles.leftColumn}>
          <Typography variant='h2' color={Colors.primary}>
            {user?.firstName || ''} {user?.lastName || ''}
          </Typography>
          <Tabs mt='1.75rem' mb='2rem'>
            <Tab
              value={UserTabs.GeneralInformation}
              text={t(UserTabs.GeneralInformation)}
              selected={tab === UserTabs.GeneralInformation}
              onClick={setTab}
              first
            />
            {user?.role === UserRoles.PropertyManager && (
              <Tab
                value={UserTabs.Properties}
                text={t(UserTabs.Properties)}
                selected={tab === UserTabs.Properties}
                onClick={setTab}
                count={0}
              />
            )}
            {/*
              <Tab
                value={UserTabs.Events}
                text={t(UserTabs.Events)}
                selected={tab === UserTabs.Events}
                onClick={setTab}
                count={0}
              />
            */}
          </Tabs>
          {renderTabContent()}
        </Box>
        <Box sx={styles.rightColumn}>
          {renderRightColumnContent()}
        </Box>
      </Box>
      {renderActionConfirmation()}
    </Box>
  )
}

export default observer(User)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginRight: {
      xl: '3rem',
      lgplus: '2.5rem',
      xs: '2rem'
    }
  },
  rightColumn: {
    position: 'sticky',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  photoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    mb: '2.25rem'
  },
  photo: {
    width: '12rem',
    height: '12rem',
    marginRight: '2rem'
  },
  photoActions: {
    height: '12rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '1rem 0',
    mb: '1rem'
  },
  dot: {
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: '50%',
    backgroundColor: Colors.primary,
    display: 'inline-block',
    marginLeft: '0.5rem',
    marginRight: '0.75rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem'
  },
  infoLabel: {
    fontSize: '1.125rem',
    fontWeight: 700,
    color: Colors.heading,
    textTransform: 'uppercase',
    mb: '0.5rem'
  },
  subtitle: {
    alignSelf: 'flex-start',
    mb: '1.25rem',
    textTransform: 'uppercase'
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontFamily: Fonts.body,
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
      transition: 'font-weight 0.3s ease-in-out'
    }
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: {
      lgplus: '2rem 2rem 0.5rem 2rem',
      xs: '1.625rem 1.5rem 0.125rem 1.5rem'
    },
    mb: '1.5rem'
  },
  deleteButton: {
    width: '11rem',
    fontSize: '1rem',
    mb: '1.5rem'
  },
  changeButton: {
    width: '11rem',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    }
  },
  addButton: {
    width: '11rem',
    fontSize: '1rem',
  }
} as const
