import React from 'react'
import { createRoot } from 'react-dom/client'
import { createStore, StoreProvider } from './Models/RootStore'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import App from './App'
import theme from './Utils/theme'
import './index.css'
import './Localization'

const rootStore = createStore()

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <StoreProvider value={rootStore}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </StoreProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
