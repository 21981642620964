import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Typography from '@mui/material/Typography'
import Checkbox from './Checkbox'
import ArrowIcon from '../../Assets/Icons/dropdown-arrow.svg'
import { Colors } from '../../Utils/theme'

export default function MultiSelect(props: any) {
  const { id, value, label, placeholder, options } = props

  const { t } = useTranslation()

  const getContainerStyle = () => {
    let extraStyles: any = {}
    if (props?.sx) extraStyles = { ...extraStyles, ...props.sx }
    if (props.width) extraStyles.width = props.width
    if (props.minWidth) extraStyles.minWidth = props.minWidth
    if (props.maxWidth) extraStyles.maxWidth = props.maxWidth
    if (props.ml) extraStyles.ml = props.ml
    if (props.mr) extraStyles.mr = props.mr
    if (props.mb) extraStyles.mb = props.mb
    if (props.mt) extraStyles.mt = props.mt
    if (Object.keys(extraStyles).length) {
      return { ...styles.container, ...extraStyles }
    }
    return styles.container
  }

  const getLabel = (option: any) => options?.find((o: any) => {
    return o.value === (option?.value || option)
  })?.label ?? ''

  const isOptionEqualToValue = (option: any, value: any) => value && value === option?.value
  // const isOptionEqualToValue = (option: any, value: any) => value && value?.includes(option?.value)

  const filterOptions = createFilterOptions({ stringify: (option: any) => option?.label })

  const onChange = (event: any, newValue: any) => {
    props.onChange(newValue.map((option: any) => option?.value ? option.value : option))
  }

  const renderArrowIcon = () => <Box component='img' src={ArrowIcon} alt='icon' />

  const renderLabel = () => {
    if (label) {
      return (
        <InputLabel
          sx={{ ...styles.label, fontWeight: props?.labelFontWeight || 600 }}
          htmlFor={id}
          disableAnimation
        >
          {label}
        </InputLabel>
      )
    }
    return null
  }

  const renderInput = (params: any) => (
    <TextField
      {...params}
      name='noAutoFill'
      placeholder={(!value || !value?.length) ? placeholder : ''}
      inputProps={{
        ...params.inputProps,
        // Disable autocomplete and autofill
        autoComplete: 'off',
        sx: styles.input
      }}
    />
  )

  const renderOption = (props: any, option: any) => {
    return (
      <Box component='li' sx={styles.option} {...props}>
        <Checkbox
          label={t(option?.label) || ''}
          checked={value && value?.includes(option?.value)}
          labelSx={styles.optionLabel}
        />
      </Box>
    )
  }

  const renderValues = (value: any) => {
    if (props?.showCountAsValue) {
      return <Typography noWrap>{placeholder} ({value.length})</Typography>
    }
    if (props?.showPlaceholderWhenAllSelected && value.length === options.length) {
      return <Typography noWrap>{placeholder}</Typography>
    }
    const values = (value || [])
      .map((value: string) => getLabel(value))
      .join(', ')
    return <Typography noWrap>{values}</Typography>
  }

  return (
    <Box sx={getContainerStyle()}>
      {renderLabel()}
      <Autocomplete
        id={id}
        value={value || []}
        onChange={onChange}
        options={options}
        getOptionLabel={getLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        renderOption={renderOption}
        renderInput={renderInput}
        renderTags={renderValues}
        sx={styles.container}
        disabled={props?.disabled}
        popupIcon={!props?.disabled ? renderArrowIcon() : null}
        openText={t('open')}
        closeText={t('close')}
        clearText={t('clear')}
        noOptionsText={t('no_options')}
        filterOptions={filterOptions}
        // freeSolo
        // forcePopupIcon
        disableClearable={!!props?.disableClearable}
        disableCloseOnSelect
        multiple
        autoHighlight
      />
    </Box>
  )
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    '& > .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root': {
      flexWrap: 'nowrap',
      overflowX: 'hidden'
    },
    '& .MuiAutocomplete-inputRoot': {
      p: '0rem 0.6875rem',
      height: '3.125rem',
      borderRadius: '0.3125rem',
      backgroundColor: Colors.white,
      border: `1px solid ${Colors.border}`,
      '&:hover': {
        borderColor: Colors.border
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${Colors.border}`
        }
      },
      '& > p': {
        fontSize: '1.125rem',
      }
    },
    '& .MuiAutocomplete-popupIndicator': {
      padding: '0.5rem'
    }
  },
  label: {
    margin: 0,
    marginBottom: '0.5rem',
    color: Colors.heading,
    fontSize: '1rem',
    fontWeight: 600
  },
  input: {
    fontSize: '1.125rem',
    color: Colors.text,
    borderRadius: '0.3125rem',
    border: 'none',
    '&::placeholder': {
      color: Colors.text
    }
  },
  option: {
    fontSize: '1rem',
    color: Colors.text
  },
  optionLabel: {
    fontSize: '1rem',
    color: Colors.text
  }
} as const