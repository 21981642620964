import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class NotificationStore {
  rootStore

  reminders: any = []
  reminder: any = null
  updatedAt: any = null
  params: any = {}
  loading = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setReminders = (reminders: any) => { this.reminders = reminders }
  setReminder = (reminder: any) => { this.reminder = reminder }
  setUpdatedAt = (updatedAt: any) => { this.updatedAt = updatedAt }
  setParams = (params: any) => { this.params = params }
  setLoading = (loading: boolean) => { this.loading = loading }

  reset() {
    this.setReminders([])
    this.setReminder(null)
    this.setUpdatedAt(null)
    this.setParams({})
    this.setLoading(false)
  }

  async getPropertyReminders(pUuid: string, params: any = {}) {
    this.setLoading(true)
    try {
      this.setParams(params)
      const response: any = await Api.getPropertyReminders(pUuid, params)
      if (response?.ok) {
        const reminders = response.data?.items || []
        this.setReminders(reminders)
        this.setUpdatedAt(response?.data?.updatedAt)
      } else {
        // TODO
        showMessage('')
        this.setReminders([])
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async createPropertyReminder(pUuid: string, data: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.createPropertyReminder(pUuid, data)
      if (response?.ok) {
        this.getPropertyReminders(pUuid, this.params)
        showMessage(i18n.t('reminder_created'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('reminder_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updatePropertyReminder(pUuid: string, rUuid: string, data: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updatePropertyReminder(pUuid, rUuid, data)
      if (response?.ok) {
        this.getPropertyReminders(pUuid, this.params)
        showMessage(i18n.t('reminder_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('reminder_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archivePropertyReminder(pUuid: string, rUuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.archivePropertyReminder(pUuid, rUuid)
      if (response?.ok) {
        this.getPropertyReminders(pUuid, this.params)
        showMessage(i18n.t('reminder_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('reminder_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false) 
  }
}
