import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class ProjectStore {
  rootStore

  tab: string | null = null
  projects: any = []
  totalProjects: number = 0
  project: any = null
  filters: any = null
  files: any = []
  loading = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setTab = (tab: string | null) => { this.tab = tab }
  setProjects = (projects: any) => { this.projects = projects }
  setTotalProjects = (totalProjects: number) => { this.totalProjects = totalProjects }
  setProject = (project: any) => { this.project = project }
  setFilters = (filters: any) => { this.filters = filters }
  setFiles = (files: any) => { this.files = files }
  setLoading = (loading: boolean) => { this.loading = loading }

  reset() {
    this.setTab(null)
    this.setProjects([])
    this.setTotalProjects(0)
    this.setProject(null)
    this.setFilters(null)
    this.setFiles([])
    this.setLoading(false)
  }

  async getProjects(pUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getProjects(pUuid)
      if (response?.ok) {
        const projects = response.data?.items || []
        this.setProjects(projects)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getProject(pUuid: string, projUuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.getProject(pUuid, projUuid)
      if (response?.ok) {
        const project = response?.data || null
        this.setProject(project)

        if (callback) {
          callback(project)
        }
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async createProject(pUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.createProject(pUuid, payload)
      if (response?.ok) {
        this.getProjects(pUuid)
        showMessage(i18n.t('project_created'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('project_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateProject(pUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateProject(pUuid, this.project?.uuid, payload)
      if (response?.ok) {
        const project = response?.data || null
        this.setProject(project)
        this.getProjects(pUuid)
        showMessage(i18n.t('project_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('project_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateProjectBackgroundPhoto(pUuid: string, fUuid: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateProjectBackgroundPhoto(pUuid, this.project?.uuid, fUuid)
      if (response?.ok) {
        const data = response?.data || null
        this.setProject(data)
        this.getProjects(pUuid)
        showMessage(i18n.t('project_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('project_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archiveProject(pUuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.archiveProject(pUuid, this.project?.uuid)
      if (response?.ok) {
        this.getProjects(pUuid)
        showMessage(i18n.t('project_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('project_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getProjectFiles(pUuid: string, projUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getProjectFiles(pUuid, projUuid)
      if (response?.ok) {
        const files = response?.data?.items || []
        this.setFiles(files)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async uploadProjectFiles(pUuid: string, files: any) {
    this.setLoading(true)
    try {
      const okResponses = []
      const errorResponses = []
      for (const file of files) {
        const response: any = await Api.uploadProjectFile(pUuid, this.project.uuid, file)
        if (response?.ok) {
          okResponses.push(response)
        } else {
          errorResponses.push(response)
        }
      }
      if (okResponses?.length) {
        this.getProjectFiles(pUuid, this.project.uuid)
        if (okResponses?.length === files?.length) {
          if (okResponses?.length === 1) {
            showMessage(i18n.t('attachment_uploaded'))
          } else {
            showMessage(i18n.t('attachment_uploaded'))
          }
        }
      } else {
        showMessage(i18n.t('attachment_upload_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archiveProjectFile(pUuid: string, fUuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.archiveProjectFile(pUuid, this.project.uuid, fUuid)
      if (response?.ok) {
        this.getProjectFiles(pUuid, this.project.uuid)
        showMessage(i18n.t('attachment_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('attachment_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async createProjectComment(pUuid: string, projUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.createProjectComment(pUuid, projUuid, payload)
      if (response?.ok) {
        this.getProject(pUuid, projUuid, callback)
        showMessage(i18n.t('comment_created'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('comment_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateProjectComment(pUuid: string, projUuid: string, cUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateProjectComment(pUuid, projUuid, cUuid, payload)
      if (response?.ok) {
        this.getProject(pUuid, projUuid, callback)
        showMessage(i18n.t('comment_updated'))
      } else {
        showMessage(i18n.t('comment_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archiveProjectComment(pUuid: string, projUuid: string, cUuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.archiveProjectComment(pUuid, projUuid, cUuid)
      if (response?.ok) {
        this.getProject(pUuid, projUuid, callback)
        showMessage(i18n.t('comment_deleted'))
      } else {
        showMessage(i18n.t('comment_deletion_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}
