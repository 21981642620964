import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import upperFirst from 'lodash/upperFirst'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import IconButton from '../../../Components/Common/IconButton'
import SidebarActions from '../../../Components/Common/SidebarActions'
import { Input, Select } from '../../../Components'
import {
  CostTypes,
  DefaultGraphColors,
  GraphTypes,
  GraphVisibilityOptions
} from '../../../Utils/constants'
import { YearOptions } from '../../../Utils/layout'
import { Colors } from '../../../Utils/theme'
import { fromCents, toCents } from '../../../Utils/transformers'

const CostSettings = () => {
  const { propertyStore, costStore }: any = useStore()
  const { property } = propertyStore
  const { costs } = costStore
  const { t } = useTranslation()

  const params = useParams()

  // Cost settings
  const [costYear, setCostYear] = useState(parseInt(DateTime.now().toFormat('yyyy')))
  const [costMonth, setCostMonth] = useState(parseInt(DateTime.now().toFormat('M')))

  // Inflation and interest
  const [inflationRate, setInflationRate] = useState(0)
  const [interestRate, setInterestRate] = useState(0)

  // Cost types for current year and month
  // Energia
  const [energyCost, setEnergyCost] = useState(0)
  // Hallinto
  const [managementCost, setManagementCost] = useState(0)
  // Kiinteistövero
  const [propertyTaxCost, setPropertyTaxCost] = useState(0)
  // Lainat
  const [loanCost, setLoanCost] = useState(0)
  // Jätehuolto
  const [wasteManagementCost, setWasteManagementCost] = useState(0)
  // Vesi  
  const [waterCost, setWaterCost] = useState(0)
  // Isännöinti
  const [propertyManagementCost, setPropertyManagementCost] = useState(0)
  
  // Graph settings
  // Graph type (line, stacked bar)
  const [costGraphType, setCostGraphType] = useState('')
  // Whether to show cost type filter in graph
  const [showCostTypeFilter, setShowCostTypeFilter] = useState(true)
  // Whether to allow inflation variable adjustment in graph
  const [showInflationVariableAdjustment, setShowInflationVariableAdjustment] = useState(true)
  // Whether to allow loan interest adjustment in graph
  const [showLoanInterestAdjustment, setShowLoanInterestAdjustment] = useState(true)
  // Graph colors for each cost type
  const [costGraphColors, setCostGraphColors] = useState<any>(null)

  useEffect(() => {
    const pUuid = params?.pUuid || null
    if (pUuid && pUuid?.length === 36) {
      propertyStore.getProperty(pUuid)
      costStore.getCosts(pUuid, costYear, costMonth)
    }
  }, [])

  useEffect(() => {
    if (property) {
      setCostGraphType(property?.costGraphType || GraphTypes.Line)
      setShowCostTypeFilter(
        property?.showCostTypeFilter ||
        property?.showCostTypeFilter === null
      )
      setShowInflationVariableAdjustment(
        property?.showInflationVariableAdjustment ||
        property?.showInflationVariableAdjustment === null
      )
      setShowLoanInterestAdjustment(
        property?.showLoanInterestAdjustment ||
        property?.showLoanInterestAdjustment === null
      )
      if (property?.costGraphColors) {
        setCostGraphColors(property?.costGraphColors)
      } else {
        setCostGraphColors(DefaultGraphColors)
      }
    }
  }, [property])

  useEffect(() => {
    if (costs) {
      const yearlyCosts = costs?.[costYear] ?? null
      if (yearlyCosts) {
        const monthlyCosts = yearlyCosts?.costs?.[costMonth]?.costs ?? null
        if (monthlyCosts) {
          if (yearlyCosts?.inflationRate) {
            setInflationRate(yearlyCosts?.inflationRate)
          }
          if (yearlyCosts?.interestRate) {
            setInterestRate(yearlyCosts?.interestRate)
          }
          setEnergyCost(
            fromCents(monthlyCosts?.find((cost: any) => cost?.type === CostTypes.Energy)?.total || 0)
          )
          setManagementCost(
            fromCents(monthlyCosts?.find((cost: any) => cost?.type === CostTypes.Management)?.total || 0)
          )
          setPropertyTaxCost(
            fromCents(monthlyCosts?.find((cost: any) => cost?.type === CostTypes.PropertyTax)?.total || 0)
          )
          setLoanCost(
            fromCents(monthlyCosts?.find((cost: any) => cost?.type === CostTypes.Loans)?.total || 0)
          )
          setWasteManagementCost(
            fromCents(monthlyCosts?.find((cost: any) => cost?.type === CostTypes.WasteManagement)?.total || 0)
          )
          setWaterCost(
            fromCents(monthlyCosts?.find((cost: any) => cost?.type === CostTypes.Water)?.total || 0)
          )
          setPropertyManagementCost(
            fromCents(monthlyCosts?.find((cost: any) => cost?.type === CostTypes.PropertyManagement)?.total || 0)
          )
        } else {
          setInflationRate(2)
          setInterestRate(0)
          setEnergyCost(0)
          setManagementCost(0)
          setPropertyTaxCost(0)
          setLoanCost(0)
          setWasteManagementCost(0)
          setWaterCost(0)
          setPropertyManagementCost(0)
        }
      } else {
        setInflationRate(2)
        setInterestRate(0)
        setEnergyCost(0)
        setManagementCost(0)
        setPropertyTaxCost(0)
        setLoanCost(0)
        setWasteManagementCost(0)
        setWaterCost(0)
        setPropertyManagementCost(0)
      }
    }
  }, [costYear, costMonth, costs])

  const GraphTypeOptions = Object.values(GraphTypes).map((option: any) => ({ 
    value: option,
    label: t(`${option}_graph`)
  }))
  const VisibilityOptions = Object.values(GraphVisibilityOptions).map((option: any) => ({
    value: option === 'visible' ? true : false,
    label: t(option)
  }))

  // Management costs for each year
  const CostYearOptions = [
    {
      value: DateTime.now().year + 1,
      label: `${t('year')} ${DateTime.now().year + 1}`
    },
    ...YearOptions(DateTime.now().year)
      .map((item: any) => ({
        value: parseInt(item?.value),
        label: `${t('year')} ${item?.value}` 
      }))
  ]

  const CostMonthOptions = Array.from({ length: 12 }, (_, i) => {
    const month = DateTime
      .fromObject({ month: i + 1 })
      .setLocale('fi')
      .toFormat('LLLL')
    return { value: i + 1, label: upperFirst(month) }
  })

  const getCost = (type: string) => {
    if (type === CostTypes.Energy) return energyCost
    if (type === CostTypes.Management) return managementCost
    if (type === CostTypes.PropertyTax) return propertyTaxCost
    if (type === CostTypes.Loans) return loanCost
    if (type === CostTypes.WasteManagement) return wasteManagementCost
    if (type === CostTypes.Water) return waterCost
    if (type === CostTypes.PropertyManagement) return propertyManagementCost
    return 0
  }

  const setCost = (type: string, value: number) => {
    if (type === CostTypes.Energy) setEnergyCost(value)
    if (type === CostTypes.Management) setManagementCost(value)
    if (type === CostTypes.PropertyTax) setPropertyTaxCost(value)
    if (type === CostTypes.Loans) setLoanCost(value)
    if (type === CostTypes.WasteManagement) setWasteManagementCost(value)
    if (type === CostTypes.Water) setWaterCost(value)
    if (type === CostTypes.PropertyManagement) setPropertyManagementCost(value)
  }

  const getTotalCost = useCallback((type: string) => {
    const yearlyCosts = costs?.[costYear] ?? null
    if (yearlyCosts) {
      if (type === CostTypes.Energy) {
        return fromCents(yearlyCosts?.totalEnergy) || 0
      }
      if (type === CostTypes.Management) {
        return fromCents(yearlyCosts?.totalManagement) || 0
      }
      if (type === CostTypes.PropertyTax) {
        return fromCents(yearlyCosts?.totalPropertyTax) || 0
      }
      if (type === CostTypes.Loans) {
        return fromCents(yearlyCosts?.totalLoans) || 0
      }
      if (type === CostTypes.WasteManagement) {
        return fromCents(yearlyCosts?.totalWasteManagement) || 0
      }
      if (type === CostTypes.Water) {
        return fromCents(yearlyCosts?.totalWater) || 0
      }
      if (type === CostTypes.PropertyManagement) {
        return fromCents(yearlyCosts?.totalPropertyManagement) || 0
      }
    }
    return 0
  }, [costYear, costMonth, costs])

  const InflationOptions = Array.from({ length: 100 }, (_, i) => i).map((i) => ({
    value: i / 10,
    label: `${i / 10}%`?.replace('.', ',')
  }))
  const InterestOptions = Array.from({ length: 150 }, (_, i) => i).map((i) => ({
    value: i / 10,
    label: `${i / 10}%`?.replace('.', ',')
  }))

  const isSaveDisabled = () => {
    if (!costGraphType) return true
    return false
  }

  const updateColor = (type: string, value: string) => {
    const newColors: any = { ...costGraphColors }
    newColors[type] = value
    setCostGraphColors(newColors)
  }

  const toPreviousMonth = () => {
    if (costMonth === 1) {
      setCostYear(costYear - 1)
      return setCostMonth(12)
    } else {
      return setCostMonth(costMonth - 1)
    }
  }

  const toNextMonth = () => { 
    if (costMonth === 12) {
      setCostYear(costYear + 1)
      return setCostMonth(1)
    } else {
      return setCostMonth(costMonth + 1)
    }
  }

  const save = () => {
    // Update settings first (todo: diff check)
    const updatedSettings = {
      costGraphType,
      showCostTypeFilter,
      showInflationVariableAdjustment,
      showLoanInterestAdjustment,
      costGraphColors,
    }
    costStore.updateCostSettings(params?.pUuid, updatedSettings)

    // Update monthly costs (todo: diff check)
    if (costYear && costMonth) {
      // Update costs
      const updatedCosts = {
        year: Number(costYear),
        month: Number(costMonth),
        inflationRate: inflationRate,
        interestRate: interestRate,
        costs: [
          { 
            type: CostTypes.Energy,
            total: toCents(energyCost),
            rawTotal: energyCost
          },
          {
            type: CostTypes.Management,
            total: toCents(managementCost),
            rawTotal: managementCost
          },
          {
            type: CostTypes.PropertyTax,
            total: toCents(propertyTaxCost),
            rawTotal: propertyTaxCost
          },
          {
            type: CostTypes.Loans,
            total: toCents(loanCost),
            rawTotal: loanCost
          },
          {
            type: CostTypes.WasteManagement,
            total: toCents(wasteManagementCost),
            rawTotal: wasteManagementCost
          },
          {
            type: CostTypes.Water,
            total: toCents(waterCost),
            rawTotal: waterCost
          },
          {
            type: CostTypes.PropertyManagement,
            total: toCents(propertyManagementCost),
            rawTotal: propertyManagementCost
          }
        ]
      }
      costStore.createOrUpdateCosts(params?.pUuid, updatedCosts)
    }
  }

  const renderCosts = () => {
    return Object.values(CostTypes).map((type: any) => {
      return (
        <Box key={type} sx={styles.row}>
          <Input placeholder={t(type)} mr='2rem' disabled />
          <Input
            placeholder={t('enter_cost')}
            value={getCost(type)}
            onChange={(value: number) => setCost(type, value)}
            unit='€'
            mr='2rem'
          />
          <Typography sx={styles.total}>= {t('total_short')}</Typography>
          <Input value={getTotalCost(type)} unit='€' disabled />
        </Box>
      )
    })
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h3' mt='0.5rem' mb='1rem'>{t('cost_types')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={{ ...styles.row, justifyContent: 'space-between' }}>
            <Select
              options={CostYearOptions}
              value={costYear}
              onChange={setCostYear}
              minWidth='11rem'
              mr='1.5rem'
              disableClearable
            />
            <Select
              options={InflationOptions}
              value={inflationRate}
              onChange={setInflationRate}
              placeholder={t('inflation')}
              minWidth='11.5rem'
              mr='1.5rem'
              badgeColor={Colors.primary}
              badgeLeftOffset='6.125rem'
              disableClearable
              valueAsBadge
            />
            <Select
              options={InterestOptions}
              value={interestRate}
              onChange={setInterestRate}
              placeholder={t('interest')}
              minWidth='10.5rem'
              badgeColor={Colors.primary}
              badgeLeftOffset='4.75rem'
              disableClearable
              valueAsBadge
            />
            <Box sx={{ width: '100%' }} />
            <Box sx={{ ...styles.row, width: 'auto', mb: 0 }}>
              <IconButton
                icon='arrowLeft'
                onClick={toPreviousMonth}
                sx={styles.previousButton}
                iconSize='1.125rem'
              />
              <Select
                options={CostMonthOptions}
                value={costMonth}
                onChange={setCostMonth}
                minWidth='12rem'
                disableClearable
              />
              <IconButton
                icon='arrowRight'
                onClick={toNextMonth}
                sx={styles.nextButton}
                iconSize='1.125rem'
                disabled={costMonth === 12 && costYear === DateTime.now().year + 1}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ ...styles.formContainer, pt: '2rem', pb: '0rem' }}>
          {renderCosts()}
        </Box>
        <Typography variant='h3' mt='1rem' mb='1rem'>{t('graph')}</Typography>
          <Box sx={styles.formContainer}>
            <Select
              label={t('graph_type')}
              options={GraphTypeOptions}
              value={costGraphType}
              onChange={setCostGraphType}
              mb='1.25rem'
            />
            <Box sx={styles.row}>
              <Select
                label={t('cost_type_selection')}
                options={VisibilityOptions}
                value={showCostTypeFilter}
                onChange={setShowCostTypeFilter}
                mr='2rem'
              />
              <Select
                label={t('inflation_variable')}
                options={VisibilityOptions}
                value={showInflationVariableAdjustment}
                onChange={setShowInflationVariableAdjustment}
                mr='2rem'
              />
              <Select
                label={t('rate_variable')}
                options={VisibilityOptions}
                value={showLoanInterestAdjustment}
                onChange={setShowLoanInterestAdjustment}
              />
            </Box>
            <Grid container spacing={4} mb='0.5rem'>
              {Object.values(CostTypes).map((type: string) => (
                <Grid xs={6} md={3} xxl={12/5} key={type}>
                  <Input
                    label={t(type)}
                    value={costGraphColors?.[type] || DefaultGraphColors?.[type]}
                    onChange={(val: string) => updateColor(type, val)}
                    colorPicker
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <SidebarActions
          updatedAt={property?.updatedAt || property?.createdAt || ''}
          onSave={save}
          saveDisabled={isSaveDisabled()}
          mt='3.375rem'
        />
      </Box>
    </Box>
  )
}

export default observer(CostSettings)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginRight: {
      xl: '3rem',
      lgplus: '2.5rem',
      xs: '2rem'
    }
  },
  title: {
    borderBottom: `5px solid ${Colors.border}`,
    pb: '1rem',
    mb: '2rem'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: {
      lgplus: '2rem 2rem 0.5rem 2rem',
      xs: '1.625rem 1.5rem 0.125rem 1.5rem'
    },
    mb: '1.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  divider: {
    margin: '0rem 0.75rem',
    marginTop: '1.625rem',
    fontSize: '1.5rem',
    fontWeight: 700,
    color: Colors.text
  },
  rightColumn: {
    position: 'sticky',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: {
      xl: '20rem',
      xs: '16rem'
    },
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  },
  subtitle: {
    alignSelf: 'flex-start',
    mb: '1.25rem',
    textTransform: 'uppercase'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem'
  },
  popup: {
    ml: '0.875rem'
  },
  previousButton: {
    height: '3.125rem',
    width: '3.125rem',
    minWidth: '3.125rem',
    backgroundColor: Colors.white,
    border: `0.0625rem solid ${Colors.border}`,
    justifyContent: 'center',
    ml: '1rem',
    mr: '0.75rem',
    '&:hover': {
      backgroundColor: Colors.white50
    }
  },
  nextButton: {
    height: '3.125rem',
    width: '3.125rem',
    minWidth: '3.125rem',
    backgroundColor: Colors.white,
    border: `0.0625rem solid ${Colors.border}`,
    ml: '0.75rem',
    '&:hover': {
      backgroundColor: Colors.white50
    }
  },
  total: {
    minWidth: {
      xl: '4rem',
      xs: '3.25rem'
    },
    fontSize: '1.125rem',
    fontWeight: 400,
    color: Colors.text,
    ml: {
      xl: '1rem',
      xs: '0.5rem'
    },
    mr: {
      xl: '2rem',
      xs: '1.5rem'
    },
    textAlign: 'right'
  },
  sortSelect: {
    width: {
      xxl: '20rem',
      xl: '18rem',
      xs: '14rem'
    }
  },
} as const
