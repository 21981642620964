import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { useDropzone } from 'react-dropzone'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import Avatar from '@mui/material/Avatar'
import Collapse from '@mui/material/Collapse'
import SidebarActions from '../../../Components/Common/SidebarActions'
import SidebarLoginActions from '../../../Components/Common/SidebarLoginActions'
import { Button, Input, Select } from '../../../Components'
import { UserRoles } from '../../../Utils/constants'
import { Colors } from '../../../Utils/theme'

const Profile = () => {
  const { sessionStore }: any = useStore()
  const { me } = sessionStore
  const { t } = useTranslation()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [teamsEmail, setTeamsEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [showActionConfirmation, setShowActionConfirmation] = useState(false)
  const [actionType, setActionActionType] = useState('')

  useEffect(() => {
    sessionStore.getMe()
  }, [])

  const openArchiveConfirmation = () => {
    setShowActionConfirmation(true)
    setActionActionType('archive')
  }
  const closeActionConfirmation = () => {
    setShowActionConfirmation(false)
    setActionActionType('')
  }

  useEffect(() => {
    setFirstName(me?.firstName || '')
    setLastName(me?.lastName || '')
    setEmail(me?.email || '')
    setTeamsEmail(me?.teamsEmail || '')
    setPhone(me?.phone || '')
  }, [me])

  const getRoleOptions = () => {
    if (me?.role === UserRoles.Admin) {
      return [
        { value: UserRoles.Admin, label: t(UserRoles.Admin) }
      ]
    } else if (me?.role === UserRoles.PropertyManager) {
      return [
        { value: UserRoles.PropertyManager, label: t(UserRoles.PropertyManager) }
      ]
    }
    return []
  }

  const changeProfilePhoto = (photo: any) => {
    sessionStore.updateMyProfilePhoto(photo)
  }

  const archive = () => {
    sessionStore.archiveMe(closeActionConfirmation)
  }

  const save = () => {
    sessionStore.updateMe({
      firstName,
      lastName,
      teamsEmail: teamsEmail || null,
      phone,
    })
  }

  const logout = () => sessionStore.logout()

  const onUploadProfilePhoto = useCallback((files: any) => {
    if (files && files?.length) {
      const file = files?.[0]
      changeProfilePhoto(file)
    }
  }, [])

  const onDeleteProfilePhoto = () => sessionStore.archiveMyProfilePhoto()

  const profileDrop = useDropzone({
    accept: {
      'image/jpeg': ['.jpg'],
      'image/png': ['.png']
    },
    multiple: false,
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    onDrop: onUploadProfilePhoto
  })

  const renderPhotoActions = () => {
    if (me?.profilePhoto) {
      return (
        <Collapse in={!!me?.profilePhoto} unmountOnExit>
          <Button
            text={t('delete_photo')}
            onClick={onDeleteProfilePhoto}
            sx={styles.deleteButton}
          />
          <Box {...profileDrop.getRootProps()}>
            <input {...profileDrop.getInputProps()} />
            <Button
              text={t('change_photo')}
              onClick={profileDrop.open}
              variant='secondary'
              sx={styles.changeButton}
            />
          </Box>
        </Collapse>
      )
    }
    return (
      <Box {...profileDrop.getRootProps()}>
        <input {...profileDrop.getInputProps()} />
        <Button
          text={t('add_photo')}
          onClick={profileDrop.open}
          sx={styles.addButton}
        />
      </Box>
    )
  }

  const renderActionConfirmation = () => {
    if (showActionConfirmation) {
      return (
        <ConfirmationDialog
          title={t(`${actionType}_my_user_title`)}
          content={t(`${actionType}_my_user_info`)}
          onClose={closeActionConfirmation}
          cancelText={t('cancel')}
          onAction={archive}
          actionText={t(actionType)}
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Box sx={styles.photoContainer}>
          <Avatar src={me?.profilePhoto} sx={styles.photo} />
          <Box sx={styles.photoActions}>{renderPhotoActions()}</Box>
        </Box>
        <Typography variant='h3' mt='0.25rem' mb='1rem'>{t('general_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.row}>
            <Input
              label={t('first_name')}
              value={firstName}
              onChange={setFirstName}
              maxWidth='22rem'
              mr='2rem'
            />
            <Input
              label={t('last_name')}
              value={lastName}
              onChange={setLastName}
            />
          </Box>
          <Input
            label={t('email')}
            value={email}
            onChange={setEmail}
            mb='1.5rem'
            copy
            disabled
          />
          <Input
            label={t('teams_email')}
            placeholder={t('teams_email_placeholder')}
            value={teamsEmail}
            onChange={setTeamsEmail}
            mb='1.5rem'
          />
          <Input
            label={t('phone')}
            value={phone}
            onChange={setPhone}
            mb='1.5rem'
          />
        </Box>
        <Typography variant='h3' mt='1rem' mb='1rem'>{t('permissions')}</Typography>
        <Box sx={styles.formContainer}>
          <Select
            label={t('user_role')}
            options={getRoleOptions()}
            value={me?.role}
            mb='1.5rem'
            disabled
          />
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <SidebarActions
          updatedAt={me?.updatedAt || me?.createdAt || ''}
          archivedAt={me?.archivedAt}
          onSave={save}
          onArchive={openArchiveConfirmation}
          saveDisabled={!firstName || !lastName}
          mt='17.5625rem'
        />
        <SidebarLoginActions
          updatedAt={me?.lastLoginAt || '-'}
          onLogout={logout}
        />
      </Box>
      {renderActionConfirmation()}
    </Box>
  )
}

export default observer(Profile)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // maxWidth: '80rem',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginRight: {
      xl: '3rem',
      lgplus: '2.5rem',
      xs: '2rem'
    }
  },
  photoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    mb: '2.5rem'
  },
  photo: {
    width: '12rem',
    height: '12rem',
    marginRight: '2rem'
  },
  photoActions: {
    height: '12rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: {
      lgplus: '2rem 2rem 0.5rem 2rem',
      xs: '1.625rem 1.5rem 0.125rem 1.5rem'
    },
    mb: '1.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  },
  subtitle: {
    alignSelf: 'flex-start',
    mb: '1.25rem',
    textTransform: 'uppercase'
  },
  deleteButton: {
    width: '11rem',
    fontSize: '1rem',
    mb: '1.5rem'
  },
  changeButton: {
    width: '11rem',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    }
  },
  addButton: {
    width: '11rem',
    fontSize: '1rem',
  }
} as const
