import {
  Subtitle,
  Input,
  Select,
  MultiSelect,
  Row,
  HorizontalSpacer,
  OtherOption,
  OtherPlaceholder,
  UnknownOption,
  UnknownPlaceholder,
  YearOptions,
  Option,
  IfOtherSelected,
  IfUnknownSelected,
  BooleanOptions,
  IfYesSelected,
  IfCustomSelected,
  Divider,
} from './layout'

export enum BuildingTypes {
  ApartmentBuilding = 'apartment_building',
  RowHouse = 'row_house',
  SemiDetachedHouseDuplex = 'semi_detached_house_duplex',
  DetachedHouse = 'detached_house',
  House = 'house',
  IndustrialBuilding = 'industrial_building',
  CommercialBuilding = 'commercial_building',
  PublicBuilding = 'public_building',
  Other = 'other_building_type'
}

export const BuildingMaterials = [
  Option('concrete'),
  Option('wood'),
  Option('brick'),
  OtherOption
]

export enum BuildingWaterRoofTypes {
  GableRoof = 'gable_roof',
  BrokenGableRoof = 'broken_gable_roof',
  PulpitRoof = 'pulpit_roof',
  FlatRoof = 'flat_roof',
  AumRoof = 'aum_roof',
  MansardRoof = 'mansard_roof',
  FoldingRoof = 'folding_roof',
  InverseRoof = 'inverse_roof',
  Other = 'other'
}

export enum BuildingWaterRoofMaterials {
  ProfileTinRoof = 'profile_tin_roof',
  SeamedRoof = 'seamed_roof',
  CorrugatedRoof = 'corrugated_roof',
  Copper = 'copper',
  Aluminum = 'aluminum',
  ClayTile = 'clay_tile',
  ConcreteTile = 'concrete_tile',
  BitumenRoll = 'bitumen_roll',
  FiberCement = 'fiber_cement',
  Glass = 'glass',
  Slate = 'slate',
  BitumenShingle = 'bitumen_shingle',
  WoodShingle = 'wood_shingle',
  GreenRoof = 'green_roof',
  Other = 'other'
}

export const BuildingHeatingSystems = [
  // Suorasähkölämmitys
  Option('electric', 'electric_heating'),
  // Varaava sähkölämmitys
  Option('reserving_electric', 'reserving_electric_heating'),
  // Kaukolämpö
  Option('district', 'district_heating'),
  // Öljy
  Option('oil', 'oil_heating'),
  // Pelletti/puu
  Option('wood', 'wood_heating'),
  // Maalämpö
  Option('geothermal', 'geothermal_heating'),
  // Ilmavesilämpöpumppu
  Option('air_water', 'air_water_heat_pump'),
  // Poistoilmalämpöpumppu
  Option('extract_air', 'extract_air_heat_pump')
]

export const BuildingCoolingSystemTypes = [
  Option('fan_coil_unit'),
  Option('air_heat_pump'),
  Option('supply_air_cooling'),
  Option('floor_cooling')
]

export const BuildingVentilationSystems = [
  // Mekaaninen poistoilmanvaihto
  Option('mechanical', 'mechanical_ventilation'),
  // Painovoimainen ilmanvaihto
  Option('gravity', 'gravity_ventilation'),
  // Koneellinen tulo- ja poistoilmanvaihto
  Option('mechanical_supply_and_extract', 'mechanical_supply_and_extract_ventilation')
]

export const BuildingHeatDistributionTypes = [
  Option('ceiling', 'ceiling_er_heating'),
  Option('floor', 'floor_er_heating'),
  Option('air', 'air_heating'),
  Option('radiator', 'radiator_heating'),
  Option('water_radiator', 'water_radiators'),
  Option('water_underfloor', 'water_underfloor_heating')
]

export enum AntennaSystems {
  Cable = 'cable',
  OpticalFiber = 'optical_fiber',
  DVBT2 = 'dvb_t2',
  Other = 'other'
}

export const AntennaSystemOptions = [
  Option('cable'),
  Option('optical_fiber'),
  Option('dvb_t2'),
  OtherOption
]

export enum CableCompanies {
  Lounea = 'lounea',
  Telia = 'telia',
  Elisa = 'elisa',
  DNA = 'dna',
  Other = 'other'
}

export const CableCompanyOptions = [
  Option('lounea'),
  Option('telia'),
  Option('elisa'),
  Option('dna'),
  OtherOption
]

export enum InternetSpeeds {
  TenMbs = '10 Mbit/s',
  TwentyMbs = '20 Mbit/s',
  FiftyMbs = '50 MB/s',
  HundredMbs = '100 Mbit/s',
  HundredFiftyMbs = '150 Mbit/s',
  TwoHundredMbs = '200 Mbit/s',
  ThreeHundredsMbs = '300 Mbit/s',
  FourHundredMbs = '400 Mbit/s',
  FiveHundredMbs = '500 Mbit/s',
  SixHundredMbs = '600 Mbit/s',
  ThousandMbs = '1000 Mbit/s',
  TwoThousandMbs = '2000 Mbit/s',
}

export const InternetSpeedOptions = [
  Option('10Mbs', InternetSpeeds.TenMbs),
  Option('20Mbs', InternetSpeeds.TwentyMbs),
  Option('50Mbs', InternetSpeeds.FiftyMbs),
  Option('100Mbs', InternetSpeeds.HundredMbs),
  Option('150Mbs', InternetSpeeds.HundredFiftyMbs),
  Option('200Mbs', InternetSpeeds.TwoHundredMbs),
  Option('300Mbs', InternetSpeeds.ThreeHundredsMbs),
  Option('400Mbs', InternetSpeeds.FourHundredMbs),
  Option('500Mbs', InternetSpeeds.FiveHundredMbs),
  Option('600Mbs', InternetSpeeds.SixHundredMbs),
  Option('1000Mbs', InternetSpeeds.ThousandMbs),
  Option('2000Mbs', InternetSpeeds.TwoThousandMbs)
]

export enum BuildingSections {
  // Perustukset ja perusmuuri
  Foundation = 'foundation',
  // Alapohja
  BaseFloor = 'base_floor',
  // Ulkoseinät ja julkisivu
  ExteriorWall = 'exterior_wall',
  // Välipohjat
  IntermediateFloor = 'intermediate_floor',
  // Väliseinät
  InteriorWall = 'interior_wall',
  // Yläpohja
  UpperFloor = 'upper_floor',
  // Vesikatto
  WaterRoof = 'water_roof',
  // Ikkunat
  Window = 'window',
  // Ovet
  Door = 'door',
  // Parvekkeet
  Balcony = 'balcony',
  // Terassit
  Terrace = 'terrace',
  // Ilmanvaihto
  Ventilation = 'ventilation',
  // Lämmitys
  Heating = 'heating',
  // Salaojajärjestelmä
  DrainageSystem = 'drainage_system',
  // Sadevesijärjestelmä
  RainwaterSystem = 'rainwater_system',
  // Tonttivesi
  PropertyWater = 'property_water',
  // Vesijohdot
  WaterPipe = 'water_pipe',
  // Lämmin vesi
  WarmWater = 'warm_water',
  // Jätehuolto
  WasteManagement = 'waste_management',
  // Hissit
  Elevator = 'elevator',
  // Pääjakelu
  MainDistribution = 'main_distribution',
  // Liittäminen ja tuotanto
  ConnectionAndProduction = 'connection_and_production',
  // Asennus- ja apujärjestelmät
  InstallationAndAuxiliarySystem = 'installation_and_auxiliary_system',
  // Liitäntäjärjestelmät
  ConnectionSystem = 'connection_system',
  // Valaistus
  Lighting = 'lighting',
  // Maadoitus
  Grounding = 'grounding',
  // Viestintä ja tietoverkko
  CommunicationAndDataNetwork = 'communication_and_data_network',
  // Paloturvallisuus
  FireSafety = 'fire_safety',
  // Tonttiviemäröinti
  PropertySewerage = 'property_sewerage',
  // Viemäriputket
  SewerPipe = 'sewer_pipe',
  // Jäteveden lämmöntalteenotto
  WasteWaterHeatRecovery = 'waste_water_heat_recovery'
}

const {
  Foundation,
  BaseFloor,
  ExteriorWall,
  IntermediateFloor,
  InteriorWall,
  UpperFloor,
  WaterRoof,
  Window,
  Door,
  Balcony,
  Terrace,
  Ventilation,
  Heating,
  DrainageSystem,
  RainwaterSystem,
  PropertyWater,
  WaterPipe,
  WarmWater,
  WasteManagement,
  Elevator,
  MainDistribution,
  ConnectionAndProduction,
  InstallationAndAuxiliarySystem,
  ConnectionSystem,
  Lighting,
  Grounding,
  CommunicationAndDataNetwork,
  FireSafety,
  PropertySewerage,
  SewerPipe,
  WasteWaterHeatRecovery
} = BuildingSections

export enum SystemCategories {
  // Ilmanvaihto
  Ventilation = 'ventilation',
  // Lämmitys
  Heating = 'heating',
  // Hulevesi
  Stormwater = 'stormwater',
  // Käyttövesi
  DomesticWater = 'domestic_water',
  // Jätehuolto
  WasteManagement = 'waste_management',
  // Hissit
  Elevator = 'elevator',
  // Sähkötekniikka
  ElectricalEngineering = 'electrical_engineering',
  // Viemäröinti
  Sewerage = 'sewerage'
}

export const SystemCategoryMappings = {
  [SystemCategories.Ventilation]: [Ventilation],
  [SystemCategories.Heating]: [Heating],
  [SystemCategories.Stormwater]: [
    DrainageSystem,
    RainwaterSystem
  ],
  [SystemCategories.DomesticWater]: [
    PropertyWater,
    WaterPipe,
    WarmWater
  ],
  [SystemCategories.WasteManagement]: [
    WasteManagement
  ],
  [SystemCategories.Elevator]: [
    Elevator
  ],
  [SystemCategories.ElectricalEngineering]: [
    MainDistribution,
    ConnectionAndProduction,
    InstallationAndAuxiliarySystem,
    ConnectionSystem,
    Lighting,
    Grounding,
    CommunicationAndDataNetwork,
    FireSafety
  ],
  [SystemCategories.Sewerage]: [
    PropertySewerage,
    SewerPipe,
    WasteWaterHeatRecovery
  ]
}

// Rakenteet
const BuildingStructureSections = {
  [Foundation]: [
    [
      Subtitle('basic_information'),
      {
        key: 'type',
        label: 'foundation_type',
        ...Select,
        options: [
          Option('pile', 'pile_foundation'),
          Option('pad', 'pad_foundation'),
          Option('pillar', 'pillar_foundation'),
          Option('edge_reinforced_tile'),
          Option('rock', 'rock_foundation'),
          Option('natural_stone', 'natural_stone_cladding'),
          OtherOption,
          UnknownOption
        ]
      },
      {
        key: 'typeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('type')],
        ...Input
      },
      {
        key: 'typeNotes',
        ...UnknownPlaceholder,
        visibleIfValues: [IfUnknownSelected('type')],
        ...Input
      },
      {
        key: 'material',
        label: 'foundation_material',
        visibleIfValues: [{ key: 'type', values: ['pile'] }],
        ...Select,
        options: [
          Option('steel_piles'),
          Option('concrete_piles'),
          Option('wood_piles')
        ] 
      },
      {
        key: 'material',
        label: 'foundation_material',
        visibleIfValues: [{ key: 'type', values: ['pad'] }],
        ...Select,
        options: [
          Option('poured_concrete', 'concrete_poured_in_place_alt'),
          Option('concrete_element'),
          Option('concrete_block', 'concrete_block_pad'),
        ] 
      }
    ],
    [
      Subtitle('plinth'),
      {
        key: 'plinthMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('concrete_block'),
          Option('poured_concrete', 'concrete_poured_in_place'),
          Option('concrete_element'),
          Option('steel'),
          OtherOption,
          UnknownOption
        ]
      },
      {
        key: 'plinthMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('plinthMaterial')],
        ...Input
      },
      {
        key: 'plinthMaterialNotes',
        ...UnknownPlaceholder,
        visibleIfValues: [IfUnknownSelected('plinthMaterial')],
        ...Input
      },
      {
        key: 'plinthInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'plinthCoating',
        label: 'plinth_coating',
        ...Select,
        options: [
          Option('paint'),
          Option('plaster'),
          Option('sheet_metal'),
          Option('plastic'),
          OtherOption,
          UnknownOption
        ]
      },
      {
        key: 'plinthCoatingNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('plinthCoating')],
        ...Input
      },
      {
        key: 'plinthCoatingNotes',
        ...UnknownPlaceholder,
        visibleIfValues: [IfUnknownSelected('plinthCoating')],
        ...Input
      },
      {
        key: 'plinthCoatingInstallationYear',
        label: 'coating_installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'plinthDampProofing',
        label: 'damp_proofing',
        ...Select,
        options: [
          Option('plastic_dam_plate'),
          Option('liquid_bitumen'),
          Option('bitumen_roll'),
          Option('drainable_insulation_board')
        ]
      },
      {
        key: 'plinthDampProofingNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('plinthDampProofing')],
        ...Input
      },
      {
        key: 'plinthDampProofingNotes',
        ...UnknownPlaceholder,
        visibleIfValues: [IfUnknownSelected('plinthDampProofing')],
        ...Input
      },
      {
        key: 'plinthDampProofingInstallationYear',
        label: 'damp_proofing_installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [BaseFloor]: [
    [
      Subtitle('basic_information'),
      {
        key: 'type',
        label: 'type',
        ...Select,
        options: [
          Option('concrete_steel_slab'),
          Option('supporting_concrete_steel_slab'),
          Option('edge_reinforced_concrete_steel_slab'),
          Option('plate_structure'),
          Option('aggregate_ventilated', 'aggregate_ventilated_base_floor'),
          Option('wooden_ventilated', 'wooden_ventilated_base_floor'),
        ]
      },
      {
        key: 'installationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
    ],
    [
      Subtitle('frame_material'),
      {
        key: 'frameMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('lightweight_concrete', 'lightweight_concrete_alt'),
          Option('poured_concrete', 'concrete_poured_in_place_alt'),
          Option('concrete_sub_slab_beam', 'concrete_sub_slab_beam_alt'),
          Option('concrete_element', 'concrete_element_alt'),
          Option('wood_element'),
          Option('steel'),
          Option('plate'),
          OtherOption
        ]
      },
      {
        key: 'frameMateriaNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('frameMaterial')],
        ...Input
      },
      {
        key: 'frameInsulation',
        label: 'insulation',
        ...Select,
        options: [
          Option('mineral_wool_board'),
          Option('mineral_wool', 'mineral_wool_blown'),
          Option('organic_material'),
          Option('mixed_fill', 'mixed_filling_alt'),
          Option('lightweight_concrete', 'lightweight_concrete_alt'),
          Option('eps'),
          Option('xps'),
          Option('pir'),
          Option('light_gravel'),
          Option('crushed_foam_glass'),
          OtherOption
        ]
      },
      {
        key: 'frameInsulationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('frameInsulation')],
        ...Input
      },
      {
        key: 'frameInsulationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfCustomSelected('frameInsulation', 'organic_material')],
        ...Input
      },
      {
        key: 'frameInsulationInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'frameInsulationUpperSurfacePlate',
        label: 'insulation_upper_surface_plate',
        ...Select,
        options: BooleanOptions
      }
    ],
    [
      Subtitle('surface_plate'),
      {
        key: 'surfacePlateMaterial',
        label: 'material',
        visibleIfValues: [IfYesSelected('frameInsulationUpperSurfacePlate')],
        ...Select,
        options: [
          Option('concrete'),
          Option('gypsum_mass'),
          Option('earth_moist_concrete'),
          Option('fiber_reinforced_concrete'),
          OtherOption
        ]
      },
      {
        key: 'surfacePlateMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          IfYesSelected('frameInsulationUpperSurfacePlate'),
          IfOtherSelected('surfacePlateMaterial')
        ],
        ...Input
      },
      {
        key: 'surfacePlateInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('frameInsulationUpperSurfacePlate')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('ventilation_route'),
      {
        key: 'ventilationType',
        label: 'type',
        ...Select,
        options: [
          Option('plinth', 'plinth_ventilation'),
          Option('pile', 'pile_ventilation'),
          Option('natural_stone', 'natural_stone_ventilation'),
          OtherOption,
          Option('no_viable_ventilation')
        ]
      },
      {
        key: 'ventilationTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('ventilationType')],
        ...Input
      },
      {
        key: 'ventilationTypeNotes',
        ...UnknownPlaceholder,
        visibleIfValues: [IfCustomSelected('ventilationType', 'no_viable_ventilation')],
        ...Input
      },
      {
        ...Row,
        items: [
          {
            key: 'hasMechanicalExtraction',
            label: 'mechanical_extraction',
            // disabledIfValues: [IfYesSelected('hasMechanicalVentilation')],
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasMechanicalVentilation',
            label: 'mechanical_ventilation',
            // disabledIfValues: [IfYesSelected('hasMechanicalExtraction')],
            ...Select,
            options: BooleanOptions
          }
        ]
      },
      {
        key: 'extractionMachineType',
        label: 'machine_model_extraction',
        visibleIfValues: [IfYesSelected('hasMechanicalExtraction')],
        ...Input
      },
      {
        key: 'extractionMachineInstallationYear',
        label: 'installation_year_extraction',
        visibleIfValues: [IfYesSelected('hasMechanicalExtraction')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'extractionMachineLocation',
        label: 'machine_location_extraction',
        visibleIfValues: [IfYesSelected('hasMechanicalExtraction')],
        ...Select,
        options: [
          Option('water_roof'),
          Option('ventilated_base_floor'),
          OtherOption
        ]
      },
      {
        key: 'extractionMachineLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('extractionMachineLocation')],
        ...Input
      },
      {
        key: 'ventilationMachineType',
        label: 'machine_model_ventilation',
        visibleIfValues: [IfYesSelected('hasMechanicalVentilation')],
        ...Input
      },
      {
        key: 'ventilationMachineInstallationYear',
        label: 'installation_year_ventilation',
        visibleIfValues: [IfYesSelected('hasMechanicalVentilation')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'ventilationMachineLocation',
        label: 'machine_location_ventilation',
        visibleIfValues: [IfYesSelected('hasMechanicalVentilation')],
        ...Select,
        options: [
          Option('ventilated_base_floor'),
          Option('technical_space'),
          OtherOption
        ]
      },
      {
        key: 'ventilationMachineLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('ventilationMachineLocation')],
        ...Input
      }
    ]
  ],
  [ExteriorWall]: [
    [
      Subtitle('exterior_walls'),
      {
        key: 'frameMaterial',
        label: 'frame_material',
        ...Select,
        options: [
          Option('concrete_block'),
          Option('brick'),
          Option('lightweight_concrete'),
          Option('poured_concrete_block'),
          Option('eps_block'),
          Option('poured_concrete', 'concrete_poured_in_place_alt'),
          Option('concrete_element'),
          Option('mass_wall', 'mass_wall_alt'),
          Option('wood', 'wood_built_in_place'),
          Option('wood_element'),
          Option('log'),
          Option('steel'),
          OtherOption
        ]
      },
      {
        key: 'frameMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('frameMaterial')],
        ...Input
      }
    ],
    [
      Subtitle('insulation'),
      {
        key: 'insulationMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('organic_material'),
          Option('eps'),
          Option('xps'),
          Option('pir'),
          Option('air_gap'),
          Option('no_insulation', 'no_insulation_alt'),
          OtherOption
        ]
      },
      {
        key: 'insulationMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('insulationMaterial')],
        ...Input
      },
      /* Not documented
      {
        key: 'insulationInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      */
      {
        key: 'hasWindBarrierBoard',
        label: 'wind_barrier_board',
        ...Select,
        options: BooleanOptions
      },
    ],
    [
      Subtitle('wind_barrier_board'),
      {
        key: 'windBarrierBoardType',
        label: 'type',
        visibleIfValues: [IfYesSelected('hasWindBarrierBoard')],
        ...Select,
        options: [
          Option('wind_barrier_board'),
          Option('bitumen', 'bitumen_board'),
          Option('wool', 'wind_barrier_wool'),
          Option('gypsum', 'wind_barrier_gypsum'),
          OtherOption
        ]
      },
      {
        key: 'windBarrierBoardTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('windBarrierBoardType')],
        ...Input
      },
      {
        key: 'windBarrierBoardInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasWindBarrierBoard')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('facade'),
      {
        key: 'facadeMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('poured_concrete', 'concrete_poured_in_place_alt'),
          Option('concrete_element'),
          Option('brick'),
          Option('natural_stone'),
          Option('wood', 'wood_or_wood_cladding'),
          Option('thin_plaster', 'thin_insulation_plaster'),
          Option('thick_plaster', 'thick_insulation_plaster'),
          Option('board_plaster'),
          Option('steel'),
          Option('glass'),
          OtherOption
        ] 
      },
      {
        key: 'facadeMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('facadeMaterial')],
        ...Input
      },
      {
        key: 'facadeInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      /* Not documented
      {
        key: 'facadeCoatingSameAsMaterial',
        label: 'facade_coating_same_as_material',
        ...Select,
        options: [
        ]
      },
      */
    ],
    [
      Subtitle('facade_coating'),
      {
        key: 'facadeCoatingMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('uncoated'),
          Option('paint'),
          Option('washed_concrete'),
          Option('natural_stone_tile'),
          Option('ceramic_tile'),
          Option('brick_tile'),
          Option('steel'),
          Option('coated_steel_sheet'),
          Option('aluminum'),
          Option('copper'),
          Option('fiber_cement'),
          Option('ceramic_board', 'ceramic_facade_board'),
          Option('plastic', 'plastic_coating'),
          OtherOption 
        ]
      },
      {
        key: 'facadeCoatingMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('facadeCoatingMaterial')],
        ...Input
      },
      {
        key: 'facadeCoatingInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [IntermediateFloor]: [
    [
      Subtitle('basic_information'),
      {
        key: 'frameMaterial',
        label: 'frame_material',
        ...Select,
        options: [
          Option('lightweight_concrete', 'lightweight_concrete_alt'),
          Option('poured_concrete', 'concrete_poured_in_place_alt'),
          Option('concrete_sub_slab_beam', 'concrete_sub_slab_beam_alt'),
          Option('concrete_element', 'concrete_element_alt'),
          Option('wood', 'wood_built_in_place'),
          Option('wood_element'),
          Option('steel_beam'),
          Option('plate'),
          OtherOption
        ]
      },
      {
        key: 'frameMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('frameMaterial')],
        ...Input
      },
      {
        key: 'installationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'frameInsulationMaterial',
        label: 'insulation_material',
        ...Select,
        options: [
          Option('mineral_wool_board'),
          Option('mineral_wool', 'mineral_wool_blown'),
          Option('organic_material'),
          Option('mixed_fill', 'mixed_filling_alt'),
          Option('lightweight_concrete', 'lightweight_concrete_alt'),
          Option('eps'),
          Option('xps'),
          Option('pir'),
          Option('light_gravel'),
          Option('crushed_foam_glass'),
          OtherOption,
          Option('no_insulation', 'no_insulation_alt')
        ]
      },
      {
        key: 'frameInsulationMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('frameInsulationMaterial')],
        ...Input
      },
      {
        key: 'frameInsulationMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfCustomSelected('frameInsulationMaterial', 'organic_material')],
        ...Input
      },
      {
        key: 'insulationUpperSurfacePlate',
        label: 'insulation_upper_surface_plate',
        ...Select,
        options: BooleanOptions
      }
    ],
    [
      Subtitle('surface_plate'),
      {
        key: 'surfacePlateMaterial',
        label: 'material',
        visibleIfValues: [IfYesSelected('insulationUpperSurfacePlate')],
        ...Select,
        options: [
          Option('concrete'),
          Option('gypsum_mass'),
          Option('earth_moist_concrete'),
          Option('fiber_reinforced_concrete'),
          OtherOption
        ]
      },
      {
        key: 'surfacePlateMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('surfacePlateMaterial')],
        ...Input
      },
      {
        key: 'surfacePlateInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('insulationUpperSurfacePlate')],
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [InteriorWall]: [
    [
      Subtitle('bearing_interior_walls'),
      /* Not documented
      {
        key: 'bearingType',
        label: 'type',
        ...Select,
        options: [
        ]
      },
      */
      {
        key: 'bearingMaterial',
        label: 'material',
        ...MultiSelect,
        options: [
          Option('block'),
          Option('brick'),
          Option('lightweight_concrete'),
          Option('poured_concrete_block'),
          Option('eps_block'),
          Option('poured_concrete', 'concrete_poured_in_place_alt'),
          Option('concrete_element'),
          Option('mass_wall', 'mass_wall_alt'),
          Option('wood', 'wood_built_in_place'),
          Option('wood_element'),
          Option('log'),
          Option('steel'),
          Option('other', 'other_type')
        ]
      },
      {
        key: 'bearingMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('bearingMaterial')],
        ...Input
      }
    ],
    [
      Subtitle('lightweight_interior_walls'),
      {
        key: 'lightweightMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('wood'),
          Option('kerto'),
          Option('steel_frame'),
          Option('mass_wall', 'mass_wall_alt'),
          Option('block'),
          Option('brick'),
          Option('lightweight_concrete'),
          OtherOption
        ]
      },
      {
        key: 'lightweightMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('lightweightMaterial')],
        ...Input
      },
    ],
    [
      Subtitle('insulation'),
      {
        key: 'insulationMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('mineral_wool'),
          Option('organic_material'),
          Option('eps'),
          Option('xps'),
          Option('pir'),
          Option('air_gap'),
          OtherOption,
          Option('no_insulation', 'no_insulation_alt')
        ]
      },
      {
        key: 'insulationMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('insulationMaterial')],
        ...Input
      },
      {
        key: 'insulationMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfCustomSelected('insulationMaterial', 'organic_material')],
        ...Input
      },
      {
        key: 'insulationCladdingMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('gypsum_board'),
          Option('fiberboard'),
          Option('chipboard'),
          Option('uncoated'),
          OtherOption
        ]
      },
      {
        key: 'insulationCladdingMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('insulationCladdingMaterial')],
        ...Input
      }
    ]
  ],
  [UpperFloor]: [
    [
      Subtitle('bearing_structures'),
      {
        key: 'bearingStructureMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('lightweight_concrete', 'lightweight_concrete_alt'),
          Option('poured_concrete', 'concrete_poured_in_place_alt'),
          Option('concrete_element', 'concrete_element_alt'),
          Option('wood', 'wood_built_in_place'),
          Option('wood_element'),
          Option('steel_beam'),
          Option('plate'),
          OtherOption
        ]
      },
      {
        key: 'bearingStructureMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('bearingStructureMaterial')],
        ...Input
      }
    ],
    [
      Subtitle('insulation'),
      {
        key: 'insulationMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('mineral_wool_board'),
          Option('mineral_wool', 'mineral_wool_blown'),
          Option('organic_material'),
          Option('mixed_fill', 'mixed_filling_alt'),
          Option('lightweight_concrete', 'lightweight_concrete_alt'),
          Option('eps'),
          Option('xps'),
          Option('pir'),
          Option('light_gravel'),
          Option('crushed_foam_glass'),
          OtherOption
        ]
      },
      {
        key: 'insulationMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('insulationMaterial')],
        ...Input
      },
      {
        key: 'insulationMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfCustomSelected('insulationMaterial', 'organic_material')],
        ...Input
      },
      {
        key: 'insulationUpperFirePlate',
        label: 'insulation_upper_fire_plate',
        ...Select,
        options: BooleanOptions
      }
    ],
    [
      Subtitle('fire_plate'),
      {
        key: 'firePlateMaterial',
        label: 'material',
        visibleIfValues: [IfYesSelected('insulationUpperFirePlate')],
        ...Select,
        options: [
          Option('concrete'),
          Option('thin_layer_plaster'),
          Option('lap_brick', 'lap_brick_masonry'),
          OtherOption
        ]
      },
      {
        key: 'firePlateMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('firePlateMaterial')],
        ...Input
      },
      {
        key: 'firePlateInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('insulationUpperFirePlate')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('upper_floor_space'),
      {
        key: 'ventilationRouteType',
        label: 'ventilation_route',
        ...Select,
        options: [
          Option('eaves_gap', 'through_eaves_gaps'),
          Option('gable_gap', 'through_gable_gaps'),
          Option('underpressure_fan', 'through_underpressure_fans'),
          Option('ventilation_valve', 'through_ventilation_valves_at_ends'),
          OtherOption,
          Option('no_viable_ventilation')
        ]
      },
      {
        key: 'ventilationRouteTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('ventilationRouteType')],
        ...Input
      },
      {
        key: 'ventilationRouteTypeNotes',
        ...UnknownPlaceholder,
        visibleIfValues: [IfCustomSelected('ventilationRouteType', 'no_viable_ventilation')],
        ...Input
      },
      {
        key: 'parallelOrLowStructure',
        label: 'parallel_to_water_roof_or_low_upper_floor_structure',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'ventilationHeight',
        label: 'ventilation_height',
        visibleIfValues: [IfYesSelected('parallelOrLowStructure')],
        ...Select,
        options: [
          Option('20'),
          Option('50'),
          Option('100'),
          Option('200'),
          Option('400'),
          OtherOption,
          UnknownOption,
          Option('no_viable_ventilation')
        ]
      },
      {
        key: 'ventilationHeightNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('ventilationHeight')],
        ...Input
      },
      {
        key: 'ventilationHeightNotes',
        ...UnknownPlaceholder,
        visibleIfValues: [
          IfUnknownSelected('ventilationHeight'),
          // Test this
          IfCustomSelected('ventilationHeight', 'no_viable_ventilation')
        ],
        ...Input
      }
    ]
  ],
  [WaterRoof]: [
    [
      Subtitle('bearing_structures'),
      {
        key: 'bearingStructureMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('wood_lattice'),
          Option('wood_pile'),
          Option('steel_beam'),
          OtherOption
        ]
      },
      {
        key: 'bearingStructureMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('bearingStructureMaterial')],
        ...Input
      },
      {
        key: 'bearingStructureInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'bearingStructureSameAsUpperFloor',
        label: 'bearing_structure_same_as_upper_floor',
        ...Select,
        options: BooleanOptions
      }
    ],
    [
      Subtitle('underlayment'),
      {
        key: 'oldWaterRoofUnderlayment',
        label: 'old_water_roof_as_underlayment',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'underlaymentMaterial',
        label: 'material',
        visibleIfValues: [IfYesSelected('oldWaterRoofUnderlayment')],
        ...Select,
        options: [
          Option('fiber_reinforced_plastic'),
          Option('fiberboard'),
          Option('underlayment_roll'),
          Option('solid_plank'),
          Option('bitumen_roll'),
          Option('tin_roof'),
          Option('shake_roof'),
          OtherOption
        ]
      },
      {
        key: 'underlaymentInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('oldWaterRoofUnderlayment')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('water_roof'),
      {
        key: 'type',
        label: 'type',
        ...Select,
        options: [
          Option('gable_roof'),
          Option('broken_gable_roof'),
          Option('pulpit_roof'),
          Option('flat_roof'),
          Option('aum_roof'),
          Option('mansard_roof'),
          Option('folding_roof'),
          Option('inverse_roof'),
          OtherOption
        ]
      },
      {
        key: 'typeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('type')],
        ...Input
      },
      {
        key: 'material',
        label: 'material',
        ...Select,
        options: [
          Option('profile_tin_roof'),
          Option('seamed_roof'),
          Option('corrugated_roof'),
          Option('copper'),
          Option('aluminum'),
          Option('clay_tile'),
          Option('concrete_tile'),
          Option('bitumen_roll'),
          Option('fiber_cement'),
          Option('glass'),
          Option('slate'),
          Option('bitumen_shingle'),
          Option('wood_shingle'),
          Option('green_roof'),
          OtherOption
        ]
      },
      {
        key: 'materialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('material')],
        ...Input
      },
      {
        key: 'installationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('water_drainage_system'),
      {
        key: 'waterDrainageType',
        label: 'type',
        ...Select,
        options: [
          Option('externally_supported_rainwater_gutters_with_downspouts'),
          Option('hidden_gutter_with_external_downspouts'),
          Option('hidden_gutter_with_concealed_downspouts'),
          Option('foot_gutters_and_collector_funnels_with_downspouts'),
          Option('no_separate_water_drainage')
        ]
      },
      {
        key: 'waterDrainageMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('steel'),
          Option('copper'),
          Option('plastic'),
          Option('wood'),
          Option('steel_and_plastic_structured_roof_drains'),
          OtherOption
        ]
      },
      {
        key: 'waterDrainageMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('waterDrainageMaterial')],
        ...Input
      },
      {
        ...Row,
        items: [
          {
            key: 'waterDrainageHasDeicing',
            label: 'deicing_system',
            ...Select,
            options: [
              Option('yes', 'has'),
              Option('no'),
              Option('in_part', 'has_in_part')
            ]
          },
          {
            key: 'waterDrainageInstallationYear',
            label: 'installation_year',
            ...Select,
            options: YearOptions()
          }
        ]
      }
    ]
  ],
}

const BuildingPartSections = {
  [Window]: [
    [
      Subtitle('total_counts_per_building'),
      {
        ...Row,
        items: [
          {
            key: 'totalCount',
            label: 'total_count',
            ...Input,
            unit: 'kpl',
            disabled: true
          },
          {
            key: 'apartmentWindowCount',
            label: 'apartment_windows',
            ...Input,
            unit: 'kpl',
            disabled: true
          },
          {
            key: 'commonSpaceWindowCount',
            label: 'common_space_windows',
            ...Input,
            unit: 'kpl',
            disabled: true
          }
        ]
      }
    ],
    [
      Subtitle('apartment_windows'),
      {
        key: 'apartmentWindowTypes',
        label: 'type',
        ...MultiSelect,
        options: [
          Option('msu'),
          Option('ms'),
          Option('msk'),
          Option('mse'),
          Option('msea'),
          Option('mek'),
          Option('meka'),
          Option('se'),
          Option('ms2e'),
          Option('ms3e'),
          Option('dk')
        ]
      },
    ],
    [
      Subtitle('additional_information'),
      {
        key: 'apartmentWindowMsuMaterial',
        label: 'msu_material',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'msu')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'apartmentWindowMsuOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('apartmentWindowTypes', 'msu'),
          IfOtherSelected('apartmentWindowMsuMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'msu')],
        items: [
          {
            key: 'apartmentWindowMsuCount',
            label: 'msu_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'apartmentWindowMsuInstallationYear',
            label: 'msu_installation_year',
            ...Select,
            options: YearOptions()
          },
        ],
      },
      {
        key: 'apartmentWindowMsuProperties',
        label: 'msu_equipment',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'msu')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
      Divider,
      {
        key: 'apartmentWindowMsMaterial',
        label: 'ms_material',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'ms')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'apartmentWindowMsOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('apartmentWindowTypes', 'ms'),
          IfOtherSelected('apartmentWindowMsMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'ms')],
        items: [
          {
            key: 'apartmentWindowMsCount',
            label: 'ms_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'apartmentWindowMsInstallationYear',
            label: 'ms_installation_year',
            ...Select,
            options: YearOptions()
          },
        ],
      },
      {
        key: 'apartmentWindowMsProperties',
        label: 'ms_equipment',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'ms')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
      Divider,
      {
        key: 'apartmentWindowMskMaterial',
        label: 'msk_material',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'msk')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'apartmentWindowMskOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('apartmentWindowTypes', 'msk'),
          IfOtherSelected('apartmentWindowMskMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'msk')],
        items: [
          {
            key: 'apartmentWindowMskCount',
            label: 'msk_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'apartmentWindowMskInstallationYear',
            label: 'msk_installation_year',
            ...Select,
            options: YearOptions()
          },
        ],
      },
      {
        key: 'apartmentWindowMskProperties',
        label: 'msk_equipment',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'msk')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
      Divider,
      {
        key: 'apartmentWindowMseaMaterial',
        label: 'msea_material',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'msea')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'apartmentWindowMseaOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('apartmentWindowTypes', 'msea'),
          IfOtherSelected('apartmentWindowMseaMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'msea')],
        items: [
          {
            key: 'apartmentWindowMseaCount',
            label: 'msea_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'apartmentWindowMseaInstallationYear',
            label: 'msea_installation_year',
            ...Select,
            options: YearOptions()
          },
        ],
      },
      {
        key: 'apartmentWindowMseaProperties',
        label: 'msea_equipment',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'msea')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
      Divider,
      {
        key: 'apartmentWindowMseMaterial',
        label: 'mse_material',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'mse')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'apartmentWindowMseOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('apartmentWindowTypes', 'mse'),
          IfOtherSelected('apartmentWindowMseMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'mse')],
        items: [
          {
            key: 'apartmentWindowMseCount',
            label: 'mse_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'apartmentWindowMseInstallationYear',
            label: 'mse_installation_year',
            ...Select,
            options: YearOptions()
          }
        ],
      },
      {
        key: 'apartmentWindowMseProperties',
        label: 'mse_equipment',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'mse')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
      Divider,
      {
        key: 'apartmentWindowMekMaterial',
        label: 'mek_material',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'mek')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'apartmentWindowMekOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('apartmentWindowTypes', 'mek'),
          IfOtherSelected('apartmentWindowMekMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'mek')],
        items: [
          {
            key: 'apartmentWindowMekCount',
            label: 'mek_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'apartmentWindowMekInstallationYear',
            label: 'mek_installation_year',
            ...Select,
            options: YearOptions()
          }
        ],
      },
      {
        key: 'apartmentWindowMekProperties',
        label: 'mek_equipment',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'mek')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
     Divider,
      {
        key: 'apartmentWindowMekaMaterial',
        label: 'meka_material',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'meka')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'apartmentWindowMekaOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('apartmentWindowTypes', 'meka'),
          IfOtherSelected('apartmentWindowMekaMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'meka')],
        items: [
          {
            key: 'apartmentWindowMekaCount',
            label: 'meka_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'apartmentWindowMekaInstallationYear',
            label: 'meka_installation_year',
            ...Select,
            options: YearOptions()
          }
        ],
      },
      {
        key: 'apartmentWindowMekaProperties',
        label: 'meka_equipment',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'meka')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
      Divider,
      {
        key: 'apartmentWindowSeMaterial',
        label: 'se_material',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'se')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'apartmentWindowSeOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('apartmentWindowTypes', 'se'),
          IfOtherSelected('apartmentWindowSeMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'se')],
        items: [
          {
            key: 'apartmentWindowSeCount',
            label: 'se_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'apartmentWindowSeInstallationYear',
            label: 'se_installation_year',
            ...Select,
            options: YearOptions()
          }
        ],
      },
      {
        key: 'apartmentWindowSeProperties',
        label: 'se_equipment',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'se')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
      Divider,
      {
        key: 'apartmentWindowMs2eMaterial',
        label: 'ms2e_material',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'ms2e')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'apartmentWindowMs2eOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('apartmentWindowTypes', 'ms2e'),
          IfOtherSelected('apartmentWindowMs2eMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'ms2e')],
        items: [
          {
            key: 'apartmentWindowMs2eCount',
            label: 'ms2e_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'apartmentWindowMs2eInstallationYear',
            label: 'ms2e_installation_year',
            ...Select,
            options: YearOptions()
          }
        ],
      },
      {
        key: 'apartmentWindowMs2eProperties',
        label: 'ms2e_equipment',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'ms2e')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
      Divider,
      {
        key: 'apartmentWindowMs3eMaterial',
        label: 'ms3e_material',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'ms3e')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'apartmentWindowMs3eOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('apartmentWindowTypes', 'ms3e'),
          IfOtherSelected('apartmentWindowMs3eMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'ms3e')],
        items: [
          {
            key: 'apartmentWindowMs3eCount',
            label: 'ms3e_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'apartmentWindowMs3eInstallationYear',
            label: 'ms3e_installation_year',
            ...Select,
            options: YearOptions()
          }
        ],
      },
      {
        key: 'apartmentWindowMs3eProperties',
        label: 'ms3e_equipment',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'ms3e')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
      Divider,
      {
        key: 'apartmentWindowDkMaterial',
        label: 'dk_material',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'dk')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'apartmentWindowDkOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('apartmentWindowTypes', 'dk'),
          IfOtherSelected('apartmentWindowDkMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'dk')],
        items: [
          {
            key: 'apartmentWindowDkCount',
            label: 'dk_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'apartmentWindowDkInstallationYear',
            label: 'dk_installation_year',
            ...Select,
            options: YearOptions()
          }
        ],
      },
      {
        key: 'apartmentWindowDkProperties',
        label: 'dk_equipment',
        visibleIfValues: [IfCustomSelected('apartmentWindowTypes', 'dk')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      }
    ],
    [
      Subtitle('common_space_windows'),
      {
        key: 'commonSpaceWindowTypes',
        label: 'type',
        ...MultiSelect,
        options: [
          Option('msu'),
          Option('ms'),
          Option('msk'),
          Option('mse'),
          Option('msea'),
          Option('mek'),
          Option('meka'),
          Option('se'),
          Option('ms2e'),
          Option('ms3e'),
          Option('dk')
        ]
      },
    ],
    [
      Subtitle('additional_information'),
      {
        key: 'commonSpaceWindowMsuMaterial',
        label: 'msu_material',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'msu')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'commonSpaceWindowMsuOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('commonSpaceWindowTypes', 'msu'),
          IfOtherSelected('commonSpaceWindowMsuMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'msu')],
        items: [
          {
            key: 'commonSpaceWindowMsuCount',
            label: 'msu_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'commonSpaceWindowMsuInstallationYear',
            label: 'msu_installation_year',
            ...Select,
            options: YearOptions()
          },
        ],
      },
      {
        key: 'commonSpaceWindowMsuProperties',
        label: 'msu_equipment',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'msu')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
      Divider,
      {
        key: 'commonSpaceWindowMsMaterial',
        label: 'ms_material',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'ms')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'commonSpaceWindowMsOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('commonSpaceWindowTypes', 'ms'),
          IfOtherSelected('commonSpaceWindowMsMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'ms')],
        items: [
          {
            key: 'commonSpaceWindowMsCount',
            label: 'ms_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'commonSpaceWindowMsInstallationYear',
            label: 'ms_installation_year',
            ...Select,
            options: YearOptions()
          },
        ],
      },
      {
        key: 'commonSpaceWindowMsProperties',
        label: 'ms_equipment',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'ms')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
      Divider,
      {
        key: 'commonSpaceWindowMskMaterial',
        label: 'msk_material',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'msk')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'commonSpaceWindowMskOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('commonSpaceWindowTypes', 'msk'),
          IfOtherSelected('commonSpaceWindowMskMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'msk')],
        items: [
          {
            key: 'commonSpaceWindowMskCount',
            label: 'msk_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'commonSpaceWindowMskInstallationYear',
            label: 'msk_installation_year',
            ...Select,
            options: YearOptions()
          },
        ],
      },
      {
        key: 'commonSpaceWindowMskProperties',
        label: 'msk_equipment',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'msk')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
      Divider,
      {
        key: 'commonSpaceWindowMseaMaterial',
        label: 'msea_material',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'msea')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'commonSpaceWindowMseaOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('commonSpaceWindowTypes', 'msea'),
          IfOtherSelected('commonSpaceWindowMseaMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'msea')],
        items: [
          {
            key: 'commonSpaceWindowMseaCount',
            label: 'msea_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'commonSpaceWindowMseaInstallationYear',
            label: 'msea_installation_year',
            ...Select,
            options: YearOptions()
          },
        ],
      },
      {
        key: 'commonSpaceWindowMseaProperties',
        label: 'msea_equipment',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'msea')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
      Divider,
      {
        key: 'commonSpaceWindowMseMaterial',
        label: 'mse_material',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'mse')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'commonSpaceWindowMseOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('commonSpaceWindowTypes', 'mse'),
          IfOtherSelected('commonSpaceWindowMseMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'mse')],
        items: [
          {
            key: 'commonSpaceWindowMseCount',
            label: 'mse_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'commonSpaceWindowMseInstallationYear',
            label: 'mse_installation_year',
            ...Select,
            options: YearOptions()
          }
        ],
      },
      {
        key: 'commonSpaceWindowMseProperties',
        label: 'mse_equipment',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'mse')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
      Divider,
      {
        key: 'commonSpaceWindowMekMaterial',
        label: 'mek_material',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'mek')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'commonSpaceWindowMekOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('commonSpaceWindowTypes', 'mek'),
          IfOtherSelected('commonSpaceWindowMekMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'mek')],
        items: [
          {
            key: 'commonSpaceWindowMekCount',
            label: 'mek_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'commonSpaceWindowMekInstallationYear',
            label: 'mek_installation_year',
            ...Select,
            options: YearOptions()
          }
        ],
      },
      {
        key: 'commonSpaceWindowMekProperties',
        label: 'mek_equipment',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'mek')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
     Divider,
      {
        key: 'commonSpaceWindowMekaMaterial',
        label: 'meka_material',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'meka')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'commonSpaceWindowMekaOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('commonSpaceWindowTypes', 'meka'),
          IfOtherSelected('commonSpaceWindowMekaMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'meka')],
        items: [
          {
            key: 'commonSpaceWindowMekaCount',
            label: 'meka_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'commonSpaceWindowMekaInstallationYear',
            label: 'meka_installation_year',
            ...Select,
            options: YearOptions()
          }
        ],
      },
      {
        key: 'commonSpaceWindowMekaProperties',
        label: 'meka_equipment',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'meka')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
      Divider,
      {
        key: 'commonSpaceWindowSeMaterial',
        label: 'se_material',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'se')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'commonSpaceWindowSeOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('commonSpaceWindowTypes', 'se'),
          IfOtherSelected('commonSpaceWindowSeMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'se')],
        items: [
          {
            key: 'commonSpaceWindowSeCount',
            label: 'se_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'commonSpaceWindowSeInstallationYear',
            label: 'se_installation_year',
            ...Select,
            options: YearOptions()
          }
        ],
      },
      {
        key: 'commonSpaceWindowSeProperties',
        label: 'se_equipment',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'se')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
      Divider,
      {
        key: 'commonSpaceWindowMs2eMaterial',
        label: 'ms2e_material',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'ms2e')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'commonSpaceWindowMs2eOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('commonSpaceWindowTypes', 'ms2e'),
          IfOtherSelected('commonSpaceWindowMs2eMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'ms2e')],
        items: [
          {
            key: 'commonSpaceWindowMs2eCount',
            label: 'ms2e_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'commonSpaceWindowMs2eInstallationYear',
            label: 'ms2e_installation_year',
            ...Select,
            options: YearOptions()
          }
        ],
      },
      {
        key: 'commonSpaceWindowMs2eProperties',
        label: 'ms2e_equipment',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'ms2e')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
      Divider,
      {
        key: 'commonSpaceWindowMs3eMaterial',
        label: 'ms3e_material',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'ms3e')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'commonSpaceWindowMs3eOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('commonSpaceWindowTypes', 'ms3e'),
          IfOtherSelected('commonSpaceWindowMs3eMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'ms3e')],
        items: [
          {
            key: 'commonSpaceWindowMs3eCount',
            label: 'ms3e_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'commonSpaceWindowMs3eInstallationYear',
            label: 'ms3e_installation_year',
            ...Select,
            options: YearOptions()
          }
        ],
      },
      {
        key: 'commonSpaceWindowMs3eProperties',
        label: 'ms3e_equipment',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'ms3e')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      },
      Divider,
      {
        key: 'commonSpaceWindowDkMaterial',
        label: 'dk_material',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'dk')],
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          Option('other', 'other_material')
        ]
      },
      {
        key: 'commonSpaceWindowDkOtherMaterial',
        label: 'other_material',
        visibleIfValues: [
          IfCustomSelected('commonSpaceWindowTypes', 'dk'),
          IfOtherSelected('commonSpaceWindowDkMaterial')
        ],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'dk')],
        items: [
          {
            key: 'commonSpaceWindowDkCount',
            label: 'dk_count',
            placeholder: 'enter_amount',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'commonSpaceWindowDkInstallationYear',
            label: 'dk_installation_year',
            ...Select,
            options: YearOptions()
          }
        ],
      },
      {
        key: 'commonSpaceWindowDkProperties',
        label: 'dk_equipment',
        visibleIfValues: [IfCustomSelected('commonSpaceWindowTypes', 'dk')],
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      }
    ]
  ],
  [Door]: [
    [
      Subtitle('total_counts_per_building'),
      {
        ...Row,
        items: [
          {
            key: 'totalCount',
            label: 'total_count',
            ...Input,
            unit: 'kpl',
            disabled: true
          },
          {
            key: 'exteriorDoorCount',
            label: 'building_exterior_doors',
            ...Input,
            unit: 'kpl',
            disabled: true
          },
          {
            key: 'balconyAndTerraceDoorCount',
            label: 'balcony_and_terrace_doors',
            ...Input,
            unit: 'kpl',
            disabled: true
          }
        ]
      },
      {
        ...Row,
        items: [
          {
            key: 'commonSpaceDoorCount',
            label: 'common_space_doors',
            ...Input,
            unit: 'kpl',
            disabled: true
          },
          {
            key: 'apartmentDoorCount',
            label: 'apartment_doors',
            ...Input,
            unit: 'kpl',
            disabled: true
          },
          {
            key: 'interiorDoorCount',
            label: 'interior_doors',
            ...Input,
            unit: 'kpl',
            disabled: true
          }
        ]
      }
    ],
    [
      Subtitle('building_exterior_doors'),
      {
        key: 'exteriorDoorType',
        label: 'type',
        ...Select,
        options: [
          Option('single_leaf', 'single_leaf_door'),
          Option('double_leaf', 'double_leaf_door'),
          Option('revolving', 'revolving_door'),
          Option('overhead', 'overhead_door'),
          Option('sliding', 'sliding_door'),
          OtherOption
        ]
      },
      {
        key: 'exteriorDoorTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('exteriorDoorType')],
        ...Input
      },
      {
        key: 'exteriorDoorFireCompartmentalization',
        label: 'fire_compartmentalization',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'exteriorDoorHasExtension',
        label: 'equipped_with_extension',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'exteriorDoorHasTurningClipHinges',
        label: 'turning_clip_hinges',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'exteriorDoorMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('wood_or_wood_fiber'),
          Option('steel'),
          Option('aluminum'),
          Option('glass')
        ]
      },
      {
        key: 'exteriorDoorInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'exteriorDoorHasLock',
        label: 'lock_system',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'exteriorDoorLockType',
        label: 'lock_system_type',
        visibleIfValues: [IfYesSelected('exteriorDoorHasLock')],
        ...Select,
        options: [
          Option('mechanical'),
          Option('electronic')
        ]
      },
      {
        key: 'exteriorDoorLockInstallationYear',
        label: 'lock_system_installation_year',
        visibleIfValues: [IfYesSelected('exteriorDoorHasLock')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'exteriorDoorCount',
        label: 'door_type_count',
        placeholder: 'enter_amount',
        ...Input,
        unit: 'kpl'
      }
    ],
    [
      Subtitle('balcony_and_terrace_doors'),
      {
        key: 'balconyAndTerraceDoorType',
        label: 'type',
        ...Select,
        options: [
          Option('single_leaf', 'single_leaf_door'),
          Option('double_leaf', 'double_leaf_door'),
          Option('revolving', 'revolving_door'),
          Option('overhead', 'overhead_door'),
          Option('sliding', 'sliding_door'),
          OtherOption
        ]
      },
      {
        key: 'balconyAndTerraceDoorTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('balconyAndTerraceDoorType')],
        ...Input
      },
      {
        key: 'balconyAndTerraceDoorFireCompartmentalization',
        label: 'fire_compartmentalization',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'balconyAndTerraceDoorHasExtension',
        label: 'equipped_with_extension',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'balconyAndTerraceDoorHasTurningClipHinges',
        label: 'turning_clip_hinges',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'balconyAndTerraceDoorMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('wood_or_wood_fiber'),
          Option('steel'),
          Option('aluminum'),
          Option('glass')
        ]
      },
      {
        key: 'balconyAndTerraceDoorInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'balconyAndTerraceDoorHasLock',
        label: 'lock_system',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'balconyAndTerraceDoorLockType',
        label: 'lock_system_type',
        visibleIfValues: [IfYesSelected('balconyAndTerraceDoorHasLock')],
        ...Select,
        options: [
          Option('mechanical'),
          Option('electronic')
        ]
      },
      {
        key: 'balconyAndTerraceDoorLockInstallationYear',
        label: 'lock_system_installation_year',
        visibleIfValues: [IfYesSelected('balconyAndTerraceDoorHasLock')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'balconyAndTerraceDoorCount',
        label: 'door_type_count',
        placeholder: 'enter_amount',
        ...Input,
        unit: 'kpl'
      }
    ],
    [
      Subtitle('building_common_space_doors'),
      {
        key: 'commonSpaceDoorType',
        label: 'type',
        ...Select,
        options: [
          Option('single_leaf', 'single_leaf_door'),
          Option('double_leaf', 'double_leaf_door'),
          Option('revolving', 'revolving_door'),
          Option('overhead', 'overhead_door'),
          Option('sliding', 'sliding_door'),
          OtherOption
        ]
      },
      {
        key: 'commonSpaceDoorTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('commonSpaceDoorType')],
        ...Input
      },
      {
        key: 'commonSpaceDoorFireCompartmentalization',
        label: 'fire_compartmentalization',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'commonSpaceDoorHasExtension',
        label: 'equipped_with_extension',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'commonSpaceDoorHasTurningClipHinges',
        label: 'turning_clip_hinges',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'commonSpaceDoorMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('wood_or_wood_fiber'),
          Option('steel'),
          Option('aluminum'),
          Option('glass')
        ]
      },
      {
        key: 'commonSpaceDoorInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'commonSpaceDoorHasLock',
        label: 'lock_system',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'commonSpaceDoorLockType',
        label: 'lock_system_type',
        visibleIfValues: [IfYesSelected('commonSpaceDoorHasLock')],
        ...Select,
        options: [
          Option('mechanical'),
          Option('electronic')
        ]
      },
      {
        key: 'commonSpaceDoorLockInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('commonSpaceDoorHasLock')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'commonSpaceDoorCount',
        label: 'door_type_count',
        placeholder: 'enter_amount',
        ...Input,
        unit: 'kpl'
      }
    ],
    [
      Subtitle('building_apartment_doors'),
      {
        key: 'apartmentDoorType',
        label: 'type',
        ...Select,
        options: [
          Option('single_leaf', 'single_leaf_door'),
          Option('double_leaf', 'double_leaf_door'),
          Option('revolving', 'revolving_door'),
          Option('overhead', 'overhead_door'),
          Option('sliding', 'sliding_door'),
          OtherOption
        ]
      },
      {
        key: 'apartmentDoorTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentDoorType')],
        ...Input
      },
      {
        key: 'apartmentDoorFireCompartmentalization',
        label: 'fire_compartmentalization',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'apartmentDoorHasExtension',
        label: 'equipped_with_extension',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'apartmentDoorHasTurningClipHinges',
        label: 'turning_clip_hinges',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'apartmentDoorMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('wood_or_wood_fiber'),
          Option('steel'),
          Option('aluminum'),
          Option('glass')
        ]
      },
      {
        key: 'apartmentDoorInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'apartmentDoorHasLock',
        label: 'lock_system',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'apartmentDoorLockType',
        label: 'lock_system_type',
        visibleIfValues: [IfYesSelected('apartmentDoorHasLock')],
        ...Select,
        options: [
          Option('mechanical'),
          Option('electronic')
        ]
      },
      {
        key: 'apartmentDoorLockInstallationYear',
        label: 'lock_system_installation_year',
        visibleIfValues: [IfYesSelected('apartmentDoorHasLock')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'apartmentDoorCount',
        label: 'door_type_count',
        placeholder: 'enter_amount',
        ...Input,
        unit: 'kpl'
      }
    ],
    [
      Subtitle('building_interior_doors'),
      {
        key: 'interiorDoorType',
        label: 'type',
        ...Select,
        options: [
          Option('single_leaf', 'single_leaf_door'),
          Option('double_leaf', 'double_leaf_door'),
          Option('revolving', 'revolving_door'),
          Option('overhead', 'overhead_door'),
          Option('sliding', 'sliding_door'),
          OtherOption
        ]
      },
      {
        key: 'interiorDoorTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('interiorDoorType')],
        ...Input
      },
      {
        key: 'interiorDoorFireCompartmentalization',
        label: 'fire_compartmentalization',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'interiorDoorHasExtension',
        label: 'equipped_with_extension',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'interiorDoorHasTurningClipHinges',
        label: 'turning_clip_hinges',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'interiorDoorMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('wood_or_wood_fiber'),
          Option('steel'),
          Option('aluminum'),
          Option('glass')
        ]
      },
      {
        key: 'interiorDoorInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'interiorDoorHasLock',
        label: 'lock_system',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'interiorDoorLockType',
        label: 'lock_system_type',
        visibleIfValues: [IfYesSelected('interiorDoorHasLock')],
        ...Select,
        options: [
          Option('mechanical'),
          Option('electronic')
        ]
      },
      {
        key: 'interiorDoorLockInstallationYear',
        label: 'lock_system_installation_year',
        visibleIfValues: [IfYesSelected('interiorDoorHasLock')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'interiorDoorCount',
        label: 'door_type_count',
        placeholder: 'enter_amount',
        ...Input,
        unit: 'kpl'
      }
    ],
  ],
  [Balcony]: [
    [
      Subtitle('basic_information'),
      {
        key: 'buildingHasBalconies',
        label: 'building_has_balconies',
        ...Select,
        // Todo: default is true
        options: BooleanOptions
      }
    ],
    [
      Subtitle('total_counts_per_building'),
      {
        ...Row,
        items: [
          {
            key: 'apartmentBalconyCount',
            label: 'apartment_balconies',
            ...Input,
            unit: 'kpl',
            disabled: true
          },
          {
            key: 'commonSpaceBalconyCount',
            label: 'common_space_balconies',
            ...Input,
            unit: 'kpl',
            disabled: true
          },
          {
            key: 'glassBalconyCount',
            label: 'balconies_with_glass',
            ...Input,
            unit: 'kpl',
            disabled: true
          }
        ]
      }
    ],
    [
      Subtitle('apartment_balconies'),
      {
        key: 'balconyInApartments',
        label: 'all_apartments_have_balconies',
        ...Select,
        options: [
          Option('yes'),
          Option('only_some'),
        ]
      },
      {
        key: 'apartmentBalconyType',
        label: 'type',
        ...Select,
        options: [
          Option('in', 'balcony_in'),
          Option('out', 'balcony_out'),
          Option('french', 'french_balcony')
        ]
      },
      {
        key: 'apartmentBalconyStructureType',
        label: 'bearing_structures',
        ...Select,
        options: [
          Option('building_frame'),
          Option('substructures'),
          Option('pillar', 'pillar_structures'),
          Option('hung')
        ]
      },
      {
        key: 'apartmentBalconyStructureMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('concrete'),
          Option('steel'),
          Option('wood')
        ]
      },
      {
        key: 'apartmentBalconyInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      // Todo: possible position change
      {
        key: 'apartmentBalconyCount',
        label: 'balcony_type_count',
        placeholder: 'enter_amount',
        ...Input,
        unit: 'kpl'
      },
      Subtitle('water_proofing'),
      {
        key: 'apartmentBalconyWaterProofingType',
        label: 'type',
        ...Select,
        options: [
          Option('no', 'no_water_proofing'),
          UnknownOption,
          Option('mass', 'mass_water_proofing'),
          Option('paint'),
          Option('bitumen'),
          OtherOption
        ]
      },
      {
        key: 'apartmentBalconyWaterProofingTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentBalconyWaterProofingType')],
        ...Input
      },
      {
        key: 'apartmentBalconyWaterProofingInstallationYear',
        label: 'installation_year',
        visibleIfValues: [{ key: 'apartmentBalconyWaterProofingType', values: ['mass', 'paint', 'bitumen', 'other'] }],
        ...Select,
        options: YearOptions()
      },
      Subtitle('rails'),
      {
        key: 'apartmentBalconyRailType',
        label: 'type',
        ...Select,
        options: [
          Option('steel'),
          Option('aluminum'),
          Option('concrete'),
          Option('wood'),
          Option('glass'),
          Option('plate', 'balcony_plating')
        ]
      },
      {
        key: 'apartmentBalconyRailInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      Subtitle('glazings'),
      {
        key: 'apartmentHaveGlazedBalconies',
        label: 'balconies_have_glazing',
        ...Select,
        options: [
          Option('all', 'in_all_balconies'),
          Option('some', 'in_some_balconies'),
          Option('no')
        ]
      },
      {
        key: 'apartmentBalconyGlazingInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'apartmentBalconyGlazedCount',
        label: 'glazed_balcony_count',
        placeholder: 'enter_amount',
        ...Input,
        unit: 'kpl'
      }
    ],
    [
      Subtitle('common_space_balconies'),
      {
        key: 'commonSpaceBalconyType',
        label: 'type',
        ...Select,
        options: [
          Option('in', 'balcony_in'),
          Option('out', 'balcony_out'),
          Option('french', 'french_balcony')
        ]
      },
      {
        key: 'commonSpaceBalconyStructureType',
        label: 'bearing_structures',
        ...Select,
        options: [
          Option('building_frame'),
          Option('substructures'),
          Option('pillar', 'pillar_structures'),
          Option('hung')
        ]
      },
      {
        key: 'commonSpaceBalconyStructureMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('concrete'),
          Option('steel'),
          Option('wood')
        ]
      },
      {
        key: 'commonSpaceBalconyInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'commonSpaceBalconyCount',
        label: 'balcony_type_count',
        placeholder: 'enter_amount',
        ...Input,
        unit: 'kpl'
      },
      Subtitle('water_proofing'),
      {
        key: 'commonSpaceBalconyWaterProofingType',
        label: 'type',
        ...Select,
        options: [
          Option('no', 'no_water_proofing_alt'),
          UnknownOption,
          Option('mass'),
          Option('paint'),
          Option('bitumen'),
          OtherOption
        ]
      },
      {
        key: 'commonSpaceBalconyWaterProofingTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('commonSpaceBalconyWaterProofingType')],
        ...Input
      },
      {
        key: 'commonSpaceBalconyWaterProofingInstallationYear',
        label: 'installation_year',
        visibleIfValues: [{ key: 'commonSpaceBalconyWaterProofingType', values: ['mass', 'paint', 'bitumen', 'other'] }],
        ...Select,
        options: YearOptions()
      },
      Subtitle('rails'),
      {
        key: 'commonSpaceBalconyRailType',
        label: 'type',
        ...Select,
        options: [
          Option('steel'),
          Option('aluminum'),
          Option('concrete'),
          Option('wood'),
          Option('glass'),
          Option('plate', 'plating')
        ]
      },
      {
        key: 'commonSpaceBalconyRailInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      Subtitle('glazings'),
      {
        key: 'commonSpaceHasGlazedBalconies',
        label: 'balconies_have_glazing',
        ...Select,
        options: [
          Option('all', 'in_all_balconies'),
          Option('some', 'in_some_balconies'),
          Option('no')
        ]
      },
      {
        key: 'commonSpaceBalconyGlazingInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'commonSpaceBalconyGlazedCount',
        label: 'glazed_balcony_count',
        placeholder: 'enter_amount',
        ...Input,
        unit: 'kpl'
      }
    ]
  ],
  [Terrace]: [
    [
      Subtitle('basic_information'),
      {
        key: 'buildingHasTerraces',
        label: 'building_has_terraces',
        ...Select,
        // Todo: default is true
        options: BooleanOptions
      },
      {
        key: 'allApartmentsHaveTerraces',
        label: 'all_apartments_have_terraces',
        ...Select,
        options: BooleanOptions
      }
    ],
    [
      Subtitle('total_counts_per_building'),
      {
        ...Row,
        items: [
          {
            key: 'apartmentTerraceCount',
            label: 'apartment_terraces',
            ...Input,
            unit: 'kpl',
            disabled: true
          },
          {
            key: 'commonSpaceTerraceCount',
            label: 'common_space_terraces',
            ...Input,
            unit: 'kpl',
            disabled: true
          },
          {
            key: 'glassTerraceCount',
            label: 'terraces_with_glass',
            ...Input,
            unit: 'kpl',
            disabled: true
          }
        ]
      }
    ],
    [
      Subtitle('apartment_terraces'),
      {
        key: 'apartmentTerraceType',
        label: 'type',
        ...Select,
        options: [
          Option('covered', 'covered_terrace'),
          Option('uncovered', 'uncovered_terrace'),
          Option('partly_covered'),
          Option('in', 'terrace_in'),
          Option('patio')
        ]
      },
      {
        key: 'apartmentTerraceFrameMaterial',
        label: 'frame_material',
        ...Select,
        options: [
          Option('wood'),
          Option('steel'),
          Option('aluminum'),
          Option('concrete'),
          Option('stratum', 'stratums'),
          OtherOption
        ]
      },
      {
        key: 'apartmentTerraceFrameMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentTerraceFrameMaterial')],
        ...Input
      },
      {
        key: 'apartmentTerraceSurfaceMaterial',
        label: 'surface_structures',
        ...Select,
        options: [
          Option('wood'),
          Option('concrete'),
          Option('stone'),
          OtherOption
        ]
      },
      {
        key: 'apartmentTerraceSurfaceMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentTerraceSurfaceMaterial')],
        ...Input
      },
      {
        key: 'apartmentTerraceInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'apartmentTerraceCount',
        label: 'apartment_terrace_count',
        ...Input,
        unit: 'kpl'
      },
      Subtitle('water_proofing'),
      {
        key: 'apartmentTerraceWaterProofingType',
        label: 'type',
        ...Select,
        options: [
          Option('no', 'no_water_proofing'),
          UnknownOption,
          Option('mass', 'mass_water_proofing'),
          Option('paint'),
          Option('bitumen', 'bitumen_roll'),
          OtherOption
        ]
      },
      {
        key: 'apartmentTerraceWaterProofingNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentTerraceWaterProofingType')],
        ...Input
      },
      {
        key: 'apartmentTerraceWaterProofingInstallationYear',
        label: 'installation_year',
        visibleIfValues: [
          { key: 'apartmentTerraceWaterProofingType', values: ['mass', 'paint', 'bitumen', 'other'] }
        ],
        ...Select,
        options: YearOptions()
      },
      Subtitle('rails'),
      {
        key: 'apartmentTerraceRailMaterial',
        label: 'type',
        ...Select,
        options: [
          Option('steel'),
          Option('aluminum'),
          Option('concrete'),
          Option('wood'),
          Option('glass'),
          Option('plate', 'plating')
        ]
      },
      {
        key: 'apartmentTerraceRailInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      Subtitle('glazings'),
      {
        key: 'apartmentsHaveGlazedTerraces',
        label: 'terraces_have_glazing',
        ...Select,
        options: [
          Option('all', 'yes_in_all_apartments'),
          Option('some', 'yes_in_some_apartments'),
          Option('no', 'terraces_have_no_glazing')
        ]
      },
      {
        key: 'apartmentTerraceGlazingInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'apartmentTerraceGlazedCount',
        label: 'glazed_terrace_count',
        placeholder: 'enter_amount',
        ...Input,
        unit: 'kpl'
      }
    ],
    [
      Subtitle('common_space_terraces'),
      {
        key: 'commonSpaceTerraceType',
        label: 'type',
        ...Select,
        options: [
          Option('covered', 'covered_terrace'),
          Option('uncovered', 'uncovered_terrace'),
          Option('in', 'terrace_in'),
          Option('patio')
        ]
      },
      {
        key: 'commonSpaceTerraceFrameMaterial',
        label: 'frame_material',
        ...Select,
        options: [
          Option('wood'),
          Option('steel'),
          Option('aluminum'),
          Option('concrete'),
          Option('stratum', 'stratums'),
          OtherOption
        ]
      },
      {
        key: 'commonSpaceTerraceFrameMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('commonSpaceTerraceFrameMaterial')],
        ...Input
      },
      {
        key: 'commonSpaceTerraceSurfaceMaterial',
        label: 'surface_structures',
        ...Select,
        options: [
          Option('wood'),
          Option('concrete'),
          Option('stone'),
          OtherOption
        ]
      },
      {
        key: 'commonSpaceTerraceSurfaceMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('commonSpaceTerraceSurfaceMaterial')],
        ...Input
      },
      {
        key: 'commonSpaceTerraceInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'commonSpaceTerraceCount',
        label: 'common_space_specific_terrace_count',
        placeholder: 'enter_amount',
        ...Input,
        unit: 'kpl'
      },
      Subtitle('water_proofing'),
      {
        key: 'commonSpaceTerraceWaterProofingType',
        label: 'type',
        ...Select,
        options: [
          Option('no', 'no_water_proofing'),
          UnknownOption,
          Option('mass', 'mass_water_proofing'),
          Option('paint'),
          Option('bitumen', 'bitumen_roll'),
          OtherOption
        ]
      },
      {
        key: 'commonSpaceTerraceWaterProofingNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('commonSpaceTerraceWaterProofingType')],
        ...Input
      },
      {
        key: 'commonSpaceTerraceWaterProofingInstallationYear',
        label: 'installation_year',
        visibleIfValues: [
          { key: 'commonSpaceTerraceWaterProofingType', values: ['mass', 'paint', 'bitumen', 'other'] }
        ],
        ...Select,
        options: YearOptions()
      },
      Subtitle('rails'),
      {
        key: 'commonSpaceTerraceRailMaterial',
        label: 'type',
        ...Select,
        options: [
          Option('steel'),
          Option('aluminum'),
          Option('concrete'),
          Option('wood'),
          Option('glass'),
          Option('plate', 'plating')
        ]
      },
      {
        key: 'commonSpaceTerraceRailInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      Subtitle('glazings'),
      {
        key: 'commonSpaceTerraceHasGlazing',
        label: 'terraces_have_glazing',
        ...Select,
        options: [
          Option('all', 'yes_in_all_apartments'),
          Option('some', 'yes_in_some_apartments'),
          Option('no', 'terraces_have_no_glazing')
        ]
      },
      {
        key: 'commonSpaceTerraceGlazingInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'commonSpaceTerraceGlazedCount',
        label: 'glazed_terrace_count',
        placeholder: 'enter_amount',
        ...Input,
        unit: 'kpl'
      }
    ]
  ]
}

export const BuildingSystemSections = {
  [Ventilation]: [
    [
      Subtitle('basic_information'),
      {
        key: 'type',
        label: 'ventilation_type',
        ...Select,
        options: [
          // Koneellinen poistoilmanvaihto
          Option('mechanical', 'mechanical_ventilation'),
          // Painovoimainen ilmanvaihto
          Option('gravity', 'gravity_ventilation'),
          // Koneellinen tulo- ja poistoilmanvaihto
          Option('mechanical_supply_and_extract', 'mechanical_supply_and_extract_ventilation')
        ]
      },
      {
        key: 'machineModel',
        label: 'machine_model',
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract'] }
        ],
        ...Input,
        /*
        ...Select,
        options: [
          ...DefaultOptions,
          OtherOption
        ]
        */
      },
      {
        key: 'machineModelNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract'] },
          IfOtherSelected('machineModel')
        ],
        ...Input
      },
      {
        key: 'machineLocation',
        label: 'machine_location',
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract'] }
        ],
        ...Select,
        options: [
          Option('air_supply_room'),
          Option('water_roof'),
          Option('upper_floor'),
          Option('technical_space'),
          OtherOption
        ]
      },
      {
        key: 'machineLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract'] },
          IfOtherSelected('machineLocation')
        ],
        ...Input
      },
      {
        ...Row,
        items: [
          {
            key: 'hasCondenseWaterDrainage',
            label: 'condense_water_drainage',
            visibleIfValues: [
              { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract'] }
            ],
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasHeatRecovery',
            label: 'heat_recovery',
            visibleIfValues: [
              { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract'] }
            ],
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasApartmentSpecificMachine',
            label: 'apartment_specific_machine',
            visibleIfValues: [
              { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract'] }
            ],
            ...Select,
            options: BooleanOptions
          }
        ]
      },
      {
        key: 'condenseWaterDrainageLocation',
        label: 'condense_water_drainage_location',
        visibleIfValues: [
          { key: 'hasCondenseWaterDrainage', values: ['yes'] }
        ],
        ...Select,
        options: [
          Option('floor_drain'),
          Option('sewer'),
          OtherOption
        ]
      },
      {
        key: 'condenseWaterDrainageLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'hasCondenseWaterDrainage', values: ['yes'] },
          IfOtherSelected('condenseWaterDrainageLocation')
        ],
        ...Input
      },
      {
        key: 'heatRecoveryCell',
        label: 'heat_recovery_cell',
        visibleIfValues: [
          { key: 'hasHeatRecovery', values: ['yes'] }
        ],
        ...Select,
        options: [
          Option('cross_flow'),
          Option('counter_flow'),
          Option('rotary')
        ]
      },
      {
        key: 'apartmentSpecificMachineLocation',
        label: 'apartment_specific_machine_location',
        visibleIfValues: [
          { key: 'hasApartmentSpecificMachine', values: ['yes'] }
        ],
        ...Select,
        options: [
          Option('bathroom', 'bathroom_short'),
          Option('utility_room', 'utility_room_short'),
          Option('walk_in_closet', 'walk_in_closet_short'),
          OtherOption
        ]
      },
      {
        key: 'apartmentSpecificMachineLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'hasApartmentSpecificMachine', values: ['yes'] },
          IfOtherSelected('apartmentSpecificMachineLocation')
        ],
        ...Input
      }
    ],
    [
      Subtitle('filters'),
      {
        key: 'filterType',
        label: 'filters',
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract'] }
        ],
        ...Select,
        options: [
          Option('mat', 'mat_filters'),
          Option('cassette', 'cassette_filters'),
          Option('bag', 'bag_filters')
        ]
      },
      {
        key: 'filtersReplacedAt',
        label: 'replaced',
        placeholder: 'enter_date',
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract'] }
        ],
        ...Input
      }
    ],
    [
      Subtitle('replacement_air'),
      {
        key: 'replacementAirRoute',
        label: 'route',
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract', 'gravity'] }
        ],
        ...Select,
        options: [
          Option('window_frame_valve', 'window_frame_valves'),
          Option('extraction_air_valve', 'extraction_air_valves'),
          Option('ventilation_window', 'ventilation_windows'),
          Option('no'),
          OtherOption
        ]
      },
      {
        key: 'replacementAirRouteNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract', 'gravity'] },
          IfOtherSelected('replacementAirRoute')
        ],
        ...Input
      }
    ],
    [
      Subtitle('ventilation_ducts'),
      {
        key: 'ductMaterial',
        label: 'material',
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'gravity', 'mechanical_supply_and_extract'] }
        ],
        ...Select,
        options: [
          Option('plastic'),
          Option('stone', 'stone_material'),
          Option('metal'),
          Option('asbestos')
        ]
      },
      {
        key: 'ductSize',
        label: 'size',
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'gravity', 'mechanical_supply_and_extract'] }
        ],
        ...Select,
        options: [
          Option('100'),
          Option('125'),
          Option('160'),
          Option('200'),
          Option('250'),
          Option('300'),
          OtherOption
        ]
      },
      {
        key: 'ductSizeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'gravity', 'mechanical_supply_and_extract'] },
          IfOtherSelected('ductSize')
        ],
        ...Input
      }
    ],
    [
      Subtitle('valves'), 
      {
        key: 'extractValveType',
        label: 'extract_valves',
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'gravity', 'mechanical_supply_and_extract'] }
        ],
        ...Select,
        options: [
          Option('poppet', 'poppet_valve'),
          Option('extract', 'extract_air_valve'),
          OtherOption
        ]
      },
      {
        key: 'extractValveTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'gravity', 'mechanical_supply_and_extract'] },
          IfOtherSelected('extractValveType')
        ],
        ...Input
      },
      {
        key: 'supplyValveType',
        label: 'supply_valves',
        visibleIfValues: [
          { key: 'type', values: ['mechanical_supply_and_extract'] }
        ],
        ...Select,
        options: [
          Option('supply', 'supply_air_valve'),
          Option('supply_diffuser', 'supply_air_diffuser'),
          Option('poppet', 'poppet_valve'),
          OtherOption
        ]
      },
      {
        key: 'supplyValveTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['mechanical_supply_and_extract'] },
          IfOtherSelected('supplyValveType')
        ],
        ...Input
      }
    ],
  ],
  [Heating]: [
    [
      Subtitle('basic_information'),
      {
        key: 'type',
        label: 'heating_type',
        ...Select,
        options: [
          // Suorasähkölämmitys
          Option('electric', 'electric_heating'),
          // Varaava sähkölämmitys
          Option('reserving_electric', 'reserving_electric_heating'),
          // Kaukolämpö
          Option('district', 'district_heating'),
          // Öljy
          Option('oil', 'oil_heating'),
          // Pelletti/puu
          Option('wood', 'wood_heating'),
          // Maalämpö
          Option('geothermal', 'geothermal_heating'),
          // Ilmavesilämpöpumppu
          Option('air_water', 'air_water_heat_pump'),
          // Poistoilmalämpöpumppu
          Option('extract_air', 'extract_air_heat_pump')
        ]
      },
      {
        key: 'machineLocation',
        label: 'machine_location_alt',
        visibleIfValues: [
          { key: 'type', values: ['electric'] }
        ],
        ...Select,
        options: [
          Option('technical_space'),
          Option('heat_distribution_room'),
          Option('boiler_room'),
          OtherOption
        ],
      },
      {
        key: 'machineLocationNotes',
        ...OtherPlaceholder,
        ...Input,
        visibleIfValues: [IfOtherSelected('machineLocation')]
      },
      {
        key: 'hasApartmentSpecificMachine',
        label: 'apartment_specific_heat_pump',
        visibleIfValues: [
          { key: 'type', values: ['electric'] }
        ],
        ...Select,
        options: BooleanOptions
      },
      {
        ...Row,
        items: [
          {
            key: 'hasApartmentSpecificElectricHeating',
            label: 'apartment_specific_electric_heating',
            visibleIfValues: [
              { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
            ],
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasPressureReliefValveExtraction',
            label: 'pressure_relief_valve_extraction',
            visibleIfValues: [
              { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
            ],
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasApartmentSpecificMachine',
            label: 'apartment_specific_machine_alt',
            visibleIfValues: [
              { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
            ],
            ...Select,
            options: BooleanOptions
          }
        ]
      }
    ],
    [
      {
        ...Subtitle('water_heater'),
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric'] }
        ]
      },
      {
        ...Subtitle('heat_exchanger'),
        visibleIfValues: [
          { key: 'type', values: ['district'] }
        ]
      },
      {
        ...Subtitle('pot_and_burner'),
        visibleIfValues: [
          { key: 'type', values: ['oil', 'wood'] }
        ]
      },
      {
        ...Subtitle('heating_machine'),
        visibleIfValues: [
          { key: 'type', values: ['geothermal', 'air_water', 'extract_air'] }
        ]
      },
      {
        key: 'machineManufacturer',
        label: 'manufacturer',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
        ],
        ...Input,
      },
      {
        key: 'machineModel',
        label: 'model',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
        ],
        ...Input,
      },
      {
        key: 'machineModelNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
          IfOtherSelected('machineModel')
        ],
        ...Input,
      },
      {
        key: 'machineVolume',
        label: 'volume',
        placeholder: 'enter_amount_litres',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric'] }
        ],
        ...Input
      },
      {
        key: 'machineInstallationYear',
        label: 'installation_year',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood'] }
        ],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'machineLocation',
        label: 'location',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood'] }
        ],
        ...Select,
        options: [
          Option('technical_space'),
          Option('heat_distribution_room'),
          Option('boiler_room'),
          OtherOption
        ]
      },
      {
        key: 'machineLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood'] },
          IfOtherSelected('machineLocation')
        ],
        ...Input,
      }
    ],
    [
      Subtitle('relief_valve_extraction'),
      {
        key: 'reliefValveExtractionLocation',
        label: 'location',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
          { key: 'hasPressureReliefValveExtraction', values: ['yes'] }
        ],
        ...Select,
        options: [
          Option('floor_drain'),
          Option('sewer'),
          OtherOption
        ]
      },
      {
        key: 'reliefValveExtractionLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
          { key: 'hasPressureReliefValveExtraction', values: ['yes'] },
          IfOtherSelected('reliefValveExtractionLocation')
        ],
        ...Input,
      }
    ],
    [
      Subtitle('apartment_specific_heat_pump'),
      {
        key: 'apartmentSpecificHeatPumpManufacturer',
        label: 'manufacturer',
        visibleIfValues: [
          { key: 'type', values: ['electric', 'reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
          { key: 'hasApartmentSpecificMachine', values: ['yes'] }
        ],
        ...Input,
      },
      {
        key: 'apartmentSpecificHeatPumpModel',
        label: 'model',
        visibleIfValues: [
          { key: 'type', values: ['electric', 'reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
          { key: 'hasApartmentSpecificMachine', values: ['yes'] }
        ],
        ...Input,
      },
      {
        key: 'apartmentSpecificHeatPumpModelNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['electric', 'reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
          { key: 'hasApartmentSpecificMachine', values: ['yes'] },
          IfOtherSelected('apartmentSpecificHeatPumpModel')
        ],
        ...Input,
      },
      {
        key: 'apartmentSpecificHeatPumpInstallationYear',
        label: 'installation_year',
        placeholder: 'enter_date',
        visibleIfValues: [
          { key: 'type', values: ['electric', 'reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
          { key: 'hasApartmentSpecificMachine', values: ['yes'] }
        ],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'apartmentSpecificHeatPumpLocation',
        label: 'location',
        visibleIfValues: [
          { key: 'type', values: ['electric', 'reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
          { key: 'hasApartmentSpecificMachine', values: ['yes'] }
        ],
        ...Select,
        options: [
          Option('living_room', 'living_room_short'),
          Option('bedroom', 'bedroom_short'),
          Option('hallway', 'hallway_short'),
          OtherOption
        ]
      },
      {
        key: 'apartmentSpecificHeatPumpLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['electric', 'reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
          { key: 'hasApartmentSpecificMachine', values: ['yes'] },
          IfOtherSelected('apartmentSpecificHeatPumpLocation')
        ],
        ...Input,
      }
    ],
    [
      Subtitle('heat_distribution'),
      {
        key: 'heatDistributionType',
        label: 'type',
        visibleIfValues: [
          { key: 'type', values: ['electric', 'extract_air'] }
        ],
        ...Select,
        options: [
          Option('ceiling', 'ceiling_er_heating'),
          Option('floor', 'floor_er_heating'),
          Option('air', 'air_heating'),
          Option('radiator', 'radiator_heating'),
        ]
      },
      {
        key: 'heatDistributionType',
        label: 'type',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water'] }
        ],
        ...Select,
        options: [
          Option('water_radiator', 'water_radiators'),
          Option('water_underfloor', 'water_underfloor_heating')
        ]
      },
      {
        key: 'heatDistributionInstallationYear',
        label: 'installation_year',
        placeholder: 'enter_date',
        visibleIfValues: [
          { key: 'type', values: ['electric', 'reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
        ],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'heatDistributionThermostatInstallationYear',
        label: 'thermostat_installation_year',
        placeholder: 'enter_date',
        visibleIfValues: [
          { key: 'type', values: ['electric', 'reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
        ],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'heatDistributionFrameLines',
        label: 'frame_lines',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
        ],
        ...Select,
        options: [
          Option('plastic_pcx'),
          Option('steel'),
          Option('copper'),
          Option('composite')
        ]
      },
      {
        key: 'heatDistributionFrameLineSize',
        label: 'frame_line_size',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
        ],
        ...Select,
        options: [
          Option('22'),
          Option('25'),
          Option('32'),
          Option('40'),
          Option('50')
        ]
      },
      {
        key: 'heatDistributionCircuits',
        label: 'heat_distribution_circuits',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
        ],
        ...Select,
        options: [
          Option('plastic_pcx'),
          Option('steel'),
          Option('copper'),
          Option('composite')
        ]
      },
      {
        key: 'heatDistributionCircuitSize',
        label: 'heat_distribution_circuit_size',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
        ],
        ...Select,
        options: [
          Option('12'),
          Option('15'),
          Option('18'),
          Option('22'),
          Option('25'),
        ]
      },
    ]
  ],
  [DrainageSystem]: [
    [
      Subtitle('drainage_pipes'),
      {
        key: 'drainagePipeMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('plastic_double_layer'),
          Option('plastic_single_layer'),
          Option('brick'),
          Option('concrete'),
          OtherOption
        ]
      },
      {
        key: 'drainagePipeMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('drainagePipeMaterial')],
        ...Input
      },
      {
        key: 'drainagePipeSize',
        label: 'size',
        ...Select,
        options: [
          Option('65'),
          Option('80'),
          Option('110'),
          Option('160'),
          OtherOption
        ]
      },
      {
        key: 'drainagePipeSizeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('drainagePipeSize')],
        ...Input
      },
      {
        key: 'drainagePipeOutletPoint',
        label: 'outlet_point',
        ...Select,
        options: [
          Option('municipal_infrastructure'),
          Option('terrain'),
          Option('unknown'),
          OtherOption
        ]
      },
      {
        key: 'drainagePipeOutletPointNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('drainagePipeOutletPoint')],
        ...Input
      },
      {
        key: 'drainagePipeInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('inspection_pipes'),
      {
        key: 'inspectionPipeMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('plastic'),
          Option('concrete'),
          OtherOption
        ]
      },
      {
        key: 'inspectionPipeMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('inspectionPipeMaterial')],
        ...Input
      },
      {
        key: 'inspectionPipeSize',
        label: 'size',
        ...Select,
        options: [
          Option('160'),
          Option('250'),
          Option('300'),
          Option('400'),
          OtherOption
        ]
      },
      {
        key: 'inspectionPipeSizeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('inspectionPipeSize')],
        ...Input
      },
      {
        key: 'inspectionPipeOutletPoint',
        label: 'outlet_point',
        ...Select,
        options: [
          Option('municipal_infrastructure'),
          Option('terrain'),
          Option('unknown'),
          OtherOption
        ]
      },
      {
        key: 'inspectionPipeOutletPointNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('inspectionPipeOutletPoint')],
        ...Input
      },
      {
        key: 'inspectionPipeInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('foundation_drain_well'),
      {
        key: 'foundationDrainWellHasBackwaterValve',
        label: 'backwater_valve',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'foundationDrainWellMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('plastic'),
          Option('concrete'),
          OtherOption
        ]
      },
      {
        key: 'foundationDrainWellMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('foundationDrainWellMaterial')],
        ...Input
      },
      {
        key: 'foundationDrainWellSize',
        label: 'size',
        ...Select,
        options: [
          Option('160'),
          Option('250'),
          Option('300'),
          Option('400'),
          OtherOption
        ]
      },
      {
        key: 'foundationDrainWellSizeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('foundationDrainWellSize')],
        ...Input
      },
      {
        key: 'foundationDrainWellOutletPoint',
        label: 'outlet_point',
        ...Select,
        options: [
          Option('municipal_infrastructure'),
          Option('terrain'),
          Option('unknown'),
          OtherOption
        ]
      },
      {
        key: 'foundationDrainWellOutletPointNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('foundationDrainWellOutletPoint')],
        ...Input
      },
      {
        key: 'foundationDrainWellInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [RainwaterSystem]: [
    [
      Subtitle('rainwater_pipes'),
      {
        key: 'rainwaterPipeMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('plastic_double_layer'),
          Option('plastic_single_layer'),
          Option('concrete'),
          OtherOption
        ]
      },
      {
        key: 'rainwaterPipeMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('rainwaterPipeMaterial')],
        ...Input
      },
      {
        key: 'rainwaterPipeSize',
        label: 'size',
        ...Select,
        options: [
          Option('65'),
          Option('80'),
          Option('110'),
          Option('160'),
          OtherOption
        ]
      },
      {
        key: 'rainwaterPipeSizeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('rainwaterPipeSize')],
        ...Input
      },
      {
        key: 'rainwaterPipeOutletPoint',
        label: 'outlet_point',
        ...Select,
        options: [
          Option('municipal_infrastructure'),
          Option('terrain'),
          Option('unknown'),
          OtherOption
        ]
      },
      {
        key: 'rainwaterPipeOutletPointNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('rainwaterPipeOutletPoint')],
        ...Input
      },
      {
        key: 'rainwaterPipeInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('rainwater_wells'),
      {
        key: 'rainwaterWellMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('plastic'),
          Option('concrete'),
          OtherOption
        ]
      },
      {
        key: 'rainwaterWellMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('rainwaterWellMaterial')],
        ...Input
      },
      {
        key: 'rainwaterWellSize',
        label: 'size',
        ...Select,
        options: [
          Option('300'),
          Option('400'),
          Option('500'),
          Option('600'),
          OtherOption
        ]
      },
      {
        key: 'rainwaterWellSizeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('rainwaterWellSize')],
        ...Input
      },
      {
        key: 'rainwaterWellOutletPoint',
        label: 'outlet_point',
        ...Select,
        options: [
          Option('municipal_infrastructure'),
          Option('terrain'),
          Option('unknown'),
          OtherOption
        ]
      },
      {
        key: 'rainwaterWellOutletPointNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('rainwaterWellOutletPoint')],
        ...Input
      },
      {
        key: 'rainwaterWellInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('collection_wells'),
      {
        key: 'collectionWellMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('plastic'),
          Option('concrete'),
          OtherOption
        ]
      },
      {
        key: 'collectionWellMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('collectionWellMaterial')],
        ...Input
      },
      {
        key: 'collectionWellSize',
        label: 'size',
        ...Select,
        options: [
          Option('300'),
          Option('400'),
          Option('500'),
          Option('600'),
          OtherOption
        ]
      },
      {
        key: 'collectionWellSizeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('collectionWellSize')],
        ...Input
      },
      {
        key: 'collectionWellOutletPoint',
        label: 'outlet_point',
        ...Select,
        options: [
          Option('municipal_infrastructure'),
          Option('terrain'),
          Option('unknown'),
          OtherOption
        ]
      },
      {
        key: 'collectionWellOutletPointNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('collectionWellOutletPoint')],
        ...Input
      },
      {
        key: 'collectionWellInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [PropertyWater]: [
    [
      Subtitle('property_shut_off_valve'),
      {
        key: 'propertyShutOffValveMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('plastic'),
          Option('brass'),
          Option('unknown'),
          OtherOption
        ]
      },
      {
        key: 'propertyShutOffValveMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('propertyShutOffValveMaterial')],
        ...Input
      },
      {
        key: 'propertyShutOffValveInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('property_water_pipe'),
      {
        key: 'propertyWaterPipeMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('steel'),
          Option('plastic'),
          Option('copper'),
          OtherOption
        ]
      },
      {
        key: 'propertyWaterPipeMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('propertyWaterPipeMaterial')],
        ...Input
      },
      {
        key: 'propertyWaterPipeSize',
        label: 'size',
        ...Select,
        options: [
          Option('32'),
          Option('40'),
          Option('63'),
          Option('90'),
          Option('110'),
          OtherOption
        ]
      },
      {
        key: 'propertyWaterPipeSizeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('propertyWaterPipeSize')],
        ...Input
      },
      {
        key: 'propertyWaterPipeInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'propertyWaterPipeHasTraceHeating',
        label: 'trace_heating',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'propertyWaterPipeTraceHeatingInstallationYear',
        label: 'trace_heating_installation_year',
        visibleIfValues: [IfYesSelected('propertyWaterPipeHasTraceHeating')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'propertyWaterPipeHasCasing',
        label: 'casing_pipe',
        ...Select,
        options: BooleanOptions
      }
    ],
    [
      Subtitle('water_meters'),
      {
        key: 'waterMeterLocation',
        label: 'location',
        ...Select,
        options: [
          Option('technical_space'),
          Option('heat_distribution_room'),
          OtherOption
        ]
      },
      {
        key: 'waterMeterLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('waterMeterLocation')],
        ...Input
      },
      {
        key: 'waterMeterMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('steel'),
          Option('plastic'),
          Option('brass'),
          OtherOption
        ]
      },
      {
        key: 'waterMeterMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('waterMeterMaterial')],
        ...Input
      },
      {
        key: 'waterMeterType',
        label: 'type',
        ...Select,
        options: [
          Option('remote_readable'),
          Option('locally_readable'),
          OtherOption
        ]
      },
      {
        key: 'waterMeterTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('waterMeterType')],
        ...Input
      },
      {
        key: 'waterMeterInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'hasRoomSpecificWaterMeter',
        label: 'room_specific_water_meters',
        ...Select,
        options: BooleanOptions
      }
    ],
    [
      Subtitle('water_meter_shut_off_valves'),
      {
        key: 'waterMeterShutOffValveType',
        label: 'type',
        ...Select,
        options: [
          Option('ball_valve'),
          Option('gate_valve'),
          OtherOption
        ]
      },
      {
        key: 'waterMeterShutOffValveTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('waterMeterShutOffValveType')],
        ...Input
      },
      {
        key: 'waterMeterShutOffValveMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('steel'),
          Option('plastic'),
          Option('brass'),
          OtherOption
        ]
      },
      {
        key: 'waterMeterShutOffValveMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('waterMeterShutOffValveMaterial')],
        ...Input
      },
      {
        key: 'waterMeterShutOffValveInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [WaterPipe]: [
    [
      Subtitle('main_lines'),
      {
        key: 'mainLineHorizontalRunMaterial',
        label: 'horizontal_run_material',
        ...Select,
        options: [
          Option('steel'),
          Option('plastic'),
          Option('copper'),
          Option('composite'),
          OtherOption
        ]
      },
      {
        key: 'mainLineHorizontalRunMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('mainLineHorizontalRunMaterial')],
        ...Input
      },
      {
        key: 'mainLineHorizontalRunSize',
        label: 'horizontal_run_size',
        ...Select,
        options: [
          Option('22'),
          Option('28'),
          Option('32'),
          Option('40'),
          Option('50'),
          OtherOption
        ]
      },
      {
        key: 'mainLineHorizontalRunSizeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('mainLineHorizontalRunSize')],
        ...Input
      },
      {
        key: 'mainLineHorizontalRunInstallationMethod',
        label: 'horizontal_run_installation_method',
        ...Select,
        options: [
          Option('pipe_channel'),
          Option('pipe_casing'),
          Option('surface_mounting'),
          OtherOption
        ]
      },
      {
        key: 'mainLineHorizontalRunInstallationMethodNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('mainLineHorizontalRunInstallationMethod')],
        ...Input
      },
      {
        key: 'mainLineHorizontalRunInstallationYear',
        label: 'horizontal_run_installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'mainLinePipeRiserMaterial',
        label: 'pipe_riser_material',
        ...Select,
        options: [
          Option('steel'),
          Option('plastic'),
          Option('copper'),
          Option('composite'),
          OtherOption
        ]
      },
      {
        key: 'mainLinePipeRiserMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('mainLinePipeRiserMaterial')],
        ...Input
      },
      {
        key: 'mainLinePipeRiserLocation',
        label: 'pipe_riser_location',
        ...Select,
        options: [
          Option('in_the_stairwell'),
          Option('in_the_apartments'),
          Option('outside_the_building'),
          OtherOption
        ]
      },
      {
        key: 'mainLinePipeRiserLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('mainLinePipeRiserLocation')],
        ...Input
      },
      {
        key: 'mainLinePipeRiserLocationInApartment',
        label: 'pipe_riser_location_in_apartments',
        visibleIfValues: [IfCustomSelected('mainLinePipeRiserLocation', 'in_the_apartments')],
        ...Select,
        options: [
          Option('hallway', 'hallway_short'),
          Option('bathroom', 'bathroom_short'),
          Option('walk_in_closet', 'walk_in_closet_short'),
          Option('kitchen', 'kitchen_short'),
          OtherOption
        ]
      },
      {
        key: 'mainLinePipeRiserLocationInApartmentNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('mainLinePipeRiserLocationInApartment')],
        ...Input
      },
      {
        key: 'mainLinePipeRiserInstallationMethod',
        label: 'pipe_riser_installation_method',
        ...Select,
        options: [
          Option('pipe_channel'),
          Option('pipe_casing'),
          Option('surface_mounting'),
          OtherOption
        ]
      },
      {
        key: 'mainLinePipeRiserInstallationMethodNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('mainLinePipeRiserInstallationMethod')],
        ...Input
      },
      {
        key: 'mainLinePipeRiserSize',
        label: 'pipe_riser_size',
        ...Select,
        options: [
          Option('22'),
          Option('28'),
          Option('32'),
          Option('40'),
          Option('50'),
          OtherOption
        ]
      },
      {
        key: 'mainLinePipeRiserSizeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('mainLinePipeRiserSize')],
        ...Input
      },
      {
        key: 'mainLinePipeRiserInstallationYear',
        label: 'pipe_riser_installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('apartment_lines'),
      {
        key: 'apartmentLineMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('steel'),
          Option('plastic'),
          Option('copper'),
          Option('composite'),
          OtherOption
        ]
      },
      {
        key: 'apartmentLineMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentLineMaterial')],
        ...Input
      },
      {
        key: 'apartmentLineSize',
        label: 'size',
        ...Select,
        options: [
          Option('32'),
          Option('50'),
          Option('75'),
          Option('80'),
          Option('100'),
          Option('110'),
          OtherOption
        ]
      },
      {
        key: 'apartmentLineSizeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentLineSize')],
        ...Input
      },
      {
        key: 'apartmentLineInstallationMethod',
        label: 'installation_method',
        ...Select,
        options: [
          Option('surface_mounting'),
          Option('inside_the_structure'),
          OtherOption
        ]
      },
      {
        key: 'apartmentLineInstallationMethodNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentLineInstallationMethod')],
        ...Input
      },
      {
        key: 'apartmentLineInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [WarmWater]: [
    [
      Subtitle('basic_information'),
      {
        key: 'waterHeaterType',
        label: 'type',
        ...Select,
        options: [
          Option('water_heater', 'warm_water_is_generated_with_heater'),
          OtherOption
        ]
      },
      {
        key: 'waterHeaterTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('waterHeaterType')],
        ...Input
      },
      {
        key: 'waterHeaterLocation',
        label: 'location',
        visibleIfValues: [IfCustomSelected('waterHeaterType', 'water_heater')],
        ...Select,
        options: [
          Option('technical_space'),
          Option('heat_distribution_room'),
          OtherOption
        ]
      },
      {
        key: 'waterHeaterLocation',
        label: 'location',
        visibleIfValues: [IfOtherSelected('waterHeaterType')],
        ...Select,
        options: [
          Option('technical_space'),
          Option('heat_distribution_room'),
          OtherOption
        ]
      },
      {
        key: 'waterHeaterLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('waterHeaterLocation')],
        ...Input
      },
      /*
      {
        key: 'waterHeaterManufacturer',
        label: 'manufacturer',
        visibleIfValues: [IfCustomSelected('waterHeaterType', 'water_heater')],
        ...Select,
        options: [
          ...DefaultOptions,
          OtherOption
        ]
      },
      {
        key: 'waterHeaterManufacturerNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('waterHeaterManufacturer')],
        ...Input
      },
      */
      /*
      {
        key: 'waterHeaterModel',
        label: 'model',
        visibleIfValues: [IfCustomSelected('waterHeaterType', 'water_heater')],
        ...Select,
        options: [
          ...DefaultOptions,
          OtherOption
        ]
      },
      {
        key: 'waterHeaterModelNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('waterHeaterModel')],
        ...Input
      },
      */
      {
        key: 'waterHeaterManufacturer',
        label: 'machine_information',
        placeholder: 'manufacturer',
        visibleIfValues: [IfCustomSelected('waterHeaterType', 'water_heater')],
        ...Input
      },
      {
        key: 'waterHeaterModel',
        placeholder: 'model',
        visibleIfValues: [IfCustomSelected('waterHeaterType', 'water_heater')],
        ...Input
      },
      {
        key: 'waterHeaterManufacturer',
        label: 'machine_information',
        placeholder: 'manufacturer',
        visibleIfValues: [IfOtherSelected('waterHeaterType')],
        ...Input
      },
      {
        key: 'waterHeaterModel',
        placeholder: 'model',
        visibleIfValues: [IfOtherSelected('waterHeaterType')],
        ...Input
      },
      {
        key: 'waterHeaterInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfCustomSelected('waterHeaterType', 'water_heater')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'waterHeaterInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfOtherSelected('waterHeaterType')],
        ...Select,
        options: YearOptions()
      }
      /*
      {
        key: 'waterHeaterModel',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('waterHeaterModel')],
        ...Select,
        options: [
          ...DefaultOptions,
          OtherOption
        ]
      },
      {
        key: 'waterHeaterManufacturer',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('waterHeaterManufacturer')],
        ...Select,
        options: [
          ...DefaultOptions,
          OtherOption
        ]
      },
      */
    ],
    [
      Subtitle('apartment_specific_water_heater'),
      {
        key: 'hasApartmentSpecificWaterHeater',
        label: 'apartment_specific_water_heater',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'apartmentSpecificWaterHeaterLocation',
        label: 'location',
        visibleIfValues: [IfYesSelected('hasApartmentSpecificWaterHeater')],
        ...Select,
        options: [
          Option('bathroom', 'bathroom_short'),
          Option('utility_room', 'utility_room_short'),
          Option('kitchen', 'kitchen_short'),
          Option('walk_in_closet', 'walk_in_closet_short'),
          OtherOption
        ]
      },
      {
        key: 'apartmentSpecificWaterHeaterLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentSpecificWaterHeaterLocation')],
        ...Input
      },
      {
        key: 'apartmentSpecificWaterHeaterManufacturer',
        label: 'machine_information',
        placeholder: 'manufacturer',
        visibleIfValues: [IfYesSelected('hasApartmentSpecificWaterHeater')],
        ...Input
      },
      /*
      {
        key: 'apartmentSpecificWaterHeaterManufacturerNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentSpecificWaterHeaterManufacturer')],
        ...Input
      },
      */
      {
        key: 'apartmentSpecificWaterHeaterModel',
        placeholder: 'model',
        visibleIfValues: [IfYesSelected('hasApartmentSpecificWaterHeater')],
        ...Input
      },
      /*
      {
        key: 'apartmentSpecificWaterHeaterModelNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentSpecificWaterHeaterModel')],
        ...Input
      },
      */
      {
        key: 'apartmentSpecificWaterHeaterInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasApartmentSpecificWaterHeater')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('solar_collectors'),
      {
        key: 'hasSolarCollectors',
        label: 'solar_collectors',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'solarCollectorLocation',
        label: 'location',
        visibleIfValues: [IfYesSelected('hasSolarCollectors')],
        ...Select,
        options: [
          Option('roof', 'water_roof'),
          Option('wall'),
          OtherOption
        ]
      },
      {
        key: 'solarCollectorLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('solarCollectorLocation')],
        ...Input
      },
      {
        key: 'solarCollectorManufacturer',
        label: 'machine_information',
        placeholder: 'manufacturer',
        visibleIfValues: [IfYesSelected('hasSolarCollectors')],
        ...Input
      },
      {
        key: 'solarCollectorModel',
        placeholder: 'model',
        visibleIfValues: [IfYesSelected('hasSolarCollectors')],
        ...Input
      },
      {
        key: 'solarCollectorInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasSolarCollectors')],
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [WasteManagement]: [
    [
      Subtitle('basic_information'),
      {
        key: 'location',
        label: 'location',
        ...Select,
        options: [
          Option('inside_residential_building'),
          Option('inside_side_building'),
          Option('in_waste_shelter'),
          Option('in_enclosure_in_outdoor_area'),
          Option('under_canopy_in_outdoor_area'),
          Option('openly_in_outdoor_area'),
          Option('waste_chute')
        ]
      },
      {
        key: 'container',
        label: 'garbage_containers',
        ...Select,
        options: [
          Option('loose_waste_bins'),
          Option('deep_collection_containers'),
          Option('waste_compactor')
        ]
      },
      {
        key: 'installationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('collected_waste_categories'),
      {
        ...Row,
        items: [
          {
            key: 'hasCombustibleWasteCollection',
            label: 'combustible_waste',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasMixedWasteCollection',
            label: 'mixed_waste',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasPlasticCollection',
            label: 'plastic_waste',
            ...Select,
            options: BooleanOptions
          }
        ]
      },
      {
        ...Row,
        items: [
          {
            key: 'hasBioWasteCollection',
            label: 'bio_waste',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasPaperCollection',
            label: 'paper',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasCardboardCollection',
            label: 'cardboard',
            ...Select,
            options: BooleanOptions
          }
        ]
      },
      {
        ...Row,
        items: [
          {
            key: 'hasMetalCollection',
            label: 'metal',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasGlassCollection',
            label: 'glass',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasElectronicWasteCollection',
            label: 'electronic_waste',
            ...Select,
            options: BooleanOptions
          }
        ]
      }
    ]
  ],
  [Elevator]: [
    [
      Subtitle('basic_information'),
      {
        ...Row,
        items: [
          {
            key: 'count',
            label: 'count',
            ...Select,
            options: [
              Option('1'),
              Option('2'),
              Option('3'),
              Option('4'),
              Option('5')
            ]
          },
          {
            key: 'installationYear',
            label: 'installation_year',
            ...Select,
            options: YearOptions()
          }
        ]
      }
    ]
  ],
  [MainDistribution]: [
    [
      Subtitle('transformer_station'),
      {
        key: 'transformerStationFuseType',
        label: 'fuse_type',
        ...Select,
        options: [
          Option('automatic', 'automatic_fuse'),
          Option('plug'),
          OtherOption
        ]
      },
      {
        key: 'transformerStationFuseTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('transformerStationFuseType')],
        ...Input
      },
      {
        key: 'transformerStationInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('main_distribution_board'),
      {
        key: 'mainDistributionBoardFuseType',
        label: 'fuse_type',
        ...Select,
        options: [
          Option('automatic', 'automatic_fuse'),
          Option('plug'),
          OtherOption
        ]
      },
      {
        key: 'mainDistributionBoardFuseTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('mainDistributionBoardFuseType')],
        ...Input
      },
      {
        key: 'mainDistributionBoardInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('property_distribution_board'),
      {
        key: 'propertyDistributionBoardFuseType',
        label: 'fuse_type',
        ...Select,
        options: [
          Option('automatic', 'automatic_fuse'),
          Option('plug'),
          OtherOption
        ]
      },
      {
        key: 'propertyDistributionBoardFuseTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('propertyDistributionBoardFuseType')],
        ...Input
      },
      {
        key: 'propertyDistributionBoardInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('metering_board'),
      {
        key: 'meteringBoardFuseType',
        label: 'fuse_type',
        ...Select,
        options: [
          Option('automatic', 'automatic_fuse'),
          Option('plug'),
          OtherOption
        ]
      },
      {
        key: 'meteringBoardFuseTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('meteringBoardFuseType')],
        ...Input
      },
      {
        key: 'meteringBoardInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('sub_distribution_board'),
      {
        key: 'subDistributionBoardFuseType',
        label: 'fuse_type',
        ...Select,
        options: [
          Option('automatic', 'automatic_fuse'),
          Option('plug'),
          OtherOption
        ]
      },
      {
        key: 'subDistributionBoardFuseTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('subDistributionBoardFuseType')],
        ...Input
      },
      {
        key: 'subDistributionBoardInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('ev_charging_station'),
      {
        key: 'evChargingStationFuseType',
        label: 'fuse_type',
        ...Select,
        options: [
          Option('automatic', 'automatic_fuse'),
          Option('plug'),
          OtherOption
        ]
      },
      {
        key: 'evChargingStationFuseTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('evChargingStationFuseType')],
        ...Input
      },
      {
        key: 'evChargingStationInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('socket_distribution_board'),
      {
        key: 'socketDistributionBoardFuseType',
        label: 'fuse_type',
        ...Select,
        options: [
          Option('automatic', 'automatic_fuse'),
          Option('plug'),
          OtherOption
        ]
      },
      {
        key: 'socketDistributionBoardFuseTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('socketDistributionBoardFuseType')],
        ...Input
      },
      {
        key: 'socketDistributionBoardInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('smoke_extraction_control_center'),
      {
        key: 'smokeExtractionControlCenterFuseType',
        label: 'fuse_type',
        ...Select,
        options: [
          Option('automatic', 'automatic_fuse'),
          Option('plug'),
          OtherOption
        ]
      },
      {
        key: 'smokeExtractionControlCenterFuseTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('smokeExtractionControlCenterFuseType')],
        ...Input
      },
      {
        key: 'smokeExtractionControlCenterInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('hvac_control_center'),
      {
        key: 'hvacControlCenterFuseType',
        label: 'fuse_type',
        ...Select,
        options: [
          Option('automatic', 'automatic_fuse'),
          Option('plug'),
          OtherOption
        ]
      },
      {
        key: 'hvacControlCenterFuseTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('hvacControlCenterFuseType')],
        ...Input
      },
      {
        key: 'hvacControlCenterInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [ConnectionAndProduction]: [
    [
      Subtitle('electrical_connection'),
      {
        key: 'connectionDistributionSystem',
        label: 'distribution_system',
        ...Select,
        options: [
          Option('four_wire'),
          Option('five_wire')
        ]
      },
      {
        key: 'connectionInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('solar_power_plant'),
      {
        key: 'solarPowerPanelManufacturer',
        label: 'panel_manufacturer',
        placeholder: 'manufacturer',
        ...Input
      },
      {
        key: 'solarPowerPanelModel',
        placeholder: 'model',
        ...Input
      },
      {
        key: 'solarPowerInverterManufacturer',
        label: 'inverter_manufacturer',
        placeholder: 'manufacturer',
        ...Input
      },
      {
        key: 'solarPowerInverterModel',
        placeholder: 'model',
        ...Input
      },
      {
        key: 'solarPowerLocation',
        label: 'location',
        ...Select,
        options: [
          Option('water_roof'),
          Option('exterior_wall'),
          OtherOption
        ]
      },
      {
        key: 'solarPowerLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('solarPowerLocation')],
        ...Input
      },
      {
        key: 'solarPowerInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('energy_storage'),
      {
        key: 'batterySystemManufacturer',
        label: 'battery_system_manufacturer',
        placeholder: 'manufacturer',
        ...Input
      },
      {
        key: 'batterySystemModel',
        placeholder: 'model',
        ...Input
      },
      {
        key: 'batterySystemSize',
        label: 'battery_system_size',
        ...Select,
        options: [
          OtherOption
        ]
      },
      {
        key: 'batterySystemSizeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('batterySystemSize')],
        ...Input
      },
      {
        key: 'batterySystemLocation',
        label: 'location',
        ...Select,
        options: [
          Option('battery_room'),
          Option('technical_space'),
          OtherOption
        ]
      },
      {
        key: 'batterySystemLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('batterySystemLocation')],
        ...Input
      }
    ]
  ],
  [InstallationAndAuxiliarySystem]: [
    [
      Subtitle('basic_information'),
      {
        ...Row,
        items: [
          {
            key: 'hasCableTray',
            label: 'cable_tray',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasCableDuct',
            label: 'cable_duct',
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasFloorDuct',
            label: 'floor_duct',
            ...Select,
            options: BooleanOptions
          }
        ]
      },
      {
        ...Row,
        items: [
          {
            key: 'hasSuspensionRail',
            label: 'suspension_rail',
            ...Select,
            options: BooleanOptions,
            maxWidth: 'calc(33% - 0.8125rem)'
          },
          {
            key: 'hasPipeAndCableWells',
            label: 'pipe_and_cable_wells',
            ...Select,
            options: BooleanOptions,
            maxWidth: 'calc(33% - 0.8125rem)'
          },
          HorizontalSpacer
        ]
      }
    ],
    [
      Subtitle('cable_tray'),
      {
        key: 'cableTrayInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasCableTray')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('cable_duct'),
      {
        key: 'cableDuctInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasCableDuct')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('floor_duct'),
      {
        key: 'floorDuctInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasFloorDuct')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('suspension_rail'),
      {
        key: 'suspensionRailInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasSuspensionRail')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('pipe_and_cable_wells'),
      {
        key: 'pipeAndCableWellsInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasPipeAndCableWells')],
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [ConnectionSystem]: [
    [
      Subtitle('sockets'),
      {
        key: 'socketInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('switches'),
      {
        key: 'switchInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('vehicle_charging_sockets'),
      {
        key: 'vehicleChargingSocketInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('vehicle_heating_poles'),
      {
        key: 'vehicleHeatingPoleInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [Lighting]: [
    [
      Subtitle('interior_lighting'),
      {
        key: 'interiorLightingTypes',
        label: 'type',
        ...MultiSelect,
        options: [
          Option('led'),
          Option('halogen'),
          OtherOption
        ]
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('interiorLightingTypes', 'led')],
        items: [
          {
            key: 'interiorLightingLedCount',
            label: 'led_count',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'interiorLightingLedInstallationYear',
            label: 'led_installation_year',
            ...Select,
            options: YearOptions()
          }
        ]
      },
      {
        ...Row,
        visibleIfValues: [IfCustomSelected('interiorLightingTypes', 'halogen')],
        items: [
          {
            key: 'interiorLightingHalogenCount',
            label: 'halogen_count',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'interiorLightingHalogenInstallationYear',
            label: 'halogen_installation_year',
            ...Select,
            options: YearOptions()
          }
        ]
      },
      {
        key: 'interiorLightingOtherType',
        label: 'interior_lighting_other_type',
        placeholder: 'enter_more_specific_description',
        visibleIfValues: [IfOtherSelected('interiorLightingTypes')],
        ...Input
      },
      {
        ...Row,
        visibleIfValues: [IfOtherSelected('interiorLightingTypes')],
        items: [
          {
            key: 'interiorLightingOtherCount',
            label: 'interior_lighting_other_count',
            ...Input,
            unit: 'kpl'
          },
          {
            key: 'interiorLightingOtherInstallationYear',
            label: 'interior_lighting_other_installation_year',
            ...Select,
            options: YearOptions()
          }
        ]
      }
    ],
    [
      Subtitle('area_lighting'),
      {
        key: 'areaLightingType',
        label: 'type',
        ...Select,
        options: [
          Option('led'),
          Option('halogen'),
          OtherOption
        ]
      },
      {
        key: 'areaLightingTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('areaLightingType')],
        ...Input
      },
      {
        key: 'areaLightingInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('facade_lighting'),
      {
        key: 'facadeLightingType',
        label: 'type',
        ...Select,
        options: [
          Option('led'),
          Option('halogen'),
          OtherOption
        ]
      },
      {
        key: 'facadeLightingTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('facadeLightingType')],
        ...Input
      },
      {
        key: 'facadeLightingInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('advertising_lighting'),
      {
        key: 'advertisingLightingType',
        label: 'type',
        ...Select,
        options: [
          Option('led'),
          Option('halogen'),
          OtherOption
        ]
      },
      {
        key: 'advertisingLightingTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('advertisingLightingType')],
        ...Input
      },
      {
        key: 'advertisingLightingInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [Grounding]: [
    [
      Subtitle('lightning_protection_system'),
      {
        key: 'lightningProtectionSystemInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('equipotential_bonding_system'),
      {
        key: 'equipotentialBondingSystemInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [CommunicationAndDataNetwork]: [
    [
      Subtitle('antenna_system'),
      {
        key: 'antennaSystemType',
        label: 'type',
        ...Select,
        options: [
          Option('cable'),
          Option('optical_fiber'),
          Option('dvb_t2'),
          OtherOption
        ]
      },
      {
        key: 'antennaSystemTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('antennaSystemType')],
        ...Input
      },
      {
        key: 'antennaSystemInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('telecommunication_system'),
      {
        key: 'telecommunicationSystemType',
        label: 'type',
        ...Select,
        options: [
          Option('cable'),
          Option('none', 'has_not'),
          OtherOption
        ]
      },
      {
        key: 'telecommunicationSystemTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('telecommunicationSystemType')],
        ...Input
      },
      {
        key: 'telecommunicationSystemInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [FireSafety]: [
    [
      Subtitle('fire_alarm_system'),
      {
        key: 'fireAlarmSystemType',
        label: 'type',
        ...Select,
        options: [
          Option('automatic'),
          Option('manual')
        ]
      },
      {
        key: 'fireAlarmSystemInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('smoke_detectors'),
      {
        key: 'smokeDetectorType',
        label: 'type',
        ...Select,
        options: [
          Option('connected_to_power'),
          Option('battery_operated')
        ]
      },
      {
        key: 'smokeDetectorInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [PropertySewerage]: [
    [
      Subtitle('sewer_inspection_well'),
      {
        key: 'sewerInspectionWellMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('plastic'),
          Option('concrete'),
          OtherOption
        ]
      },
      {
        key: 'sewerInspectionWellMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('sewerInspectionWellMaterial')],
        ...Input
      },
      {
        ...Row,
        items: [
          {
            key: 'sewerInspectionWellCount',
            label: 'count',
            ...Select,
            options: [
              Option('1'),
              Option('2'),
              Option('3'),
              Option('4'),
              Option('5')
            ]
          },
          {
            key: 'sewerInspectionWellInstallationYear',
            label: 'installation_year',
            ...Select,
            options: YearOptions()
          }
        ]
      }
    ],
    [
      Subtitle('property_sewer_pipe'),
      {
        key: 'propertySewerPipeMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('cast_iron'),
          Option('plastic'),
          Option('concrete'),
          OtherOption
        ]
      },
      {
        key: 'propertySewerPipeMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('propertySewerPipeMaterial')],
        ...Input
      },
      {
        key: 'propertySewerPipeSize',
        label: 'size',
        ...Select,
        options: [
          Option('80'),
          Option('100'),
          Option('110'),
          Option('160'),
          Option('200'),
          OtherOption
        ]
      },
      {
        key: 'propertySewerPipeSizeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('propertySewerPipeSize')],
        ...Input
      },
      {
        key: 'propertySewerPipeInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('horizontal_check_valve'),
      {
        key: 'horizontalCheckValveMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('steel'),
          Option('plastic'),
          OtherOption
        ]
      },
      {
        key: 'horizontalCheckValveMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('horizontalCheckValveMaterial')],
        ...Input
      },
      {
        key: 'horizontalCheckValveLocation',
        label: 'location',
        ...Select,
        options: [
          Option('technical_space'),
          Option('heat_distribution_room'),
          Option('stairwell'),
          OtherOption
        ]
      },
      {
        key: 'horizontalCheckValveLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('horizontalCheckValveLocation')],
        ...Input
      },
      {
        key: 'horizontalCheckValveInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [SewerPipe]: [
    [
      Subtitle('main_lines'),
      {
        key: 'mainLineHorizontalRunMaterial',
        label: 'horizontal_run_material',
        ...Select,
        options: [
          Option('cast_iron'),
          Option('plastic'),
          Option('concrete'),
          OtherOption
        ]
      },
      {
        key: 'mainLineHorizontalRunMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('mainLineHorizontalRunMaterial')],
        ...Input
      },
      {
        key: 'mainLineHorizontalRunSize',
        label: 'horizontal_run_size',
        ...Select,
        options: [
          Option('80'),
          Option('100'),
          Option('110'),
          Option('160'),
          Option('200'),
          OtherOption
        ]
      },
      {
        key: 'mainLineHorizontalRunSizeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('mainLineHorizontalRunSize')],
        ...Input
      },
      {
        key: 'mainLineHorizontalRunInstallationMethod',
        label: 'horizontal_run_installation_method',
        ...Select,
        options: [
          Option('pipe_channel'),
          Option('pipe_casing'),
          Option('surface_mounting'),
          OtherOption
        ]
      },
      {
        key: 'mainLineHorizontalRunInstallationMethodNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('mainLineHorizontalRunInstallationMethod')],
        ...Input
      },
      {
        key: 'mainLineHorizontalRunInstallationYear',
        label: 'horizontal_run_installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'mainLinePipeRiserMaterial',
        label: 'pipe_riser_material',
        ...Select,
        options: [
          Option('cast_iron'),
          Option('plastic'),
          Option('concrete'),
          OtherOption
        ]
      },
      {
        key: 'mainLinePipeRiserMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('mainLinePipeRiserMaterial')],
        ...Input
      },
      {
        key: 'mainLinePipeRiserLocation',
        label: 'pipe_riser_location',
        ...Select,
        options: [
          Option('in_the_stairwell'),
          Option('in_the_apartments'),
          Option('outside_the_building'),
          OtherOption
        ]
      },
      {
        key: 'mainLinePipeRiserLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('mainLinePipeRiserLocation')],
        ...Input
      },
      {
        key: 'mainLinePipeRiserLocationInApartments',
        label: 'pipe_riser_location_in_apartments',
        visibleIfValues: [IfCustomSelected('mainLinePipeRiserLocation', 'in_the_apartments')],
        ...Select,
        options: [
          Option('hallway', 'hallway_short'),
          Option('bathroom', 'bathroom_short'),
          Option('walk_in_closet', 'walk_in_closet_short'),
          Option('kitchen', 'kitchen_short'),
          OtherOption
        ]
      },
      {
        key: 'mainLinePipeRiserLocationInApartmentsNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('mainLinePipeRiserLocationInApartments')],
        ...Input
      },
      {
        key: 'mainLinePipeRiserInstallationMethod',
        label: 'pipe_riser_installation_method',
        ...Select,
        options: [
          Option('pipe_channel'),
          Option('pipe_casing'),
          Option('surface_mounting'),
          OtherOption
        ]
      },
      {
        key: 'mainLinePipeRiserInstallationMethodNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('mainLinePipeRiserInstallationMethod')],
        ...Input
      },
      {
        key: 'mainLinePipeRiserSize',
        label: 'pipe_riser_size',
        ...Select,
        options: [
          Option('22'),
          Option('28'),
          Option('32'),
          Option('40'),
          Option('50'),
          OtherOption
        ]
      },
      {
        key: 'mainLinePipeRiserSizeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('mainLinePipeRiserSize')],
        ...Input
      },
      {
        key: 'mainLinePipeRiserInstallationYear',
        label: 'pipe_riser_installation_year',
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('apartment_lines'),
      {
        key: 'apartmentLineMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('cast_iron'),
          Option('plastic'),
          Option('concrete'),
          OtherOption
        ]
      },
      {
        key: 'apartmentLineMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentLineMaterial')],
        ...Input
      },
      {
        key: 'apartmentLineSize',
        label: 'size',
        ...Select,
        options: [
          Option('32'),
          Option('50'),
          Option('75'),
          Option('80'),
          Option('100'),
          Option('110'),
          OtherOption
        ]
      },
      {
        key: 'apartmentLineSizeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentLineSize')],
        ...Input
      },
      {
        key: 'apartmentLineInstallationMethod',
        label: 'installation_method',
        ...Select,
        options: [
          Option('surface_mounting'),
          Option('inside_the_structure'),
          OtherOption
        ]
      },
      {
        key: 'apartmentLineInstallationMethodNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentLineInstallationMethod')],
        ...Input
      },
      {
        key: 'apartmentLineInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [WasteWaterHeatRecovery]: [
    [
      Subtitle('basic_information'),
      {
        key: 'hasWasteHeatRecoverySystem',
        label: 'waste_heat_recovery_system',
        ...Select,
        options: BooleanOptions
      }
    ],
    [
      Subtitle('additional_information'),
      {
        key: 'wasteHeatRecoverySystemLocation',
        label: 'location',
        visibleIfValues: [IfYesSelected('hasWasteHeatRecoverySystem')],
        ...Select,
        options: [
          Option('technical_space'),
          Option('heat_distribution_room'),
          OtherOption
        ]
      },
      {
        key: 'wasteHeatRecoverySystemLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('wasteHeatRecoverySystemLocation')],
        ...Input
      },
      {
        key: 'wasteHeatRecoverySystemManufacturer',
        label: 'machine_information',
        placeholder: 'manufacturer',
        visibleIfValues: [IfYesSelected('hasWasteHeatRecoverySystem')],
        ...Input
      },
      /*
      {
        key: 'wasteHeatRecoverySystemManufacturerNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('wasteHeatRecoverySystemManufacturer')],
        ...Input
      },
      */
      {
        key: 'wasteHeatRecoverySystemModel',
        placeholder: 'model',
        visibleIfValues: [IfYesSelected('hasWasteHeatRecoverySystem')],
        ...Input
      },
      /*
      {
        key: 'wasteHeatRecoverySystemModelNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('wasteHeatRecoverySystemModel')],
        ...Input
      },
      */
      {
        key: 'wasteHeatRecoverySystemInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasWasteHeatRecoverySystem')],
        ...Select,
        options: YearOptions()
      }
    ]
  ]
}

export const BuildingStructuresLayout = [
  {
    title: Foundation,
    label: 'foundations_and_plinth',
    sections: BuildingStructureSections[Foundation]
  },
  {
    title: BaseFloor,
    sections: BuildingStructureSections[BaseFloor]
  },
  {
    title: ExteriorWall,
    label: 'exterior_walls_and_facade',
    sections: BuildingStructureSections[ExteriorWall]
  },
  {
    title: IntermediateFloor,
    label: 'intermediate_floors',
    sections: BuildingStructureSections[IntermediateFloor]
  },
  {
    title: InteriorWall,
    label: 'interior_walls',
    sections: BuildingStructureSections[InteriorWall]
  },
  {
    title: UpperFloor,
    sections: BuildingStructureSections[UpperFloor]
  },
  {
    title: WaterRoof,
    sections: BuildingStructureSections[WaterRoof]
  },
]

export const BuildingPartsLayout = [
  {
    title: Window,
    label: 'windows',
    sections: BuildingPartSections[Window]
  },
  {
    title: Door,
    label: 'doors',
    sections: BuildingPartSections[Door]
  },
  {
    title: Balcony,
    label: 'balconies',
    sections: BuildingPartSections[Balcony]
  },
  {
    title: Terrace,
    label: 'terraces',
    sections: BuildingPartSections[Terrace]
  }
]

export const BuildingSystemsLayout = [
  {
    title: Ventilation,
    sections: BuildingSystemSections[Ventilation]
  },
  {
    title: Heating,
    sections: BuildingSystemSections[Heating]
  },
  {
    title: DrainageSystem,
    sections: BuildingSystemSections[DrainageSystem]
  },
  {
    title: RainwaterSystem,
    sections: BuildingSystemSections[RainwaterSystem]
  },
  {
    title: PropertyWater,
    sections: BuildingSystemSections[PropertyWater]
  },
  {
    title: WaterPipe,
    label: 'water_pipes',
    sections: BuildingSystemSections[WaterPipe]
  },
  {
    title: WarmWater,
    sections: BuildingSystemSections[WarmWater]
  },
  {
    title: WasteManagement,
    sections: BuildingSystemSections[WasteManagement]
  },
  {
    title: Elevator,
    label: 'elevators',
    sections: BuildingSystemSections[Elevator]
  },
  {
    title: MainDistribution,
    sections: BuildingSystemSections[MainDistribution]
  },
  {
    title: ConnectionAndProduction,
    sections: BuildingSystemSections[ConnectionAndProduction]
  },
  {
    title: InstallationAndAuxiliarySystem,
    sections: BuildingSystemSections[InstallationAndAuxiliarySystem]
  },
  {
    title: ConnectionSystem,
    sections: BuildingSystemSections[ConnectionSystem]
  },
  {
    title: Lighting,
    sections: BuildingSystemSections[Lighting]
  },
  {
    title: Grounding,
    sections: BuildingSystemSections[Grounding]
  },
  {
    title: CommunicationAndDataNetwork,
    sections: BuildingSystemSections[CommunicationAndDataNetwork]
  },
  {
    title: FireSafety,
    sections: BuildingSystemSections[FireSafety]
  },
  {
    title: PropertySewerage,
    sections: BuildingSystemSections[PropertySewerage]
  },
  {
    title: SewerPipe,
    sections: BuildingSystemSections[SewerPipe]
  },
  {
    title: WasteWaterHeatRecovery,
    sections: BuildingSystemSections[WasteWaterHeatRecovery]
  }
]

export const BuildingStructuresOptions = [
  Option(Foundation, 'foundations_and_plinth'),
  Option(BaseFloor),
  Option(ExteriorWall, 'exterior_walls_and_facade'),
  Option(IntermediateFloor, 'intermediate_floors'),
  Option(InteriorWall, 'interior_walls'),
  Option(UpperFloor),
  Option(WaterRoof)
]

export const BuildingPartsOptions = [
  Option(Window, 'windows'),
  Option(Door, 'doors'),
  Option(Balcony, 'balconies'),
  Option(Terrace, 'terraces')
]

export const BuildingSystemsOptions = [
  Option(Ventilation),
  Option(Heating),
  Option(DrainageSystem),
  Option(RainwaterSystem),
  Option(PropertyWater),
  Option(WaterPipe, 'water_pipes'),
  Option(WarmWater),
  Option(WasteManagement),
  Option(Elevator, 'elevators'),
  Option(MainDistribution),
  Option(ConnectionAndProduction),
  Option(InstallationAndAuxiliarySystem),
  Option(ConnectionSystem),
  Option(Lighting),
  Option(Grounding),
  Option(CommunicationAndDataNetwork),
  Option(FireSafety),
  Option(PropertySewerage),
  Option(SewerPipe),
  Option(WasteWaterHeatRecovery)
]