import { useMemo, forwardRef } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Chip from'./Chip'
import ProjectCard from './ProjectCard'
import LineIcon from '../../Assets/Icons/line.svg'
import { Colors } from '../../Utils/theme'

const Timeline = forwardRef((props: any, ref: any) => {
  const { year, projects, onClick } = props

  const Total = useMemo(() => {
    if (projects && projects.length) {
      const total = projects
        .map((project: any) => project?.costs)
        .flat()
        .map((cost: any) => cost?.year === year ? Number(cost?.total) : 0)
        .reduce((acc: number, curr: number) => acc + curr, 0)
      return `${total.toLocaleString('fi')}€`
    }
    return '0€'
  }, [projects])

  const renderProjects = () => {
    if (projects) {
      return projects.map((project: any, index: number) => {
        if (!project) {
          return <Box key={index} sx={styles.spacer} />
        }
        return (
          <ProjectCard
            key={index}
            project={project}
            showConnector={Number(project?.endTime?.slice(0, 4)) > year}
            isFirst={Number(project?.startTime?.slice(0, 4)) === year}
            isMultiYear={Number(project?.startTime?.slice(0, 4)) !== Number(project?.endTime?.slice(0, 4))}
            isLast={index === projects.length - 1}
            onClick={onClick}
            layoutStyle={props.layoutStyle}
          />
        )
      })
    }
  }

  return (
    <Stack
      ref={ref}
      sx={styles.container}
      height={`${props.maxItems * 12.5}rem`}
      pr='1.5rem'
    >
      <Box component='img' src={LineIcon} alt='line' sx={styles.divider} />
      <Box component='img' src={LineIcon} alt='line' sx={styles.divider2} />
      <Typography
        variant='body1'
        color={Colors.primary}
        fontSize='1.125rem'
        fontWeight={700}
        mb='0.625rem'
      >
        {year}
      </Typography>
      <Chip text={Total} sx={styles.total} />
      {renderProjects()}
    </Stack>
  )
})

export default Timeline

const styles = {
  container: {
    position: 'relative',
    minWidth: 'calc(100vw / 6.5)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '0.625rem',
    scrollBehavior: 'smooth'
  },
  total: {
    minWidth: '6rem',
    height: '2.25rem',
    borderRadius: '1.125rem',
    backgroundColor: Colors.primary,
    fontSize: '1rem',
    p: '0.25rem',
    mb: '1rem',
    boxShadow: '0px 4px 12px 0px #00000040'
  },
  divider: {
    height: '42rem',
    position: 'absolute',
    top: '4.525rem',
    alignSelf: 'center'
  },
  divider2: {
    height: '42rem',
    position: 'absolute',
    top: '46.525rem',
    alignSelf: 'center'
  },
  spacer: {
    height: '11.5rem',
    width: '100%'
  }
} as const