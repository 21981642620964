import { useState } from 'react'
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from './IconButton'
import { Colors } from '../../Utils/theme'

export default function PopupButton(props: any) {
  const { icon, text } = props

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)

  const openPopup = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget)
  }
  const hidePopup = () => setAnchor(null)

  return (
    <>
      <IconButton
        icon={icon ?? 'exclamation'}
        onClick={openPopup}
        iconSize='2rem'
        sx={{ ...styles.button, ...(props?.buttonSx ?? {}) }}
        ariaDescribedBy={!!anchor ? 'custom-popup' : undefined}
      />
      <BasePopup
        id={!!anchor ? 'custom-popup' : undefined}
        open={!!anchor}
        anchor={anchor}
        placement='top'
      >
        <Box sx={styles.content}>
          <Typography sx={styles.text}>{text ?? ''}</Typography>
        </Box>
        <Box sx={styles.arrow} />
      </BasePopup>
      {anchor ? (
        <ClickAwayListener onClickAway={hidePopup}>
          <Box />
        </ClickAwayListener>
      ) : null}
    </>
  )
}

const styles = {
  button: {
    width: '1.875rem',
    height: '1.875rem',
    borderRadius: '50%',
    backgroundColor: Colors.white,
    '&:hover': {
      backgroundColor: Colors.white
    }
  },
  content: {
    width: 'max-content',
    maxWidth: '18rem',
    padding: '0.75rem 1rem',
    margin: '0.5rem',
    marginBottom: '1rem',
    borderRadius: '0.5rem',
    backgroundColor: Colors.highlight,
    boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.25)'
  },
  text: {
    color: Colors.text,
    fontWeight: 400,
    fontSize: '0.875rem'
  },
  arrow: {
    position: 'absolute',
    top: 'calc(100% - 1.5rem)',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 0,
    height: 0, 
    borderLeft: '1.25rem solid transparent',
    borderRight: '1.25rem solid transparent',
    borderTop: `1.25rem solid ${Colors.highlight}`
  }
} as const