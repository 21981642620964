import { useMemo, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import flatten from 'lodash/flatten'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { GridRenderCellParams } from '@mui/x-data-grid'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import Lightbox from '../../../Components/Common/Lightbox'
import TableActions from '../../../Components/Common/TableActions'
import TextEditor from '../../../Components/Common/TextEditor'
import PropertyAreas from './PropertyAreas'
import CreateBuildingModal from '../../../Components/Common/CreateBuildingModal'
import CreateApartmentModal from '../../../Components/Common/CreateApartmentModal'
import CheckboxMultiSelect from '../../../Components/Common/CheckboxMultiSelect'
import ReminderModal from '../../../Components/Common/ReminderModal'
import SidebarActions from '../../../Components/Common/SidebarActions'
import SidebarReminders from '../../../Components/Common/SidebarReminders'
import IconButton from '../../../Components/Common/IconButton'
import { Button, CoverImage, Tabs, Tab, Table, Input, Select, MultiSelect, Chip } from '../../../Components'
import { FinnishMunicipalities, PropertySpacesAndAreas, PropertyTabs, UserRoles } from '../../../Utils/constants'
import { Colors, Fonts } from '../../../Utils/theme'
import { YearOptions } from '../../../Utils/layout'
import { BuildingPartsOptions, BuildingStructuresOptions, BuildingSystemsOptions, BuildingTypes } from '../../../Utils/building'
import { ApartmentTypes } from '../../../Utils/apartment'
import { validateBusinessId } from '../../../Utils/validators'
import { showMessage } from '../../../Utils/message'
import { AreaSectionOptions } from '../../../Utils/area'
import { RoomSectionOptions } from '../../../Utils/room'

const Property = () => {
  const {
    propertyStore,
    buildingStore,
    apartmentStore,
    sessionStore,
    reminderStore,
    userStore
  }: any = useStore()
  const { isAdmin } = sessionStore
  const { property, entities, loading } = propertyStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const [searchParams, setSearchParams]: any = useSearchParams()

  const savePropertyDataRef = useRef<any>(null)

  const [tab, setTab] = useState(PropertyTabs.BasicInformation)
  const [selectedImage, setSelectedImage] = useState(false)
  const [remindersOpen, setRemindersOpen] = useState(true)
  const [reminder, setReminder] = useState<any>(null)

  const openRemindersTab = () => setTab(PropertyTabs.Reminders)
  const toggleOpenReminders = () => setRemindersOpen(!remindersOpen)
  const openReminder = (reminder: any) => {
    setReminder(reminder)
    propertyStore.getPropertyEntities(params.uuid)
  }
  const closeReminder = () => setReminder(null)

  // Property basic information (editable)
  const [nameFi, setNameFi] = useState('')
  const [businessId, setBusinessId] = useState('')
  const [registrationDate, setRegistrationDate] = useState('')
  const [area, setArea] = useState('')
  const [hasMultipleProperties, setHasMultipleProperties] = useState(false)
  const [propertyCount, setPropertyCount] = useState('')
  const [propertyId, setPropertyId] = useState('')
  const [address, setAddress] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [city, setCity] = useState('')

  // Property admin
  const [propertyAdmin, setPropertyAdmin] = useState('')

  // Filters
  const [buildingFilter, setBuildingFilter] = useState('')
  const [apartmentFilter, setApartmentFilter] = useState('')
  const [reminderFilter, setReminderFilter] = useState('upcoming')

  // Property building information

  // The following fields are aggregate, non-editable data
  const [buildingTypes, setBuildingTypes] = useState([])
  const [buildingTypeInfo, setBuildingTypeInfo] = useState('')
  const [buildingCount, setBuildingCount] = useState('')
  const [buildingCommissioningYears, setBuildingCommissioningYears] = useState([])

  // Property apartment, space & area information

  // The following fields are aggregate, non-editable data
  const [otherSpaceCount, setOtherSpaceCount] = useState('')

  // The following fields are editable data
  const [apartmentCount, setApartmentCount] = useState('')
  const [apartmentArea, setApartmentArea] = useState('')
  const [commercialSpaceCount, setCommercialSpaceCount] = useState('')
  const [commercialSpaceArea, setCommercialSpaceArea] = useState('')
  const [otherSpaceArea, setOtherSpaceArea] = useState('')
  const [spaceTypes, setSpaceTypes] = useState<any>([])
  const [spaceTypesInfo, setSpaceTypesInfo] = useState('')

  // Property parking information (editable)
  const [parkingSpaceCount, setParkingSpaceCount] = useState('')

  // Maintenance information (editable)
  const [hasMaintenanceReport, setHasMaintenanceReport] = useState(null)
  const [hasMaintenancePlan, setHasMaintenancePlan] = useState(null)
  const [pastMaintenanceInfo, setPastMaintenanceInfo] = useState('')
  const [plannedMaintenanceInfo, setPlannedMaintenanceInfo] = useState('')

  // Energy information (editable)
  const [hasValidEnergyCertificate, setHasValidEnergyCertificate] = useState(null)
  const [energyCertificateStartYear, setEnergyCertificateStartYear] = useState('')
  const [energyCertificateEndYear, setEnergyCertificateEndYear] = useState('')
  
  // Property management information (editable)
  const [propertyManagerName, setPropertyManagerName] = useState('')
  const [propertyManagerCompany, setPropertyManagerCompany] = useState('')
  const [propertyManagerPhone, setPropertyManagerPhone] = useState('')
  const [propertyManagerEmail, setPropertyManagerEmail] = useState('')
  const [propertyManagerAddress, setPropertyManagerAddress] = useState('')
  const [propertyManagerPostalCode, setPropertyManagerPostalCode] = useState('')
  const [propertyManagerCity, setPropertyManagerCity] = useState('')

  // Confirmation dialog (property)
  const [showActionConfirmation, setShowActionConfirmation] = useState(false)
  const [actionType, setActionType] = useState('')
  const [actionTarget, setActionTarget] = useState<any>(null)

  const openCreateEntityDialog = (entity: string) => {
    if (entity === 'building') {
      setActionType('create_building')
    } else if (entity === 'apartment') {
      setActionType('create_apartment')
    } else if (entity === 'reminder') {
      setActionType('create_reminder')
      propertyStore.getPropertyEntities(params.uuid)
    }
  }
  const closeEntityDialog = () => setActionType('')

  const openArchivePropertyConfirmation = () => {
    setShowActionConfirmation(true)
    setActionType('archive_property')
  }
  const openArchiveReminderConfirmation = (reminder: any) => {
    setShowActionConfirmation(true)
    setActionType('archive_reminder')
    setActionTarget(reminder)
  }
  const closeActionConfirmation = () => {
    setShowActionConfirmation(false)
    setActionType('')
    setActionTarget(null)
  }

  const clearSelectedImage = () => setSelectedImage(false)

  const updateSpaceTypes = (space: string) => {
    if (spaceTypes?.includes(space)) {
      setSpaceTypes(spaceTypes.filter((item: string) => item !== space))
    } else {
      setSpaceTypes([...spaceTypes, space])
    }
  }

  useEffect(() => {
    const uuid = params?.uuid || null
    if (uuid && uuid?.length === 36) {
      propertyStore.getProperty(uuid)
      if (isAdmin) {
        reminderStore.getPropertyReminders(params?.uuid)
        userStore.getUsers()
      }
    }
  }, [])

  useEffect(() => {
    const uuid = params?.uuid || null
    if (uuid && uuid?.length === 36) {
      if (property?.uuid === uuid) {
        setNameFi(property?.nameFi || '')
        setBusinessId(property?.businessId || '')
        setRegistrationDate(property?.registrationDate || '')
        setArea(property?.area || '')
        setHasMultipleProperties(property?.hasMultipleProperties || false)
        setPropertyCount(property?.hasMultipleProperties ? property?.propertyCount : 1)
        setPropertyId(property?.propertyId || '')
        setBuildingCommissioningYears(property?.buildingCommissioningYears || [])
        setAddress(property?.address || '')
        setPostalCode(property?.postalCode || '')
        setCity(property?.city || '')
        setPropertyAdmin(property?.propertyAdmin || '')
        setBuildingTypes(property?.buildingTypes || [])
        setBuildingTypeInfo(property?.buildingTypeInfo || '')
        setBuildingCount(property?.buildingCount || '')
        setApartmentCount(property?.apartmentCount || '')
        setApartmentArea(property?.apartmentArea || '')
        setCommercialSpaceCount(property?.commercialSpaceCount || '')
        setCommercialSpaceArea(property?.commercialSpaceArea || '')
        setOtherSpaceCount(property?.otherSpaceCount || '')
        setOtherSpaceArea(property?.otherSpaceArea || '')
        setSpaceTypes(property?.spaceTypes?.split(',') || [])
        setSpaceTypesInfo(property?.spaceTypesInfo || '')
        setParkingSpaceCount(property?.parkingSpaceCount || '')
        setHasMaintenanceReport(property?.hasMaintenanceReport || null)
        setHasMaintenancePlan(property?.hasMaintenancePlan || null)
        setPastMaintenanceInfo(property?.pastMaintenanceInfo || '')
        setPlannedMaintenanceInfo(property?.plannedMaintenanceInfo || '')
        setHasValidEnergyCertificate(property?.hasValidEnergyCertificate || null)
        setEnergyCertificateStartYear(property?.energyCertificateStartYear || '')
        setEnergyCertificateEndYear(property?.energyCertificateEndYear || '')
        setPropertyManagerName(property?.propertyManagerName || '')
        setPropertyManagerCompany(property?.propertyManagerCompany || '')
        setPropertyManagerPhone(property?.propertyManagerPhone || '')
        setPropertyManagerEmail(property?.propertyManagerEmail || '')
        setPropertyManagerAddress(property?.propertyManagerAddress || '')
        setPropertyManagerPostalCode(property?.propertyManagerPostalCode || '')
        setPropertyManagerCity(property?.propertyManagerCity || '')
      }
    }
  }, [property])

  useEffect(() => {
    if (tab === PropertyTabs.BasicInformation) {
      setSearchParams({}, { replace: true })
      propertyStore.getProperty(params.uuid)
    } else if (tab === PropertyTabs.Buildings) {
      if (searchParams?.tab !== PropertyTabs.Buildings) {
        setSearchParams({ tab: PropertyTabs.Buildings }, { replace: true })
      }
      buildingStore.getBuildings(params?.uuid)
    } else if (tab === PropertyTabs.Apartments) {
      if (searchParams?.tab !== PropertyTabs.Apartments) {
        setSearchParams({ tab: PropertyTabs.Apartments }, { replace: true })
      }
      apartmentStore.getApartments(params?.uuid)
    } else if (tab === PropertyTabs.Areas) {
      if (searchParams?.tab !== PropertyTabs.Areas) {
        setSearchParams({ tab: PropertyTabs.Areas }, { replace: true })
      }
      propertyStore.getPropertyAreas(params?.uuid)
    } else if (tab === PropertyTabs.Reminders) {
      if (searchParams?.tab !== PropertyTabs.Reminders) {
        setSearchParams({ tab: PropertyTabs.Reminders }, { replace: true })
      }
      reminderStore.getPropertyReminders(params?.uuid)
    }
  }, [tab])

  useEffect(() => {
    if (location?.search?.includes('tab')) {
      const selectedTab = location?.search?.split('tab=')?.[1] || ''
      if (selectedTab === 'buildings') setTab(PropertyTabs.Buildings)
      else if (selectedTab === 'apartments') setTab(PropertyTabs.Apartments)
      else if (selectedTab === 'areas') setTab(PropertyTabs.Areas)
      else if (selectedTab === 'reminders') setTab(PropertyTabs.Reminders)
    }
  }, [location])

  useEffect(() => {
    if (reminderFilter !== 'upcoming') {
      reminderStore.getPropertyReminders(params?.uuid, { all: true })
    }
  }, [reminderFilter])

  const Title = useMemo(() => {
    if (tab === PropertyTabs.BasicInformation) {
      return t('edit_property')
    }
    return property?.nameFi || ''
  }, [tab, property])

  const BuildingRows = useMemo(() => {
    if (buildingFilter) {
      return buildingStore?.buildings?.filter((building: any) => building?.type === buildingFilter)
    }
    return buildingStore?.buildings
  }, [buildingStore?.buildings, buildingFilter])

  const ApartmentRows = useMemo(() => {
    if (apartmentFilter) {
      return apartmentStore?.apartments?.filter((apartment: any) => apartment?.type === apartmentFilter)
    }
    return apartmentStore?.apartments
  }, [apartmentStore?.apartments, apartmentFilter])

  const ReminderRows = useMemo(() => {
    const now = DateTime.now()
    if (reminderFilter === 'upcoming' || !reminderFilter) {
      // Filter out archived or past reminders
      return reminderStore?.reminders?.filter((reminder: any) => {
        return !reminder?.archivedAt &&
        (DateTime.fromISO(reminder?.actionDate) > now ||
        reminder?.scheduledReminders?.some((item: any) => {
          return DateTime.fromISO(item?.scheduledAt) > now
        }))
      })
    } else if (reminderFilter === 'past') {
      return reminderStore?.reminders?.filter((reminder: any) => {
        return !reminder?.archivedAt &&
        DateTime.fromISO(reminder?.actionDate) < now &&
        reminder?.scheduledReminders?.every((item: any) => {
          return DateTime.fromISO(item?.scheduledAt) < now
        })
      })
    } else if (reminderFilter === 'archived') {
      return reminderStore?.reminders?.filter((reminder: any) => reminder?.archivedAt)
    }
    return reminderStore?.reminders
  }, [reminderStore?.reminders, reminderFilter])

  const UpcomingReminderCount = useMemo(() => {
    return reminderStore?.reminders?.filter((reminder: any) => {
      return !reminder?.archivedAt &&
      DateTime.fromISO(reminder?.actionDate) > DateTime.now() &&
      reminder?.scheduledReminders?.some((item: any) => {
        return DateTime.fromISO(item?.scheduledAt) > DateTime.now()
      })
    })?.length
  }, [reminderStore?.reminders])

  const BuildingSectionOptions = useMemo(() => {
    const structures = BuildingStructuresOptions.map((item: any) => ({ value: item.value, label: `${t('structures')} - ${t(item?.label || item?.value)}` }))
    const parts = BuildingPartsOptions.map((item: any) => ({ value: item.value, label: `${t('building_parts')} - ${t(item?.label || item?.value)}` }))
    const systems = BuildingSystemsOptions.map((item: any) => ({ value: item.value, label: `${t('systems')} - ${t(item?.label || item?.value)}` }))
    return [...structures, ...parts, ...systems]
  }, [])

  const BuildingFilterOptions = Object.values(BuildingTypes).map((item: any) => ({ value: item, label: t(item) }))
  const ApartmentFilterOptions = Object.values(ApartmentTypes).map((item: any) => ({ value: item, label: item.split('+').join(' + ') }))
  const ReminderFilterOptions = [
    { value: 'upcoming', label: t('only_upcoming') },
    { value: 'past', label: t('only_past') },
    { value: 'archived', label: t('only_archived') },
    { value: 'all', label: t('all') }
  ]
  const AdminUserOptions = useMemo(() => {
    if (userStore?.users?.length) {
      return userStore?.users
        ?.filter((user: any) => user?.role === UserRoles.Admin)
        ?.map((user: any) => {
        return {
          value: user.uuid,
          label: `${user?.firstName} ${user?.lastName}`,
          email: user?.email || '',
          phone: user?.phone || ''
        }
      })
    }
    return []
  }, [userStore.users])

  const toBuilding = (building: any) => navigate(`${location.pathname}/buildings/${building?.uuid}`)
  const toApartment = (apartment: any) => navigate(`${location.pathname}/apartments/${apartment?.uuid}`)
  const toCosts = () => navigate(`${location.pathname}/costs`)

  const openBackgroundPhoto = () => setSelectedImage(property?.backgroundPhoto)

  const toCreateBuilding = () => openCreateEntityDialog('building')
  const toCreateApartment = () => openCreateEntityDialog('apartment')
  const toCreateReminder = () => openCreateEntityDialog('reminder')

  const BuildingColumns = [
    {
      field: 'nameFi',
      headerName: t('name_or_identifier'),
      flex: 1,
      minWidth: 200,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={t(params.row.nameFi)}
          onClick={() => toBuilding(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'type',
      headerName: t('type'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Chip text={params.row?.type ? t(params.row.type) : '-'} />
      )
    },
    {
      field: 'commissioningYear',
      headerName: t('commissioning_year'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value || '-'
    },
    {
      field: 'updatedAt',
      headerName: t('last_edited_at'),
      flex: 1,
      maxWidth: 320,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    }
  ]

  const ApartmentColumns = [
    {
      field: 'nameFi',
      headerName: t('name_or_identifier'),
      flex: 1,
      minWidth: 200,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={t(params.row.nameFi)}
          onClick={() => toApartment(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'type',
      headerName: t('type'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Chip text={params.row?.type ? t(params.row.type) : '-'} />
      )
    },
    {
      field: 'area',
      headerName: t('area'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value ? `${value} m²` : '-'
    },
    {
      field: 'updatedAt',
      headerName: t('last_edited_at'),
      flex: 1,
      maxWidth: 320,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    }
  ]

  const AdminReminderColumns = useMemo(() => [
    {
      field: 'actionTitle',
      headerName: t('title'),
      flex: 1,
      minWidth: 200,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={t(params.row.actionTitle)}
          onClick={() => openReminder(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'target',
      headerName: t('property'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        const isBuilding = params?.row?.targetSubEntity === 'building'
        let label = isBuilding ?
          BuildingSectionOptions?.find((item: any) => item.value === params?.row?.target)?.label :
          params?.row?.target
        if (label?.includes(' - ')) {
          label = label.split(' - ')[1]
        }
        return <Chip text={label ? t(label) : '-'} />
      }
    },
    {
      field: 'actionDate',
      headerName: t('action_date'),
      flex: 1,
      maxWidth: 320,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.setLocale('fi').toFormat('ccc')} ${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      maxWidth: 170,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box sx={styles.actions}>
          <IconButton
            icon='binAlt'
            onClick={() => openArchiveReminderConfirmation(params.row)}
            iconSize='1.25rem'
            mr='1rem'
            transparentBg
          />
        </Box>
      )
    }
  ], [isAdmin])

  const ReminderColumns = useMemo(() => {
    return AdminReminderColumns.slice(0, 3)
  }, [isAdmin])

  const getCityOptions = () => {
    return FinnishMunicipalities.map((item: any) => {
      return { value: item, label: item }
    })
  }

  const PropertySpaceOptions: any = Object.values(PropertySpacesAndAreas)
    .map((item: any) => ({ value: item, label: t(item) }))

  const getReminderTargetEntityOptions = () => {
    return [
      { value: 'areas', label: t('areas') },
      entities?.buildings?.length ? { value: 'buildings', label: t('buildings') } : null,
      entities?.apartments?.length ? { value: 'apartments', label: t('apartments') } : null
    ].filter((item: any) => item)
  }

  const getReminderTargetSubEntityOptions = (entity: string) => {
    if (entity === 'buildings') {
      return flatten(entities?.buildings?.map((building: any) => {
        return [
          { value: building.uuid, label: building.nameFi },
          ...building?.spaces?.map((space: any) => {
            return {
              value: space.uuid,
              parentValue: building.uuid,
              label: `${building.nameFi} - ${space.nameFi}`
            }
          })
        ]
      }))
    } else if (entity === 'apartments') {
      return flatten(entities?.apartments?.map((apartment: any) => {
        return [
          { value: apartment.uuid, label: apartment.nameFi, },
          ...apartment?.rooms?.map((room: any) => {
            return {
              value: room.uuid,
              parentValue: apartment.uuid,
              label: `${apartment.nameFi} - ${room.nameFi}`
            }
          })
        ]
      }))
    }
    return []
  }

  const getReminderTargetOptions = (targetEntity: string, targetSubEntity?: string) => {
    if (targetEntity === 'areas') {
      return AreaSectionOptions.map((item: any) => ({ value: item.value, label: t(item.label) }))
    } else if (targetEntity === 'buildings') {
      const subEntity: any = getReminderTargetSubEntityOptions(targetEntity)?.find((item: any) => item.value === targetSubEntity)
      if (subEntity?.parentValue) {
        return RoomSectionOptions.map((item: any) => ({ value: item.value, label: t(item.label) }))
      } else {
        return BuildingSectionOptions
      }
    } else if (targetEntity === 'apartments') {
      return RoomSectionOptions.map((item: any) => ({ value: item.value, label: t(item.label) }))
    }
    return []
  }

  const isSaveDisabled = () => {
    if (!nameFi) {
      return true
    }
    return false
  }

  const changeBackgroundPhoto = (photo: any) => {
    propertyStore.updatePropertyBackgroundPhoto(photo)
  }

  const createBuilding = (newBuilding: any) => {
    buildingStore.createBuilding(
      params?.uuid,
      newBuilding,
      () => closeEntityDialog()
    )
  }
  const createApartment = (newApartment: any) => {
    apartmentStore.createApartment(
      params?.uuid,
      newApartment,
      () => closeEntityDialog()
    )
  }

  const save = () => {
    if (tab !== PropertyTabs.Areas) {
      if (businessId && !validateBusinessId(businessId)) {
        return showMessage(t('invalid_business_id'), 'error')
      }

      const updatedProperty = {
        nameFi,
        businessId,
        registrationDate,
        area,
        hasMultipleProperties,
        propertyCount,
        propertyId,
        address,
        postalCode,
        city,
        admins: propertyAdmin ? [propertyAdmin] : [],
        apartmentCount,
        apartmentArea,
        commercialSpaceCount,
        commercialSpaceArea,
        // otherSpaceCount,
        otherSpaceArea,
        spaceTypes: spaceTypes?.join(','),
        spaceTypesInfo,
        parkingSpaceCount,
        hasMaintenanceReport,
        hasMaintenancePlan,
        pastMaintenanceInfo,
        plannedMaintenanceInfo,
        hasValidEnergyCertificate,
        energyCertificateStartYear,
        energyCertificateEndYear,
        propertyManagerName,
        propertyManagerCompany,
        propertyManagerPhone,
        propertyManagerEmail,
        propertyManagerAddress,
        propertyManagerPostalCode,
      }
      propertyStore.updateProperty(property?.uuid, updatedProperty)
    } else {
      savePropertyDataRef.current.onSave()
    }
  }
  const archiveProperty = () => {
    propertyStore.archiveProperty(
      property?.uuid,
      () => {
        closeActionConfirmation()
        navigate('/properties', { replace: true })
      }
    )
  }

  const createReminder = (newReminder: any) => {
    reminderStore.createPropertyReminder(
      params?.uuid,
      newReminder,
      () => closeEntityDialog()
    )
  }
  const updateReminder = (updatedReminder: any) => {
    reminderStore.updatePropertyReminder(
      params?.uuid,
      reminder?.uuid,
      updatedReminder,
      () => closeReminder()
    )
  }
  const archiveReminder = () => {
    reminderStore.archivePropertyReminder(
      params?.uuid,
      actionTarget?.uuid,
      () => closeActionConfirmation()
    )
  }

  if (!property || !params.uuid || params.uuid?.length !== 36) {
    return null
  }

  const renderTabContent = () => {
    if (tab === PropertyTabs.BasicInformation) {
      return (
        <>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('basic_information')}
            </Typography>
            <Input
              label={t('property_name')}
              value={nameFi}
              onChange={setNameFi}
              mb='1.25rem'
              disabled={!isAdmin}
              // invalid={property?.nameFi && !nameFi}
            />
            <Box sx={styles.row}>
              <Input
                label={t('business_id')}
                value={businessId}
                onChange={setBusinessId}
                mr='2rem'
                disabled={!isAdmin}
                // invalid={property?.businessId && !validateBusinessId(businessId)}
              />
              <Input
                label={t('company_registration_date')}
                value={registrationDate}
                onChange={setRegistrationDate}
                mr='2rem'
                disabled={!isAdmin}
              />
              <Input
                label={t('plot_area')}
                value={area}
                onChange={setArea}
                unit='m²'
                disabled={!isAdmin}
              />
            </Box>
            <Box sx={styles.row}>
              <Select
                label={t('has_multiple_properties')}
                options={[{ value: true, label: t('yes') }, { value: false, label: t('no') }]}
                value={hasMultipleProperties}
                onChange={setHasMultipleProperties}
                maxWidth='16rem'
                mr='2rem'
                disabled={!isAdmin}
              />
              <Input
                label={t('property_count')}
                value={propertyCount}
                onChange={setPropertyCount}
                disabled={!hasMultipleProperties || !isAdmin}
                unit={t('pcs')}
              />
            </Box>
            <MultiSelect
              options={YearOptions()}
              value={buildingCommissioningYears}
              onChange={() => {}}
              label={t('commissioning_years')}
              mb='1.25rem'
              disabled
            />
            <Input
              label={t('street_address')}
              value={address}
              onChange={setAddress}
              mb='1.25rem'
              disabled={!isAdmin}
            />
            <Box sx={styles.row}>
              <Input
                label={t('postal_code')}
                value={postalCode}
                onChange={setPostalCode}
                maxWidth='16rem'
                mr='2rem'
                disabled={!isAdmin}
              />
              <Select
                label={t('city_or_municipality')}
                options={getCityOptions()}
                value={city}
                onChange={setCity}
                disabled={!isAdmin}
              />
            </Box>
          </Box>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('property_admin')}
            </Typography>
            <Select
              label={t('user')}
              placeholder={isAdmin ? t('select') : null}
              options={AdminUserOptions}
              value={propertyAdmin}
              onChange={setPropertyAdmin}
              mb='1.25rem'
              disabled={!isAdmin}
            />
            <Box sx={styles.row}>
              <Input
                label={t('phone_number')}
                value={AdminUserOptions?.find((user: any) => user?.value === propertyAdmin)?.phone || ''}
                onChange={() => {}}
                maxWidth='16rem'
                mr='2rem'
                disabled
              />
              <Input
                label={t('email')}
                value={AdminUserOptions?.find((user: any) => user?.value === propertyAdmin)?.email || ''}
                onChange={() => {}}
                disabled
              />
            </Box>
          </Box>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('buildings')}
            </Typography>
            <MultiSelect
              label={t('building_types')}
              placeholder={buildingTypes?.length ? null : t('unknown')}
              options={Object.values(BuildingTypes).map((item: any) => ({ value: item, label: t(item) }))}
              value={buildingTypes}
              mb='1.25rem'
              disabled
            />
            {buildingTypes?.some((type: any) => type === BuildingTypes.Other && buildingTypeInfo) && (
              <Input
                placeholder={t('enter_more_specific_description')}
                value={buildingTypeInfo}
                onChange={() => {}}
                mb='1.25rem'
                disabled
              />
            )}
            <Input
              label={t('building_count')}
              value={buildingCount}
              onChange={() => {}}
              unit={t('pcs')}
              mb='1.25rem'
              disabled
            />
          </Box>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('common_spaces_and_areas')}
            </Typography>
            <Box sx={styles.row}>
              <Input
                label={t('residence_count')}
                value={apartmentCount}
                onChange={setApartmentCount}
                mr='2rem'
                unit={t('pcs')}
                disabled={!isAdmin}
              />
              <Input
                label={t('total_residence_area')}
                value={apartmentArea}
                onChange={setApartmentArea}
                unit='m²'
                disabled={!isAdmin}
              />
            </Box>
            <Box sx={styles.row}>
              <Input
                label={t('commercial_space_count')}
                value={commercialSpaceCount}
                onChange={setCommercialSpaceCount}
                mr='2rem'
                unit={t('pcs')}
                disabled={!isAdmin}
              />
              <Input
                label={t('total_commercial_space_area')}
                value={commercialSpaceArea}
                onChange={setCommercialSpaceArea}
                unit='m²'
                disabled={!isAdmin}
              />
            </Box>
            <Box sx={styles.row}>
              <Input
                label={t('other_space_count')}
                value={otherSpaceCount}
                onChange={setOtherSpaceCount}
                mr='2rem'
                unit={t('pcs')}
                disabled
              />
              <Input
                label={t('total_other_space_area')}
                value={otherSpaceArea}
                onChange={setOtherSpaceArea}
                unit='m²'
                disabled={!isAdmin}
                // disabled
              />
            </Box>
            <CheckboxMultiSelect
              options={PropertySpaceOptions}
              value={spaceTypes}
              onChange={updateSpaceTypes}
              label={t('common_spaces_and_areas')}
              mb='0.625rem'
              disabled={!isAdmin}
            />
            {spaceTypes?.some((space: any) => space === 'other') && (
              <Input
                placeholder={t('enter_more_specific_description')}
                value={spaceTypesInfo}
                onChange={setSpaceTypesInfo}
                mb='1.25rem'
                disabled={!isAdmin}
              />
            )}
          </Box>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('parking')}
            </Typography>
            <Input
              label={t('parking_space_count')}
              value={parkingSpaceCount}
              onChange={setParkingSpaceCount}
              mb='1.25rem'
              unit={t('pcs')}
              disabled={!isAdmin}
            />
          </Box>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('maintenance_information')}
            </Typography>
            <Box sx={styles.row}>
              <Select
                label={t('has_maintenance_report')}
                placeholder={isAdmin ? t('select') : null}
                options={[{ value: true, label: t('yes') }, { value: false, label: t('no') }]}
                value={hasMaintenanceReport}
                onChange={setHasMaintenanceReport}
                mr='2rem'
                disabled={!isAdmin}
              />
              <Select
                label={t('has_maintenance_plan')}
                placeholder={isAdmin ? t('select') : null}
                options={[{ value: true, label: t('yes') }, { value: false, label: t('no') }]}
                value={hasMaintenancePlan}
                onChange={setHasMaintenancePlan}
                disabled={!isAdmin}
              />
            </Box>
            {hasMaintenancePlan && (
              <>
                <Box sx={styles.editorContainer}>
                  <TextEditor
                    label={t('past_maintenance_info')}
                    value={pastMaintenanceInfo}
                    onChange={setPastMaintenanceInfo}
                    disabled={!isAdmin}
                  />
                </Box>
                <Box sx={styles.editorContainer}>
                  <TextEditor
                    label={t('planned_maintenance_info')}
                    value={plannedMaintenanceInfo}
                    onChange={setPlannedMaintenanceInfo}
                    disabled={!isAdmin}
                  />
                </Box>
              </>
            )}
          </Box>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('energy_certificate')}
            </Typography>
            <Select
              label={t('property_has_valid_energy_certificate')}
              placeholder={isAdmin ? t('select') : null}
              options={[{ value: true, label: t('yes') }, { value: false, label: t('no') }]}
              value={hasValidEnergyCertificate}
              onChange={setHasValidEnergyCertificate}
              mb='1.25rem'
              disabled={!isAdmin}
            />
            {hasValidEnergyCertificate && (
              <Box sx={styles.row}>
                <Select
                  options={YearOptions()}
                  value={energyCertificateStartYear}
                  onChange={setEnergyCertificateStartYear}
                  label={t('year_of_preparation')}
                  placeholder={isAdmin ? t('select') : null}
                  mr='2rem'
                  disabled={!isAdmin}
                />
                <Select
                  options={YearOptions()}
                  value={energyCertificateEndYear}
                  onChange={setEnergyCertificateEndYear}
                  label={t('valid_until_year')}
                  placeholder={isAdmin ? t('select') : null}
                  disabled={!isAdmin}
                />
              </Box>
            )}
          </Box>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('property_management')}
            </Typography>
            <Input
              label={t('contact_person')}
              value={propertyManagerName}
              onChange={setPropertyManagerName}
              mb='1.25rem'
              disabled={!isAdmin}
            />
            <Input
              label={t('company')}
              value={propertyManagerCompany}
              onChange={setPropertyManagerCompany}
              mb='1.25rem'
              disabled={!isAdmin}
            />
            <Box sx={styles.row}>
              <Input
                label={t('phone_number')}
                value={propertyManagerPhone}
                onChange={setPropertyManagerPhone}
                maxWidth='20rem'
                mr='2rem'
                disabled={!isAdmin}
              />
              <Input
                label={t('email')}
                value={propertyManagerEmail}
                onChange={setPropertyManagerEmail}
                disabled={!isAdmin}
              />
            </Box>
            <Input
              label={t('address')}
              value={propertyManagerAddress}
              onChange={setPropertyManagerAddress}
              mb='1.25rem'
              disabled={!isAdmin}
            />
            <Box sx={styles.row}>
              <Input
                label={t('postal_code')}
                value={propertyManagerPostalCode}
                onChange={setPropertyManagerPostalCode}
                maxWidth='16rem'
                mr='2rem'
                disabled={!isAdmin}
              />
              <Input
                label={t('city_or_municipality')}
                value={propertyManagerCity}
                onChange={setPropertyManagerCity}
                disabled={!isAdmin}
              />
            </Box>
          </Box>
        </>
      )
    } else if (tab === PropertyTabs.Buildings) {
      return (
        <>
          <TableActions
            sort={buildingFilter}
            sortPlaceholder={t('view_all')}
            sortOptions={BuildingFilterOptions}
            onSortSelect={setBuildingFilter}
            // onPhotosClick={viewBuildingPhotos}
            onAddClick={isAdmin ? toCreateBuilding : undefined}
            allowClearable
          />
          <Table
            rows={BuildingRows}
            columns={BuildingColumns}
            loading={loading}
            hideCheckboxSelection
          />
        </>
      )
    } else if (tab === PropertyTabs.Apartments) {
      return (
        <>
          <TableActions
            sort={apartmentFilter}
            sortPlaceholder={t('view_all')}
            sortOptions={ApartmentFilterOptions}
            onSortSelect={setApartmentFilter}
            // onPhotosClick={viewBuildingPhotos}
            onAddClick={isAdmin ? toCreateApartment : undefined}
            allowClearable
          />
          <Table
            rows={ApartmentRows}
            columns={ApartmentColumns}
            loading={loading}
            hideCheckboxSelection
          />
        </>
      )
    } else if (tab === PropertyTabs.Areas) {
      return <PropertyAreas uuid={params?.uuid} saveRef={savePropertyDataRef} />
    } else if (tab === PropertyTabs.Reminders) {
      return (
        <>
          <TableActions
            sort={reminderFilter}
            sortPlaceholder={t('view_all')}
            sortOptions={ReminderFilterOptions}
            onSortSelect={setReminderFilter}
            onAddClick={isAdmin ? toCreateReminder : undefined}
          />
          <Table
            rows={ReminderRows}
            columns={isAdmin ? AdminReminderColumns : ReminderColumns}
            loading={loading}
            hideCheckboxSelection
          />
        </>
      )
    }
    return null
  }

  const renderLightbox = () => {
    if (property?.backgroundPhoto && selectedImage) {
      return (
        <Lightbox
          open={selectedImage}
          onClose={clearSelectedImage}
          index={0}
          images={[{ src: property?.backgroundPhoto }]}
        />
      )
    }
    return null
  }

  const renderRightColumnContent = () => {
    if (isAdmin) {
      return (
        <>
          <SidebarActions
            updatedAt={property?.updatedAt || property?.createdAt || ''}
            archivedAt={property?.archivedAt}
            onSave={save}
            onArchive={openArchivePropertyConfirmation}
            saveDisabled={isSaveDisabled()}
            mt='5.125rem'
          />
          <SidebarReminders
            updatedAt={reminderStore?.updatedAt || ''}
            count={UpcomingReminderCount}
            onAdd={toCreateReminder}
            onView={openRemindersTab}
            onToggleOpen={toggleOpenReminders}
            isOpen={remindersOpen}
          />
        </>
      )
    }
    return null
  }

  const renderEntityDialog = () => {
    if (actionType === 'create_building') {
      return (
        <CreateBuildingModal
          onClose={closeEntityDialog}
          onSave={createBuilding}
        />
      )
    } else if (actionType === 'create_apartment') {
      return (
        <CreateApartmentModal
          onClose={closeEntityDialog}
          onSave={createApartment}
        />
      )
    } else if (actionType === 'create_reminder' || reminder) {
      return (
        <ReminderModal
          mode={reminder ? 'edit' : 'create'}
          property={property}
          onClose={reminder ? closeReminder : closeEntityDialog}
          onSave={reminder ? updateReminder : createReminder}
          users={AdminUserOptions}
          getTargetEntityOptions={getReminderTargetEntityOptions}
          getTargetSubEntityOptions={getReminderTargetSubEntityOptions}
          getTargetOptions={getReminderTargetOptions}
          reminder={reminder}
          tab={reminder ? undefined : tab}
          disabled={!isAdmin}
        />
      )
    }
    return null
  }

  const renderActionConfirmation = () => {
    if (showActionConfirmation) {
      if (actionType === 'archive_property') {
        return (
          <ConfirmationDialog
            title={t('archive_property_title')}
            content={t('archive_property_info')}
            onClose={closeActionConfirmation}
            cancelText={t('cancel')}
            onAction={archiveProperty}
            actionText={t('archive')}
          />
        )
      } else if (actionType === 'archive_reminder') {
        return (
          <ConfirmationDialog
            title={t('archive_reminder_title')}
            content={t('archive_reminder_info')}
            onClose={closeActionConfirmation}
            cancelText={t('cancel')}
            onAction={archiveReminder}
            actionText={t('archive')}
          />
        )
      }
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <CoverImage
        bgPhotoUrl={property?.backgroundPhoto}
        onUpdateBackgroundPhoto={isAdmin ? changeBackgroundPhoto : undefined}
        onClick={property?.backgroundPhoto ? openBackgroundPhoto : undefined}
      />
      <Box sx={styles.contentContainer}>
        <Box sx={styles.leftColumn}>
          <Typography variant='h2' color={Colors.primary} mt='1rem'>
            {Title}
          </Typography>
          <Tabs mt='1.75rem' mb='2rem'>
            <Tab
              value={PropertyTabs.BasicInformation}
              text={t(PropertyTabs.BasicInformation)}
              selected={tab === PropertyTabs.BasicInformation}
              onClick={setTab}
              first
            />
            <Tab
              value={PropertyTabs.Buildings}
              text={t(PropertyTabs.Buildings)}
              selected={tab === PropertyTabs.Buildings}
              onClick={setTab}
              count={0}
            />
            <Tab
              value={PropertyTabs.Apartments}
              text={t(PropertyTabs.Apartments)}
              selected={tab === PropertyTabs.Apartments}
              onClick={setTab}
              count={0}
            />
            <Tab
              value={PropertyTabs.Areas}
              text={t(PropertyTabs.Areas)}
              selected={tab === PropertyTabs.Areas}
              onClick={setTab}
              count={0}
            />
            <Tab
              value={PropertyTabs.Reminders}
              text={t(PropertyTabs.Reminders)}
              selected={tab === PropertyTabs.Reminders}
              onClick={setTab}
              count={0}
            />
            <Tab
              value={PropertyTabs.Costs}
              text={t(PropertyTabs.Costs)}
              selected={tab === PropertyTabs.Costs}
              onClick={toCosts}
              count={0}
              link
            />
          </Tabs>
          {renderTabContent()}
          {renderLightbox()}
        </Box>
        <Box sx={styles.rightColumn}>
          {renderRightColumnContent()}
        </Box>
      </Box>
      {renderEntityDialog()}
      {renderActionConfirmation()}
    </Box>
  )
}

export default observer(Property)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginRight: {
      xl: '3rem',
      lgplus: '2.5rem',
      xs: '2rem'
    }
  },
  rightColumn: {
    position: 'sticky',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '1rem 0',
    mb: '1rem'
  },
  dot: {
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: '50%',
    backgroundColor: Colors.primary,
    display: 'inline-block',
    marginLeft: '0.5rem',
    marginRight: '0.75rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  column: {
    flexDirection: 'column',
    width: '100%'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(50% - 1rem)',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem'
  },
  infoLabel: {
    fontSize: '1.125rem',
    fontWeight: 700,
    color: Colors.heading,
    textTransform: 'uppercase',
    mb: '0.5rem'
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontFamily: Fonts.body,
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
      transition: 'font-weight 0.3s ease-in-out'
    }
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: {
      lgplus: '2rem 2rem 0.5rem 2rem',
      xs: '1.625rem 1.5rem 0.125rem 1.5rem'
    },
    mb: '1.5rem'
  },
  subtitle: {
    alignSelf: 'flex-start',
    mb: '1.25rem',
    textTransform: 'uppercase'
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '18rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  },
  editorContainer: {
    width: '100%'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem'
  },
} as const
