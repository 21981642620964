import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import flatten from 'lodash/flatten'
import { GridRenderCellParams } from '@mui/x-data-grid'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FileUpload from '../../../Components/Common/FileUpload'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import File from '../../../Components/Common/File'
import Lightbox from '../../../Components/Common/Lightbox'
import TableActions from '../../../Components/Common/TableActions'
import Table from '../../../Components/Common/Table'
import CreateRoomModal from '../../../Components/Common/CreateRoomModal'
import UpdateFileModal from '../../../Components/Common/UpdateFileModal'
import IconButton from '../../../Components/Common/IconButton'
import SidebarActions from '../../../Components/Common/SidebarActions'
import SidebarReminders from '../../../Components/Common/SidebarReminders'
import ReminderModal from '../../../Components/Common/ReminderModal'
import { CoverImage, Tabs, Tab, Chip, Button, Input, Select } from '../../../Components'
import { ApartmentTabs, UserRoles } from '../../../Utils/constants'
import { RoomSectionOptions } from '../../../Utils/room'
import { ApartmentTypes } from '../../../Utils/apartment'
import { Colors, Fonts } from '../../../Utils/theme'

const Apartment = () => {
  const {
    sessionStore,
    userStore,
    propertyStore,
    apartmentStore,
    roomStore,
    reminderStore
  }: any = useStore()
  const { isAdmin } = sessionStore
  const { entities } = propertyStore
  const { apartment, loading } = apartmentStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const [searchParams, setSearchParams]: any = useSearchParams()

  const [tab, setTab] = useState(ApartmentTabs.BasicInformation)
  const [selectedImage, setSelectedImage] = useState(false)
  const [remindersOpen, setRemindersOpen] = useState(true)
  const [reminder, setReminder] = useState<any>(null)

  const openRemindersTab = () => setTab(ApartmentTabs.Reminders)
  const toggleOpenReminders = () => setRemindersOpen(!remindersOpen)
  const openReminder = (reminder: any) => {
    setReminder(reminder)
    propertyStore.getPropertyEntities(params.pUuid, { types: ['apartments', 'rooms'] })
    userStore.getUsers()
  }
  const closeReminder = () => setReminder(null)

  // Basic apartment information
  const [nameFi, setNameFi] = useState('')
  const [type, setType] = useState('')
  const [typeInfo, setTypeInfo] = useState('')
  const [area, setArea] = useState(null)
  const [address, setAddress] = useState('')
  const [building, setBuilding] = useState(null)
  const [residentName, setResidentName] = useState('')
  const [residentEmail, setResidentEmail] = useState('')
  const [residentPhone, setResidentPhone] = useState('')
  const [residentMovedInAt, setResidentMovedInAt] = useState('')

  // Confirmation dialog
  const [showActionConfirmation, setShowActionConfirmation] = useState(false)
  const [actionType, setActionType] = useState('')
  const [actionTarget, setActionTarget] = useState<any>(null)

  const openCreateEntityDialog = (entity: string) => {
    if (entity === 'reminder') {
      setActionType('create_reminder')
      propertyStore.getPropertyEntities(params.pUuid, { types: ['apartments', 'rooms'] })
      userStore.getUsers()
    }
  }
  const toCreateReminder = () => openCreateEntityDialog('reminder')
  const closeEntityDialog = () => setActionType('')

  // Filters
  const [reminderFilter, setReminderFilter] = useState('upcoming')

  const openCreateRoom = () => {
    setActionType('create_room')
  }
  const closeCreateRoom = () => {
    setActionType('')
  }
  const openUpdateFile = (file: any) => {
    setActionType('update_file')
    setActionTarget(file)
  }
  const closeUpdateFile = () => {
    setActionType('')
    setActionTarget(null)
  }
  const openArchiveApartmentConfirmation = () => {
    setShowActionConfirmation(true)
    setActionType('archive_apartment')
  }
  const openArchiveRoomConfirmation = (room: any) => {
    setShowActionConfirmation(true)
    setActionType('archive_room')
    setActionTarget(room)
    roomStore.setRoom(room)
  }
  const openArchiveReminderConfirmation = (reminder: any) => {
    setShowActionConfirmation(true)
    setActionType('archive_reminder')
    setActionTarget(reminder)
  }
  const closeActionConfirmation = () => {
    setShowActionConfirmation(false)
    setActionType('')
    setActionTarget(null)
  }

  const openApartmentBackgroundPhoto = () => setSelectedImage(apartment?.backgroundPhoto)
  const clearSelectedImage = () => setSelectedImage(false)

  useEffect(() => {
    const pUuid = params?.pUuid || null
    const aUuid = params?.aUuid || null
    if (pUuid && aUuid && pUuid?.length === 36 && aUuid?.length === 36) {
      apartmentStore.getApartment(pUuid, aUuid)
      if (isAdmin) {
        reminderStore.getPropertyReminders(params.pUuid, { apartmentUuid: aUuid })
        propertyStore.getPropertyEntities(params.pUuid, { types: ['buildings'] })
      }
    }
  }, [])

  useEffect(() => {
    const aUuid = params?.aUuid || null
    if (aUuid && aUuid?.length === 36) {
      if (apartment && apartment?.uuid === aUuid) {
        setNameFi(apartment.nameFi)
        setType(apartment.type)
        setTypeInfo(apartment.typeInfo)
        setArea(apartment.area)
        setAddress(apartment.address)
        setResidentName(apartment.residentName)
        setResidentEmail(apartment.residentEmail)
        setResidentPhone(apartment.residentPhone)
        setResidentMovedInAt(apartment.residentMovedInAt)
        setBuilding(apartment?.building?.uuid || null)
      }
    }
  }, [apartment])

  useEffect(() => {
    if (tab === ApartmentTabs.BasicInformation) {
      setSearchParams({}, { replace: true })
    } else if (tab === ApartmentTabs.Rooms) {
      setSearchParams({ tab: ApartmentTabs.Rooms }, { replace: true })
      roomStore.getRooms(params.pUuid, params.aUuid)
    } else if (tab === ApartmentTabs.Photos) {
      setSearchParams({ tab: ApartmentTabs.Photos }, { replace: true })
    } else if (tab === ApartmentTabs.Attachments) {
      setSearchParams({ tab: ApartmentTabs.Attachments }, { replace: true })
    } else if (tab === ApartmentTabs.Reminders) {
      if (searchParams?.tab !== ApartmentTabs.Reminders) {
        setSearchParams({ tab: ApartmentTabs.Reminders }, { replace: true })
      }
      reminderStore.getPropertyReminders(params?.pUuid, { apartmentUuid: params?.aUuid })
    }
  }, [tab])

  useEffect(() => {
    if (location?.search?.includes('tab')) {
      const selectedTab = location?.search?.split('tab=')?.[1] || ''
      if (selectedTab === ApartmentTabs.Rooms) {
        setTab(ApartmentTabs.Rooms)
      } else if (selectedTab === ApartmentTabs.Photos) {
        setTab(ApartmentTabs.Photos)
      } else if (selectedTab === ApartmentTabs.Attachments) {
        setTab(ApartmentTabs.Attachments)
      } else if (selectedTab === ApartmentTabs.Reminders) {
        setTab(ApartmentTabs.Reminders)
      }
    }
  }, [location])

  useEffect(() => {
    if (reminderFilter !== 'upcoming') {
      reminderStore.getPropertyReminders(params?.pUuid, { all: true, apartmentUuid: params?.aUuid })
    }
  }, [reminderFilter])

  const Attachments = useMemo(() => {
    if (apartment && apartment?.files) {
      if (tab === ApartmentTabs.Photos) {
        return (apartment?.files || [])?.filter((file: any) => file?.type === 'image') || []
      } else if (tab === ApartmentTabs.Attachments) {
        return (apartment?.files || [])?.filter((file: any) => file?.type !== 'image') || []
      }
    }
    return []
  }, [tab, apartment])

  const ApartmentTypeOptions = Object
    .values(ApartmentTypes)
    .map((item: any) => {
      if (item === ApartmentTypes.Other) {
        return { value: item, label: t(item) }
      } else {
        return { value: item, label: item.split('+').join(' + ') }
      }
    })

  const BuildingOptions = useMemo(() => {
    if (entities?.buildings?.length) {
      return entities?.buildings?.map((item: any) => ({
        value: item.uuid,
        label: item.nameFi
      }))
    }
    return []
  }, [entities])

  const AdminUserOptions = useMemo(() => {
    if (userStore?.users?.length) {
      return userStore?.users
        ?.filter((user: any) => user?.role === UserRoles.Admin)
        ?.map((user: any) => {
        return {
          value: user.uuid,
          label: `${user?.firstName} ${user?.lastName}`,
          email: user?.email || '',
          phone: user?.phone || ''
        }
      })
    }
    return []
  }, [userStore.users])

  const RoomColumns = isAdmin ? [
    {
      field: 'nameFi',
      headerName: t('name_or_identifier'),
      flex: 1,
      minWidth: 200,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={t(params.row.nameFi)}
          onClick={() => toRoom(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'type',
      headerName: t('type'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value ? t(value) : '-'
    },
    {
      field: 'updatedAt',
      headerName: t('last_edited_at'),
      flex: 1,
      maxWidth: 320,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      maxWidth: 170,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box sx={styles.actions}>
          <IconButton
            icon='binAlt'
            onClick={() => openArchiveRoomConfirmation(params.row)}
            iconSize='1.25rem'
            mr='1rem'
            transparentBg
          />
        </Box>
      )
    }
  ] : [
    {
      field: 'nameFi',
      headerName: t('name_or_identifier'),
      flex: 1,
      minWidth: 200,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={t(params.row.nameFi)}
          onClick={() => toRoom(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'type',
      headerName: t('type'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value ? t(value) : '-'
    },
    {
      field: 'updatedAt',
      headerName: t('last_edited_at'),
      flex: 1,
      maxWidth: 320,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    }
  ]

  const AdminReminderColumns = useMemo(() => [
    {
      field: 'actionTitle',
      headerName: t('title'),
      flex: 1,
      minWidth: 200,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={t(params.row.actionTitle)}
          onClick={() => openReminder(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'target',
      headerName: t('property'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return <Chip text={params.row?.target ? t(params.row.target) : '-'} />
      }
    },
    {
      field: 'actionDate',
      headerName: t('action_date'),
      flex: 1,
      maxWidth: 320,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      maxWidth: 170,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box sx={styles.actions}>
          <IconButton
            icon='binAlt'
            onClick={() => openArchiveReminderConfirmation(params.row)}
            iconSize='1.25rem'
            mr='1rem'
            transparentBg
          />
        </Box>
      )
    }
  ], [isAdmin])

  const ReminderColumns = useMemo(() => {
    return AdminReminderColumns.slice(0, 3)
  }, [isAdmin])
  
  const isSaveDisabled = () => {
    if (!nameFi) return true
    return false
  }

  const updateApartmentBackgroundPhoto = (file: any) => {
    apartmentStore.updateApartmentBackgroundPhoto(params?.pUuid, file?.uuid)
  }

  const downloadFile = async (file: any) => {
    try {
      // Fetch the image as a Blob
      const response = await fetch(file.url, { mode: 'cors' });
      const blob = await response.blob()

      // Create a local URL for the downloaded image
      const blobUrl = URL.createObjectURL(blob)

      // Create a link element to trigger the download
      const link = document.createElement('a')
      link.href = blobUrl
      link.setAttribute('download', file?.name || file?.originalName)
      
      // Append link to the body, trigger click, and clean up
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      // Revoke the object URL after the download
      URL.revokeObjectURL(blobUrl)

      // window.open(file?.url, '_blank')
    } catch (error) {
      console.error('Error downloading the image:', error)
    }
  }

  const archiveFile = (file: any) => apartmentStore.archiveApartmentFile(params?.pUuid, file?.uuid)

  const getFileActionOptions = (file: any) => {
    if (file?.type === 'image') {
      if (file?.url === apartment?.backgroundPhoto) {
        return [
          { text: t('update_file'), onClick: () => openUpdateFile(file) },
          { text: t('download_file'), onClick: downloadFile },
          { text: t('delete_file'), onClick: archiveFile }
        ]
      } else {
        return [
          { text: t('set_as_main_photo'), onClick: updateApartmentBackgroundPhoto },
          { text: t('update_file'), onClick: () => openUpdateFile(file) },
          { text: t('download_file'), onClick: downloadFile },
          { text: t('delete_file'), onClick: archiveFile }
        ]
      }
    }
    return [
      { text: t('update_file'), onClick: () => openUpdateFile(file) },
      { text: t('download_file'), onClick: downloadFile },
      { text: t('delete_file'), onClick: archiveFile }
    ]
  }

  const ReminderFilterOptions = [
    { value: 'upcoming', label: t('only_upcoming') },
    { value: 'past', label: t('only_past') },
    { value: 'archived', label: t('only_archived') },
    { value: 'all', label: t('all') }
  ]

  const getReminderTargetEntityOptions = () => {
    return [{ value: 'apartments', label: t('apartments') }]
  }

  const getReminderTargetSubEntityOptions = (entity: string) => {
    if (entity === 'apartments') {
      return flatten(
        entities?.apartments
          ?.filter((a: any) => a?.uuid === apartment?.uuid)
          ?.map((apartment: any) => {
            return [
              { value: apartment.uuid, label: apartment.nameFi, },
              ...apartment?.rooms?.map((room: any) => {
                return {
                  value: room.uuid,
                  parentValue: apartment.uuid,
                  label: `${apartment.nameFi} - ${room.nameFi}`
                }
              })
            ]
          }
        )
      )
    }
    return []
  }

  const getReminderTargetOptions = () => {
    return RoomSectionOptions.map((item: any) => ({ value: item.value, label: t(item.label) }))
  }

  const ReminderRows = useMemo(() => {
    const now = DateTime.now()
    if (reminderFilter === 'upcoming' || !reminderFilter) {
      // Filter out archived or past reminders
      return reminderStore?.reminders?.filter((reminder: any) => {
        return !reminder?.archivedAt &&
        (DateTime.fromISO(reminder?.actionDate) > now ||
        reminder?.scheduledReminders?.some((item: any) => {
          return DateTime.fromISO(item?.scheduledAt) > now
        }))
      })
    } else if (reminderFilter === 'past') {
      return reminderStore?.reminders?.filter((reminder: any) => {
        return !reminder?.archivedAt &&
        DateTime.fromISO(reminder?.actionDate) < now &&
        reminder?.scheduledReminders?.every((item: any) => {
          return DateTime.fromISO(item?.scheduledAt) < now
        })
      })
    } else if (reminderFilter === 'archived') {
      return reminderStore?.reminders?.filter((reminder: any) => reminder?.archivedAt)
    }
    return reminderStore?.reminders
  }, [reminderStore?.reminders, reminderFilter])

  const UpcomingReminderCount = useMemo(() => {
    return reminderStore?.reminders?.filter((reminder: any) => {
      return !reminder?.archivedAt &&
      DateTime.fromISO(reminder?.actionDate) > DateTime.now() &&
      reminder?.scheduledReminders?.some((item: any) => {
        return DateTime.fromISO(item?.scheduledAt) > DateTime.now()
      })
    })?.length
  }, [reminderStore?.reminders])

  const uploadFiles = (files: any) => {
    apartmentStore.uploadApartmentFiles(params?.pUuid, files)
  }
  
  const updateApartmentFile = (file: any) => {
    apartmentStore.updateApartmentFile(
      params?.pUuid,
      actionTarget?.uuid,
      file,
      () => closeUpdateFile()
    )
  }

  const onFileClick = (file: any) => {
    if (file?.type === 'image') {
      setSelectedImage(file?.url)
    }
  }

  const toRoom = (room: any) => navigate(`${location.pathname}/rooms/${room?.uuid}`)

  const save = () => {
    const updatedApartment = {
      nameFi,
      type,
      typeInfo: type === ApartmentTypes.Other ? typeInfo : null,
      area,
      address,
      building: building || null,
      residentName,
      residentEmail,
      residentPhone,
      residentMovedInAt
    }
    apartmentStore.updateApartment(params?.pUuid, updatedApartment)
  }

  const createRoom = (room: any) => {
    roomStore.createRoom(
      params?.pUuid,
      params?.aUuid,
      room,
      () => closeCreateRoom()
    )
  }

  const archiveApartment = () => {
    apartmentStore.archiveApartment(
      params?.pUuid,
      () => {
        closeActionConfirmation()
        navigate(`/properties/${params?.pUuid}?tab=apartments`, { replace: true })
      }
    )
  }
  const archiveRoom = () => {
    roomStore.archiveRoom(
      params?.pUuid,
      params?.aUuid,
      () => {
        closeActionConfirmation()
        roomStore.setRoom(null)
      }
    )
  }

  const createReminder = (newReminder: any) => {
    reminderStore.createPropertyReminder(
      params?.pUuid,
      newReminder,
      () => closeEntityDialog()
    )
  }
  const updateReminder = (updatedReminder: any) => {
    reminderStore.updatePropertyReminder(
      params?.pUuid,
      reminder?.uuid,
      updatedReminder,
      () => closeReminder()
    )
  }
  const archiveReminder = () => {
    reminderStore.archivePropertyReminder(
      params?.pUuid,
      actionTarget?.uuid,
      () => closeActionConfirmation()
    )
  }

  if (
    !apartment ||
    !params.pUuid ||
    !params.aUuid ||
    params.pUuid?.length !== 36 ||
    params.aUuid?.length !== 36
  ) {
    return null
  }

  const renderAttachments = () => {
    return Attachments.map((file: any) => (
      <Grid key={file.uuid} md={6} xl={4} xxxl={3}>
        <File
          file={file}
          menuOptions={isAdmin ? getFileActionOptions(file) : undefined}
          onFileClick={onFileClick}
        />
      </Grid>
    ))
  }

  const renderTabContent = () => {
    if (tab === ApartmentTabs.BasicInformation) {
      return (
        <>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('basic_information')}
            </Typography>
            <Input
              label={t('apartment_number_or_identifier')}
              value={nameFi}
              onChange={setNameFi}
              mb='1.25rem'
              disabled={!isAdmin}
            />
            <Select
              label={t('apartment_type')}
              options={ApartmentTypeOptions}
              value={type}
              onChange={setType}
              mb='1.25rem'
              disabled={!isAdmin}
            />
            {type === ApartmentTypes.Other && (
              <Input
                placeholder={t('enter_more_specific_description')}
                value={typeInfo}
                onChange={setTypeInfo}
                mb='1.25rem'
                disabled={!isAdmin}
              />
            )}
            <Input
              label={t('area')}
              value={area}
              onChange={setArea}
              unit='m²'
              mb='1.25rem'
              disabled={!isAdmin}
            />
            <Input
              label={t('address_information')}
              value={address}
              onChange={setAddress}
              mb='1.25rem'
              disabled={!isAdmin}
            />
            <Box sx={styles.row}>
              <Input
                label={t('postal_code')}
                value={apartment?.property?.postalCode}
                onChange={() => {}}
                maxWidth='16rem'
                mr='2rem'
                disabled
              />
              <Input
                label={t('city_or_municipality')}
                value={apartment?.property?.city}
                onChange={() => {}}
                disabled
              />
            </Box>
          </Box>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('building_information')}
            </Typography>
            <Select
              label={t('building')}
              placeholder={t('select')}
              options={BuildingOptions}
              value={building}
              onChange={setBuilding}
              disabled={!isAdmin}
              mb='1.25rem'
            />
          </Box>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('resident_information')}
            </Typography>
            <Input
              label={t('resident')}
              value={residentName}
              onChange={setResidentName}
              mb='1.25rem'
              disabled={!isAdmin}
            />
            <Input
              label={t('resident_moved_in_at')}
              value={residentMovedInAt}
              onChange={setResidentMovedInAt}
              mb='1.25rem'
              disabled={!isAdmin}
            />
            <Box sx={styles.row}>
              <Input
                label={t('phone_number')}
                value={residentPhone}
                onChange={setResidentPhone}
                maxWidth='20rem'
                mr='2rem'
                disabled={!isAdmin}
              />
              <Input
                label={t('email')}
                value={residentEmail}
                onChange={setResidentEmail}
                disabled={!isAdmin}
              />
            </Box>
          </Box>
        </>
      )
    } else if (tab === ApartmentTabs.Rooms) {
      return (
        <>
          <TableActions
            onPhotosClick={apartment?.files?.some((file: any) => file?.type === 'image') && openApartmentBackgroundPhoto}
            photoActionText={apartment?.files?.some((file: any) => file?.type === 'image') && t('apartment_photos')}
            align='left'
            onAddClick={isAdmin ? openCreateRoom : undefined}
          />
          <Table
            rows={roomStore.rooms}
            columns={RoomColumns}
            loading={loading}
            hideCheckboxSelection
          />
        </>
      ) 
    } else if (tab === ApartmentTabs.Photos) {
      return (
        <>
          <Grid
            container
            columns={12}
            columnSpacing={{ md: 3, xl: 4, xxl: 5, xxxl: 6 }}
            rowSpacing='1.5rem'
          >
            <Grid md={6} xl={4} xxxl={3}>
              {isAdmin && (
                <FileUpload
                  onUploadFiles={uploadFiles}
                  loading={loading && Attachments?.length}
                />
              )}
            </Grid>
            {renderAttachments()}
          </Grid>
        </>
      )
    } else if (tab === ApartmentTabs.Attachments) {
      return (
        <>
          <Grid
            container
            columns={12}
            columnSpacing={{ md: 3, xl: 4, xxl: 5, xxxl: 6 }}
            rowSpacing='1.5rem'
          >
            <Grid md={6} xl={4} xxxl={3}>
              {isAdmin && (
                <FileUpload
                  onUploadFiles={uploadFiles}
                  loading={loading && Attachments?.length}
                />
              )}
            </Grid>
            {renderAttachments()}
          </Grid>
        </>
      )
    } else if (tab === ApartmentTabs.Reminders) {
      return (
        <>
          <TableActions
            sort={reminderFilter}
            sortPlaceholder={t('view_all')}
            sortOptions={ReminderFilterOptions}
            onSortSelect={setReminderFilter}
            onAddClick={isAdmin ? toCreateReminder : undefined}
          />
          <Table
            rows={ReminderRows}
            columns={isAdmin ? AdminReminderColumns : ReminderColumns}
            loading={loading}
            hideCheckboxSelection
          />
        </>
      )
    }
    return null
  }

  const renderLightbox = () => {
    if (selectedImage && apartment?.files?.some((file: any) => file?.type === 'image')) {
      return (
        <Lightbox
          open={selectedImage}
          onClose={clearSelectedImage}
          index={
            apartment?.files
              .filter((file: any) => file?.type === 'image')
              .map((file: any) => ({ src: file?.url }))
              .findIndex((file: any) => file.src === selectedImage)
          }
          images={
            apartment?.files
              .filter((file: any) => file?.type === 'image')
              .map((file: any) => ({ src: file?.url }))
          }
        />
      )
    }
    return null
  }

  const renderRightColumnContent = () => {
    if (isAdmin) {
      return (
        <>
          <SidebarActions
            updatedAt={apartment?.updatedAt || apartment?.createdAt || ''}
            archivedAt={apartment?.archivedAt}
            onSave={save}
            onArchive={openArchiveApartmentConfirmation}
            saveDisabled={isSaveDisabled()}
          />
          <SidebarReminders
            updatedAt={reminderStore?.updatedAt || ''}
            count={UpcomingReminderCount}
            onAdd={toCreateReminder}
            onView={openRemindersTab}
            onToggleOpen={toggleOpenReminders}
            isOpen={remindersOpen}
          />
        </>
      )
    }
    return null
  }

  const renderUpdateFileDialog = () => {
    if (actionType === 'update_file' && actionTarget) {
      return (
        <UpdateFileModal
          onClose={closeActionConfirmation}
          onSave={updateApartmentFile}
          loading={loading}
          file={actionTarget}
        />
      )
    }
    return null
  }

  const renderEntityDialog = () => {
    if (actionType === 'create_room') {
      return (
        <CreateRoomModal
          onClose={closeEntityDialog}
          onSave={createRoom}
        />
      )
    } else if (actionType === 'create_reminder' || reminder) {
      return (
        <ReminderModal
          mode={reminder ? 'edit' : 'create'}
          property={apartment?.property}
          apartment={apartment}
          onClose={reminder ? closeReminder : closeEntityDialog}
          onSave={reminder ? updateReminder : createReminder}
          users={AdminUserOptions}
          getTargetEntityOptions={getReminderTargetEntityOptions}
          getTargetSubEntityOptions={getReminderTargetSubEntityOptions}
          getTargetOptions={getReminderTargetOptions}
          reminder={reminder}
          disabled={!isAdmin}
        />
      )
    }
    return null
  }

  const renderActionConfirmation = () => {
    if (showActionConfirmation) {
      if (actionType === 'archive_apartment') {
        return (
          <ConfirmationDialog
            title={t('archive_apartment_title')}
            content={t('archive_apartment_info')}
            onClose={closeActionConfirmation}
            cancelText={t('cancel')}
            onAction={archiveApartment}
            actionText={t('archive')}
          />
        )
      } else if (actionType === 'archive_room') {
        return (
          <ConfirmationDialog
            title={t('archive_room_title')}
            content={t('archive_room_info')}
            onClose={closeActionConfirmation}
            cancelText={t('cancel')}
            onAction={archiveRoom}
            actionText={t('archive')}
          />
        )
      } else if (actionType === 'archive_reminder') {
        return (
          <ConfirmationDialog
            title={t('archive_reminder_title')}
            content={t('archive_reminder_info')}
            onClose={closeActionConfirmation}
            cancelText={t('cancel')}
            onAction={archiveReminder}
            actionText={t('archive')}
          />
        )
      }
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <CoverImage
        bgPhotoUrl={apartment?.backgroundPhoto}
        onClick={apartment?.backgroundPhoto ? openApartmentBackgroundPhoto : null}
      />
      <Box sx={styles.contentContainer}>
        <Box sx={styles.leftColumn}>
          <Typography variant='h2' color={Colors.primary} mt='1rem'>
            {apartment?.nameFi || '-'}
          </Typography>
          <Box sx={styles.infoRow}>
            <Chip text={apartment?.property?.nameFi || ''} />
          </Box>
          <Tabs mb={tab === ApartmentTabs.Rooms ? '2.5rem' : '2rem'}>
            <Tab
              value={ApartmentTabs.BasicInformation}
              text={t(ApartmentTabs.BasicInformation)}
              selected={tab === ApartmentTabs.BasicInformation}
              onClick={setTab}
              first
            />
            <Tab
              value={ApartmentTabs.Rooms}
              text={t(ApartmentTabs.Rooms)}
              selected={tab === ApartmentTabs.Rooms}
              onClick={setTab}
              count={0}
            />
            <Tab
              value={ApartmentTabs.Photos}
              text={t(ApartmentTabs.Photos)}
              selected={tab === ApartmentTabs.Photos}
              onClick={setTab}
              count={0}
            />
            <Tab
              value={ApartmentTabs.Attachments}
              text={t(ApartmentTabs.Attachments)}
              selected={tab === ApartmentTabs.Attachments}
              onClick={setTab}
              count={0}
            />
            <Tab
              value={ApartmentTabs.Reminders}
              text={t(ApartmentTabs.Reminders)}
              selected={tab === ApartmentTabs.Reminders}
              onClick={setTab}
              count={0}
            />
          </Tabs>
          {renderTabContent()}
          {renderLightbox()}
        </Box>
        <Box sx={styles.rightColumn}>
          {renderRightColumnContent()}
        </Box>
      </Box>
      {renderUpdateFileDialog()}
      {renderEntityDialog()}
      {renderActionConfirmation()}
    </Box>
  )
}

export default observer(Apartment)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '1rem 0',
    mb: '1rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflowY: 'hidden',
    overflowX: 'hidden',
    marginRight: {
      xl: '3rem',
      lgplus: '2.5rem',
      xs: '2rem'
    }
  },
  column: {
    flexDirection: 'column',
    width: '100%'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(50% - 1rem)',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem'
  },
  infoLabel: {
    fontSize: '1.125rem',
    fontWeight: 700,
    color: Colors.heading,
    textTransform: 'uppercase',
    mb: '0.5rem'
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontFamily: Fonts.body,
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
      transition: 'font-weight 0.3s ease-in-out'
    }
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: {
      lgplus: '2rem 2rem 0.5rem 2rem',
      xs: '1.625rem 1.5rem 0.125rem 1.5rem'
    },
    mb: '1.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  },
  subtitle: {
    alignSelf: 'flex-start',
    mb: '1.25rem',
    textTransform: 'uppercase'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem'
  },
} as const
