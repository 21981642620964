import { useState, MouseEvent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Backdrop from '@mui/material/Backdrop'
import ButtonBase from '@mui/material/ButtonBase'
import IconButton from './IconButton'
import DefaultPhoto from '../../Assets/Images/cover.png'
import ChatIcon from '../../Assets/Icons/chat.svg'
import LinkIcon from '../../Assets/Icons/link.svg'
import { Colors } from '../../Utils/theme'

export default function ProjectCard(props: any) {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const { project, isLast, layoutStyle } = props

  const DenseLayout = useMemo(() => layoutStyle === 'dense', [layoutStyle])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const onMenuClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const onMenuClose = () => setAnchorEl(null)

  const toEditProject = () => navigate(`${location.pathname}/${project?.uuid}/edit`)
  const onClick = () => props.onClick(project)

  const MenuOptions = [
    { text: t('edit_project'), onClick: toEditProject }
  ]
  
  const renderMenuItems = () => {
    return MenuOptions.map((option: any, index: number) => {
      return (
        <MenuItem key={index} onClick={() => option.onClick(project)}>
          {option.text || '-'}
        </MenuItem>
      )
    })
  }

  const renderMenuButton = () => {
    if (MenuOptions && !DenseLayout) {
      return (
        <>
          <IconButton
            icon='menu'
            onClick={onMenuClick}
            sx={styles.menuButton}
            iconSize='1.125rem'
          />
          <Backdrop sx={styles.backdrop} open={isMenuOpen} onClick={onMenuClose}>
            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={onMenuClose}
              // anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              {renderMenuItems()}
            </Menu>
          </Backdrop>
        </>
      )
    }
    return null
  }

  const renderConnector = () => {
    if (!props?.showConnector) {
      return null
    }
    if (DenseLayout) {
      return null
    }
    return (
      <Box sx={styles.connector}>
        <Box sx={styles.linkIcon} component='img' src={LinkIcon} alt='link' />
      </Box>
    )
  }

  const renderContent = () => {
    if (DenseLayout) {
      if (props?.isMultiYear && !props?.isFirst) {
        return (
          <ButtonBase
            sx={styles.denseConnectedContentContainer}
            onClick={onClick}
          />
        )
      }
      return (
        <ButtonBase
          sx={styles.denseContentContainer}
          onClick={onClick}
        >
          <Typography variant='body2' sx={styles.denseName}>
            {project?.nameFi}
          </Typography>
        </ButtonBase>
      )
    }
    return (
      <ButtonBase
        sx={styles.imageContainer}
        onClick={onClick}
      >
        <Box
          component='img'
          src={project?.backgroundPhoto || DefaultPhoto}
          sx={styles.image}
        />
        <Box sx={styles.footer}>
          <Typography variant='body2' sx={styles.name}>{project?.nameFi}</Typography>
          <Box sx={styles.row}>
            <Box component='img' src={ChatIcon} alt='kommentit' sx={styles.commentIcon} />
            <Typography variant='body2' sx={styles.comments}>
              {project?.comments?.length || 0}
            </Typography>
          </Box>
        </Box>
      </ButtonBase>
    )
  }

  if (!project) {
    return <Box sx={styles.placeholder} />
  }

  return (
    <Box
      sx={styles.container}
      mb={isLast ? 0 : '1.5rem'}
    >
      {renderContent()}
      {renderMenuButton()}
      {renderConnector()}
    </Box>
  )
}

const styles = {
  placeholder: {
    // minWidth: '18rem',
    width: '100%',
    height: '11rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.secondary10,
    overflow: 'hidden'
  },
  container: {
    position: 'relative',
    width: '100%'
  },
  imageContainer: {
    // minWidth: '18rem',
    width: '100%',
    height: '11rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.secondary10,
    overflow: 'hidden'
  },
  image: {
    width: '100%',
    height: '100%',
    maxHeight: '8.75rem',
    objectFit: 'cover',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: Colors.imageBackground,
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      opacity: 0.8
    }
  },
  name: {
    width: 'calc(100% - 3rem)',
    fontSize: '0.875rem',
    color: Colors.white,
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left'
  },
  menuButton: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    height: '2rem',
    width: '2rem'
  },
  backdrop: {
    backgroundColor: Colors.backdrop,
    zIndex: 1000
  },
  footer: {
    width: 'calc(100%)',
    backgroundColor: Colors.primary,
    color: Colors.white,
    padding: '0.325rem 0.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  commentIcon: {
    width: '1.125rem',
    height: '1.125rem'
  },
  comments: {
    fontSize: '1rem',
    fontWeight: 500,
    ml: '0.25rem'
  },
  connector: {
    position: 'absolute',
    top: '50%',
    right: '-2.25rem',
    transform: 'translateY(-50%)',
    backgroundColor: Colors.primary,
    borderRadius: '50%',
    padding: '0.75rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1
  },
  linkIcon: {
    width: '1.5rem',
    height: '1.5rem'
  },
  denseContentContainer: {
    // minWidth: '18rem',
    width: '100%',
    height: '4rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.primary,
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: Colors.primary90
    }
  },
  denseConnectedContentContainer: {
    // minWidth: '18rem',
    width: 'calc(100% + 1.5rem)',
    marginLeft: '-1.5rem',
    height: '4rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.secondary50,
    /*
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: Colors.primary
    }
    */
  },
  denseName: {
    fontSize: '1rem',
    color: Colors.white,
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left'
  },
  denseConnector: {
    position: 'absolute',
    top: '50%',
    right: '-1.5rem',
    transform: 'translateY(-50%)',
    backgroundColor: Colors.secondary50,
    minHeight: '4rem',
    minWidth: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1
  },
} as const
