import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Select from './Select'
import Button from './Button'
import Chip from './Chip'
import { Colors } from '../../Utils/theme'

export default function FilterPropertiesModal(props: any) {
  const { onClose, loading, filters, cityOptions, propertyManagerOptions } = props
  const { t } = useTranslation()

  const [city, setCity] = useState('')
  const [propertyManager, setPropertyManager] = useState('')
  // const [status, setStatus] = useState('')
  const [visibility, setVisibility] = useState('')

  useEffect(() => {
    if (filters) {
      setCity(filters?.city || '')
      setPropertyManager(filters?.propertyManager || '')
      // setStatus(filters?.status || '')
      setVisibility(filters?.visibility || '')
    }
  }, [filters])

  /*
  const getStatusOptions = () => [
    { value: '', label: t('all') },
    { value: 'active', label: t('only_active') },
    { value: 'archived', label: t('only_archived') }
  ]
  */

  const getVisibilityOptions = () => {
    return [
      { value: 'only_properties', label: t('only_properties') },
      { value: 'properties_and_buildings', label: t('properties_and_buildings') },
      { value: 'properties_and_buildings_and_apartments', label: t('properties_and_buildings_and_apartments') }
    ]
  }

  const getPropertyCount = () => {
    const newFilters: any = {}
    if (city) {
      newFilters.city = city
    }
    if (propertyManager) {
      newFilters.propertyManager = propertyManager
    }
    /*
    if (status) {
      newFilters.status = status
    }
    */
    if (visibility) {
      newFilters.visibility = visibility
    }
    return props?.getPropertyCount(newFilters)
  }

  const clearFilters = () => {
    setCity('')
    setPropertyManager('')
    // setStatus('')
    setVisibility('')
    props.clearFilters()
  }
  const saveFilters = () => {
    const newFilters: any = {}
    if (city) {
      newFilters.city = city
    }
    if (propertyManager) {
      newFilters.propertyManager = propertyManager
    }
    /*
    if (status) {
      newFilters.status = status
    }
    */
    if (visibility) {
      newFilters.visibility = visibility
    }
    props.updateFilters(newFilters)
  }

  return (
    <Dialog
      onClose={onClose}
      sx={styles.dialog}
      disableRestoreFocus
      open
    >
      <Chip
        onClick={onClose}
        sx={styles.closeButton}
        text={t('close')}
      />
      <DialogTitle variant='h2' sx={styles.title}>{t('filter_properties')}</DialogTitle>
      <DialogContent sx={styles.content}>
        <DialogContentText variant='body2' sx={styles.contentText}>
          {t('filter_properties_info')}
        </DialogContentText>
        <Select
          label={t('city')}
          options={cityOptions}
          value={city}
          onChange={setCity}
          mb='1.25rem'
        />
        {/*
          <Select
            label={t('state')}
            options={getStatusOptions()}
            value={status}
            onChange={setStatus}
            maxWidth='15rem'
          />
        */}
        {propertyManagerOptions?.length > 0 && (
          <Select
            label={t('property_manager')}
            options={propertyManagerOptions}
            value={propertyManager}
            onChange={setPropertyManager}
            mb='1.25rem'
          />
        )}
        <Select
          label={t('visible_on_list')}
          options={getVisibilityOptions()}
          value={visibility}
          onChange={setVisibility}
        />
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.textButton}
          text={t('clear')}
          onClick={clearFilters}
          variant='text'
        />
        <Button
          sx={styles.button}
          text={`${t('view')} (${getPropertyCount()})`}
          onClick={saveFilters}
          loading={loading}
          disabled={loading}
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '40rem',
      maxWidth: '40rem',
      borderRadius: '0.625rem',
      padding: '1.25rem 0.75rem'
    }
  },
  closeButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem'
  },
  title: {
    color: Colors.primary
  },
  content: {
  },
  contentText: {
    marginBottom: '1.5rem'
  },
  textButton: {
    textDecoration: 'underline',
    color: Colors.primary,
    fontWeight: 400,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  button: {
    pl: '2.5rem',
    pr: '2.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: '1rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    border: 0,
    marginTop: '0.5rem'
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  }
} as const
