import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import BackIcon from '../../Assets/Icons/arrow-left.svg'
import SortIcon from '../../Assets/Icons/sort.svg'
import CopyIcon from '../../Assets/Icons/copy.svg'
import PlayIcon from '../../Assets/Icons/play.svg'
import ChartIcon from '../../Assets/Icons/graph.svg'
import ImageIcon from '../../Assets/Icons/image.svg'
import { Colors } from '../../Utils/theme'

const IconMappings: any = {
  back: BackIcon,
  sort: SortIcon,
  copy: CopyIcon,
  play: PlayIcon,
  chart: ChartIcon,
  image: ImageIcon
}

const BaseButton = React.forwardRef((props: any, ref: any) => {
  const { text, sx, variant, onClick, loading } = props

  const getStyles = () => {
    let buttonStyles: any = { ...styles.button }
    if (props?.variant === 'secondary') {
      buttonStyles.backgroundColor = Colors.transparent
      buttonStyles.color = Colors.button
      buttonStyles.border = `3px solid ${Colors.button}`
    }
    if (props?.variant === 'text') {
      buttonStyles.backgroundColor = 'transparent'
      buttonStyles.color = Colors.text
    }
    if (sx) buttonStyles = { ...buttonStyles, ...sx }
    if (props.mb) buttonStyles.marginBottom = props.mb
    if (props.mt) buttonStyles.marginTop = props.mt
    if (props.ml) buttonStyles.marginLeft = props.ml
    if (props.mr) buttonStyles.marginRight = props.mr
    if (props.pl) buttonStyles.paddingLeft = props.pl
    if (props.pr) buttonStyles.paddingRight = props.pr
    if (props?.width) buttonStyles.width = props.width
    if (props?.height) buttonStyles.height = props.height
    return buttonStyles
  }

  const getIconStyle = () => {
    let iconStyles: any = { ...styles.icon }
    if (props?.iconRight) {
      iconStyles.marginLeft = '0.625rem'
      iconStyles.marginRight = 0
    }
    if (props.iconSx) iconStyles = { ...iconStyles, ...props.iconSx }
    return iconStyles
  }

  const renderContent = () => {
    if (loading) return <CircularProgress size='1.25rem' sx={styles.progress} />
    if (props?.icon || props?.iconRight) {
      if (props?.iconRight) {
        return (
          <>
            {text ?? null}
            <Box component='img' src={IconMappings[props.iconRight]} alt='icon' sx={getIconStyle()} />
          </>
        )
      }
      return (
        <>
          <Box component='img' src={IconMappings[props.icon]} alt='icon' sx={getIconStyle()} />
          {text ?? null}
        </>
      )
    }
    if (props.children) return props.children
    return text ?? null
  }

  return (
    <Button
      sx={getStyles()}
      variant={variant ?? 'contained'}
      onClick={onClick}
      disabled={loading || props?.disabled}
      ref={ref ?? null}
    >
      {renderContent()}
    </Button>
  )
})

export default BaseButton

const styles = {
  button: {
    padding: '0.75rem 1.75rem',
    boxShadow: 0,
    backgroundColor: Colors.button,
    '&:hover': {
      backgroundColor: Colors.buttonHover
    },
    ':hover': {
      boxShadow: 0
    },
    ':disabled': {
      backgroundColor: Colors.buttonDisabled,
      color: Colors.white
    }
  },
  progress: {
    color: Colors.white
  },
  icon: {
    width: '1.3125rem',
    height: 'auto',
    mr: '0.625rem'
  }
} as const