import React from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import { Colors } from '../../Utils/theme'

const SquareButton = React.forwardRef((props: any, ref: any) => {
  const { sx, onClick, loading } = props

  const getStyles = () => {
    let buttonStyles: any = { ...styles.button }

    if (sx) buttonStyles = { ...buttonStyles, ...sx }
    if (props?.mr) buttonStyles.mr = props.mr
    if (props?.ml) buttonStyles.ml = props.ml
    if (props?.mt) buttonStyles.mt = props.mt
    if (props?.size) {
      buttonStyles.height = props.size
      buttonStyles.width = props.size
    }
    if (props?.backgroundColor) {
      buttonStyles.backgroundColor = props.backgroundColor
    }
    if (props?.hoverColor) {
      buttonStyles[':hover'] = { backgroundColor: props.hoverColor }
    }

    return buttonStyles
  }

  const getIconStyle = () => {
    const iconStyles: any = { ...styles.icon }
    if (props?.iconSize) {
      iconStyles.height = props.iconSize
      iconStyles.width = 'auto'
    }
    return iconStyles
  }

  return (
    <ButtonBase
      sx={getStyles()}
      onClick={onClick}
      disabled={loading || props?.disabled}
      ref={ref ?? null}
      // {...rest}
    >
      {
        props?.icon ? (
          <Box component='img' src={props.icon} sx={getIconStyle()} />
        ) : props?.iconComponent ? (
          <props.iconComponent sx={getIconStyle()} />
        ) : null
      }
    </ButtonBase>
  )
})

export default SquareButton

const styles = {
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 0,
    minWidth: 0,
    height: '2.25rem',
    width: '2.25rem',
    borderRadius: '0.625rem',
    backgroundColor: Colors.primaryDarker,
    ':hover': {
      backgroundColor: Colors.primaryDarker,
      boxShadow: 0
    },
  },
  icon: {
    height: '1.375rem',
    width: 'auto',
    userSelect: 'none',
    userDrag: 'none',
    WebkitUserDrag: 'none',
    MozUserDrag: 'none',
  }
} as const
