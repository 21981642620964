import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Select from './Select'
import Button from './Button'
import Chip from './Chip'
import Input from './Input'
import { BuildingTypes } from '../../Utils/building'
import { Colors } from '../../Utils/theme'

export default function CreateBuildingModal(props: any) {
  const { onClose, loading, onSave } = props
  const { t } = useTranslation()

  const [nameFi, setNameFi] = useState('')
  const [type, setType] = useState('')
  const [typeInfo, setTypeInfo] = useState('')

  const BuildingTypeOptions = Object.values(BuildingTypes)
    .map((item: any) => ({ value: item, label: t(item) }))

  const createBuilding = () => onSave({ nameFi, type, typeInfo: typeInfo || undefined })

  return (
    <Dialog
      onClose={onClose}
      sx={styles.dialog}
      disableRestoreFocus
      open
    >
      <Chip
        onClick={onClose}
        sx={styles.closeButton}
        text={t('close')}
      />
      <DialogTitle variant='h2' sx={styles.title}>{t('add_building')}</DialogTitle>
      <DialogContent sx={styles.content}>
        <Input
          label={t('name')}
          value={nameFi}
          onChange={setNameFi}
          mb='1.25rem'
        />
        <Select
          label={t('building_type')}
          options={BuildingTypeOptions}
          value={type}
          onChange={setType}
          mb='1.25rem'
        />
        {type === BuildingTypes.Other && (
          <Input
            placeholder={t('enter_more_specific_description')}
            value={typeInfo}
            onChange={setTypeInfo}
            mb='1.25rem'
          />
        )}
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.outlinedButton}
          text={t('cancel')}
          onClick={onClose}
          variant='secondary'
        />
        <Button
          sx={styles.button}
          text={t('save')}
          onClick={createBuilding}
          loading={loading}
          disabled={loading || !nameFi || !type}
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '39rem',
      borderRadius: '0.625rem',
      padding: '1.25rem 0.75rem'
    }
  },
  closeButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem'
  },
  title: {
    color: Colors.primary
  },
  content: {
  },
  contentText: {
    marginBottom: '1.5rem'
  },
  outlinedButton: {
    fontWeight: 700,
    minWidth: '10rem',
    color: Colors.button,
    border: `0.0625rem solid ${Colors.button}`,
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    }
  },
  button: {
    pl: '2.5rem',
    pr: '2.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: '1rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'center',
    width: 'calc(100% - 1.5rem)',
    border: 0
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  }
} as const
