import { useState, MouseEvent } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Backdrop from '@mui/material/Backdrop'
import ButtonBase from '@mui/material/ButtonBase'
import PdfIcon from '../../Assets/Images/pdf.png'
import DocIcon from '../../Assets/Images/doc.png'
import ExcelIcon from '../../Assets/Images/xls.png'
import PowerpointIcon from '../../Assets/Images/ppt.png'
import TextIcon from '../../Assets/Images/txt.png'
import IconButton from './IconButton'
import { FileTypes } from '../../Utils/constants'
import { Colors } from '../../Utils/theme'

export default function File(props: any) {
  const { file, menuOptions, dense } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const onMenuClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const onMenuClose = () => setAnchorEl(null)

  const getIcon = () => {
    if (FileTypes.Image.includes(file?.mimeType)) {
      return file?.url
    } else if (file?.mimeType === FileTypes.Pdf) {
      return PdfIcon
    } else if (FileTypes.Doc.includes(file?.mimeType)) {
      return DocIcon
    } else if (FileTypes.Excel.includes(file?.mimeType)) {
      return ExcelIcon
    } else if (FileTypes.Powerpoint.includes(file?.mimeType)) {
      return PowerpointIcon
    } else if (file?.mimeType === FileTypes.Text) {
      return TextIcon
    }
    return null
  }

  const onFileClick = () => {
    if (file?.type === 'image') {
      return props.onFileClick(file)
    }
  }
  
  const renderMenuItems = () => {
    return menuOptions.map((option: any, index: number) => {
      return (
        <MenuItem key={index} onClick={() => option.onClick(file)}>
          {option.text || '-'}
        </MenuItem>
      )
    })
  }

  const renderMenuButton = () => {
    if (menuOptions) {
      return (
        <>
          <IconButton
            icon='menu'
            onClick={onMenuClick}
            sx={dense ? styles.denseMenuButton : styles.menuButton}
            iconSize={dense ? '1.125rem' : undefined}
          />
          <Backdrop sx={styles.backdrop} open={isMenuOpen} onClick={onMenuClose}>
            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={onMenuClose}
              // anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              {renderMenuItems()}
            </Menu>
          </Backdrop>
        </>
      )
    }
    return null
  }

  const renderFile = () => {
    if (FileTypes.Image.includes(file?.mimeType)) {
      return (
        <ButtonBase
          sx={!dense ? styles.imageContainer : styles.denseImageContainer}
          onClick={onFileClick}
        >
          <Box component='img' src={file?.url} sx={styles.image} />
        </ButtonBase>
      )
    }
    return (
      <Box sx={!dense ? styles.fileContainer : styles.denseFileContainer}>
        <Box component='img' src={getIcon()} sx={styles.icon} />
      </Box>
    )
  }

  return (
    <Box sx={styles.container}>
      {renderFile()}
      {
        !dense ? (
          <Typography variant='body2' sx={styles.fileName}>
            {file?.name || file?.originalName}
          </Typography>
         ) : null
        }
      {renderMenuButton()}
    </Box>
  )
}

const styles = {
  container: {
    position: 'relative'
  },
  imageContainer: {
    // minWidth: '18rem',
    width: '100%',
    height: '18rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.625rem',
    overflow: 'hidden'
  },
  denseImageContainer: {
    // minWidth: '18rem',
    width: '100%',
    height: '11rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.625rem',
    overflow: 'hidden'
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '0.625rem',
    backgroundColor: Colors.imageBackground,
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  fileContainer: {
    // minWidth: '18rem',
    height: '18rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.625rem',
    border: `3px dashed ${Colors.primary40}`,
    padding: '2rem'
  },
  denseFileContainer: {
    // minWidth: '18rem',
    height: '11rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.625rem',
    border: `3px dashed ${Colors.primary40}`,
    padding: '2rem'
  },
  icon: {
    height: '6rem',
    width: 'auto',
    userSelect: 'none',
    userDrag: 'none',
    WebkitUserDrag: 'none',
    MozUserDrag: 'none'
  },
  fileName: {
    fontSize: '1rem',
    color: Colors.heading,
    fontWeight: 500,
    mt: '1rem',
    textAlign: 'center'
  },
  menuButton: {
    position: 'absolute',
    top: '0.75rem',
    right: '0.75rem'
  },
  denseMenuButton: {
    position: 'absolute',
    top: '0.625rem',
    right: '0.625rem',
    height: '2.125rem',
    width: '2.125rem'
  },
  backdrop: {
    backgroundColor: Colors.backdrop,
    zIndex: 1000
  }
} as const
