import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import ArrowDown from '../../Assets/Icons/arrow-down-white.svg'
import { Colors } from '../../Utils/theme'

export default function CollapseSection(props: any) {
  const { title, open, onClick } = props

  return (
    <>
      <Box sx={styles.titleRow} mt='1rem'>
        <Typography
          variant='h5'
          color={Colors.white}
          fontWeight={700}
          textTransform='uppercase'
        >
          {title}
        </Typography>
        <ButtonBase onClick={onClick}>
          <Box
            component='img'
            src={ArrowDown}
            alt='arrow-down'
            sx={{ ...styles.arrowIcon, transform: `scaleY(${open ? 1 : -1})` }}
          />
        </ButtonBase>
      </Box>
      <Collapse in={open}>
        {props.children}
      </Collapse>
    </>
  )
}

const styles = {
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.primaryDarkest,
    borderRadius: '0.625rem',
    padding: '1rem 1.5rem',
    mb: '1.25rem'
  },
  arrowIcon: {
    width: '1.5rem',
    height: '1.5rem'
  }
} as const