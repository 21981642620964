import { useState, useCallback, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../Models/RootStore'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Lottie from 'lottie-react'
import constructionAnimation from '../../Assets/Animations/construction.json'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import ButtonBase from '@mui/material/ButtonBase'
import Sidebar from './Sidebar'
import Avatar from '@mui/material/Avatar'
import Backdrop from '@mui/material/Backdrop'
import { Button, Chip } from '../../Components'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Slide from '@mui/material/Slide'
import Fade from '@mui/material/Fade'
import logo from '../../Assets/Icons/logo.svg'
import { Menu } from '@emotion-icons/material-rounded'
import Typography from '@mui/material/Typography'
import { AvailablePrimaryRoutes, AvailableSecondaryRoutes } from '../../Utils/constants'
import styled from '@emotion/styled'
import DefaultProfilePhoto from '../../Assets/Images/user.png'
import SettingsIcon from '../../Assets/Icons/settings.svg'
import { Colors, Fonts } from '../../Utils/theme'
import NotificationItem from '../../Components/Common/NotificationItem'

const MenuIcon = styled(Menu)`
  color: ${Colors.heading};
  font-weight: 700;
`

function AppLayout(props: any) {
  const { t } = useTranslation()

  const currentLocation = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const {
    sessionStore,
    propertyStore,
    projectStore,
    notificationStore
  }: any = useStore()
  const { me, language } = sessionStore

  const scrollTrigger = useScrollTrigger({ threshold: 25 })

  const [open, setOpen] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)
  
  const toggleDrawer = () => setOpen(!open)
  const toggleNotifications = () => setShowNotifications(!showNotifications)

  const toProfile = () => navigate('/profile')
  const toCosts = () => navigate(`/properties/${propertyStore?.property?.uuid}/costs`)
  const toProjects = () => navigate(`/properties/${propertyStore?.property?.uuid}/projects`)
  const toCostSettings = () => navigate(`/properties/${propertyStore?.property?.uuid}/cost-settings`)
  const goBack = () => navigate(-1)

  useEffect(() => {
    if (showNotifications) {
      notificationStore.getNotifications()
      sessionStore.updateNotificationsLastOpenedAt()
    }
  }, [showNotifications])

  const ScreenTitle = useMemo(() => {
    if (me) {
      if (currentLocation?.pathname) {
        const routes = [
          ...AvailablePrimaryRoutes(me?.role),
          ...(AvailablePrimaryRoutes(me?.role).map((item: any) => item?.children ?? []).flat()),
          ...AvailableSecondaryRoutes(me?.role)
        ].flat()
        const title = routes.find((item: any) => item.url === currentLocation.pathname)?.title || ''
        if (title) {
          return t(title)
        }
      }
    }
    if (currentLocation.pathname === '/profile') {
      return t('profile')
    }
    if (currentLocation.pathname?.endsWith('/projects') || currentLocation.pathname?.endsWith('/costs')) {
      if (propertyStore?.property) {
        return propertyStore?.property?.nameFi
      }
    }
    return ''
  }, [currentLocation, me, language, propertyStore?.property])

  const getPrimaryNavigationItems = useCallback(() => {
    if (me) {
      const role = me?.role ?? null
      return AvailablePrimaryRoutes(role)
    }
    return []
  }, [me])

  const getSecondaryNavigationItems = useCallback(() => {
    if (me) {
      const role = me?.role ?? null
      return AvailableSecondaryRoutes(role)
    }
    return []
  }, [me])

  const NotificationItems = useMemo(() => {
    if (notificationStore?.notifications?.length) {
      return notificationStore.notifications.map((item: any) => (
        <NotificationItem key={item.uuid} notification={item} />
      ))
    }
    if (!notificationStore.loading) {
      return (
        <Typography variant='body1' sx={styles.noNotifications}>
          {t('no_notifications')}
        </Typography>
      )
    }
  }, [notificationStore?.notifications])

  const LoadMoreNotificationsButton = useMemo(() => {
    if (
      notificationStore?.notifications?.length &&
      notificationStore?.notifications?.length < notificationStore?.totalNotifications
    ) {
      return (
        <Button
          text={t('load_more')}
          onClick={notificationStore.getNotifications}
          sx={styles.loadMoreButton}
        />
      )
    }
    return null
  }, [notificationStore?.notifications, notificationStore?.totalNotifications])

  const renderScreenTitleOrAction = () => {
    if (!lgUp) {
      return <Box component='img' src={logo} alt='logo' sx={styles.logo} />
    }
    if (!ScreenTitle && !['/profile'].some((item) => currentLocation.pathname?.endsWith(item))) {
      return (
        <Button
          sx={styles.backButton}
          text={t('back')}
          onClick={goBack}
          icon='back'
          iconSx={styles.backButtonIcon}
        />
      )
    }
    /*
    if (currentLocation.pathname?.endsWith('/cost-settings')) {
      return (
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Button
            sx={styles.backButton}
            text={t('back')}
            onClick={goBack}
            icon='back'
            iconSx={styles.backButtonIcon}
          />
          <Box component='img' src={SettingsIcon} alt='ikoni' sx={styles.icon} />
          <Typography variant='h1'>{t('settings')}</Typography>
        </Box>
      )
    }
    */
    return <Typography variant='h1' fontWeight={800}>{ScreenTitle}</Typography>
  }

  const renderTabs = () => {
    if (currentLocation.pathname?.endsWith('/projects') || currentLocation.pathname?.endsWith('/costs')) {
      return (
        <Box sx={styles.tabs}>
          <ButtonBase
            onClick={toCosts}
            sx={projectStore?.tab === 'costs' ? styles.selectedTab : styles.tab}
          >
            {t('cost_development')}
          </ButtonBase>
          <ButtonBase
            onClick={toProjects}
            sx={projectStore?.tab === 'projects' ? styles.selectedTab : styles.tab}
          >
            {t('projects')}
          </ButtonBase>
        </Box>
      )
    }
  }

  const renderDateAndTime = () => {
    if (lgUp && !currentLocation.pathname?.endsWith('/costs') && sessionStore?.time) {
      return (
        <Typography sx={styles.time} variant='body1'>
          {sessionStore.time}
        </Typography>
      )
    }
    return null
  }

  const renderProfile = () => {
    if (lgUp && !currentLocation.pathname?.endsWith('/costs')) {
      return (
        <ButtonBase sx={styles.profileButton} onClick={toProfile}>
          <Avatar
            sx={styles.avatar}
            src={me?.profilePhoto || DefaultProfilePhoto}
            alt='photo'
          />
        </ButtonBase>
      )
    }
    return null
  }
  
  const renderSettings = () => {
    if (lgUp && currentLocation.pathname?.endsWith('/costs')) {
      return (
        <ButtonBase onClick={toCostSettings}>
          <Box component='img' src={SettingsIcon} alt='ikoni' sx={styles.icon} />
        </ButtonBase>
      )
    }
    return null
  }

  const renderMenu = () => {
    if (!lgUp) {
      return (
        <ButtonBase sx={styles.menuButton} onClick={toggleDrawer}>
          <MenuIcon size='2.5rem' />
        </ButtonBase>
      )
    }
    return null
  }

  if (!me) {
    return null
  }

  if (!lgUp) {
    return (
      <Box sx={styles.underConstruction}>
        <Typography variant='h3' sx={styles.underConstructionTitle}>
          {t('mobile_ui_under_construction_title')}
        </Typography>
        <Typography variant='body1' sx={styles.underConstructionInfo}>
          {t('mobile_ui_under_construction_info')}
        </Typography>
        <Lottie
          animationData={constructionAnimation}
          style={styles.underConstructionAnimation}
        />
      </Box>
    )
  }

  return (
    <Box sx={styles.root}>
      <Box sx={styles.sidebarContainer}>
        <Sidebar
          open={open}
          toggleDrawer={toggleDrawer}
          toggleNotifications={toggleNotifications}
          primaryNavigationItems={getPrimaryNavigationItems()}
          secondaryNavigationItems={getSecondaryNavigationItems()}
          me={me}
          permanentDrawer={lgUp}
        />
      </Box>
      <Slide appear={false} direction='down' in={!scrollTrigger}>
        <AppBar sx={styles.appBar} elevation={0}>
          <Toolbar sx={styles.toolbar} disableGutters>
            {renderScreenTitleOrAction()}
            {renderTabs()}
            <Box sx={styles.actions}>
              {renderDateAndTime()}
              {renderProfile()}
              {renderSettings()}
              {renderMenu()}
            </Box>
          </Toolbar>
        </AppBar>
      </Slide>
      <Box component='main' sx={styles.content}>
        {props.children}
      </Box>
      <Fade in={showNotifications}>
        <Backdrop
          sx={styles.backdrop}
          open={showNotifications}
          onClick={toggleNotifications}
        >
          <Box sx={styles.notificationsContainer}>
            <Box sx={styles.notificationsHeader}>
              <Typography variant='h2'>{t('notifications')}</Typography>
              <Chip sx={styles.closeButton} text={t('close')} onClick={toggleNotifications} />
            </Box>
            <Box sx={styles.notifications}>
              {NotificationItems}
              {LoadMoreNotificationsButton}
            </Box>
          </Box>
        </Backdrop>
      </Fade>
    </Box>
  )
}

export default observer(AppLayout)

const styles = {
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    height: '100vh',
    backgroundColor: '#FFFFFF'
  },
  sidebarContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 999
  },
  appBar: {
    zIndex: 997,
    alignItems: 'center',
    height: {
      xs: '6rem'
    },
    width: {
      xxxl: 'calc(100% - 20rem)',
      // lg: 'calc(100% - 20rem)',
      lgplus: 'calc(100% - 17rem)',
      lg: 'calc(100% - 16rem)',
      xs: '100%'
    },
    ml: {
      xxxl: '20rem',
      // lg: 'calc(100% - 20rem)',
      lgplus: '17rem',
      lg: '16rem',
      xs: 0
    },
    pl: {
      xxxl: '4rem',
      xl: '3rem',
      lgplus: '2.5rem',
      xs: '2rem'
    },
    pr: {
      xxxl: '4rem',
      xl: '3rem',
      lgplus: '2.5rem',
      xs: '2rem'
    }
  },
  contentContainer: {
    flexDirection: 'column'
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logo: {
    width: '10rem',
    height: 'auto'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  time: {
    // width: '10.825rem',
    width: '9.75rem',
    color: Colors.text,
    fontWeight: 700
  },
  profileButton: {
  },
  button: {
    borderRadius: '50%',
    mr: {
      sm: '2rem',
      xs: '1.5rem'
    }
  },
  avatar: {
    width: '2.5rem',
    height: '2.5rem'
  },
  profileColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    ml: '1rem'
  },
  name: {
    fontWeight: 700
  },
  role: {
    color: Colors.text,
    fontWeight: 400
  },
  menuButton: {
    height: '3rem',
    width: '3rem',
    backgroundColor: Colors.primary,
    borderRadius: '0.625rem'
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    width: {
      xxxl: 'calc(100% - 20rem)',
      // lg: 'calc(100% - 20rem)',
      lgplus: 'calc(100% - 17rem)',
      lg: 'calc(100% - 16rem)',
      xs: '100%'
    },
    ml: {
      xxxl: '20rem',
      // lg: 'calc(100% - 20rem)',
      lgplus: '17rem',
      lg: '16rem',
      xs: 0
    },
    pt: {
      xs: '6.5rem'
    },
    pl: {
      xxxl: '4rem',
      xl: '3rem',
      lgplus: '2.5rem',
      xs: '2rem'
    },
    pr: {
      xxxl: '4rem',
      xl: '3rem',
      lgplus: '2.5rem',
      xs: '2rem'
    }
  },
  backdrop: {
    zIndex: 998,
    justifyContent: 'flex-start',
    ml: {
      xxxl: '20rem',
      // lg: 'calc(100% - 20rem)',
      lg: '17rem',
      xs: 0
    }
  },
  notificationsContainer: {
    position: 'relative',
    height: '100%',
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: Colors.white,
    display: 'flex',
    flexDirection: 'column',
    alingItems: 'center',
    justifyContent: 'center',
    maxWidth: {
      xs: '33vw'
    },
  },
  notificationsHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: {
      xs: '1.5rem 2rem'
    },
    boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.1)'
  },
  notifications: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 'calc(100% - 5rem)',
    overflow: 'auto',
    padding: {
      xs: '1.5rem 2rem 3rem 2rem'
    },
  },
  closeButton: {
    height: '2rem',
    fontSize: '1rem'
  },
  backButton: {
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    padding: '0.75rem 1.375rem',
  },
  backButtonIcon: {
    width: '0.875rem',
    height: '0.875rrem',
    marginRight: '0.4375rem'
  },
  underConstruction: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100vh',
    p: '2rem'
  },
  underConstructionTitle: {
    textAlign: 'center',
    mb: '1.5rem'
  },
  underConstructionInfo: {
    fontSize: 'rem',
    color: Colors.text,
    textAlign: 'center',
  },
  underConstructionAnimation: {
    width: '70vw',
    height: 'auto',
    paddingLeft: '10vw'
  },
  tabs: {
    width: '27rem',
    height: '3.5rem',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Colors.secondary10,
    color: Colors.black,
    borderRadius: '0.625rem',
    padding: '0.625rem'
  },
  tab: {
    width: '12.5rem',
    height: '100%',
    fontFamily: Fonts.body,
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.inactive,
    textTransform: 'uppercase',
    transition: 'background-color 0.3s',
    padding: '0 1rem',
    borderRadius: '0.625rem',
    '&:hover': {
      color: Colors.primary,
      backgroundColor: Colors.secondary20
    },
  },
  selectedTab: {
    width: '12.5rem',
    height: '100%',
    fontFamily: Fonts.body,
    fontSize: '1rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    transition: 'background-color 0.3s',
    padding: '0 1rem',
    backgroundColor: Colors.primaryDarkest,
    color: Colors.white,
    borderRadius: '0.625rem',
    '&:hover': {
      backgroundColor: Colors.primary90
    }
  },
  icon: {
    width: '2rem',
    height: 'auto',
    marginRight: '1rem'
  },
  noNotifications: {
    fontSize: '1.125rem',
    fontWeight: 700,
    color: Colors.text,
    textAlign: 'center',
    mt: 'calc(50vh - 7.0625rem)'
  },
  loadMoreButton: {
    // position: 'absolute',
    // bottom: '1.125rem',
    width: '13rem',
    alignSelf: 'center',
    boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.1)',
    mt: '2rem'
  }
} as const