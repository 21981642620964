import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Select from './Select'
import MultiSelect from './MultiSelect'
import Button from './Button'
import Chip from './Chip'
import { UserRoles, UserStatuses } from '../../Utils/constants'
import { Colors } from '../../Utils/theme'

export default function FilterUsersModal(props: any) {
  const { onClose, loading, filters, viewerRole, propertyOptions } = props
  const { t } = useTranslation()

  const [role, setRole] = useState('')
  const [status, setStatus] = useState('')
  const [properties, setProperties] = useState([])

  useEffect(() => {
    if (filters) {
      setRole(filters?.role || '')
      setStatus(filters?.status || '')
      setProperties(filters?.properties || [])
    }
  }, [filters])

  const getRoleOptions = () => {
    if (viewerRole === UserRoles.Admin) {
      return [
        { value: '', label: t('all') },
        { value: UserRoles.Admin, label: t(UserRoles.Admin) },
        { value: UserRoles.PropertyManager, label: t(UserRoles.PropertyManager) },
        // { value: UserRoles.Shareholder, label: t(UserRoles.Shareholder) }
      ]
    } else if (viewerRole === UserRoles.PropertyManager) {
      return [
        { value: '', label: t('all') },
        // { value: UserRoles.Shareholder, label: t(UserRoles.Shareholder) }
      ]
    }
    return []
  }

  const getStatusOptions = () => [
    { value: UserStatuses.Active, label: t(UserStatuses.Active) },
    { value: UserStatuses.Inactive, label: t(UserStatuses.Inactive) },
    { value: UserStatuses.Archived, label: t(UserStatuses.Archived) }
  ]

  const getUserCount = () => {
    const newFilters: any = {}
    if (role) {
      newFilters.role = role
    }
    if (status) {
      newFilters.status = status
    }
    if (properties?.length) {
      newFilters.properties = properties
    }
    return props?.getUserCount(newFilters)
  }

  const clearFilters = () => {
    setRole('')
    setStatus('')
    props.clearFilters()
  }
  const saveFilters = () => {
    const newFilters: any = {}
    if (role) {
      newFilters.role = role
    }
    if (status) {
      newFilters.status = status
    }
    if (properties?.length) {
      newFilters.properties = properties
    }
    props.updateFilters(newFilters)
  }

  return (
    <Dialog
      onClose={onClose}
      sx={styles.dialog}
      disableRestoreFocus
      open
    >
      <Chip onClick={onClose} sx={styles.closeButton} text={t('close')} />
      <DialogTitle variant='h2' sx={styles.title}>{t('filter_users')}</DialogTitle>
      <DialogContent sx={styles.content}>
        <DialogContentText variant='body2' sx={styles.contentText}>
          {t('filter_users_info')}
        </DialogContentText>
        <Box sx={styles.row}>
          <Select
            label={t('user_level')}
            options={getRoleOptions()}
            value={role}
            onChange={setRole}
            mr='2rem'
          />
          <Select
            label={t('state')}
            options={getStatusOptions()}
            value={status}
            onChange={setStatus}
          />
        </Box>
        <MultiSelect
          label={t('properties')}
          options={propertyOptions}
          value={properties}
          onChange={setProperties}
          mb='1.5rem'
        />
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.textButton}
          text={t('clear')}
          onClick={clearFilters}
          variant='text'
        />
        <Button
          sx={styles.button}
          text={`${t('view')} (${getUserCount()})`}
          onClick={saveFilters}
          loading={loading}
          disabled={loading}
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '39rem',
      borderRadius: '0.625rem',
      padding: '1.25rem 0.75rem'
    }
  },
  closeButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem'
  },
  title: {
    color: Colors.primary
  },
  content: {
  },
  contentText: {
    marginBottom: '1.5rem'
  },
  textButton: {
    textDecoration: 'underline',
    color: Colors.primary,
    fontWeight: 400,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  button: {
    pl: '2.5rem',
    pr: '2.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: '1rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    border: 0,
    marginTop: '0.5rem'
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  }
} as const
